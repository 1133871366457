<script>
export default {
  data() {
    return {
      appId: null,
      openAppId: null,
      principalName: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    validate() {
      if (!this.appId) {
        this.$rs_warning("请选择要绑定开放平台的公众号");
        return false;
      }
      if (!this.openAppId) {
        this.$rs_warning("请输入开放平台AppId");
        return false;
      }
      if (this.openAppId.length < 5) {
        this.$rs_warning("开放平台AppId最小长度为5");
        return false;
      }
      return true;
    },
  },
  render() {
    const conditions = [
      { key: "appType", vals: ["mp"] },
      { key: "status", vals: [1] },
    ];

    const conf = {
      props: {
        model: "wechat-app",
        placeholder: "请选择公众号",
        outlined: true,
        keyLabel: "nickName",
        keyValue: "id",
        simpleMode: true,
        conditions,
        fields: ["principalName"],
      },
    };
    return (
      <div>
        <q-item>
          <q-item-section class="col-auto" style="width:8em">
            公众号
          </q-item-section>
          <q-item-section>
            <rs-select
              class="rs-size-sm"
              v-model={this.appId}
              on-input={(e, itm) => {
                this.principalName = itm.principalName;
              }}
              {...conf}></rs-select>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section class="col-auto" style="width:8em">
            开放平台AppId
          </q-item-section>
          <q-item-section>
            <rs-input
              class="rs-size-sm"
              placeholder="请输入开放平台AppId"
              outlined
              dense
              v-model={this.openAppId}
              maxlength={64}
            />
          </q-item-section>
        </q-item>
      </div>
    );
  },
};
</script>
