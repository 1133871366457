<template>
  <rs-list name="user" keywords="nickName,mobile,email" :filter-placeholder="$t_settings('user-filterPlaceholder')">
    <rs-search label-width="5em" :value="{ status: 1 }">
      <rs-filter type="keywords" name="nickName,mobile,email"></rs-filter>
      <rs-filter name="id"></rs-filter>
      <rs-filter name="userName"></rs-filter>
      <rs-filter name="nickName"></rs-filter>
      <rs-filter name="mobile"></rs-filter>
      <rs-filter name="email"></rs-filter>
      <rs-filter name="status"></rs-filter>
      <rs-filter name="beginDate" front></rs-filter>
      <rs-filter name="endDate" front></rs-filter>
      <rs-filter name="language"></rs-filter>
      <rs-filter name="adminUser"></rs-filter>
      <rs-filter name="comp"></rs-filter>
      <rs-filter name="roles"></rs-filter>
      <rs-filter name="crtTime"></rs-filter>
      <rs-filter name="crtUser"></rs-filter>
      <rs-filter name="recTime"></rs-filter>
      <rs-filter name="recUser"></rs-filter>
    </rs-search>
    <rs-field name="id"></rs-field>
    <rs-field name="userName"></rs-field>
    <rs-field name="nickName"></rs-field>
    <rs-field name="empNo"></rs-field>
    <rs-field name="mobile"></rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="beginDate"></rs-field>
    <rs-field name="endDate"></rs-field>
    <rs-field name="language"></rs-field>
    <rs-field name="email"></rs-field>
    <rs-field name="adminUser"></rs-field>
    <rs-field name="comp.name"></rs-field>
    <rs-field hidden name="lastLoginTime"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field type="toolbar">
      <rs-button name="edit" action="settings.account._edit"></rs-button>
    </rs-field>
    <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
      <rs-button name="add" color="primary" action="settings.account._add"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      // isSub: this.$rs_user.isSub,
      // platform: null, // 选择的平台
      employee: null, // 选择的员工
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onUserDeleteBeforeConfirm(e, params) {
      if (params.find((d) => !!d.lastLoginTime)) {
        e.preventDefault();
        this.$rs_warning(this.$t_settings("account-deleteLoginTime"));
      } else if (params.find((d) => d.id === this.$rs_user.uid)) {
        e.preventDefault();
        this.$rs_warning(this.$t_settings("account-deleteNotSelf"));
      } else if (params.find((d) => d.adminUser)) {
        e.preventDefault();
        this.$rs_warning(this.$t_settings("account-deleteNotAdminUser"));
      }
    },
    onUserDeleteAllBeforeConfirm(e, params) {
      this.onUserDeleteBeforeConfirm(e, params);
    },
    // onUserAddBeforeClick(e) {
    //   e.preventDefault();
    //   this.selectEmployeeDialog(e);
    //   // if (!this.isSub) {
    //   //   this.$rs_model_dlg(
    //   //     { modelName: "platform" },
    //   //     {
    //   //       title: "选择-登陆平台",
    //   //     },
    //   //   ).then(({ selected, dlg }) => {
    //   //     if (selected[0]) {
    //   //       this.platform = selected[0];
    //   //       dlg.hide();
    //   //       this.selectEmployeeDialog(e);
    //   //     }
    //   //   });
    //   // } else {
    //   //   this.selectEmployeeDialog(e);
    //   // }
    // },
    // selectEmployeeDialog(e) {
    //   this.$rs_model_dlg(
    //     { modelName: "employee", conditions: [{ key: "comp.plat.id", vals: [this.$rs_user.platform] }] },
    //     {
    //       title: "选择-员工",
    //     },
    //   ).then(({ selected, dlg }) => {
    //     if (selected[0]) {
    //       this.employee = selected[0];
    //       dlg.hide();
    //       // this.selectTemplateUserDialog(e);
    //       const query = {
    //         empId: this.employee.id,
    //         // templateId: selected[0].id,
    //       };
    //       // if (this.platform) {
    //       //   query.platformId = this.platform.id;
    //       //   query.platformName = this.platform.name;
    //       // }
    //       e.next({ actionPayload: { query } });
    //     }
    //   });
    // },
    // selectTemplateUserDialog(e) {
    //   this.$rs_model_dlg(
    //     { modelName: "template-user", modelParams: { relation: "id,name" }, fields: ["id", "name"] },
    //     {
    //       title: "选择-账户模板",
    //       listConfig: { returnAllAttrs: true },
    //     },
    //   ).then(({ selected, dlg }) => {
    //     if (selected[0]) {
    //       dlg.hide();
    //       const query = {
    //         empId: this.employee.id,
    //         templateId: selected[0].id,
    //       };
    //       if (this.platform) {
    //         query.platformId = this.platform.id;
    //         query.platformName = this.platform.name;
    //       }
    //       e.next({ actionPayload: { query } });
    //     }
    //   });
    // },
  },
};
</script>
