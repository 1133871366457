<template>
  <div class="full-height rs-page-company-list">
    <rs-list
      ref="companyList"
      name="company"
      keywords="name,id"
      :filter-placeholder="$t_mdata('company-filterPlaceholder')"
    >
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="crtUser"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete', 'editDept', 'changeStatus']" v-slot="row">
        <rs-button
          :label="$t_mdata('companyList-editLabel')"
          color="secondary"
          name="editDept"
          icon="arrow_forward"
          action="settings.company._edit"
        ></rs-button>
        <rs-button
          name="changeStatus"
          :icon="row.status ? 'lock' : 'lock_open'"
          :label="row.status ? $t_s('disable') : $t_s('enable')"
        />
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary"></rs-button>
        <!-- <rs-button name="edit" action="mdata.company_form"></rs-button> -->
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      editRow: null,
    };
  },
  computed: {},
  methods: {
    onCompanyChangeStatus(e, params) {
      const changeVal = params.status ? 0 : 1;
      const done = () => {
        this.$refs.companyList.modelService.setEnable([params.id], changeVal, "company").then(() => {
          const row = this.$refs.companyList.innerList.find((d) => d.id === params.id);
          row.status = changeVal;
        });
      };
      if (changeVal === 0) {
        this.$q
          .dialog({
            title: "提示!",
            message: this.$t_settings("company-unableStatusTip"),
            persistent: true,
            cancel: true,
          })
          .onOk(() => {
            done();
          });
      } else {
        done();
      }
    },
    onCompanyAdd(e) {
      e.preventDefault();
      this.editRow = null;
      this.openDialog();
    },
    onCompanyEdit(e, selected) {
      e.preventDefault();
      this.editRow = selected;
      this.openDialog();
    },
    // 编辑时 给form传入 编辑的id
    beforeRequest() {
      return { params: { id: this.editRow ? this.editRow.id : null } };
    },
    openDialog() {
      const dlg = this.$rs_dlg({
        parent: this,
        title: `${this.$t_s(this.editRow ? "edit" : "new")}${this.$t_mdata("company")}`,
        cardStyle: "width: 700px",
        content: (
          <rs-form
            ref="company"
            isUpdateMode={!!this.editRow}
            name="company"
            col="1"
            label-width="6.5em"
            class="col-auto">
            <rs-field name="id" readonly={false} />
            <rs-field name="name" />
            <rs-field name="status" value={1} disable={!!this.editRow} />
          </rs-form>
        ),
      }).onOk(() => {
        this.$refs.company.core.validate().then((r) => {
          if (!r) {
            this.$refs.company.modelService[this.editRow ? "update" : "create"](this.$refs.company.core.value).then(
              (res) => {
                this.$refs.companyList.refreshTable(true);
                this.editRow = null;
                dlg.hide();
                if (this.editRow) {
                  this.$rs_success(this.$t_s("edit-success"));
                } else {
                  this.$rs_success(this.$t_s("add-success"));
                  this.$rs_action("settings.company._edit", { query: { id: res.id } });
                }
              },
            );
          }
        });
      });
    },
  },
};
</script>
