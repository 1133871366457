<template>
  <div class="q-px-md q-gutter-sm">
    <section class="  ">
      <div class="label full-width q-py-sm">
        <span class="text-bold ">签名名称</span>
        <span class="text-red text-bold">*</span>
      </div>
      <q-input v-model="signature" dense outlined class="rs-size-sm flex-grow full-width"></q-input>
      <div class="full-width q-py-sm">
        <span class="text-red text-bold">*</span>
        <span class="text-caption text-grey">请事先在阿里云后台配置好签名，并在审核通过之后填在此处</span>
      </div>
    </section>
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";

export default {
  mixins: [RsThirdApiMixins],
  props: ["tenUser", "name"],
  data() {
    return { signature: "" };
  },
  created() {
    this.signature = this.name;
  },
  methods: {
    submit() {
      if (!this.signature) {
        this.$rs_error("请填写签名!");
        return Promise.reject();
      }
      const url = `/admin/sms/signature/${this.name ? "update" : "add"}`;
      const p = {
        updator: this.$rs_user.nickName,
        creator: this.$rs_user.nickName,
        appId: this.tenUser.appId,
        isEnable: 1,
        name: this.signature,
      };
      this.name && delete p.creator;
      return this.post(url, p);
    },
  },
};
</script>
<style scoped>
.label {
  width: 7em;
}
</style>
