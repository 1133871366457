<template>
  <div class="q-pa-sm full-height channel-list">
    <div class="full-height bg-white column">
      <q-tabs v-model="tab" dense align="left" class="text-primary col-auto" @input="tabChange">
        <q-tab name="info" :label="$t_mdata('label-orgChannelInfo')" />
        <!-- <q-tab name="ctrl" :label="$t_mdata('label-orgChannelCtrl')" /> -->
        <q-tab name="tree" :label="$t_mdata('label-orgChannelTree')" />
      </q-tabs>
      <q-separator />
      <q-tab-panels v-model="tab" animated class="col">
        <q-tab-panel name="info">
          <div class="text-orange q-ma-md text-left">
            {{ $t_mdata("channelList-info") }}
          </div>
          <q-img
            @click="imgHover = !imgHover"
            :style="imgStyle"
            :contain="true"
            :src="require('../../../assets/channel.svg')"
          />
          <rs-list
            ref="list"
            name="retail-channel"
            keywords="name,id"
            :filter-placeholder="$t_mdata('retailChannel-filterPlaceholder')"
          >
            <rs-search label-width="5em" :value="{ status: 1 }">
              <rs-filter type="keywords" name="name,id"></rs-filter>
              <rs-filter name="id"></rs-filter>
              <rs-filter name="name"></rs-filter>
              <rs-filter name="bset" front></rs-filter>
              <rs-filter name="brands"></rs-filter>
              <rs-filter name="status"></rs-filter>
              <rs-filter name="recTime"></rs-filter>
              <rs-filter name="recUser"></rs-filter>
            </rs-search>
            <rs-field name="id"></rs-field>
            <rs-field name="name"></rs-field>
            <!-- <rs-field name="info"></rs-field> -->
            <rs-field name="status"></rs-field>
            <rs-field name="bset.name"></rs-field>
            <rs-field name="brands.name" v-slot="props">
              {{ props.brands ? props.brands.map((d) => d.name).join() : "" }}
            </rs-field>
            <rs-field name="recTime"></rs-field>
            <rs-field name="recUser"></rs-field>
            <rs-field type="toolbar">
              <rs-button name="edit" action="mdata.channel._edit"></rs-button>
            </rs-field>
            <rs-toolbar>
              <rs-button name="add" color="primary" action="mdata.channel._add"></rs-button>
            </rs-toolbar>
          </rs-list>
        </q-tab-panel>

        <!-- <q-tab-panel name="ctrl">
          <q-card flat>
            <q-card-section>
              <div class="text-subtitle2">多渠道间的业务策略</div>
            </q-card-section>
            <q-separator />
            <q-card-actions vertical>
              <rs-form>
                <rs-field name="id"></rs-field>
                <rs-field name="name"></rs-field>
                <rs-field name="info"></rs-field>
              </rs-form>
            </q-card-actions>
          </q-card>
        </q-tab-panel> -->
        <q-tab-panel name="tree">
          <q-splitter v-model="splitterModel" :limits="[30, 50]" class="full-height" separator-style="width: 2px">
            <template v-slot:before>
              <rs-tree-picker
                ref="tree"
                tickStrategy="none"
                :data="treeData"
                node-key="id"
                label-key="name"
                @selectedChange="selectedChange"
              >
                <template slot="toolbar">
                  <q-space />
                  <q-btn size="xs" icon="refresh" @click="refreshTree" />
                </template>
              </rs-tree-picker>
            </template>
            <template v-slot:after>
              <div>
                <rs-list name="site" :conditions="treeListCond">
                  <rs-search label-width="5em">
                    <rs-filter type="keywords" name="name,id"></rs-filter>
                    <rs-filter name="id"></rs-filter>
                    <rs-filter name="name"></rs-filter>
                    <rs-filter name="type"></rs-filter>
                    <rs-filter name="status"></rs-filter>
                  </rs-search>
                  <rs-field name="id"></rs-field>
                  <rs-field name="name"></rs-field>
                  <rs-field name="type"></rs-field>
                  <rs-field name="bset.name"></rs-field>
                  <rs-field name="status"></rs-field>
                </rs-list>
              </div>
            </template>
          </q-splitter>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { list2tree } from "rsui-core/src/core/util/list2tree";
import RsTreePicker from "rsui-core/src/system/components/tree-picker";
export default {
  components: { RsTreePicker },
  data: function() {
    return {
      tab: "info",
      splitterModel: 30,
      imgHover: false,
      selelctedNode: null,
      treeData: [],
      bizSubjectService: new ModelService(),
    };
  },
  computed: {
    imgStyle: function() {
      return `transition:all ease .3s;display:block;margin:auto;${this.imgHover ? "width:100%;" : "width:50%;"}${
        this.imgHover ? "cursor:zoom-out;" : "cursor:zoom-in;"
      }`;
    },
    treeListCond: function() {
      if (this.selelctedNode) {
        return [
          { key: "bset.id", vals: [this.selelctedNode.bset.id] },
          { key: "channels.channel.id", vals: [this.selelctedNode.channel.id] },
        ];
      }
      return [];
    },
  },
  methods: {
    tabChange(e) {
      if (e === "tree" && this.treeData.length === 0) {
        this.refreshTree();
      }
    },
    beforeQuery(e, params, eventId) {
      if (eventId.model === "site" && this.selelctedNode == null) {
        e.preventDefault();
      }
    },
    refreshTree() {
      this.bizSubjectService.allListByModel({}, "biz-subject").then(({ list }) => {
        const result = (list || []).map((d) => {
          return {
            ...d,
            _id: `${d.channel.id}|${d.bset.id}`,
            _pid: d.pset ? `${d.channel.id}|${d.pset.id}` : null,
          };
        });
        this.treeData = list2tree(result, "_id", "_pid");
      });
    },
    selectedChange(node) {
      this.selelctedNode = node;
    },
  },
};
</script>
