<template>
  <I18n name="ten-translation" title="" :clearCache="false" :moduleProps="moduleProps" />
</template>
<script>
import I18n from "../../i18n-setting";
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: { I18n },
  data: function() {
    return {
      moduleProps: {},
      tenModuleService: new ModelService(),
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.tenModuleService.list({ fields: ["id", "name"] }, "ten-module").then((resp) => {
      if (resp && resp.list) {
        this.moduleProps = {
          data: [{ id: "_system_", name: "_system_" }, ...resp.list],
        };
      }
    });
  },
  methods: {},
};
</script>
