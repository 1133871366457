var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-sm full-height ops-ten-service-version-form column"},[_c('rs-form',{ref:"form",staticClass:"col-auto full-width",attrs:{"name":"ten-service-version","value":{ compatibility: true }},on:{"change":_vm.onChange}},[_c('rs-field',{attrs:{"name":"id","hidden":""}}),_c('rs-field',{attrs:{"name":"service","widget":{
        returnAllAttrs: true,
        fields: [
          'id',
          'name',
          'lastVersion',
          'dbless',
          { props: { name: 'gitServer.id', hidden: true } },
          'gitProject' ],
      }}}),_c('rs-field',{attrs:{"name":"service.dbless","hidden":""}}),_c('rs-field',{attrs:{"name":"service.gitServer.id","hidden":""}}),_c('rs-field',{attrs:{"name":"service.gitProject","hidden":""}}),_c('rs-field',{attrs:{"name":"versionType"}}),_c('rs-field',{attrs:{"name":"version"}}),_c('rs-field',{attrs:{"name":"fixVersion","required":_vm.isHotfix,"hidden":!_vm.isHotfix}}),_c('rs-field',{attrs:{"name":"gitBranch","required":_vm.isGitEnable,"hidden":!_vm.isGitEnable}}),_c('rs-field',{attrs:{"name":"compatibility"}}),_c('rs-field',{attrs:{"name":"deployStatus","readonly":""}}),_c('rs-field',{attrs:{"name":"onlineTime","readonly":""}}),_c('rs-field',{attrs:{"name":"offlineTime","readonly":""}}),_c('rs-field',{attrs:{"name":"info","col":"12","widget":{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }}}),_c('rs-toolbar',[_c('rs-button',{attrs:{"name":"cancel","action":"ops.serviceVersion._view"}}),_c('rs-button',{attrs:{"name":"datas","label":"查看版本数据","hidden":!_vm.id}}),_c('rs-button',{attrs:{"name":"refetch","label":"重新获取版本数据","hidden":!_vm.id || _vm.hasApproved || _vm.refetched || !_vm.isGitEnable}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }