<script>
import Config from "rsui-core/src/config";
export default {
  methods: {
    post(url, params) {
      return this.$rs_post(Config.API.SMS_RUNSA_URL + url, {
        ...params,
      }).then((r) => {
        if (r && r.content) {
          //
        }
      });
    },
    get(url, { page, size, ...rest } = {}) {
      return this.$rs_get(Config.API.SMS_RUNSA_URL + url, {
        page,
        pageSize: size,
        ...rest,
      }).then((r) => {
        if (r && r.content) {
          const { count, ...rest } = r.content;
          return {
            ...rest,
            total: count,
          };
        }
      });
    },
    getInfo(url, params) {
      return this.$rs_get(Config.API.SMS_RUNSA_URL + url, params).then((resp) => {
        if (resp && resp.code === 0) {
          return resp.content;
        }
      });
    },
    dealList({ url, data }, e, params) {
      const table = e.context;
      const [listParam] = params;
      const { ordeby, page, size, conditions } = listParam;
      let keywords = "";
      if (Array.isArray(conditions)) {
        try {
          const cond = conditions.find((i) => i.or);
          const firstCond = cond.or[0];
          keywords = firstCond && firstCond.vals && firstCond.vals[0];
        } catch (error) {
          //
        }
      }
      this.get(url, { page, size, keywords, ...(data || {}) }).then((r) => {
        if (r) {
          table.onQueryrSuccess(r);
        }
      });
    },
  },
};
</script>
