<template>
  <div class="full-height tenant-menu-view-list">
    <rs-list name="ten-menu-view" keywords="name">
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="name"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="type"></rs-filter>
        <rs-filter name="priority"></rs-filter>
        <rs-filter name="archDb"></rs-filter>
        <rs-filter name="archFs"></rs-filter>
        <rs-filter name="customize"></rs-filter>
        <rs-filter name="tenant"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="priority"></rs-field>
      <rs-field name="archDb"></rs-field>
      <rs-field name="archFs"></rs-field>
      <rs-field name="customize"></rs-field>
      <rs-field name="tenant.name"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']">
        <rs-button name="edit" color="primary" action="tenants.view._edit"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary" action="tenants.view._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
