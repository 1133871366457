<template>
  <div class="q-pa-sm full-height ten-order-form">
    <rs-form ref="form" name="ten-order" :col="2">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id" readonly></rs-field>
        <rs-field name="tenant" readonly></rs-field>
        <rs-field name="type" readonly></rs-field>
        <rs-field name="orderFrom" readonly></rs-field>
        <rs-field name="totalPrice" readonly></rs-field>
        <rs-field name="totalPaid" readonly></rs-field>
        <rs-field name="orderStatus" readonly></rs-field>
        <rs-field name="payStatus" readonly></rs-field>
        <rs-field name="orderTime" readonly></rs-field>
        <rs-field name="payTime" readonly></rs-field>
        <rs-field
          name="remark"
          col="12"
          :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
        ></rs-field>
        <rs-field name="ext" hidden></rs-field>
      </rs-field>
      <rs-field type="group" name="items" ignore>
        <rs-cascades ref="orderItems" :loadDefault="id" disable>
          <rs-field name="name" :editable="false"></rs-field>
          <rs-field name="specs" :editable="false" label="规格信息"></rs-field>
          <rs-field name="quantity" :editable="false"></rs-field>
          <rs-field name="unit" :editable="false"></rs-field>
          <rs-field name="duration" :editable="false" label="时长"></rs-field>
          <rs-field name="durationUnit" :editable="false" label="时长单位"></rs-field>
          <rs-field name="price" :editable="false" label="金额"></rs-field>
        </rs-cascades>
      </rs-field>
      <rs-toolbar :include="['confirm', 'cancel', 'joblog', 'deployInfo']" exclude="*">
        <rs-button name="cancel" action="tenants.orders._view" :payload="{ query: { tenantId } }"></rs-button>
        <rs-button v-if="showConfirm" name="confirm" color="positive" label="确认订单" :sort="1" />
        <rs-button
          v-if="!!jobId"
          name="joblog"
          label="任务日志"
          action="matrix.jobLog._view"
          :payload="{ query: { jobId, directlog: 'true' } }"
        />
        <rs-button
          v-if="!!deployId"
          name="deployInfo"
          label="发布详情"
          action="ops.saasDeploy._edit"
          :payload="{ query: { id: deployId } }"
        />
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      id: null,
      tenantId: null,
      jobId: null,
      deployId: null,
      tenantModelService: new ModelService(),
      showConfirm: false,
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.tenantId = this.$route.query.tenantId;
    if (!this.id && this.tenantId) {
      this.tenantModelService
        .refs(
          [this.tenantId],
          {
            fields: ["id", "name"],
          },
          "tenant",
        )
        .then((resp) => {
          if (resp && resp[0]) {
            this.$refs.form.core.setValue({ tenant: resp[0] });
          }
        });
    }
  },
  methods: {
    //
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          this.showConfirm = params.payStatus === 1 && params.orderStatus === 0;
          if (params.ext) {
            this.jobId = params.ext.jobId;
            this.deployId = params.ext.deployId;
          }
        },
      };
    },
    // 确认订单
    onTenOrderConfirm(e, params) {
      let memo = null;
      const COMP = {
        data: function() {
          return {
            confirmMemo: null,
          };
        },
        render(h) {
          return (
            <div class="q-pa-md">
              {this.$t_tenants("orders-memo")}：
              <q-input outlined v-model={this.confirmMemo} on-input={(e) => (memo = e)} type="textarea" />
            </div>
          );
        },
      };
      const dlg = this.$rs_dlg({
        parent: this,
        title: this.$t_tenants("orders-confirm"),
        cardStyle: "width: 700px",
        contentComponent: COMP,
      }).onOk(() => {
        this.$rs_post("/api/ten-order/confirm", {
          orderId: this.id,
          tenId: this.$refs.form.core.value.tenant.id,
          memo: memo,
        }).then((resp) => {
          if (resp && resp.code === 0 && resp.content.result) {
            this.$rs_success("订单确认成功");
            this.$refs.form.core.setValue({
              orderStatus: 3,
            });
            this.$refs.form.dealQuery();
          } else {
            this.$rs_error("订单确认失败：" + resp.content.message || resp.message || "");
          }
        });
        dlg.hide();
      });
    },
  },
};
</script>
