<template>
  <rs-list name="erp-event" keywords="name,id" filterPlaceholder="请输入事件项/事件名称进行检索">
    <rs-search label-width="5em">
      <rs-filter name="id"></rs-filter>
      <rs-filter name="name"></rs-filter>
      <rs-filter name="crtTime"></rs-filter>
      <rs-filter name="recTime"></rs-filter>
    </rs-search>
    <rs-field name="id" desc="事件项"></rs-field>
    <rs-field name="name" desc="事件名称"></rs-field>
    <rs-field name="info" desc="描述"></rs-field>
    <rs-field name="topic" desc="kafka消息topic"></rs-field>
    <rs-field name="configs" desc="事件默认配置"></rs-field>
    <rs-field name="eventType" desc="事件类型"></rs-field>
    <rs-field name="subject.name" desc="所属主体"></rs-field>
    <rs-field name="crtTime" desc="创建时间"></rs-field>
    <rs-field name="recTime" desc="修改时间"></rs-field>
    <rs-toolbar exclude="*"></rs-toolbar>
  </rs-list>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    beforeQuery(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          (response?.list || []).forEach((d) => {
            d.configs = JSON.stringify(d.configs);
          });
        },
      };
    },
  },
};
</script>
