<template>
  <div class="full-height rs-page-employee-list">
    <div class="full-height ">
      <rs-list
        key="test_key"
        name="employee"
        :filter-placeholder="$t_mdata('employee-filterPlaceholder')"
        filter-style="width: 325px"
        :data="test"
        keywords="id,name,mobile,email"
        :defImportConfig="{ 'depts.ns': 'dept' }"
      >
        <rs-search ref="search" label-width="5em" @change="searchChange" :value="{ status: 1 }">
          <rs-filter type="keywords" name="name,id,mobile"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="mobile"></rs-filter>
          <rs-filter name="email"></rs-filter>
          <rs-filter name="comp" front></rs-filter>
          <rs-filter name="depts" front :widget="{ conditions: deptConditions }"></rs-filter>
          <rs-filter name="gender"></rs-filter>
          <rs-filter name="jobRank"></rs-filter>
          <rs-filter name="jobPost"></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="entryDate" front></rs-filter>
          <rs-filter name="leaveDate" front></rs-filter>
          <rs-filter name="crtTime"></rs-filter>
          <rs-filter name="crtUser"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id" :editable="false"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="gender"></rs-field>
        <rs-field name="email"></rs-field>
        <rs-field name="mobile"></rs-field>
        <rs-field name="post"></rs-field>
        <rs-field name="jobRank"></rs-field>
        <rs-field name="jobPost"></rs-field>
        <rs-field name="comp.name"></rs-field>
        <rs-field name="depts.name" v-slot="props">{{ (props.depts || []).map((d) => d.name).join() }}</rs-field>
        <rs-field name="status"></rs-field>
        <rs-field :label="$t_mdata('employee-status')" v-slot="props">
          {{ getEmployeeStatus(props) }}
        </rs-field>
        <rs-field name="entryDate"></rs-field>
        <rs-field name="leaveDate"></rs-field>
        <rs-field name="crtPlat"></rs-field>
        <rs-field name="crtTime"></rs-field>
        <rs-field name="crtUser"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.employee._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary" action="mdata.employee._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  data: function() {
    return {
      title: this.$t_mdata("employee-title"),
      test: null,
      deptConditions: [],
    };
  },
  mounted() {},
  methods: {
    // setDeptConditions() {
    //   if (this.$refs.search && this.$refs.search.$refs.queryForm && this.$refs.search.$refs.queryForm.core) {
    //     this.$refs.search.$refs.queryForm.core.setProps("conditions", this.deptConditions);
    //   }
    // },
    searchChange(val, fireKey, core) {
      if (fireKey.find((d) => d === "comp")) {
        if (val.comp) {
          this.$set(this, "deptConditions", [
            { key: "rcode", vals: [val.comp.id] },
            { key: "code", eq: OP_TYPE.NE, vals: [val.comp.id] },
          ]);
        } else {
          this.$set(this, "deptConditions", []);
        }
        // this.setDeptConditions();
      }
    },
    getEmployeeStatus(row) {
      const st = {
        work: this.$t_mdata("employee-work"), // 在职
        pending: this.$t_mdata("employee-pending"), // 待入职
        leave: this.$t_mdata("employee-leave"), // 离职
      };
      const nowDate = moment().format("YYYY-MM-DD");
      if (row.entryDate && row.leaveDate) {
        if (!moment(row.entryDate).isAfter(row.leaveDate)) {
          // 入职时间是不是在今天之后，是:待入职
          if (moment(row.entryDate).isAfter(nowDate)) {
            return st.pending;
          } else {
            // 离职时间是不是在今天之前，是:离职，否:在职
            return moment(row.leaveDate).isBefore(nowDate) ? st.leave : st.work;
          }
        }
      } else if (row.entryDate) {
        // 入职时间是不是在今天之后，是:待入职，否:在职
        return moment(row.entryDate).isAfter(nowDate) ? st.pending : st.work;
      } else if (row.leaveDate) {
        // 离职时间是不是在今天之前，是:离职，否:在职
        return moment(row.leaveDate).isBefore(nowDate) ? st.leave : st.work;
      }
      return "";
    },
  },
  event: {
    beforeQuery() {},
  },
};
</script>
