<template>
  <div class="full-height rs-page-employee-form scroll">
    <rs-form
      ref="form"
      name="employee"
      label-width="7em"
      :value="{ status: 1 }"
      :isUpdateMode="!!id"
      floatLayout
      @change="onChange"
    >
      <rs-field name="headImage" :widget="{ _type: 'rs-head-image', fixedNumber: [108, 108] }" floatRight></rs-field>
      <rs-field name="name" required></rs-field>
      <rs-field name="id" required mode="edit=preview"></rs-field>
      <rs-field name="gender"></rs-field>
      <rs-field name="comp"></rs-field>
      <rs-field name="depts" :widget="deptsCmpOpt" :disable="!this.currentComp"></rs-field>
      <rs-field name="post"></rs-field>
      <rs-field name="jobPost"></rs-field>
      <rs-field name="jobRank"></rs-field>
      <rs-field name="mobile" required></rs-field>
      <rs-field name="email"></rs-field>
      <!-- <rs-field name="status" :value="1"></rs-field> -->
      <rs-field name="id" :label="$t_mdata('employee-status')" v-slot="props">
        <div class="row full-height items-center text-black">
          {{ getEmployeeStatus(props) }}
        </div>
      </rs-field>
      <rs-field name="idcard"></rs-field>
      <rs-field name="entryDate" :widget="{ dateProps: { options: entryDateOptFn } }"></rs-field>
      <rs-field name="leaveDate" :widget="{ dateProps: { options: leaveDateOptFn } }"></rs-field>
      <rs-field name="area" col="6" widget="RsAddressPicker"></rs-field>
      <rs-field name="address" col="6"></rs-field>
      <rs-field name="remark" col="12"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.employee._view"></rs-button>
        <!-- <rs-button name="site" label="选网点"></rs-button> -->
      </rs-toolbar>
    </rs-form>
    <q-list class="q-ma-sm">
      <rs-group name="guider" :label="$t_mdata('site-guiderTab')">
        <rs-list
          ref="guider"
          name="guider"
          :preventDbClick="true"
          edit-mode="row"
          :filterHidden="true"
          :conditions="guiderConditions"
          :loadDefault="!!$route.query.id"
          class="guider-sticky-column-table"
        >
          <rs-field
            name="sit"
            :required="true"
            minWidth="120px"
            :widget="{ conditions: sitConditions, returnAllAttrs: true }"
            v-slot="props"
          >
            <div class="full-height row items-center">{{ props.sit ? props.sit.id || "" : "" }}</div>
          </rs-field>
          <rs-field name="sit.name" :editable="false"></rs-field>
          <rs-field name="sit.type" :editable="false"></rs-field>
          <rs-field name="role" :required="true" :value="102"></rs-field>
          <rs-field name="status" :value="1"></rs-field>
          <rs-field type="toolbar"></rs-field>
          <rs-toolbar :exclude="['add', 'imports', 'exports']">
            <rs-button name="addGuider" icon="add" color="primary" :label="$t_s('add')"></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-group>
    </q-list>
  </div>
</template>
<script>
import moment from "moment";
import { OP_TYPE } from "rsui-core/src/core/types";
import ModelService from "rsui-core/src/core/model-service";
import Config from "rsui-core/src/config";
import { uid } from "quasar";
export default {
  data: function() {
    return {
      currentComp: null,
      id: null,
      entryDateOptFn: (data) => true,
      leaveDateOptFn: (data) => true,
      sitConditions: [],
      modelService: new ModelService(),
    };
  },
  computed: {
    deptsCmpOpt: function() {
      return {
        _type: "RsTreeSelect",
        _extend: true,
        conditions: this.currentComp
          ? [
              { key: "rcode", vals: [this.currentComp.id] },
              { key: "ns", vals: ["dept"] },
            ]
          : [],
        treeConf: {
          tickStrategy: "strict",
          beforeSetData: (list, cb) => {
            list.forEach((d) => (d.tickable = false));
            cb(list);
          },
        },
      };
    },
    guiderConditions: function() {
      return this.id ? [{ key: "emp.id", vals: [this.id] }] : [];
    },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    getEmployeeStatus(row) {
      const st = {
        work: this.$t_mdata("employee-work"), // 在职
        pending: this.$t_mdata("employee-pending"), // 待入职
        leave: this.$t_mdata("employee-leave"), // 离职
      };
      const nowDate = moment().format("YYYY-MM-DD");
      if (row.entryDate && row.leaveDate) {
        if (!moment(row.entryDate).isAfter(row.leaveDate)) {
          // 入职时间是不是在今天之后，是:待入职
          if (moment(row.entryDate).isAfter(nowDate)) {
            return st.pending;
          } else {
            // 离职时间是不是在今天之前，是:离职，否:在职
            return moment(row.leaveDate).isBefore(nowDate) ? st.leave : st.work;
          }
        }
      } else if (row.entryDate) {
        // 入职时间是不是在今天之后，是:待入职，否:在职
        return moment(row.entryDate).isAfter(nowDate) ? st.pending : st.work;
      } else if (row.leaveDate) {
        // 离职时间是不是在今天之前，是:离职，否:在职
        return moment(row.leaveDate).isBefore(nowDate) ? st.leave : st.work;
      }
      return "";
    },
    // onEmployeeSite() {
    //   this.$rs_sitepicker();
    // },
    beforeSubmit(e, params) {
      // 判断导购信息是否处于行编辑状态
      if (this.$refs.guider && this.$refs.guider.isEditing) {
        this.$rs_warning(this.$t_mdata("site-guiderIsEditing"));
        e.preventDefault();
        return;
      }
      return {
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          this.modelService.cascade("guiders", id, this.getGuiders(), "employee").then((res) => {
            this.$rs_actionRefresh("mdata.employee._view");
          });
        },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.find((d) => d === "comp")) {
        this.$set(this, "currentComp", val.comp);
        if (fireKey.length === 1) {
          setTimeout(() => {
            core.setValue({ depts: [] });
          });
        }
      }
      if (fireKey.find((d) => d === "entryDate")) {
        if (val.entryDate) {
          this.leaveDateOptFn = (date) => {
            // 入职时间是不是在我离职时间之后，取反
            return !moment(val.entryDate).isAfter(date);
          };
        } else {
          this.leaveDateOptFn = (date) => true;
        }
      }

      if (fireKey.find((d) => d === "leaveDate")) {
        if (val.leaveDate) {
          this.entryDateOptFn = (date) => {
            // 离职时间是不是在我入职时间之前，取反
            return !moment(val.leaveDate).isBefore(date);
          };
        } else {
          this.entryDateOptFn = (date) => true;
        }
      }
    },
    // 获取导购信息需要保存的数据
    getGuiders() {
      const list = this.$refs.guider.innerList;
      return list.map((d) => {
        return {
          sit: {
            id: d.sit.id,
          },
          role: d.role,
          status: d.status,
        };
      });
    },
    // 设置添加网点条件
    setGuiderSiteCond(list = null) {
      this.sitConditions = [
        { key: "id", op: OP_TYPE.NE, vals: (list || this.$refs.guider.innerList).map((d) => d.sit.id) },
      ];
    },
    // 导购列表请求数据 设置添加过滤条件
    beforeQuery(e, params, eventId) {
      if (eventId.model === "guider") {
        params[0].fields.push("sit.id", "status");
        return {
          params,
          resolve: ({ response }) => {
            if (response && response.list) {
              this.setGuiderSiteCond(response.list);
            }
          },
        };
      }
    },
    onGuiderAddGuider(e, params, eventId) {
      if (this.$refs.guider.isEditing) {
        this.$rs_warning(this.$t_mdata("site-guiderIsEditing"));
        return;
      }
      const dlg = this.$rs_model_dlg({
        modelName: "site",
        multiple: true,
        conditions: this.sitConditions,
      }).then(({ selected, dlg }) => {
        if (selected.length > 0) {
          const data = selected.map((d) => {
            return {
              id: uid(),
              sit: d,
              role: 102,
              status: 1,
            };
          });
          this.$set(this.$refs.guider, "innerList", [...data, ...this.$refs.guider.innerList]);
          this.setGuiderSiteCond();
        }
        dlg.hide();
      });
    },
    onGuiderDeleteBeforeConfirm(e, params, eventId) {
      this.onGuiderDeleteAllBeforeConfirm(e, params, eventId);
    },
    onGuiderDeleteAllBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      params.forEach((d) => {
        this.$refs.guider.deleteEditRowByIndex(d.__index);
      });
      this.setGuiderSiteCond();
    },
    // 导购信息保存
    onGuiderSaveLine(e, params, eventId) {
      e.preventDefault();
      this.$refs.guider.formCore.validate().then((res) => {
        if (!res) {
          const row = params.row;
          if (params.isCreated) {
            row.status = 1;
            row.id = uid();
            this.$refs.guider.innerList.push(row);
          } else {
            this.$set(this.$refs.guider.innerList, this.$refs.guider.tableForm.currentEditRowIndex, {
              ...this.$refs.guider.innerList[this.$refs.guider.tableForm.currentEditRowIndex],
              ...row,
            });
          }
          this.$refs.guider.cacelEdit();
          this.setGuiderSiteCond();
        }
      });
    },
  },
};
</script>
