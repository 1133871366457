<template>
  <div class="q-pa-sm full-height ten-shared-db-form">
    <rs-form ref="form" name="ten-shared-db" :value="{ nowSlots: 0, lastAllocTime: 0 }">
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="platform"></rs-field>
      <rs-field name="version" readonly></rs-field>
      <rs-field type="group" col="4" :expansion="false"></rs-field>
      <rs-field name="dbHost"></rs-field>
      <rs-field name="dbPort"></rs-field>
      <rs-field name="userPass"></rs-field>
      <rs-field name="adminUser"></rs-field>
      <rs-field name="adminPass"></rs-field>
      <rs-field type="group" col="4" :expansion="false"></rs-field>
      <rs-field name="maxSlots"></rs-field>
      <rs-field name="nowSlots" readonly></rs-field>
      <rs-field name="lastAllocTime" readonly></rs-field>
      <rs-field
        name="dbCpu"
        :widget="{ _type: 'rs-input-select', selectOpts: 'core', defSelectedValue: 'core' }"
      ></rs-field>
      <rs-field name="dbMem" :widget="{ _type: 'rs-input-select', selectOpts: 'g', defSelectedValue: 'g' }"></rs-field>
      <rs-field name="dbDisk" :widget="{ suffix: 'GB' }"></rs-field>
      <rs-field name="kafkaPartition"></rs-field>
      <rs-field
        name="info"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.sharedDb._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      e.preventDefault();
      const testPromise = [];
      const testParams = pick(params, ["platform", "dbHost", "dbPort"]);
      testParams.database = params.platform;
      // 检查admin
      testPromise.push(
        this.$rs_post("/api/deploy/db/server/test", {
          ...testParams,
          dbUser: params.adminUser,
          dbPass: params.adminPass,
        }),
      );
      // 检查user
      testPromise.push(
        this.$rs_post("/api/deploy/db/server/test", {
          ...testParams,
          dbUser: "portal",
          dbPass: params.userPass,
        }),
      );
      Promise.all(testPromise).then((respList) => {
        let isSuc = true;
        let version = null;
        respList.every((resp) => {
          if (resp && resp.code === 0 && resp.content.result) {
            // 数据库版本号
            version = resp.content.version;
            return true;
          }
          isSuc = false;
          this.$rs_warning("DB配置无效：" + resp.content.message || resp.message || "");
          return false;
        });
        if (isSuc) {
          // 设置版本号
          params.version = version;
          e.next({
            params,
            resolve: ({ response }) => {
              this.$rs_actionRefresh("tenants.sharedDb._view");
            },
          });
        }
      });
    },
  },
};
</script>
