var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rs-form',{staticClass:"flex-column",attrs:{"no-border":"","name":"data-job","col":"1","value":{ handler: _vm.on.handler }},on:{"change":_vm.onchange}},[_c('rs-field',{attrs:{"name":"handler","hidden":""}}),_c('rs-field',{attrs:{"name":"ext","col":"12","extConfig":{
      tenant: {
        widget: {
          returnAllAttrs: true,
          conditions:
            _vm.on.handler === 'pullTaskHandler'
              ? [
                  { key: 'status', vals: [1] },
                  { key: 'brokers', op: 'relExists', vals: [{ key: 'rel.status', vals: [1] }] } ]
              : [{ key: 'status', vals: [1] }],
        },
      },
      jobDesc: {
        disable: true,
        value: _vm.desc,
      },
      model: {
        widget: {
          emitValue: false,
        },
      },
      subject: {
        widget: {
          isSelectedMode: true,
          selectProps: { simpleMode: true },
        },
      },
    }}}),_c('rs-toolbar',{attrs:{"type":"toolbar"}},[_c('rs-button',{attrs:{"name":"submit","label":"确认执行","icon":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }