<template>
  <div class="q-pa-sm full-height tenant-service-form">
    <rs-form ref="form" name="ten-service" @change="onChange" :value="{ priority: 100, virtual: false }">
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="dbless"></rs-field>
      <rs-field name="dbPlatform" v-bind="disableBind"></rs-field>
      <rs-field name="dbGroup" v-bind="disableBind"></rs-field>
      <rs-field name="priority" hidden></rs-field>
      <rs-field name="virtual"></rs-field>
      <rs-field name="gitServer"></rs-field>
      <rs-field name="gitProject"></rs-field>
      <rs-field name="dataless"></rs-field>
      <rs-field name="dataPath" v-bind="dataDisableBind"></rs-field>
      <rs-field type="group" col="4" :expansion="false"></rs-field>
      <rs-field name="podName"></rs-field>
      <rs-field name="svcName"></rs-field>
      <rs-field name="routePath"></rs-field>
      <rs-field
        name="info"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.service._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      dbDisbale: false,
      dataDisable: false,
      gitEnable: true,
    };
  },
  computed: {
    disableBind() {
      return this.dbDisbale ? { disable: true } : {};
    },

    dataDisableBind() {
      return this.dataDisable ? { disable: true } : {};
    },
  },
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      if (params.gitServer) {
        return this.$rs_post("/api/deploy/git/project/check", {
          gitServerId: params.gitServer.id,
          gitProject: params.gitProject,
        }).then((resp) => {
          if (resp && resp.code === 0) {
            if (resp.content.result) {
              return {
                params,
                resolve: ({ response }) => {
                  this.$rs_actionRefresh("tenants.service._view");
                },
              };
            } else {
              this.$rs_warning("Git仓库配置无效：" + resp.content.message || "");
            }
          } else {
            this.$rs_warning("Git仓库配置无效：" + resp.message || "");
          }
          e.preventDefault();
        });
      } else {
        return {
          params,
          resolve: ({ response }) => {
            this.$rs_actionRefresh("tenants.service._view");
          },
        };
      }
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("dbless")) {
        this.dbDisbale = val.dbless;
        if (this.dbDisbale) {
          setTimeout(() => {
            core.setValue({ dbGroup: null, dbPlatform: null });
          });
        }
      }
      if (fireKey.includes("dataless")) {
        this.dataDisable = val.dataless;
        if (this.dataDisable) {
          setTimeout(() => {
            core.setValue({ dataPath: null });
          });
        }
      }
    },
  },
};
</script>
