<template>
  <div class="q-pa-sm full-height">
    <div class="full-height bg-white column">
      <q-toolbar>
        <q-btn color="primary" :label="$t_s('save')" />
        <q-btn class="q-ml-md" color="grey-6" :label="$t_s('cancel')" />
        <q-space />
        <q-tabs v-model="tab" class="text-primary col-auto">
          <q-tab name="SMS" :label="$t_mdata('pushChannel-SMS')" />
          <q-tab name="MMS" :label="$t_mdata('pushChannel-MMS')" />
          <q-tab name="EMAIL" :label="$t_mdata('pushChannel-Email')" />
          <q-tab name="WECHAT" :label="$t_mdata('pushChannel-WeChat')" />
          <q-tab name="VOICE" :label="$t_mdata('pushChannel-Voice')" />
        </q-tabs>
      </q-toolbar>
      <q-separator />
      <q-tab-panels keep-alive v-model="tab" animated class="col">
        <q-tab-panel name="SMS" class="q-pa-sm">
          <rs-list name="push-channel" :conditions="conditions">
            <rs-field name="id"></rs-field>
            <rs-field name="name"></rs-field>
            <rs-field name="type"></rs-field>
            <rs-field name="serviceType"></rs-field>
            <rs-field name="bset"></rs-field>
            <rs-field name="status"></rs-field>
            <!-- <rs-field name="configInfo"></rs-field> -->
          </rs-list>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      tab: "SMS",
    };
  },
  computed: {
    conditions: function() {
      return [{ key: "type", vals: [this.tab] }];
    },
  },
  mounted() {},
  methods: {},
};
</script>
