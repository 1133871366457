<template>
  <div class="q-pa-sm column full-height">
    <div class="bg-white row col-auto q-pa-sm">
      <q-space />
      <q-btn :loading="loading" icon="refresh" color="primary" @click="getData">
        <template v-slot:loading>
          <q-spinner-hourglass size="1em" />
        </template>
      </q-btn>
    </div>
    <q-table
      class="col full-width rs-sticky-header-table"
      :data="data"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :rows-per-page-options="[10]"
    >
      <template v-slot:body-cell-triggerMsg="props">
        <q-td :props="props" class="ellipsis" style="max-width:200px" v-html="props.row.triggerMsg"></q-td>
      </template>
      <template v-slot:body-cell-op="props">
        <q-td :props="props">
          <div v-if="props.row.triggerCode == 200 && checkDate(props.row.triggerTime)">
            <router-link
              class="rs-link"
              :to="{
                name: 'settings.jobs._view3',
                query: { id: props.row.id, seconds: getSeconds(props.row.triggerTime), jobGroup: props.row.jobGroup },
              }"
            >
              {{ $t_settings("jobsTrigger-toLogBtn") }}
            </router-link>
          </div>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "JobTriggerCache",
  data: function() {
    return {
      data: [],
      columns: [
        {
          field: "id",
          label: this.$t_settings("jobsTrigger-id"),
          align: "left",
        },
        {
          field: "triggerCode",
          label: this.$t_settings("jobsTrigger-triggerCode"),
          align: "left",
          format: (val) => {
            return val == 200
              ? this.$t_settings("jobsTrigger-success")
              : val == 500
              ? this.$t_settings("jobsTrigger-fail")
              : val;
          },
        },
        {
          name: "triggerMsg",
          label: this.$t_settings("jobsTrigger-triggerMsg"),
          align: "left",
        },
        {
          field: "triggerTime",
          label: this.$t_settings("jobsTrigger-triggerTime"),
          align: "left",
          format: (val) => {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
          },
        },
        {
          field: "executorAddress",
          label: this.$t_settings("jobsTrigger-executorAddress"),
          align: "left",
        },
        {
          field: "executorFailRetryCount",
          label: this.$t_settings("jobsTrigger-executorFailRetryCount"),
          align: "left",
        },
        {
          field: "handleCode",
          label: this.$t_settings("jobsTrigger-handleCode"),
          align: "left",
          format: (val) => {
            return val == 200
              ? this.$t_settings("jobsTrigger-success")
              : val == 500
              ? this.$t_settings("jobsTrigger-fail")
              : "";
          },
        },
        {
          field: "handleTime",
          label: this.$t_settings("jobsTrigger-handleTime"),
          align: "left",
          format: (val) => {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
          },
        },
        {
          name: "op",
          label: this.$t_s("operate"),
          align: "center",
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      loading: false,
    };
  },
  activated() {
    // this.$set(this, "data", []);
    // this.getData();
  },
  mounted() {
    this.$set(this, "data", []);
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$rs_post("/api/ops/task/logs", {
        start: (this.pagination.page - 1) * this.pagination.rowsPerPage,
        length: this.pagination.rowsPerPage,
        jobId: this.$route.query.id,
      })
        .then((resp) => {
          if (resp && resp.content && resp.content.data) {
            this.$set(this, "data", resp.content.data);
          } else {
            this.$set(this, "data", []);
          }
        })
        .finally(() => (this.loading = false));
    },
    getSeconds(date) {
      return moment(date).unix();
    },
    checkDate(date) {
      return moment()
        .subtract(7, "days")
        .isBefore(date);
    },
  },
};
</script>
