<template>
  <rs-form ref="form" name="mock-appdeploy" no-border class="full-height flex-column">
    <rs-field type="group" label="基本信息" disable>
      <rs-field name="wxAppId"></rs-field>
      <rs-field name="wxAppName"></rs-field>
      <rs-field name="tenId"></rs-field>
      <rs-field name="tenCode"></rs-field>
      <rs-field name="wxPrincipalName"></rs-field>
      <rs-field name="id" v-slot>
        <span></span>
      </rs-field>
      <rs-field name="wxQrCode" v-slot="form">
        <img
          class="rs-border "
          style="width:120px"
          :src="'/api/mini-mp-deploy/mini/mp/code/qrcode?wxAppId=' + form.wxAppId"
          alt=""
        />
      </rs-field>
    </rs-field>
    <rs-field type="group" label="开发域名配置">
      <div class="row q-gutter-sm">
        <q-btn size="sm" color="primary" @click="setOpenDomain">开放域名</q-btn>
      </div>
      <div class="row  justify-between q-gutter-sm items-center">
        <!-- <q-field dense style="width:480px" borderless>
          <template v-slot:before>
            <span class="text-subtitle2 rs-link" @click="setOpenDomain">开放域名</span>
          </template>
        </q-field> -->

        <q-list bordered class="scroll" style="width:260px" v-for="(val, key) of domainDetail" :key="key + val">
          <q-item-label header :key="key">{{ key }}</q-item-label>
          <q-separator />
          <template>
            <q-item clickable v-for="item in val" :key="item + key">
              <q-item-section>{{ item }}</q-item-section>
            </q-item>
          </template>
        </q-list>
      </div>
    </rs-field>
    <rs-field type="group" label="申请隐私接口">
      <rs-list
        :hideBottom="true"
        class="width-100"
        :data="newPrivacyInterfaces"
        name="mock-allinone"
        key="template"
        :selection="false"
      >
        <rs-field name="api_name" label="api 英文名"></rs-field>
        <rs-field name="api_ch_name" label="api中文名"></rs-field>
        <!-- <rs-field name="api_desc" label="api描述"></rs-field> -->
        <rs-field name="status" label="申请状态" v-slot="row">
          {{ statusMp[row.status] }}
        </rs-field>
        <!-- <rs-field name="status_value" label="申请状态值"></rs-field> -->
        <rs-field name="content" label="申请原因"></rs-field>
        <rs-field name="fail_reason" label="失败原因"></rs-field>
        <rs-field name="apply_time" label="申请时间"></rs-field>
        <rs-field type="toolbar" exclude="*" include="apply" v-slot="row">
          <rs-button
            :hidden="row.status != 1 && row.status != 4 && row.status != 2"
            name="apply"
            color="primary"
            type="link"
            label="接口申请"
          ></rs-button>
        </rs-field>
      </rs-list>
    </rs-field>
    <rs-field type="group" label="订阅消息模板">
      <rs-list
        :hideBottom="true"
        class="width-100"
        :data="templateDetail"
        name="mock-allinone"
        key="template"
        :selection="false"
      >
        <rs-field name="tid"></rs-field>
        <rs-field name="title"></rs-field>
        <rs-field name="type"></rs-field>
        <rs-field name="priTmplId"></rs-field>
        <rs-toolbar exclude="*" include="configTemp">
          <rs-button name="configTemp" color="primary" label="设置订阅模板"></rs-button>
        </rs-toolbar>
      </rs-list>
    </rs-field>
    <!--  -->
    <rs-field type="group" label="测试者列表">
      <rs-list
        :hideBottom="true"
        name="mock-allinone"
        key="tester"
        :data="tester"
        :selection="false"
        :isPagination="false"
      >
        <rs-field name="wechatId"></rs-field>
        <rs-field type="toolbar" exclude="*" include="del">
          <rs-button name="del" label="删除" type="link" color="red"></rs-button>
        </rs-field>
        <template v-slot:summary>
          <div class="row q-gutter-sm items-center">
            <q-input
              placeholder="输入微信账号"
              v-model="testName"
              dense
              class="rs-size-sm w-search"
              outlined
              clearable
            ></q-input>
            <div>
              <q-btn label="添加" size="sm" color="primary" @click="addTester"></q-btn>
            </div>
          </div>
        </template>
      </rs-list>
    </rs-field>
    <!--  -->
    <rs-field type="group" label="当前模板">
      <rs-list :hideBottom="true" name="mock-mptemplate" class="width-100" :data="codeTemplate" :selection="false">
        <rs-field name="templateId"></rs-field>
        <rs-field name="templateVersion"></rs-field>
        <rs-field name="templateDesc"></rs-field>
        <rs-field name="createTime"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field type="toolbar" exclude="*" :include="['tocommit']">
          <rs-button type="link" color="primary" label="提交审核" name="tocommit"></rs-button>
        </rs-field>
        <rs-toolbar exclude="*" include="updateTemplate">
          <rs-button name="updateTemplate" label="更新代码" color="primary"></rs-button>
        </rs-toolbar>
      </rs-list>
    </rs-field>
    <!--  -->
    <rs-field type="group" label="审核版本">
      <rs-list
        :hideBottom="true"
        class="width-100"
        name="mock-allinone"
        key="lastCodeActInfo"
        :data="lastCodeActInfo"
        :selection="false"
        :isPagination="false"
        :hiddenTop="true"
      >
        <rs-field name="appActId"></rs-field>
        <rs-field name="appActVersion"></rs-field>
        <rs-field name="appActStatus"></rs-field>
        <rs-field name="appAuditStatus" v-slot="row">
          {{ stateMap[row.appAuditStatus] }}
        </rs-field>
        <rs-field name="appActTime"></rs-field>
        <rs-field type="toolbar" exclude="*" :include="['fb', 'cx', 'jj', 'ckyy']" v-slot="row">
          <rs-button :hidden="row.appAuditStatus != 0" name="fb" label="发布" type="link" color="primary"></rs-button>
          <rs-button
            :hidden="row.appAuditStatus != 2"
            name="cx"
            label="撤回审核"
            type="link"
            color="primary"
          ></rs-button>
          <rs-button
            :hidden="row.appAuditStatus != 2"
            name="jj"
            label="加急审核"
            type="link"
            color="primary"
          ></rs-button>
          <rs-button
            :hidden="row.appAuditStatus != 1"
            name="ckyy"
            label="查看失败原因"
            type="link"
            color="primary"
          ></rs-button>
        </rs-field>
      </rs-list>
    </rs-field>
    <!--  -->
    <rs-field type="group" label="线上版本">
      <rs-list
        :hideBottom="true"
        class="width-100"
        name="mock-allinone"
        key="deployedinfo"
        :data="deployInfo"
        :selection="false"
        :isPagination="false"
        :hiddenTop="true"
      >
        <rs-field name="deployId"></rs-field>
        <rs-field name="deployTime"></rs-field>
        <rs-field name="deployVersion"></rs-field>
        <rs-field name="deployDesc"></rs-field>
        <rs-field type="toolbar" exclude="*" include="revert">
          <rs-button name="revert" label="回退版本" type="link" color="red"></rs-button>
        </rs-field>
      </rs-list>
    </rs-field>
    <rs-field type="group" label="更新历史">
      <rs-list
        class="width-100"
        name="mock-allinone"
        key="lastCodeActInfo"
        :data="lastCodeActInfo"
        :selection="false"
        :isPagination="false"
        :hiddenTop="true"
      >
        <rs-field name="appActId"></rs-field>
        <rs-field name="appActVersion"></rs-field>
        <rs-field name="appActStatus"></rs-field>
        <rs-field name="appAuditStatus" v-slot="row">
          {{ stateMap[row.appAuditStatus] }}
        </rs-field>
        <rs-field name="appActTime"></rs-field>
        <rs-field type="toolbar" exclude="*" :include="['fb', 'cx', 'jj', 'ckyy']" v-slot="row">
          <rs-button
            :hidden="row.appAuditStatus != 1"
            name="ckyy"
            label="查看失败原因"
            type="link"
            color="primary"
          ></rs-button>
        </rs-field>
      </rs-list>
    </rs-field>
    <rs-field type="group" label="日志">
      <div class="row items-center full-width">
        <span class="q-mr-md">起止时间</span>
        <rs-date
          class="rs-size-sm"
          wrap-class=""
          placeholder="查询日期"
          outlined
          dense
          style="width: 200px"
          v-model="logDate"
        />
        <div class="q-ml-sm row items-center" style="width: 270px">
          <rs-time
            class="rs-size-sm"
            wrap-class=""
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            :dateProps="{
              isRange: true,
              rangeSeparator: '至',
              startPlaceholder: '开始时间',
              endPlaceholder: '结束时间',
            }"
            v-model="logTimeArr"
          />
        </div>
        <span class="q-mx-md">openId</span>
        <rs-input v-model="logoOpenId" class="rs-size-sm" outlined dense style="width:315px"></rs-input>
        <q-btn class="q-ml-md" size="sm" icon="search" color="primary" @click="logSubmit">查询</q-btn>
      </div>

      <rs-list
        :hidden-top="true"
        ref="userLogs"
        class="full-width"
        name="mock-allinone"
        key="userLogs"
        :load-default="false"
        :selection="false"
        style="width:100%"
      >
        <rs-field name="_" label="小程序日志" v-slot="row" width="100%">
          <div
            class="q-pb-lg "
            v-for="(item, i) in row.msg"
            :key="i"
            :style="{ lineHeight: '26px', background: i % 2 === 1 ? '#ddd' : '#fff' }"
          >
            【{{ getTime(item.time * 1000) }}】{{ item.msg[0] }}
          </div>
        </rs-field>
      </rs-list>
    </rs-field>
    <rs-toolbar exclude="*"></rs-toolbar>
  </rs-form>
</template>
<script>
import RsThirdApiMixins from "./appdeploy-mixins.vue";
import Dlg from "./app_deploy__mptemplate";
import moment from "moment";

export default {
  mixins: [RsThirdApiMixins],
  data() {
    return {
      reason: "",
      statusMp: { 1: "待申请开通", 2: "无权限", 3: "申请中", 4: "申请失败", 5: "已开通" },
      wxAppId: "",
      templateCode: null, // 选中的订阅消息模版code
      templates: [], //订阅消息模板
      domains: [], //开放域名列表
      domainConfig: { domainCode: null }, // 当前小程序选中的开发域名
      tester: [], // 测试者列表
      codeTemplate: [], // 当前小程序的代码模板
      lastCodeActInfo: [], // 小程序最新行为
      deployInfo: [], //线上版本信息
      codeActList: [], // 版本历史
      templateDetail: [], // 订阅消息模版
      newPrivacyInterfaces: [], // 隐私接口申请
      testName: "",
      detailInfo: {},
      stateMap: {
        "-1": "未审核",
        "0": "审核成功",
        "1": "审核失败",
        "2": "审核中",
        "3": "审核撤回",
      },

      logDate: moment().format("YYYY-MM-DD"),
      logTimeArr: null,
      logoOpenId: null,
    };
  },
  computed: {
    domainDetail: function() {
      const det = this.domainConfig?.domainDetail || {};
      const webdet = this.domainConfig?.webviewDomainDetail || {};
      const all = { ...det, ...webdet };
      return all;
    },
  },
  created() {
    this.wxAppId = this.$route.query.id;
    this.getOpenDomains();
    this.getTemplates();
  },
  methods: {
    // 隐私接口申请
    onMockAllinoneApply(e, row) {
      const Cpnt = {
        props: {
          value: {},
        },
        data() {
          return {
            reason: "",
          };
        },
        mounted() {
          this.reason = this.value;
        },
        render() {
          return (
            <div class="rs-form">
              <rs-input
                class="q-ma-sm"
                placeholder="请填写申请原因，不超过300个字符"
                rows="5"
                maxlength={300}
                counter={true}
                outlined
                type="textarea"
                v-model={this.reason}></rs-input>
            </div>
          );
        },
      };
      this.$rs_dlg({
        parent: this,
        title: "申请隐私接口",
        contentComponentProps: {
          value: row.content,
        },
        contentComponent: Cpnt,
      }).onOk((e, { content, dlg }) => {
        this.post("/mini/mp/applyPrivacyInterface", {
          wxAppId: this.wxAppId,
          apiContent: { api_name: row.api_name, content: content.reason },
        }).then((r) => {
          dlg.close();
          this.$refs["form"].dealQuery();
        });
      });
    },
    // 获取订阅消息模板
    getTemplates() {
      this.post("/mini/mp/new/message/template/list", { wxAppId: this.wxAppId }).then((r) => {
        this.templates = r.list;
      });
    },
    // 设置开放域名
    setOpenDomain() {
      const ctx = this;
      this.$rs_dlg({
        parent: this,
        title: "设置开放域名",
        contentComponent: {
          render() {
            return (
              <div class="q-pa-sm">
                <rs-select
                  class="rs-size-sm"
                  v-model={ctx.domainConfig.domainCode}
                  keyLabel="domainDesc"
                  keyValue="domainCode"
                  data={ctx.domains}
                  outlined></rs-select>
              </div>
            );
          },
        },
      }).onOk((e, { content, dlg }) => {
        this.post("/mini/mp/domain/config", { wxAppId: this.wxAppId, domainCode: this.domainConfig.domainCode });
        dlg.close();
        this.$refs["form"].dealQuery();
        // e.context.refreshTable();
      });
    },
    // 开放域名获取
    getOpenDomains() {
      this.post("/mini/mp/domain/list", { wxAppId: this.wxAppId }).then((r) => {
        this.domains = r.list;
      });
    },
    // 表单数据获取
    beforeRequest(e, params, eventId) {
      e.preventDefault();
      this.post("/mini/mp/info", { wxAppId: this.wxAppId }).then((content) => {
        e.context.core.value = {};
        e.context.core.setValue(content || {}, { isDefault: true });
        if (content.domainConfig) {
          this.domainConfig = content.domainConfig;
        }
        this.tester = content?.testerConfig?.testerList || [];
        const lstInfo = content?.lastCodeActInfo;
        this.lastCodeActInfo = lstInfo ? [lstInfo] : [];
        const codeTemp = content?.lastCodeActInfo?.codeTemplate;
        this.codeTemplate = codeTemp ? [codeTemp] : [];
        this.deployInfo = content?.deployInfo ? [content?.deployInfo] : [];
        const tempDet = content?.newMessageTemplateConfig?.templateDetail;
        const newPrivacyInterfaces = content?.newPrivacyInterfaces;
        this.templateDetail = Array.isArray(tempDet) ? tempDet : [];
        this.detailInfo = content;
        this.newPrivacyInterfaces = Array.isArray(newPrivacyInterfaces) ? newPrivacyInterfaces : [];
      });
    },
    logSubmit() {
      if (this.logDate && this.logTimeArr?.length) {
        this.$refs.userLogs.refreshTable();
      } else {
        this.$rs_warning("请选择起止时间");
      }
    },
    getUserLogs() {
      return this.post("/user/log/search", {
        wxAppId: this.wxAppId,
        params: {
          date: this.logDate.replaceAll("-", ""),
          begintime: this.logTimeArr[0],
          endtime: this.logTimeArr[1],
          id: this.logoOpenId,
          limit: 50,
          start: 1,
        },
      }).then((r) => {
        // this.logList = r.ret.list;

        return r.ret;
        // console.log("---user/log/search", r);
      });
    },
    // 列表数据获取
    beforeQuery(e, params, { model, key }) {
      e.preventDefault();
      if (key === "userLogs") {
        const [{ page, size }] = params;

        e.context.modelService.loading = true;
        this.getUserLogs()
          .then((r) => {
            const cont = r;
            cont.size = size;
            cont.total = +cont.total;
            cont.totalPages = Math.ceil(cont.total / size);

            // 分页数据
            e.context.onQueryrSuccess(cont);
          })
          .finally(() => {
            e.context.modelService.loading = false;
          });
        // this.getUserLogs()
      }
    },
    addTester() {
      if (!this.testName) {
        this.$rs_error("请输入微信号");
        return;
      }
      this.post("/mini/mp/tester/add", { wechatId: this.testName, wxAppId: this.wxAppId }).then((r) => {
        this.$rs_success("已添加");
        this.$refs["form"].dealQuery();
        // if (!this.tester.find((i) => i.wechatId == `${this.testName}`.trim())) {
        //   this.tester.push({ wechatId: this.testName });
        // }
        this.testName = "";
      });
    },
    onMockAllinoneDel(a, params, { key }) {
      // 测试者删除
      if (key == "tester") {
        this.post("/mini/mp/tester/del", { userStr: params.userStr, wxAppId: this.wxAppId }).then((r) => {
          this.$rs_success("已删除");
          this.$refs["form"].dealQuery();
        });
      }
    },
    //更新当前代码模板
    onMockMptemplateUpdateTemplate() {
      this.$rs_dlg({
        parent: this,
        cardStyle: "width: 840px",
        title: "更新代码",
        contentComponent: Dlg,
        contentComponentProps: { pickmode: true, selection: "single" },
      }).onOk((e, { content, dlg }) => {
        dlg.close();
        const selected = content.getSelected();
        if (selected && selected.length) {
          this.codeTemplate = selected;
        }
        // e.context.refreshTable();
      });
    },
    onMockMptemplateTocommit() {
      this.doAct("audit", { list: [{ wxAppId: this.wxAppId }], wxAppId: undefined }).then((r) => {
        this.$rs_success("已提交审核");
        setTimeout(() => {
          this.$refs["form"].dealQuery();
        }, 1000);
      });
    },
    // 查看审核失败原因 TODO
    onMockAllinoneCkyy() {
      this.doAct("audit/status");
    },
    // 发布
    onMockAllinoneFb() {
      this.doAct("release");
    },
    // 撤销
    onMockAllinoneCx() {
      this.doAct("/undo/audit").then((r) => {
        this.$rs_success("已撤销审核");
        setTimeout(() => {
          this.$refs["form"].dealQuery();
        }, 1000);
      });
    },
    onMockAllinoneJj(e, p) {
      // TODO
      this.post("/mini/mp/speed/up/audit", { wxAppId: this.wxAppId, auditId: p.appAuditId });
    },
    doAct(act, params) {
      return this.post(`/mini/mp/code/${act}`, { wxAppId: this.wxAppId, ...(params || {}) }).then((r) => {
        this.$refs["form"].dealQuery();
        return r;
      });
    },
    // 订阅模板设置
    onMockAllinoneConfigTemp() {
      const ctx = this;
      this.$rs_dlg({
        parent: this,
        title: "设置订阅消息模板",
        contentComponent: {
          render() {
            return (
              <div class="q-pa-sm">
                <rs-select
                  class="rs-size-sm"
                  v-model={ctx.templateCode}
                  setDefault={true}
                  keyLabel="templateDesc"
                  keyValue="templateCode"
                  data={ctx.templates}
                  outlined></rs-select>
              </div>
            );
          },
        },
      }).onOk((e, { content, dlg }) => {
        this.post("/mini/mp/new/message/template/config", { wxAppId: this.wxAppId, templateCode: this.templateCode });
        dlg.close();
        this.$refs["form"].dealQuery();
        // e.context.refreshTable();
      });
    },

    getTime(timeStep = "") {
      return moment(timeStep).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
