<template>
  <rs-form
    key="actionForm"
    ref="actionForm"
    name="ten-tenant-menu-action"
    :col="1"
    noBorder
    flexLayout
    :restoreByQuery="false"
    wrapPadding=""
    bodyPadding="q-pa-md"
    :value="initFormVal"
  >
    <rs-field name="id" hidden></rs-field>
    <rs-field name="code"></rs-field>
    <rs-field name="name"></rs-field>
    <rs-field name="type"></rs-field>
    <rs-field name="label" value="other"></rs-field>
    <rs-field name="url"></rs-field>
    <rs-field name="scopes" :value="0"></rs-field>
    <rs-field name="target"></rs-field>
    <rs-field name="view"></rs-field>
    <rs-field name="status" :value="1"></rs-field>
    <rs-toolbar></rs-toolbar>
  </rs-form>
</template>
<script>
import Config from "rsui-core/src/config";

// 租户菜单管理中 按钮列表中 新建、编辑自定义Action
export default {
  components: {},
  props: ["initFormVal", "list", "closeDialog", "on"],
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    // 表单提交
    beforeSubmit(e, params) {
      e.preventDefault();
      // 根据是否存在id 判断是不是新建还是更新
      const isCreated = !params?.id;
      if (isCreated) {
        const newId = `${params?.menu?.id || ""}.${params.code}`;
        // 全局按钮中 已存在，请先禁用后再创建
        const sameItem = (this.list || []).find((d) => d.id === newId && d.globals && d.status === 1);
        if (sameItem) {
          this.$rs_error(`相同的ID【${newId}】只能存在一个可用的按钮，请先禁用【${sameItem.name}】后再继续创建。`);
          return;
        }
        // 自定义按钮中 已存在，不能创建相同的两个
        const sameCustItem = (this.list || []).find((d) => d.id === newId && !d.globals);
        if (sameCustItem) {
          this.$rs_error(`已存在相同ID的自定义按钮【${sameCustItem.name}】`);
          return;
        }
      }
      this.$rs_post(
        isCreated ? Config.API.TEN_TENANT_MENU_ACTION_CREATE : Config.API.TEN_TENANT_MENU_ACTION_UPDATE,
        params,
      ).then((resp) => {
        const { id, result } = resp?.content || {};
        // 新建成功后返回ID 或者更新成功后返回result=true
        if (isCreated ? id : result) {
          this.$rs_success(this.$t_s(isCreated ? "add-success" : "edit-success"));
          this.on && this.on(); // 调用外部方法 刷新列表
          this.closeDialog && this.closeDialog();
        }
      });
    },
  },
};
</script>
