<template>
  <rs-list name="wechat-open-platform">
    <rs-field name="id"></rs-field>
    <rs-field name="createType"></rs-field>
    <rs-field name="principalName"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="createBy.nickName"></rs-field>
    <rs-field name="binded" v-slot="row">
      {{ row.binded.map((i) => i.id) }}
    </rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field type="toolbar" exclude="*" include="['bind','unbind']">
      <rs-button name="bind" color="primary" icon="link" label="绑定"></rs-button>
      <rs-button name="unbind" color="negative" icon="link_off" label="解绑"></rs-button>
    </rs-field>
    <rs-toolbar exclude="*" include="relation">
      <rs-button name="relation" color="primary" label="创建开放平台账号"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
import RelationDlg from "@/dialogs/app-auth/relation.vue";
export default {
  methods: {
    onWechatOpenPlatformBind(e) {
      // this.$rs_post("/api/wechat/open/platform/bind", {
      //   openAppId: "openAppidTest", //开放平台账号Id
      //   appIds: ["wxa86c5f462142dev1", "wxa86c5f462142test"],
      // }).then((r) => {
      //   console.error(r);
      // });
      this.$rs_model_dlg({
        modelName: "wechat-app",
        multiple: true,
        conditions: this.sitConditions,
      }).then(({ selected, dlg }) => {
        console.info(selected);
        dlg.hide();
      });
    },
    onWechatOpenPlatformUnbind(e) {
      this.$rs_post("/api/wechat/open/platform/unbind", {
        openAppId: "openAppidTest", //开放平台账号Id
        appIds: ["appid1", "appid2", "appid3"],
      }).then((r) => {
        console.error(r);
      });
    },

    onWechatOpenPlatformRelation() {
      this.$rs_post("/api/wechat/open/platform/create", {
        cltId: 2000,
        createType: "app",
        principalName: "常州众奇",
        createBy: { id: "wxa86c5f462142a632" }, //公众号小程序授权信息对象
      }).then((r) => {
        console.error(r);
      });
      // this.$rs_dlg({
      //   title: "绑定关系",
      //   cardStyle: "width:900px",
      //   parent: this,
      //   contentComponent: RelationDlg,
      //   showFooter: false,
      // });
    },
  },
};
</script>
