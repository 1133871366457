<template>
  <div class="full-height rs-page-ops-ten-service-version-list">
    <rs-list name="ten-service-version" :conditions="versionConditions" keywords="service.id">
      <rs-search label-width="5em">
        <rs-filter name="service" v-if="!serviceId"></rs-filter>
        <rs-filter name="version"></rs-filter>
        <rs-filter name="versionType"></rs-filter>
        <rs-filter name="deployStatus"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="service.id"></rs-field>
      <rs-field name="service.name"></rs-field>
      <rs-field name="version"></rs-field>
      <rs-field name="versionType"></rs-field>
      <rs-field name="apvStatus"></rs-field>
      <rs-field name="deployStatus"></rs-field>
      <rs-field name="onlineTime"></rs-field>
      <rs-field name="offlineTime"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']" exclude="*" v-slot="row">
        <rs-button name="delete" icon="delete" color="negative" :hidden="row.apvStatus === 2"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add']" exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      serviceId: null,
    };
  },
  computed: {
    versionConditions: function() {
      const cons = [];
      if (this.serviceId) {
        cons.push({ key: "service.id", vals: [this.serviceId] });
      }
      return cons;
    },
  },
  mounted() {
    this.serviceId = this.$route.query.serviceId;
  },
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
  },
};
</script>
