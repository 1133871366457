<template>
  <rs-list
    key="actionList"
    ref="actionList"
    name="ten-tenant-menu-action"
    filterHidden
    :isPagination="false"
    wrapClass=""
    :conditions="[
      { key: 'tenant.id', vals: [tenantId] },
      { key: 'menu.id', vals: [menuId] },
    ]"
  >
    <rs-field name="id" hidden></rs-field>
    <rs-field name="code"></rs-field>
    <rs-field name="name"></rs-field>

    <!-- 自定义action 特有的两个字段 globals:是否全局 status:是否可用 -->
    <rs-field name="globals"></rs-field>
    <rs-field name="status"></rs-field>

    <rs-field name="type"></rs-field>
    <rs-field name="label"></rs-field>
    <rs-field name="url"></rs-field>
    <rs-field name="scopes"></rs-field>
    <rs-field name="target"></rs-field>
    <rs-field name="view.name" label="视图"></rs-field>
    <rs-field type="toolbar" :include="['changeStatus', 'edit', 'delete']" exclude="*" v-slot="row">
      <rs-button name="edit" :hidden="!!row.globals"></rs-button>
      <rs-button name="delete" :hidden="!!row.globals"></rs-button>
      <rs-button
        name="changeStatus"
        :icon="row.status === 1 ? 'lock' : 'lock_open'"
        :label="row.status === 1 ? $t_s('disable') : $t_s('enable')"
      />
    </rs-field>
    <rs-toolbar :include="['add']" exclude="*">
      <rs-button name="add" :auth="null"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
import pick from "lodash/pick";
import Config from "rsui-core/src/config";
import TenantMenuActionForm from "./tenant_menu_action__form.vue";

// 租户菜单管理中 按钮列表【自定义action】
export default {
  components: {},
  props: ["tenantId", "menuId", "moduleId"],
  data: function() {
    return {
      list: [], // 列表数据用于新建时 验证是否有冲突
    };
  },
  computed: {},
  methods: {
    // 自定义排序
    beforeQuery(e, params, { key, model }) {
      if (key === "actionList" && model === "ten-tenant-menu-action") {
        params[0].orderby = "globals,sort,id";
      }
      return {
        params,
        resolve: ({ response }) => {
          this.list = (response?.list || []).map((d) => pick(d, ["id", "name", "status", "globals"]));
        },
      };
    },
    // 禁用启用
    onTenTenantMenuActionChangeStatus(e, params) {
      e.preventDefault();
      const changeVal = params.status === 1 ? -1 : 1;
      // 如果要启用一个按钮，先查询一下有没有相同ID的数据已经是启用的,
      // 因为相同的ID只能启用一个
      if (changeVal === 1) {
        const sameItem = this.list.find((d) => d.id == params.id && d.status === 1);
        if (sameItem) {
          this.$rs_error(
            `相同的ID【${sameItem.id}】只能存在一个可用的按钮，请先禁用【${sameItem.name}】后再继续启用当前数据。`,
          );
          return;
        }
      }
      const msg = changeVal === 1 ? this.$t_s("enable") : this.$t_s("disable");
      this.$rs_post(`${Config.API.TEN_TENANT_MENU_ACTION_SET_ENABLE}/${changeVal}`, {
        id: params.id,
        tenant: { id: this.tenantId },
        menu: { id: this.menuId },
        globals: params.globals,
        module: params.module,
      }).then((resp) => {
        if (resp?.content?.result) {
          this.refreshActionList();
          this.$rs_success(msg + this.$t_s("success"));
        }
      });
    },
    // 刷新列表
    refreshActionList() {
      this.$refs.actionList.refreshTable();
    },
    // 删除自定义action
    onTenTenantMenuActionDeleteBeforeConfirm(e, params) {
      e.preventDefault();
      const row = params[0];
      this.$rs_post(Config.API.TEN_TENANT_MENU_ACTION_REMOVE, {
        id: row.id,
        tenant: { id: this.tenantId },
        menu: { id: this.menuId },
        globals: row.globals,
      }).then((resp) => {
        this.refreshActionList();
        this.$rs_success(this.$t_s("delete-success"));
      });
    },
    // 编辑action
    onTenTenantMenuActionEdit(e, params) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "编辑按钮",
        cardStyle: "width:700px;",
        showFooter: false,
        contentComponent: TenantMenuActionForm,
        contentComponentProps: {
          initFormVal: {
            module: { id: this.moduleId }, // 模块ID
            tenant: { id: this.tenantId }, // 所属租户ID
            menu: { id: this.menuId }, // 所属菜单ID
            ...params,
          },
          on: this.refreshActionList,
        },
      });
    },
    // 新建当前租户独有的action
    onTenTenantMenuActionAdd(e, params) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "新建按钮",
        cardStyle: "width:700px;",
        showFooter: false,
        contentComponent: TenantMenuActionForm,
        contentComponentProps: {
          list: this.list,
          initFormVal: {
            module: { id: this.moduleId }, // 模块ID
            tenant: { id: this.tenantId }, // 所属租户ID
            menu: { id: this.menuId }, // 所属菜单ID
            globals: false, // 默认不是全局的action
          },
          on: this.refreshActionList,
        },
      });
    },
  },
};
</script>
