<template>
  <div class="q-pa-sm full-height tenant-biz-callback">
    <rs-form ref="form" allDirty name="biz-callback" @change="onChange" v-slot="form">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id" :hidden="!id"></rs-field>
        <rs-field name="url"></rs-field>
        <rs-field name="tenant"></rs-field>
        <rs-field name="info"></rs-field>
        <rs-field name="module"></rs-field>
        <rs-field name="signature"></rs-field>
        <!-- <rs-field name="secret" :hidden="!id || !form.signature" :widget="{ copyable: true }"></rs-field> -->
        <rs-field
          name="signatureType"
          :required="!!form.signature"
          :hidden="!form.signature"
          :widget="{ setDefault: true, clearable: false }"
        ></rs-field>
        <rs-field
          name="signatureConfigs"
          :required="!!form.signature && form.signatureType === 'self'"
          :hidden="!form.signature"
          :widget="{
            ...signatureConfigsWidget,
            signatureType: form.signatureType,
            tenantId: form.tenant ? form.tenant.id : null,
          }"
        ></rs-field>
      </rs-field>
      <rs-field type="group" ignore name="events">
        <rs-cascades
          ref="eventList"
          :loadDefault="id"
          add-model="biz-event"
          :on-select="onEventSelect"
          :convertInitList="convertInitList"
        >
          <rs-field name="event.id" :editable="false"></rs-field>
          <rs-field name="event.name" :editable="false"></rs-field>
          <rs-field name="configs"></rs-field>
          <rs-field type="toolbar"></rs-field>
        </rs-cascades>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import SignatureConfigs from "@/components/signature-configs/index.vue";

export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      signatureConfigsWidget: {
        _component: SignatureConfigs,
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onChange(val, fireKey, core) {
      // 修改租户且开启验签 且选中类型=self时 清空签名配置【因为选中接口用户有 租户ID条件】
      if (
        fireKey.find((d) => d === "tenant") &&
        fireKey.length === 1 &&
        val.signature &&
        val.signatureType === "self"
      ) {
        setTimeout(() => {
          core.setValue({ signatureConfigs: null });
        });
      }
      // 修改租户且开启验签 且选中类型=self时 清空签名配置【因为选中接口用户有 租户ID条件】
      if (fireKey.find((d) => d === "signature") && fireKey.length === 1 && !val.signature) {
        setTimeout(() => {
          core.setValue({ signatureType: null, signatureConfigs: null });
        });
      }
    },
    convertInitList(list) {
      return list.map((d) => {
        if (d.configs && typeof d.configs === "object") {
          d.configs = JSON.stringify(d.configs);
        }
        return d;
      });
    },
    beforeSubmit(e, params) {
      const p = cloneDeep(params);
      delete p.secret;
      return {
        params: p,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          this.$refs.eventList.save(id, (data) => {
            if (data?.length) {
              data.forEach((d) => {
                if (typeof d.configs === "string") {
                  try {
                    d.configs = JSON.parse(d.configs);
                  } catch (error) {
                    d.configs = null;
                  }
                }
              });
            }
            return data;
          });
          this.$rs_actionRefresh("tenants.callback._view");
        },
      };
    },
    onEventSelect(data) {
      return {
        event: { id: data.id, name: data.name },
        configs: null,
      };
    },
  },
};
</script>
