<template>
  <rs-list name="wechat-open-platform" ref="list">
    <rs-field name="id"></rs-field>
    <rs-field name="createType"></rs-field>
    <rs-field name="principalName"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="createBy.nickName"></rs-field>
    <rs-field name="binded" v-slot="row" hidden>
      {{ row.binded && row.binded.map((i) => i.id) }}
    </rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field type="toolbar" exclude="*" include="['bind','unbind']">
      <rs-button name="bind" color="primary" icon="link" label="绑定"></rs-button>
      <rs-button
        name="unbind"
        color="negative"
        icon="link_off"
        label="全部解绑"
        tips="此操作将解绑全部的公众号和小程序"
      ></rs-button>
    </rs-field>
    <rs-toolbar exclude="*" :include="['create', 'bindPlat']">
      <rs-button name="bindPlat" color="primary" label="绑定已有开放平台"></rs-button>
      <rs-button name="create" color="primary" label="创建开放平台账号"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
import EditDlg from "./openPlatform__dlg.vue";
import BindPlatDlg from "./openPlatform_bind__dlg.vue";

export default {
  methods: {
    // 编辑绑定关系
    onWechatOpenPlatformBind(e, params) {
      this.toCreatePltOrBind(false, params.binded, params.id);
    },
    // 创建开放平台
    onWechatOpenPlatformCreate() {
      this.toCreatePltOrBind(true);
    },
    // 绑定已有开放平台
    onWechatOpenPlatformBindPlat() {
      this.$rs_dlg({
        parent: this,
        title: "绑定已有开放平台",
        cardStyle: "width:600px",
        contentComponent: BindPlatDlg,
        contentComponentProps: {},
      }).onOk((e, { content, dlg }) => {
        if (content.validate()) {
          // this.$rs_post("/api/wechat/open/platform/bind", {
          //   openAppId: content.openAppId, //开放平台账号Id
          //   appIds: [content.appId],
          // }).then((r) => {
          this.$rs_post("/api/wechat/open/platform/create", {
            createType: "third",
            id: content.openAppId,
            principalName: content.principalName,
            createBy: {
              id: content.appId,
            },
          }).then((r) => {
            console.info(r);
            if (r.code === 0) {
              if (r.content?.id) {
                this.$rs_success("绑定成功!");
                dlg.close();
                this.$refs.list.refreshTable();
              }
              // TODO 错误处理
              // if (r.content?.result === false) {
              //   this.$rs_dlg({
              //     parent: this,
              //     title: this.$t_s("tips"),
              //     showFooter: false,
              //     content: (
              //       <div class="q-pa-sm">
              //         {r.content.faileds
              //           .map((f) => {
              //             return `[${f.appId || content.appId}]${f.errMsg}`;
              //           })
              //           .join(";")}
              //       </div>
              //     ),
              //   });
              // }
            }
          });
        }
      });
    },
    // 解除绑定
    onWechatOpenPlatformUnbind(e, params) {
      const ids = (params?.binded || []).map((i) => i.id);
      this.$rs_post("/api/wechat/open/platform/unbind", {
        openAppId: params.id, //开放平台账号Id
        appIds: ids,
      }).then((r) => {
        console.error(r);
        if (r?.content?.result) {
          this.$rs_success("解绑成功");
          this.$refs.list.refreshTable(true);
        } else {
          const errMsg = (r?.content?.faileds || [])
            .map((d) => `${d.appId || ""}解绑失败：${d.errMsg || ""}`)
            .join("\n");
          this.$rs_error(errMsg || "解绑失败");
        }
      });
    },
    //
    toCreatePltOrBind(isAdd, appAuthList, openAppId) {
      this.$rs_dlg({
        parent: this,
        title: isAdd ? "创建开放平台" : "修改绑定关系",
        cardStyle: "width:600px",
        contentComponent: EditDlg,
        contentComponentProps: { appAuthList },
      }).onOk((e, { content, dlg }) => {
        this.doCreateOrBind({ isAdd, list: content.list, openAppId, dlg });
      });
    },
    doCreateOrBind({ isAdd, list, openAppId, dlg }) {
      const createPlatApp = list[0];
      if (!createPlatApp || !createPlatApp.value) {
        this.$rs_warning("请选择要创建开放平台的小程序或公众号");
        return;
      }
      const url = isAdd ? `/api/wechat/open/platform/create` : `/api/wechat/open/platform/bind`;
      const createParams = {
        createType: "app",
        createBy: { id: createPlatApp.value }, //公众号小程序授权信息对象
      };
      const bindParams = {
        openAppId, //开放平台账号Id
        appIds: list.map((i) => i.value).filter(Boolean),
      };
      this.$rs_post(url, isAdd ? createParams : bindParams).then((r) => {
        if (r.code === 0) {
          if (r.content?.result === true) {
            this.$rs_success(this.$t_s(isAdd ? "add-success" : "edit-success"));
          }
          if (r.content?.result === false) {
            this.$rs_dlg({
              parent: this,
              title: this.$t_s("tips"),
              showFooter: false,
              content: (
                <div class="q-pa-sm">
                  {r.content.faileds
                    .map((f) => {
                      return `[${f.appId}]${f.errMsg}`;
                    })
                    .join(";")}
                </div>
              ),
            });
          }
        }
        try {
          dlg.close();
          this.$refs.list.refreshTable();
        } catch (error) {
          console.info("refresh Table error");
        }
      });
    },
  },
};
</script>
