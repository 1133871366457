<template>
  <rs-form
    ref="form"
    name="mock-corpConfig"
    :value="innerValue"
    @change="onFormChange"
    no-border
    allDirty
    class="full-height row"
  >
    <rs-field type="group" label="基本信息">
      <rs-field name="corp_id" readonly></rs-field>
      <rs-field name="corp_name" readonly></rs-field>
      <rs-field name="crm_id"></rs-field>
      <rs-field name="enable"></rs-field>
      <rs-field name="isv_mp_qrcode" readonly label-width="12rem" v-slot="row">
        <img class="rs-border " style="width:120px" :src="row.isv_mp_qrcode" alt="" />
      </rs-field>
    </rs-field>
    <rs-field type="group" label="部署信息">
      <rs-field name="privatization">SAAS</rs-field>
      <rs-field name="gateway_set.url" label-width="8rem" :disabled="false"></rs-field>
    </rs-field>
    <rs-field type="group" label="企微信息">
      <rs-list
        v-if="wxCorpList"
        ref="wx-list"
        name="mock-corpConfig"
        :data="wxCorpList"
        class="full-width"
        :loadDefault="false"
        hideBottom
        :key="Math.random()"
        editMode="row"
        :selection="false"
      >
        <rs-field name="_id" label="_id"></rs-field>
        <rs-field name="wx_corp_id" :editable="true"></rs-field>
        <rs-field name="wx_open_corp_id" :editable="true"></rs-field>
        <rs-field name="wx_corp_name" :editable="true"></rs-field>
        <rs-field type="toolbar"></rs-field>
        <rs-toolbar :include="['add']" exclude="*">
          <rs-button name="add" label="" icon="add" color="white" text-color="black"></rs-button>
        </rs-toolbar>
      </rs-list>
      <div v-else>本租户不使用企微</div>
    </rs-field>
    <rs-field type="group" label="第三方服务信息" name="_">
      <rs-field name="rwin.url" label-width="8rem"></rs-field>
      <rs-field name="rwin.ak" label-width="8rem"></rs-field>
      <rs-field name="rwin.sk" label-width="8rem"></rs-field>
      <rs-field name="rwin.vendor_group_code" label-width="8rem"></rs-field>
    </rs-field>
    <!--  -->
    <rs-field type="group" label="RSTORE业务配置">
      <rs-field name="coupon_ext_config" label-width="7rem" v-slot="row">
        <div class="column text-black item-center full-width">
          {{ row.coupon_ext_config }}
        </div>
      </rs-field>
      <rs-field name="corp_mp_ext_configs" label-width="7rem" v-slot="row">
        <div class="text-black ">
          {{ row.corp_mp_ext_configs }}
        </div>
      </rs-field>
      <rs-field name="target_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.target_config }}
        </div>
      </rs-field>
      <rs-field name="menu_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.menu_config }}
        </div>
      </rs-field>
      <rs-field name="member_ext_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.member_ext_config }}
        </div>
      </rs-field>
      <rs-field name="task_exec_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.task_exec_config }}
        </div>
      </rs-field>
      <rs-field name="achievement_ext_config" label-width="7rem" v-slot="row">
        <div class="full-width text-black">
          {{ row.achievement_ext_config }}
        </div>
      </rs-field>
      <rs-field name="applet_index_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.applet_index_config }}
        </div>
      </rs-field>
      <rs-field name="other_config" label-width="7rem" v-slot="row">
        <div class="row items-center full-width text-black">
          {{ row.other_config }}
        </div>
      </rs-field>
    </rs-field>
    <rs-toolbar></rs-toolbar>
  </rs-form>
</template>
<script>
import Config from "rsui-core/src/config";

export default {
  mixins: [],
  data() {
    return {
      isEditing: false,
      wxCorpList: [], // 企微配置
      innerValue: null,
    };
  },
  computed: {},
  created() {
    this.wxAppId = this.$route.query.id;
  },
  methods: {
    // 表单数据获取
    beforeRequest(e, params, eventId) {
      e.preventDefault();
      this.$rs_post(`${Config.API.RSTORE_CORP_CONFIG_FIND}/${this.wxAppId}`, {}).then((res) => {
        this.innerValue = res.content;
        this.wxCorpList = res.content.wx_corp_list;
      });
    },
    onFormChange(e) {
      this.innerValue = e;
    },

    onMockCorpConfigSubmitBeforeClick(e) {
      e.preventDefault();
      console.log(this.innerValue);
      this.$rs_post(Config.API.RSTORE_CORP_CONFIG_UPDATE, { id: this.wxAppId, ...(this.innerValue || {}) }).then(
        (r) => {
          this.$rs_success("更新成功！");
          this.$rs_actionRefresh("tenants.rstoreSetting._view");
        },
      );
    },
    onMockCorpConfigSaveLineBeforeClick(e) {
      e.preventDefault();
      this.wxCorpList.push(e.params);
    },
    onMockCorpConfigDeleteBeforeConfirm(e) {
      e.preventDefault();
      const index = this.wxCorpList.findIndex((v) => v.wx_open_corp_id === e.params[0].wx_open_corp_id);
      this.wxCorpList.splice(index, 1);
    },
  },
};
</script>
