<template>
  <div class="full-height rs-page-bookset">
    <div class="full-height scroll">
      <rs-list
        ref="list"
        name="book-set"
        keywords="name,id"
        :filter-placeholder="$t_mdata('bookSet-filterPlaceholder')"
      >
        <rs-search label-width="5em" :value="{ status: 1 }">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="type"></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="currency"></rs-filter>
          <rs-filter name="crtTime"></rs-filter>
          <rs-filter name="crtUser"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="type"></rs-field>
        <rs-field name="currency"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field name="crtPlat"></rs-field>
        <rs-field name="crtTime"></rs-field>
        <rs-field name="crtUser"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      editRow: null,
    };
  },
  computed: {},
  methods: {
    onBookSetAdd(e, params, eventId) {
      e.preventDefault();
      this.editRow = null;
      this.openDialog();
    },
    onBookSetEdit(e, params, eventId) {
      e.preventDefault();
      this.editRow = params;
      this.openDialog();
    },
    // 编辑结算主体时 给form传入 编辑的id
    beforeRequest() {
      return { params: { id: this.editRow ? this.editRow.id : null } };
    },
    // 打开新建和编辑弹框，以及保存事件处理
    openDialog() {
      const dlg = this.$rs_dlg({
        parent: this,
        title: `${this.$t_s(this.editRow ? "edit" : "new")}${this.$t_mdata("bookSet-dialogTitle")}`,
        cardStyle: "width: 700px",
        content: (
          <rs-form isUpdateMode={!!this.editRow} ref="form" name="book-set" col="1" label-width="6.5em">
            <rs-field name="id" readonly={false} />
            <rs-field name="name" />
            <rs-field name="type" />
            <rs-field name="currency" />
            <rs-field name="status" value={1} />
          </rs-form>
        ),
      }).onOk(() => {
        this.$refs.form.core.validate().then((r) => {
          if (!r) {
            if (this.editRow) {
              this.$refs.form.modelService.update(this.$refs.form.core.value).then((res) => {
                this.$rs_success(this.$t_s("edit-success"));
                this.$refs.list.refreshTable(true);
                this.editRow = null;
                dlg.hide();
              });
            } else {
              const val = { ...this.$refs.form.core.value };
              this.$refs.form.modelService.create(this.$refs.form.core.value).then((res) => {
                this.$rs_success(this.$t_s("add-success"));
                this.$refs.list.refreshTable(true);
                this.editRow = null;
                dlg.hide();
              });
            }
          }
        });
      });
    },
  },
};
</script>
