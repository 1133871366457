<template>
  <div class="page-meta_form ">
    <rs-form name="field" :col="2" @change="onFormChange">
      <rs-field name="name" />
      <rs-field name="model" :widget="{ conditions: [{ key: 'customize', vals: [true] }] }" />
      <rs-field name="type" :widget="typeWidget" />
      <rs-field name="precision" :widget="precisionWidget" />
      <rs-field name="selectionId" :widget="selectionIdWidget" />
      <!-- 这个是动态的 根据model -->
      <rs-field name="dynmFormVals" :widget="dynmFormValsWidget" :placeholder="$t_s('unlimited')" />
      <rs-field name="propName" required />
      <!-- <rs-field name="sort" /> -->
      <rs-field name="defaultVal" :widget="defaultValWidget" />
      <rs-field name="required" :value="false" />
      <rs-field name="imports" :value="false" />
      <rs-field name="exports" :value="false" />
      <rs-field name="updatable" :value="true" />
      <rs-field name="size" />
      <rs-field name="help" />
      <!-- <rs-field name="remark" /> -->
      <!-- <rs-field name="patterns" /> -->
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.customizeField._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ApiService from "rsui-core/src/system/service/api";
import ModelService from "rsui-core/src/core/model-service";
import cloneDeep from "lodash/cloneDeep";
import { FIELD_TYPE } from "rsui-core/src/core/field";
export default {
  components: {},
  data: function() {
    return {
      modelService: new ModelService(),
      typeWidget: { selection: null, data: [] }, // type字段如果是SELECTION时下拉数据源【只有简单类型】
      precisionWidget: { disable: true }, // 精度【type是数字类型适用】
      selectionIdWidget: { disable: true }, // 通用选项id【type是SELECTION类型适用】
      dynmFormValsWidget: {}, // 根据选择的model 获取对应配置
      defaultValWidget: { disable: true }, // 默认值配置
      booleanOptions: [],
    };
  },
  computed: {},
  created() {
    this.modelService.getModel("field").then((resp) => {
      const typeField = resp.fields.find((d) => d.propName === "type");
      ApiService.option(typeField.selectionId).then(({ content }) => {
        if (content && content.items) {
          const items = cloneDeep(
            content.items.filter((d) =>
              [
                FIELD_TYPE.NUMBER,
                FIELD_TYPE.STRING,
                FIELD_TYPE.BOOLEAN,
                FIELD_TYPE.DATE,
                FIELD_TYPE.DATETIME,
                FIELD_TYPE.SELECTION,
              ].includes(d.code),
            ),
          );
          items.forEach((d) => (d.code = `${d.code}`));
          items.sort((a, b) => a.sort - b.sort);
          this.$set(this.typeWidget, "data", items);
        }
      });
    });
    ApiService.metaModel("option").then((resp) => {
      if (resp && resp.content) {
        this.$set(this, "selectionIdWidget", {
          ...this.selectionIdWidget,
          _type: "rs-obj-select",
          model: resp.content,
          plain: true, // 让rs-obj-select 返回对象id，因为selectionId是字符串类型
        });
      }
    });
    // 获取布尔类型 默认值需要的下拉数据
    ApiService.option("system.boolean").then((resp) => {
      if (resp && resp.content && resp.content.items) {
        const data = cloneDeep(resp.content.items);
        data.forEach((d) => (d.code = `${d.code}`));
        data.sort((a, b) => a.sort - b.sort);
        this.$set(this, "booleanOptions", data);
        // 防止获取变量接口满，导致已回显数据，无下拉数据
        if (
          this.defaultValWidget._selection === "system.boolean" &&
          Array.isArray(this.defaultValWidget.data) &&
          this.defaultValWidget.data.length
        ) {
          this.$set(this, "defaultValWidget", { ...this.defaultValWidget, data: this.booleanOptions });
        }
      }
    });
  },
  methods: {
    onFormChange(val, fireKey, core) {
      // type 字段发生变化时，精度【precision】和通用选项ID【selectionId】的disable状态和可能需要清空值
      if (fireKey.find((d) => d === "type")) {
        const noNumber = val.type !== FIELD_TYPE.NUMBER;
        const noSelection = val.type !== FIELD_TYPE.SELECTION;
        this.$set(this, "defaultValWidget", { disable: !val.type }); // 选中type时，才可以设置默认值
        this.$set(this, "precisionWidget", { disable: noNumber });
        this.$set(this, "selectionIdWidget", { ...this.selectionIdWidget, disable: noSelection });
        if (fireKey.length === 1) {
          setTimeout(() => {
            core.setValue({
              ...(noNumber ? { precision: null } : {}),
              ...(noSelection ? { selectionId: null } : {}),
              defaultVal: null, // 类型发生变化时 清空默认值
            });
          });
          // 当type发生变化时【已排除初始化】,默认值需要设置类型
          let defOpts = null;
          switch (val.type) {
            case FIELD_TYPE.NUMBER:
              defOpts = { disable: false, type: "number" };
              break;
            case FIELD_TYPE.STRING:
              defOpts = { disable: false };
              break;
            case FIELD_TYPE.BOOLEAN:
              defOpts = { disable: false, data: this.booleanOptions, _type: "rs-select", _selection: "system.boolean" };
              break;
            case FIELD_TYPE.DATE:
              defOpts = { disable: false, _type: "rs-date" };
              break;
            case FIELD_TYPE.DATETIME:
              defOpts = { disable: false, _type: "rs-date-time" };
              break;
            case FIELD_TYPE.SELECTION:
            default:
              defOpts = { disable: true };
              break;
          }
          this.$set(this, "defaultValWidget", defOpts);
        }
      }
      if (fireKey.find((d) => d === "selectionId")) {
        if (val.selectionId) {
          ApiService.option(val.selectionId).then((resp) => {
            if (resp && resp.content && resp.content.items) {
              const data = cloneDeep(resp.content.items);
              data.forEach((d) => (d.code = `${d.code}`));
              data.sort((a, b) => a.sort - b.sort);
              this.$set(this, "defaultValWidget", { disable: false, _type: "rs-select", data });
            }
          });
        } else {
          setTimeout(() => {
            core.setValue({ defaultVal: null });
          });
        }
      }
      // model发生变化，那么动态表单值【dynmFormVals】也要发生变化，清空和设置下拉数据源
      if (fireKey.find((d) => d === "model")) {
        const setDynmFormVals = (opts = {}) => {
          if (fireKey.length === 1) {
            setTimeout(() => {
              core.setValue({
                dynmFormVals: null,
              });
              this.$set(this, "dynmFormValsWidget", opts); // 防止清空值时 会闪现一下code
            });
          } else {
            this.$set(this, "dynmFormValsWidget", opts);
          }
        };
        if (val.model && val.model.id) {
          const realModelId = val.model.id.substring(0, val.model.id.length - 4); // 获取"product-ext"的真正关联model:"product"
          ApiService.metaModel(realModelId)
            .then(({ content }) => {
              if (content && content.dynmFormKey) {
                const dynmField = content.fields.find((d) => d.propName === content.dynmFormKey);
                if (dynmField) {
                  if (dynmField.type === FIELD_TYPE.SELECTION && dynmField.selectionId) {
                    return ApiService.option(dynmField.selectionId).then((resp) => {
                      if (resp && resp.content) {
                        const data = cloneDeep(resp.content.items || []);
                        data.forEach((d) => (d.code = `${d.code}`)); // 因为dynmFormVals字段只能保存字符串数组,同classify
                        data.sort((a, b) => a.sort - b.sort);
                        return data;
                      }
                    });
                  } else if (dynmField.type === FIELD_TYPE.MANY2ONE && dynmField.relation) {
                    return ApiService.metaModel(dynmField.relation).then((res) => {
                      if (res && res.content) {
                        const metaModel = res.content;
                        return ApiService.allListByModel(metaModel, {
                          fields: ["id", metaModel.recName],
                        }).then((r) => {
                          if (r && r.content && r.content.list) {
                            const data = r.content.list.map((d) => {
                              return {
                                code: `${d.id}`,
                                name: d[metaModel.recName],
                              };
                            });
                            return data;
                          }
                        });
                      }
                    });
                  }
                }
              }
            })
            .then(
              (data) => {
                setDynmFormVals({
                  _type: "rs-select",
                  data: data || [],
                  multiple: true,
                });
              },
              () => setDynmFormVals(),
            );
        } else {
          setDynmFormVals();
        }
      }
    },
    beforeSubmit(e, params) {
      // 新建时传入一些默认值,防止创建报错
      if (!this.$route.query.id) {
        params = {
          transien: false,
          isArray: false,
          cascades: false,
          deprecates: false,
          ...params,
        };
      }
      params;
      return {
        params,
        resolve: () => {
          this.$rs_actionRefresh("mdata.customizeField._view");
        },
      };
    },
  },
};
</script>
