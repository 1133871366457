<template>
  <rs-list name="wechat-app" :conditions="[{ key: 'appType', vals: ['mp'] }]">
    <rs-field name="id"></rs-field>
    <rs-field name="tenCode"></rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="appType"></rs-field>
    <rs-field name="nickName"></rs-field>
    <rs-field name="signature"></rs-field>
    <rs-field name="userName"></rs-field>
    <rs-field name="principalName"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="openPlat.id"></rs-field>
  </rs-list>
</template>
<script>
import RelationDlg from "@/dialogs/app-auth/relation.vue";
export default {
  methods: {
    // onAuthInfoRelation() {
    //   this.$rs_dlg({
    //     title: "绑定关系",
    //     cardStyle: "width:900px",
    //     parent: this,
    //     contentComponent: RelationDlg,
    //     showFooter: false,
    //   });
    // },
  },
};
</script>
