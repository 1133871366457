<template>
  <div class="q-pa-md q-gutter-sm">
    <section class="row items-center ">
      <span class="label">
        <span>短信通道代码</span>
        <span class="text-red text-bold">*</span>
      </span>
      <q-input v-model="subAppId" :disable="!!form" autofocus dense outlined class="rs-size-sm flex-grow"></q-input>
    </section>
    <section class="row items-center ">
      <span class="label">
        <span>短信通道名称</span>
        <span class="text-red text-bold">*</span>
      </span>
      <q-input v-model="subAppName" autofocus dense outlined class="rs-size-sm flex-grow"></q-input>
    </section>
    <section class="row items-center">
      <span class="label">
        <span>所属平台</span>
        <span class="text-red text-bold">*</span>
      </span>
      <q-input value="无" disable dense outlined class="rs-size-sm flex-grow"></q-input>
    </section>
    <section class="row items-center">
      <span class="label">
        <span>签名名称</span>
        <span class="text-red text-bold">*</span>
      </span>
      <rs-select
        v-model="signature"
        key-value="name"
        :data="signatureList"
        dense
        outlined
        class="rs-size-sm flex-grow"
      ></rs-select>
    </section>
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";

export default {
  mixins: [RsThirdApiMixins],
  props: ["groupId", "form", "appId"],
  data() {
    return { signatureList: [], signature: "", subAppName: "", subAppId: "" };
  },
  created() {
    this.get("/admin/sms/signature/list", { groupId: this.groupId, size: -1 }).then((resp) => {
      this.signatureList = resp.list;
    });
    if (this.form) {
      this.signature = this.form.signature;
      this.subAppName = this.form.subAppName;
      this.subAppId = this.form.subAppId;
    }
  },
  methods: {
    submit() {
      if (!this.subAppId) {
        this.$rs_error("请输入通道代码!");
        return Promise.reject();
      }
      if (!this.subAppName) {
        this.$rs_error("请输入通道名称!");
        return Promise.reject();
      }
      if (!this.signature) {
        this.$rs_error("请选择签名!");
        return Promise.reject();
      }
      if (this.form) {
        return this.post("/admin/sms/account/update", {
          gsaId: this.form.id,
          subAppName: this.subAppName,
          signature: this.signature,
          updator: this.$rs_user.nickName,
        });
      } else {
        return this.post("/admin/sms/account/register", {
          appId: this.appId,
          subAppId: this.subAppId,
          subAppName: this.subAppName,
          signature: this.signature,
          creator: this.$rs_user.nickName,
          updator: this.$rs_user.nickName,
        });
      }
    },
  },
};
</script>
<style scoped>
.label {
  width: 7em;
}
</style>
