<template>
  <div class="full-height biz-error-list">
    <rs-list name="biz-error" keywords="code">
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="code"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="code"></rs-filter>
        <rs-filter name="message"></rs-filter>
        <rs-filter name="module"></rs-filter>
        <rs-filter name="createdAt"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="message"></rs-field>
      <rs-field name="module"></rs-field>
      <rs-field name="createdAt"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']">
        <rs-button name="edit" color="primary" action="tenants.bizError._edit"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary" action="tenants.bizError._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
