<template>
  <div class="q-pa-sm full-height ten-menu-view-form">
    <rs-form ref="form" name="ten-menu-view" @change="onChange">
      <rs-field name="id" :required="false" :hidden="!id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="priority"></rs-field>
      <rs-field name="archDb"></rs-field>
      <rs-field name="archFs"></rs-field>
      <rs-field name="customize"></rs-field>
      <rs-field name="tenant" v-bind="tenantProps"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.view._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      tenantProps: { disable: true },
    };
  },
  computed: {
    isCreated: function() {
      return !this.id;
    },
  },
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      if (!params.config) {
        params.config = null;
      }
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.view._view");
        },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("customize")) {
        this.tenantProps = { disable: !val.customize };
        if (!val.customize) {
          setTimeout(() => {
            core.setValue({ tenant: null });
          });
        }
      }
    },
  },
};
</script>
