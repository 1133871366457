<template>
  <rs-form class="flex-column" no-border name="data-job" col="1" :value="{ handler: on.handler }" @change="onchange">
    <rs-field name="handler" hidden></rs-field>
    <rs-field
      name="ext"
      col="12"
      :extConfig="{
        tenant: {
          widget: {
            returnAllAttrs: true,
            conditions:
              on.handler === 'pullTaskHandler'
                ? [
                    { key: 'status', vals: [1] },
                    { key: 'brokers', op: 'relExists', vals: [{ key: 'rel.status', vals: [1] }] },
                  ]
                : [{ key: 'status', vals: [1] }],
          },
        },
        jobDesc: {
          disable: true,
          value: desc,
        },
        model: {
          widget: {
            emitValue: false,
          },
        },
        subject: {
          widget: {
            isSelectedMode: true,
            selectProps: { simpleMode: true },
          },
        },
      }"
    ></rs-field>
    <!-- <rs-field name="tenant" :widget="{ returnAllAttrs: true }"></rs-field>
    <rs-field required name="url"></rs-field>
    <rs-field name="model" :widget="{ emitValue: false }"></rs-field>
    <rs-field name="jobDesc"></rs-field>
    <rs-field name="encType"></rs-field>
    <rs-field name="encKey"></rs-field> -->
    <rs-toolbar type="toolbar">
      <rs-button name="submit" label="确认执行" icon=""></rs-button>
      <!-- <rs-button color="primary" name="log" label="日志"></rs-button> -->
    </rs-toolbar>
  </rs-form>
</template>
<script>
export default {
  props: ["id", "closeDialog", "on"],
  data() {
    return {
      desc: "",
    };
  },
  methods: {
    onchange(e, a, b) {
      const desc = e?.ext?.model?.item?.name || "";
      this.desc = desc ? `全量事件回调-${desc}` : "";
    },
    beforeRequest(e) {
      e.preventDefault();
    },
    beforeSubmit(e, p) {
      e.preventDefault();
      const { handler, ext } = p || {};
      const { tenant, model, subject, ...params } = ext || {};
      this.$rs_post("/api/task/register", {
        tenId: tenant?.id,
        handler,
        param: {
          ...params,
          dataJobId: this.on.dataJobId,
          tenId: tenant?.id,
          tenCode: tenant?.uname,
          model: model?.item.code || subject?.id,
          modelName: model?.item.name || subject?.name,
        },
      }).then((r) => {
        this.$rs_success("开始执行");
        this.closeDialog();
      });
    },
  },
  mounted() {
    console.info(this.on);
  },
};
</script>
