<template>
  <rs-form
    ref="form"
    flexLayout
    class="full-height ten-module-info-form"
    key="ten-module-info-form"
    name="ten-module-info"
    :col="2"
    bodyPadding="q-ma-sm"
    v-slot="form"
  >
    <rs-field name="id"></rs-field>
    <rs-field name="tenant" readonly></rs-field>
    <rs-field name="module" readonly></rs-field>
    <rs-field name="version" readonly></rs-field>
    <rs-field name="startDate" readonly></rs-field>
    <rs-field name="endDate" :widget="endDataWidget" readonly></rs-field>
    <rs-field name="status" readonly></rs-field>
    <rs-field name="url"></rs-field>
    <rs-field
      name="ext"
      col="12"
      :rules="[configRule]"
      :widget="{ _type: 'rs-ace-editor', height: '100px', lang: 'json' }"
    ></rs-field>
    <!-- :widget="{ _type: 'rs-code-mirror', height: '100px', option: { mode: 'application/json' } }" -->
    <rs-field name="_" type="group" label="系统配置" groupIsCard anchorHidden groupClass="q-mt-sm no-padding" :col="12">
      <template v-slot>
        <q-splitter v-model="splitterModel" :limits="[30, 70]" style="height: 400px;">
          <template v-slot:before>
            <rs-list
              indexColumn
              ref="leftList"
              name="ten-module-setting"
              keywords="name,id"
              wrapClass="no-padding q-mr-sm full-height"
              selection="single"
              filter-placeholder="不限(输入内容与配置ID,配置名称关键字进行模糊匹配)"
              :conditions="[
                { key: 'module.id', vals: [form.module && form.module.id] },
                { key: 'status', vals: [1] },
              ]"
              class="col"
              :isPagination="false"
              @selected="listSelected"
            >
              <rs-field name="id"></rs-field>
              <rs-field name="name"></rs-field>
              <rs-field name="comment"></rs-field>
              <!-- 用来判断是否可以新建 -->
              <rs-field name="insertable" hidden></rs-field>
            </rs-list>
          </template>
          <template v-slot:after>
            <rs-list
              indexColumn
              ref="rightList"
              key="settingDetailList"
              name="ten-module-setting-detail"
              wrapClass="no-padding q-ml-sm full-height"
              :selection="false"
              :filterHidden="true"
              :isPagination="false"
              :preventDbClick="true"
              editMode="row"
              :conditions="detailListConds"
              :btnBottom="isCRMAppAuth"
            >
              <!-- isCRMAppAuth时列表左下角显示 修改授权 和修改履历按钮 -->
              <template v-slot:prependTop>
                <span class="q-pl-xs" style="height:32px;line-height:32px;">
                  {{ selectedSetting.name || "" }}
                </span>
              </template>
              <template v-slot:bottom>
                <q-btn v-if="isCRMAppAuth" size="sm" label="修改授权" color="primary" @click="updateAppAuth" />
                <q-btn
                  v-if="isCRMAppAuth"
                  flat
                  size="sm"
                  class="q-ml-sm text-underline"
                  label="修改履历"
                  color="primary"
                  @click="appAuthHistory"
                />
              </template>

              <rs-field name="id" hidden></rs-field>
              <rs-field name="globalsStatus" hidden></rs-field>
              <!-- 如果系统保留且globalsStatusChange=false,那么数据可不修改状态字段 eg:微信 -->
              <rs-field name="globalsStatusChange" hidden></rs-field>
              <rs-field
                name="code"
                :hidden="diffConf.hideFields.includes('code')"
                :rules="[codeRule]"
                :editable="({ row }) => !row.id"
              ></rs-field>
              <!-- 2023-10-13: 配置ID === SPEC_TYPE 时, 项目名称列不允许修改。 -->
              <rs-field name="name" :label="diffConf.labelMap.name" :editable="!isSpecType"></rs-field>
              <!-- 动态字段 对应顾客来源渠道、订单渠道 -->
              <template v-for="f in extFields">
                <rs-field
                  :key="f.propName"
                  :name="`ext.${f.propName}`"
                  :label="f.name"
                  :hidden="!!f.dynmFormVals && !f.dynmFormVals.includes(selectedSetting.id)"
                  :widget="f.configs"
                  minWidth="100px"
                  :editable="({ row }) => !row.id || !!f.updatable"
                >
                  <template v-slot="props">
                    <component
                      v-if="f.configs._component"
                      :is="componentIs(f.configs._component)"
                      :row="props"
                      :key="props.id"
                      @componentValueInput="componentValueInput"
                    ></component>
                    <span v-else>{{ getExtCellValue(props, f.propName) }}</span>
                    <!-- {{ props.ext ? extMap[f.propName][props.ext[f.propName]] || props.ext[f.propName] || "" : "" }} -->
                  </template>
                </rs-field>
              </template>
              <rs-field
                name="sysReserved"
                :hidden="diffConf.hideFields.includes('sysReserved')"
                :editable="false"
              ></rs-field>
              <rs-field
                name="status"
                :hidden="diffConf.hideFields.includes('status')"
                :widget="{ _type: 'toggle', falseValue: 0, trueValue: 1 }"
              >
                <template v-slot="props">
                  <q-toggle
                    dense
                    size="sm"
                    :false-value="0"
                    :true-value="1"
                    :value="props.status"
                    :disable="!props.globalsStatusChange && !!props.sysReserved"
                    @input="(val) => statusChange(val, props)"
                  />
                </template>
              </rs-field>
              <rs-field
                type="toolbar"
                :hidden="diffConf.hideFields.includes('toolbar')"
                :include="['edit', 'delete', 'saveLine', 'cancelEdit']"
                exclude="*"
              >
                <template v-slot="props">
                  <!-- 系统保留的不可编辑 删除-->
                  <!-- 2023-10-13: 配置ID === SPEC_TYPE 时, 一直允许编辑。 -->
                  <rs-button
                    name="edit"
                    :auth="null"
                    :hidden="isSpecType ? false : !selectedSetting.id || props.sysReserved"
                  ></rs-button>
                  <rs-button name="delete" :auth="null" :hidden="!selectedSetting.id || props.sysReserved"></rs-button>
                </template>
              </rs-field>
              <rs-toolbar :include="['add']" exclude="*">
                <rs-button name="add" :hidden="!selectedSetting.insertable" :auth="null"></rs-button>
              </rs-toolbar>
            </rs-list>
          </template>
        </q-splitter>
      </template>
    </rs-field>
    <rs-toolbar>
      <rs-button name="cancel"></rs-button>
    </rs-toolbar>
  </rs-form>
</template>
<script>
import pick from "lodash/pick";
import { debounce } from "quasar";
import Config from "rsui-core/src/config";
import ModelService from "rsui-core/src/core/model-service";
import ApiService from "rsui-core/src/system/service/api";
import { strFormat } from "rsui-core/src/core/util/functions";
import { UpdateAppAuth, UpdateEndData } from "@/components/tenant-module-info";
import WidgetSettingMethod from "./widget-setting-method.vue";

export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      tenantId: +this.$route.query.tenantId,
      splitterModel: 50,
      selectedSetting: {}, // 系统配置中 左侧列表选中的配置Row
      settingDetailService: new ModelService(), // 右侧列表中 ext字段名称回显需要的服务
      extMap: {}, // ext中 名称map eg: extMap = {CUSTOMER_CHANNEL:{DY:"抖音"},SALES_CHANNEL:{douyin:"抖音"} }
      extFields: [], // 获取ext fields
      appAuthVal: {}, // 获取CRM应用授权默认值，用于修改授权弹框设置默认值

      moduleId: null, // 所属模块ID
      tenModuleService: new ModelService(), // 用于获取订购应用
      hasRstore: false, // 是否订购了Rstore应用，来控制是否显示CRM应用授权-RStore许可数列
      WidgetSettingMethod,
    };
  },
  computed: {
    // 当选中MDATA的规格类型配置时, 右侧需要一些自定义修改:
    // 1.右侧列表一直允许编辑。
    // 2.右侧列表行保存,且是系统保留时, 保存时移除id字段, 让后端生成一条非系统保留数据
    // 3.项目名称列不允许修改
    // 4.右侧列表刷新时 如果存在非系统保留数据,对应code的系统保留数据需要过滤掉。
    isSpecType() {
      return this.selectedSetting.id === "SPEC_TYPE";
    },
    // 当选中CRM应用授权, 右侧列表修改一些列名
    // 1.isCRMAppAuth时列表左下角显示 修改授权 和修改履历按钮
    isCRMAppAuth() {
      return this.selectedSetting.id === "CRM_APP_AUTH";
    },
    isEYEAppAuth() {
      return this.selectedSetting.id === "EYE_TARGET";
    },
    // 根据左侧选中数据，右侧需要隐藏右侧部分列[hideFields] 或者修改列名[field]时
    diffConf() {
      const map = {
        // CRM应用授权时隐藏项目ID、是否系统保留、是否可用、操作列
        CRM_APP_AUTH: {
          hideFields: ["code", "sysReserved", "status", "toolbar"],
          labelMap: {},
        },
        COUPON_MODE: {
          hideFields: ["code", "sysReserved", "toolbar"],
          labelMap: { name: "渠道" },
        },
        EYE_TARGET: {
          hideFields: ["code", "sysReserved", "status", "toolbar"],
          labelMap: {},
        },
      };
      return map[this.selectedSetting.id] || { hideFields: [], labelMap: {} };
    },
    componentConf() {
      return {};
    },
    // 右侧列表查询条件
    detailListConds() {
      const conds = [
        { key: "tenant.id", vals: [this.tenantId] },
        { key: "setting.id", vals: [this.selectedSetting.id] },
      ];
      // 当CRM应用授权选中时，右侧详情列表只展示可用的数据
      if (this.isCRMAppAuth || this.isEYEAppAuth) {
        conds.push({ key: "status", vals: [1] });
      }
      return conds;
    },
    // 结束日期修改新模式，只有SCRM模块时显示
    endDataWidget() {
      return this.moduleId === "SCRM"
        ? {
            _component: UpdateEndData,
            moduleId: this.moduleId, // 模块ID
            tenId: this.tenantId, // 租户ID
            endDateRefresh: this.endDateRefresh,
          }
        : {};
    },
  },
  created() {
    // 防抖 防止
    this.statusChange = debounce(this.statusChange, 500, true);
    // 获取ext模型 得到configs 和 dynmFormVals
    // configs: 新建编辑时 组件需要的参数 {0} 用租户ID替换
    // dynmFormVals: 等于左侧settingId时显示该字段
    ApiService.metaModel("setting-ext").then((resp) => {
      const extMap = {};
      this.extFields = (resp?.content?.fields || []).map((d) => {
        extMap[d.propName] = {};
        return {
          name: d.name,
          propName: d.propName,
          updatable: d.updatable, // 是否可编辑
          // 需要替换conditions中 租户ID占位符
          configs: JSON.parse(strFormat(JSON.stringify(d.configs || {}), [this.tenantId])), // 用于设置widget属性
          dynmFormVals: d.dynmFormVals,
          selectionId: d.selectionId,
        };
      });
      this.extMap = extMap;
      // 通用选项 获取对应名称 用于列表显示
      this.extFields.forEach((d) => {
        if (d.selectionId) {
          ApiService.option(d.selectionId).then((resp) => {
            const itemMap = (resp?.content?.items || []).reduce((map, item) => {
              map[item.code] = item.name || "";
              return map;
            }, {});
            this.$set(this.extMap, d.propName, itemMap);
          });
        }
      });
    });

    // 获取订购应用，check是否订购Rstore,控制是否显示CRM应用授权-RStore许可数列
    this.getTenModuleInfoList();
  },
  mounted() {},
  methods: {
    // 获取ext 列对应显示的值
    getExtCellValue(row, fieldName) {
      if (row.ext) {
        // ext对应fieldName的值
        let val = row.ext[fieldName];
        // 如果是订单渠道-对应顾客来源渠道字段 或者 公域流量导入-订单渠道字段
        // 不是显示渠道ID，而是要显示对应渠道的名称
        const channelName = this.extMap[fieldName][val];

        // 如果是CRM应用授权-接入正常网点许可数、RStore许可数时
        // 如果值是-1，要显示“不限”
        if (val == -1 && ["CRM_SITE_LIMIT", "CRM_RSTORE_LIMIT"].includes(row.code)) {
          val = "不限";
        }
        return channelName || val || "";
      }
      return "";
    },
    // 获取已购应用，用于判断是否订购Rstore,来控制是否显示CRM应用授权-RStore许可数列
    // 获取初始化的授权应用数据
    getTenModuleInfoList() {
      this.tenModuleService
        .list(
          {
            conditions: [{ key: "tenant.id", vals: [this.tenantId] }],
            fields: [
              "id",
              "module.id",
              "module.name",
              "module.type",
              "module.icon",
              "startDate",
              "endDate",
              "status",
              "version",
            ],
            orderby: "sort",
          },
          "ten-module-info",
        )
        .then((resp) => {
          if (resp && resp.list) {
            this.hasRstore = !!resp.list.find((d) => d.module?.id === "RSTORE");
          }
        });
    },
    // config JSON 验证规则添加， 如果value 是字符串类型 说明JSON格式是错误的，因为在rs-code-mirror组件里如果是正确的会转换成object
    configRule(rule, value, callback) {
      if (value && typeof value === "string") {
        callback(new Error(this.$t_s("json-error")));
      } else {
        callback();
      }
    },
    // 查询结果拦截 获取当前所属模块ID
    beforeRequest(e, params, { key, model }) {
      if (model === "ten-module-info" && key === "ten-module-info-form") {
        return {
          params,
          resolve: ({ params }) => {
            this.moduleId = params?.module?.id;
          },
        };
      }
    },
    // 修改结束时间成功后，刷新结束时间
    endDateRefresh(endDate) {
      setTimeout(() => {
        this.$refs.form.core.setValue({ endDate });
      });
    },
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params: pick(params, ["id", "url", "ext"]),
        resolve: ({ response }) => {
          if (this.tenantId) {
            this.$rs_action("tenants.tenant._edit", { query: { id: this.tenantId } });
          }
        },
      };
    },
    // 系统配置 左侧列表选择事件
    listSelected(e) {
      if (this.$refs.rightList.isEditing) {
        this.$rs_confirm(this.$t_s("cancel-tip")).then(
          () => {
            this.$refs.rightList.cacelEdit();
            this.selectedSetting = e?.[0] || {};
          },
          () => {
            // 取消就还原选择项
            setTimeout(() => {
              this.$refs.leftList.currentRow = this.selectedSetting.id ? this.selectedSetting : null;
              this.$refs.leftList.selected = this.selectedSetting.id ? [this.selectedSetting] : [];
            });
          },
        );
      } else {
        this.selectedSetting = e?.[0] || {};
      }
    },
    // 刷新右侧列表
    rightListRefresh() {
      this.$refs.rightList.refreshTable(true);
    },
    // 右侧列表是否可用状态切换
    statusChange(val, row) {
      this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_SET_ENABLE, {
        id: row.id,
        status: row.status ? 0 : 1,
        tenant: { id: this.tenantId }, // 所属租户
      }).then((resp) => {
        if (resp?.content?.result) {
          this.$rs_success(this.$t_s("edit-success"));
          this.rightListRefresh();
        }
      });
    },
    // 为了获取对应ext字段 下拉数据和 列表回显
    beforeQuery(e, params, { model, key }) {
      if (model === "ten-module-setting-detail" && key === "settingDetailList") {
        return {
          params,
          resolve: ({ response }) => {
            if (this.isCRMAppAuth) {
              const keyMap = {
                CRM_ACCOUNT_SET_LIMIT: "accountSetLimit", // 账套许可数
                CRM_SITE_GROUP_LIMIT: "siteGroupLimit", // 品牌组许可数
                CRM_SITE_LIMIT: "siteLimit", // 网点许可数
                CRM_RSTORE_LIMIT: "rstoreLimit", // RStore许可数
                CRM_MALL_LIMIT: "mallLimit", // 会员商城许可数
                CRM_MALL_POLICY_LIMIT: "mallPolicyLimit", // 会员商城分销功能许可数
              };
              this.appAuthVal = (response?.list || []).reduce((map, d) => {
                if (d?.ext?.AUTH_LIMIT) {
                  const key = keyMap[d.code];
                  if (key) map[key] = +d.ext.AUTH_LIMIT;
                }
                return map;
              }, {});
              // 当是CRM应用授权选中时，如果没有订购Rstore应用，不显示“RStore许可数”数据
              if (!this.hasRstore && response?.list) {
                response.list = response.list.filter((d) => d.code !== "CRM_RSTORE_LIMIT");
              }
            }
            // 如果是MDATA的SPEC_TYPE规格类型配置,
            // 如果存在非系统保留数据,对应code的系统保留数据需要过滤掉。
            if (this.isSpecType && response?.list) {
              const filterCodes = response.list.filter((d) => !d.sysReserved).map((d) => d.code);
              response.list = response.list.filter((d) => !(filterCodes.includes(d.code) && d.sysReserved));
            }
            // 过滤当前不显示的ext fields 且不是对象下拉数据
            this.extFields
              .filter((d) => d.configs?.model && (!d.dynmFormVals || d.dynmFormVals.includes(this.selectedSetting.id)))
              .forEach((d) => {
                // 获取列表显示需要的名称
                // 行编辑时 下拉数据获取可用的~
                // 查询ext字段对应全部数据，下拉数据只显示可用的
                this.settingDetailService
                  .allListByModel(
                    {
                      fields: d.configs?.fields || "*",
                      conditions: (d.configs?.conditions || []).filter((d) => d.key !== "status"),
                    },
                    d.configs.model,
                  )
                  .then((resp) => {
                    const itemMap = (resp?.list || []).reduce((map, d) => {
                      map[d.code] = d.name;
                      return map;
                    }, {});
                    this.$set(this.extMap, d.propName, itemMap);
                  });
              });
          },
        };
      }
    },
    // 新建的时候 项目ID【code】不能重复
    codeRule(rule, value, callback) {
      const sameItems = this.$refs.rightList.innerList.filter((d) => d.code === value);
      if (value && sameItems.length) {
        callback(new Error(this.$t_s(`exists-invalid`)));
      } else {
        callback();
      }
    },
    // 新建设置默认值
    onTenModuleSettingDetailAdd(e, params) {
      return {
        params: { sysReserved: false, status: 1 },
      };
    },
    // 保存行
    onTenModuleSettingDetailSaveLine(e, params) {
      e.preventDefault();
      const postParams = {
        ...params.row,
        setting: { id: this.selectedSetting.id },
        tenant: { id: this.tenantId },
      };
      // 2023-10-13: 配置ID === SPEC_TYPE 时, 如果是系统保留数据 保存时 移除id字段
      // 后端会新增一条非系统保留数据
      if (this.isSpecType && params.row.sysReserved) {
        postParams.id = null;
      }
      this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_CREATE_OR_UPDATE, postParams).then((resp) => {
        this.$rs_success(this.$t_s("save-success"));
        this.rightListRefresh();
      });
    },
    // 删除
    onTenModuleSettingDetailDeleteBeforeConfirm(e, params) {
      e.preventDefault();
      this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_REMOVE, {
        tenant: { id: this.tenantId },
        id: params[0].id,
      }).then((resp) => {
        this.$rs_success(this.$t_s("delete-success"));
        this.rightListRefresh();
      });
    },
    // CRM应用授权-修改授权
    updateAppAuth(e) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "修改授权",
        cardStyle: "width:560px",
        showFooter: false,
        contentComponent: UpdateAppAuth,
        contentComponentProps: {
          defValue: { tenId: this.tenantId, ...this.appAuthVal },
          refresh: this.rightListRefresh, // 修改后，刷新数据
        },
      });
    },
    // 查看授权履历列表
    appAuthHistory(e) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "修改履历",
        cardStyle: "width: 1050px;height: 500px;",
        toolbar: [
          { name: "confirm", hidden: true },
          { name: "cancel", label: "关闭", color: "primary" },
        ],
        content: (
          <rs-list
            hiddenTop
            indexColumn
            bordered={false}
            selection={false}
            class="no-padding"
            name="ten-module-setting-detail-his"
            conditions={[
              { key: "type", vals: ["crmAppAuth"] }, // 授权应用
              { key: "tenant.id", vals: [this.tenantId] },
            ]}>
            <rs-field name="id" hidden />
            <rs-field name="summary" />
            <rs-field name="remark" />
            <rs-field name="recUser" />
            <rs-field name="recTime" />
          </rs-list>
        ),
      });
    },
    componentIs(comp) {
      return this[comp] || null;
    },
    componentValueInput(val) {
      const { __ctx, __index, ...left } = val || {};
      const postParams = { ...left, setting: { id: this.selectedSetting.id }, tenant: { id: this.tenantId } };
      const saveOrUpdate = (postParams) => {
        this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_CREATE_OR_UPDATE, postParams)
          .then(() => {
            this.$rs_success(this.$t_s("save-success"));
          })
          .finally(() => {
            this.rightListRefresh();
          });
      };
      if (this.selectedSetting.id === "EYE_TARGET" && left.globalsStatus === 1) {
        // delete postParams.id;
        this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_SET_ENABLE, {
          id: postParams.id,
          status: 0,
          tenant: { id: this.tenantId }, // 所属租户
        }).then((resp) => {
          if (resp?.content?.result) {
            delete postParams.id;
            saveOrUpdate(postParams);
          }
        });
      } else {
        saveOrUpdate(postParams);
      }
    },
  },
};
</script>
