<script>
import ModelService from "rsui-core/src/core/model-service";
import { list2tree } from "rsui-core/src/core/util/list2tree";
export default {
  name: "TenMenuTreeViewDlg",
  props: { tenantId: {} },
  data() {
    return {
      defaultModules: [
        { id: "PORTAL", name: "平台" },
        { id: "MDATA", name: "档案" },
        { id: "SCRM", name: "消费者资源管理" },
        { id: "CUSTOMERCENTER", name: "智慧会员" },
      ],
      treeData: [],
    };
  },
  created() {
    const sv = new ModelService();
    Promise.all([
      sv.list(
        {
          fields: [
            "id",
            "menu.code",
            "menu.name",
            "menu.pid",
            "menu.globals",
            "menu.scopes",
            "menu.module.name",
            "status",
          ],
          page: 1,
          size: 2000,
          orderby: "id",
          conditions: [
            { key: "tenant.id", vals: [this.tenantId] },
            { key: "menu.module.id", vals: this.defaultModules.map((m) => m.id) },
          ],
        },
        "ten-menu-info",
      ),
      this.$rs_post("/api/ops/ten-tenant-menu-action/use-list", {
        conditions: [
          { key: "tenant.id", vals: [this.tenantId] },
          { key: "module.id", vals: this.defaultModules.map((m) => m.id) },
        ],
      }),
    ]).then((r) => {
      const allTenMenu = (r?.[0]?.list || []).sort((a, b) => a.menu?.sort - b.menu?.sort);
      const allTenMenuAction = r?.[1]?.content?.list || [];
      allTenMenu.forEach((m) => {
        allTenMenuAction.forEach((a) => {
          if (m?.menu?.id === a?.menu?.id) {
            allTenMenu.push({
              id: a.id,
              menu: { id: a.id, name: `${a.name}（${a.useCount || 0}次）`, pid: a.menu.id },
              useCount: a.useCount,
            });
          }
        });
      });
      const _allTenMenu = allTenMenu.map((d) => {
        return {
          // ...d,
          _name: d.menu.name,
          _id: d.menu.id,
          _pid: d.menu.pid,
          _mid: d.menu.module?.id,
        };
      });
      const treeNodes = list2tree(_allTenMenu, "_id", "_pid");
      const result = this.defaultModules.map((v) => {
        return { _id: v.id, _name: v.name, children: [] };
      });
      result.forEach((r) => {
        treeNodes.forEach((t) => {
          if (r._id === t._mid) {
            r.children.push(t);
          }
        });
      });
      this.$set(this, "treeData", result);
    });
  },
  render() {
    return (
      <rs-tree-picker
        ref="treePicker"
        class="no-padding full-height"
        tickStrategy="none"
        data={this.treeData}
        node-key="_id"
        label-key="_name"
        on-filterFinish={(e) => {
          if (e.length === 0) {
            this.$rs_warning("无匹配记录，请重新输入！");
          }
        }}
      />
    );
  },
};
</script>
