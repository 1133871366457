<template>
  <div class="full-height rs-page-tenant-list">
    <rs-list
      ref="list"
      name="ten-menu-info"
      keywords="menu.name,menu.code"
      :conditions="[{ key: 'tenant.id', vals: [tenantId] }]"
    >
      <rs-field name="id" hidden></rs-field>
      <rs-field name="menu.code"></rs-field>
      <rs-field name="menu.name"></rs-field>
      <rs-field name="menu.pid"></rs-field>
      <rs-field name="menu.globals"></rs-field>
      <rs-field name="menu.scopes"></rs-field>
      <rs-field name="menu.module.name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field type="toolbar" :include="['changeEnable', 'actionList']" exclude="*" v-slot="row">
        <rs-button
          name="changeEnable"
          :icon="row.status === 1 ? 'lock' : 'lock_open'"
          :label="row.status === 1 ? $t_s('disable') : $t_s('enable')"
        />
        <rs-button
          name="actionList"
          label="按钮列表"
          color="secondary"
          icon="dashboard_customize"
          :hidden="!row.menu || !row.menu.pid"
        />
      </rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import TenantMenuActionList from "./tenant_menu_action__list.vue";

export default {
  components: {},
  data: function() {
    return {
      modelService: new ModelService(),
      tenantId: +this.$route.query.tenantId,
    };
  },
  computed: {},
  methods: {
    onTenMenuInfoChangeEnable(e, params) {
      const changeVal = params.status === 1 ? -1 : 1;
      const msg = changeVal === 1 ? this.$t_s("enable") : this.$t_s("disable");
      this.modelService.setEnable([params.menu.id], changeVal, "ten-menu-info", {}, this.tenantId).then((result) => {
        if (result) {
          this.$refs.list.refreshTable();
          this.$rs_success(msg + this.$t_s("success"));
        }
        // else {
        //   this.$rs_error(msg + this.$t_s("fail"));
        // }
      });
    },
    beforeQuery(e, params, { key, model }) {
      if (key === "actionList" && model === "ten-tenant-menu-action") {
        params[0].orderby = "globals,sort,id";
      }
      return { params };
    },
    onTenMenuInfoActionList(e, params) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "按钮列表",
        cardStyle: "width:80vw;height:700px;",
        toolbar: [
          { name: "confirm", hidden: true },
          { name: "cancel", label: "关闭", color: "primary" },
        ],
        contentComponent: TenantMenuActionList,
        contentComponentProps: {
          tenantId: this.tenantId,
          menuId: params.menu.id,
          moduleId: params.menu.module.id,
        },
      });
    },
  },
};
</script>
