<template>
  <div class="full-height scroll  rs-page-brand-form">
    <rs-form name="brand" label-width="6.5em" floatLayout>
      <rs-field name="image" :widget="{ _type: 'rs-head-image', fixedNumber: [108, 108] }" floatRight></rs-field>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="shortCode"></rs-field>
      <rs-field
        name="info"
        floatRight
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-field name="status"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.brand._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  methods: {
    beforeSubmit({ preventDefault }, params) {
      return {
        resolve: () => {
          this.$rs_actionRefresh("mdata.brand._view");
        },
      };
    },
  },
};
</script>
