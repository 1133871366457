<template>
  <div class="q-pa-sm full-height ops-saas-deploy-form">
    <rs-form ref="form" name="ten-deploy">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="tenantMode" value="saas" readonly></rs-field>
        <rs-field name="type" readonly></rs-field>
        <rs-field name="name" readonly></rs-field>
        <rs-field name="status" readonly></rs-field>
        <rs-field name="jobId" hidden></rs-field>
        <rs-field name="tenants" col="8"></rs-field>
        <rs-field
          name="info"
          col="12"
          readonly
          :widget="{ _type: 'input', inputStyle: { height: '60px' }, type: 'textarea' }"
        ></rs-field>
      </rs-field>
      <rs-field type="group" name="services">
        <rs-list ref="list" class="col" name="ten-deploy-service" :data="dataList" :loadDefault="false">
          <rs-field name="version"></rs-field>
          <rs-field name="service.id"></rs-field>
          <rs-field name="service.name"></rs-field>
          <rs-field type="toolbar" exclude="*"></rs-field>
          <rs-toolbar exclude="*"></rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-toolbar exclude="*" :include="['cancel', 'deploylog', 'retry']">
        <rs-button name="cancel" action="ops.saasDeploy._view"></rs-button>
        <rs-button name="deploylog" label="发布日志" icon="sticky_note_2" color="secondary" v-if="hasJobId"></rs-button>
        <rs-button
          name="retry"
          label="重试发布"
          icon="replay"
          color="deep-purple"
          v-if="hasJobId && hasError"
        ></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      dataList: [],
      modelService: new ModelService(),
      hasJobId: false,
      hasError: false,
    };
  },
  mounted() {
    if (this.id) {
      // 查询已经保存的微服务列表
      this.modelService
        .list(
          {
            fields: ["version", "service.id", "service.name"],
            page: 1,
            size: -1,
            conditions: [{ key: "deploy.id", vals: [this.id] }],
          },
          "ten-deploy-service",
        )
        .then((resp) => {
          this.dataList = resp.list || [];
        });
    }
  },
  methods: {
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          // 审批之后不允许编辑
          this.hasJobId = !!params.jobId;
          this.hasError = params.status === 3;
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      e.preventDefault();
    },

    onTenDeployDeploylog() {
      this.$router.push({
        path: "matrix.jobLog._view",
        query: { jobId: this.$refs.form.core.value.jobId, directlog: true },
      });
    },

    onTenDeployRetry() {
      const params = {
        id: this.$refs.form.core.value.jobId,
        executorParam: JSON.stringify({ deployId: this.id, retry: true }),
      };
      this.$rs_post("/api/ops/task/trigger", params).then((resp) => {
        if (resp && resp.code === 0 && resp.content.code === 200) {
          this.hasError = false;
          this.$rs_success("重试成功");
          this.onTenDeployDeploylog();
        } else {
          this.$rs_warning("重试失败：" + resp.content.msg || resp.message || "");
        }
      });
    },
  },
};
</script>
