<template>
  <div class="full-height rs-page-ten-shared-db-list">
    <rs-list name="ten-shared-db" keywords="name,id">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="platform"></rs-filter>
        <rs-filter name="dbHost"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="maxSlots"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="platform"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="dbHost"></rs-field>
      <rs-field name="maxSlots"></rs-field>
      <rs-field name="nowSlots"></rs-field>
      <rs-field name="lastAllocTime"></rs-field>
      <rs-field name="info"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']"></rs-field>
      <rs-toolbar :include="['add']" exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
