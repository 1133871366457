<template>
  <div class="full-height rs-page-invoice">
    <div class="full-height scroll">
      <rs-list
        ref="list"
        name="invoice-info"
        keywords="tinNo,name,bankName,bankAccount"
        :filter-placeholder="$t_mdata('invoiceInfo-filterPlaceholder')"
      >
        <rs-search label-width="6.5em" :value="{ status: 1 }">
          <rs-filter type="keywords" name="tinNo,name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="tinNo"></rs-filter>
          <rs-filter name="bankName"></rs-filter>
          <rs-filter name="bankAccount"></rs-filter>
          <rs-filter name="taxpayerType"></rs-filter>
          <rs-filter name="bset" front></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="tinNo"></rs-field>
        <rs-field name="address"></rs-field>
        <rs-field name="telephone"></rs-field>
        <rs-field name="bankName"></rs-field>
        <rs-field name="bankAccount"></rs-field>
        <rs-field name="taxpayerType"></rs-field>
        <rs-field name="taxRate" numeral="0.00%"></rs-field>
        <rs-field name="bset.name"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field name="recUser"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field type="toolbar"></rs-field>
        <rs-toolbar>
          <!-- <rs-button name="add" color="primary" action="mdata.tradeAccount_form"></rs-button>
        <rs-button name="edit" action="mdata.tradeAccount_form"></rs-button> -->
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      editRow: null,
    };
  },
  computed: {},
  methods: {
    onInvoiceInfoAdd(e) {
      e.preventDefault();
      this.editRow = null;
      this.openDialog();
    },
    onInvoiceInfoEdit(e, selected) {
      e.preventDefault();
      this.editRow = selected;
      this.openDialog();
    },
    // 编辑时 给form传入 编辑的id
    beforeRequest() {
      return { params: { id: this.editRow ? this.editRow.id : null } };
    },
    openDialog() {
      const dlg = this.$rs_dlg({
        parent: this,
        title: `${this.$t_s(this.editRow ? "edit" : "new")}${this.$t_mdata("invoice-info")}`,
        cardStyle: "width: 700px",
        content: (
          <rs-form
            ref="form"
            isUpdateMode={!!this.editRow}
            name="invoice-info"
            col="1"
            label-width="6.5em"
            class="col-auto">
            <rs-field name="id" />
            <rs-field name="name" />
            <rs-field name="tinNo" />
            <rs-field name="address" />
            <rs-field name="telephone" />
            <rs-field name="bankName" />
            <rs-field name="bankAccount" />
            <rs-field name="taxpayerType" />
            <rs-field name="taxRate" widget={{ _type: "rs-percent-input", _extend: true }} suffix="%" />
            <rs-field name="bset" />
            <rs-field name="status" value={1} />
          </rs-form>
        ),
      }).onOk(() => {
        this.$refs.form.core.validate().then((r) => {
          if (!r) {
            if (this.editRow) {
              this.$refs.form.modelService.update(this.$refs.form.core.value).then((res) => {
                this.$rs_success(this.$t_s("edit-success"));
                this.$refs.list.refreshTable(true);
                this.editRow = null;
                dlg.hide();
              });
            } else {
              this.$refs.form.core.value.plat = { id: this.$rs_user.platform };
              this.$refs.form.modelService.create(this.$refs.form.core.value).then((res) => {
                this.$rs_success(this.$t_s("add-success"));
                this.$refs.list.refreshTable(true);
                this.editRow = null;
                dlg.hide();
              });
            }
          }
        });
      });
    },
  },
};
</script>
