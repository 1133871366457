<template>
  <rs-list
    name="platform"
    :conditions="conditions"
    keywords="name,id"
    :filter-placeholder="$t_settings('platform-filterPlaceholder')"
  >
    <rs-search :value="{ status: 1 }">
      <rs-filter type="keywords" name="name,id"></rs-filter>
      <rs-filter name="id"></rs-filter>
      <rs-filter name="name"></rs-filter>
      <rs-filter name="bset"></rs-filter>
      <rs-filter name="adminUser" front></rs-filter>
      <rs-filter name="modules"></rs-filter>
      <rs-filter name="crtTime"></rs-filter>
      <rs-filter name="crtUser"></rs-filter>
      <rs-filter name="recTime"></rs-filter>
      <rs-filter name="recUser"></rs-filter>
      <rs-filter name="status"></rs-filter>
    </rs-search>
    <rs-field name="id"></rs-field>
    <rs-field name="name"></rs-field>
    <rs-field name="bset.name"></rs-field>
    <rs-field name="adminUser.nickName"></rs-field>
    <rs-field name="adminUser.mobile"></rs-field>
    <rs-field name="adminUser.email"></rs-field>
    <rs-field name="crtPlat"></rs-field>
    <rs-field name="status"></rs-field>
    <!-- <rs-field name="description"></rs-field> -->
    <rs-field name="crtTime"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field type="toolbar">
      <rs-button name="edit" action="settings.subs._edit"></rs-button>
    </rs-field>
    <rs-toolbar :exclude="['imports', 'exports']">
      <rs-button name="add" color="primary" action="settings.subs._add"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      conditions: [{ key: "type", vals: [2] }],
    };
  },
  computed: {},
  methods: {},
};
</script>
