<template>
  <div style="width:350px;">
    <q-tabs v-model="rsLayout.tab" dense inline-label class="rs-profile bg-grey-1 text-grey-5 " active-color="primary">
      <q-tab name="base" icon="face" :label="$t_s('profile')" class="text-caption" />
      <q-tab name="changePassword" icon="lock" :label="$t_s('reset-password')" />
      <q-tab name="setting" icon="settings" :label="$t_s('settings')" />
    </q-tabs>
    <q-separator class="full-width" />

    <q-tab-panels class=" flex-grow" v-model="rsLayout.tab" animated>
      <q-tab-panel name="base" class="no-padding">
        <q-list class="full-height">
          <q-item clickable v-ripple>
            <q-item-section>{{ $t_s("nickname-label") }}</q-item-section>
            <q-item-section side>
              <q-item-label v-if="$rs_user.isAdmin" caption class="text-body">
                {{ $t_s("administrator-label") }}
              </q-item-label>
              <q-item-label>{{ $rs_user.nickName }}</q-item-label>
            </q-item-section>
          </q-item>
          <!-- <q-item clickable v-ripple>
            <q-item-section>{{ $t_s("plt-type-label") }}</q-item-section>
            <q-item-section side>
              <q-item-label>{{ $rs_user.platformTypeName }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple>
            <q-item-section>{{ $t_s("plt-name-label") }}</q-item-section>
            <q-item-section side>
              <q-item-label>{{ $rs_user.platformName }}</q-item-label>
            </q-item-section>
          </q-item> -->
          <q-space></q-space>
          <q-item class="row q-gutter-sm">
            <!-- <q-btn color="primary" class="flex-grow" size="sm" outline @click="bind">
              <svg class="rs-icon" aria-hidden="true" style="height:1.5em;width:1.5em">
                <use xlink:href="#icondingding"></use>
              </svg>
              <span class="text-caption on-right">{{ $t_s("bind") }}{{ $t_s("ding-talk") }}</span>
              <q-popup-proxy v-model="qrcodeShow">
                <div id="login_container"></div>
              </q-popup-proxy>
            </q-btn> -->

            <rs-confirm-btn
              :tips="$t_s('logout-tip')"
              icon="power_settings_new"
              outline
              class="full-width"
              @confirm="rsLayout.logout"
              color="grey-5"
            >
              <span class="text-caption on-right">{{ $t_s("logout") }}</span>
            </rs-confirm-btn>
          </q-item>
        </q-list>
      </q-tab-panel>

      <q-tab-panel name="changePassword">
        <rs-change-pswd></rs-change-pswd>
      </q-tab-panel>

      <q-tab-panel name="setting" class="no-padding">
        <q-list>
          <q-item clickable>
            <q-item-section>{{ $t_s("setting-theme") }}</q-item-section>
            <q-item-section side style="flex-direction:row">
              <q-chip
                v-for="theme in themes"
                @click.native="$rs_user.setTheme(theme)"
                :key="theme.primary"
                :style="`background-color:${theme.primary}`"
              >
                &nbsp;
                <q-icon v-if="$rs_user.theme.primary === theme.primary" color="white" name="check"></q-icon>
              </q-chip>
            </q-item-section>
          </q-item>
          <q-item clickable>
            <q-item-section>{{ $t_s("setting-language") }}</q-item-section>
            <q-item-section side>
              <RsLanguage></RsLanguage>
            </q-item-section>
          </q-item>

          <q-item clickable>
            <q-item-section>{{ $t_s("setting-breadcrumbs") }}</q-item-section>
            <q-item-section side>
              <q-toggle v-model="$rs_user.breadcrumbs" />
            </q-item-section>
          </q-item>
          <q-item clickable>
            <q-item-section>{{ $t_s("setting-tab") }}</q-item-section>
            <q-item-section side>
              <q-toggle v-model="$rs_user.pageTab" />
            </q-item-section>
          </q-item>
          <q-item clickable>
            <q-item-section>{{ $t_s("setting-label-style") }}</q-item-section>
            <q-item-section side>
              <q-toggle v-model="$rs_user.stackLabel" />
            </q-item-section>
          </q-item>
          <q-item clickable>
            <q-item-section>{{ $t_s("setting-tab-style") }}</q-item-section>
            <q-item-section side>
              <q-toggle v-model="$rs_user.tabStyle" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import Profile from "rsui-core/src/system/buildin/profile.vue";
export default {
  mixins: [Profile],
};
</script>
