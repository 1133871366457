<template>
  <div class="full-height flex-column">
    <q-tabs
      v-model="activeTab"
      @input="activeTabChange"
      dense
      align="left"
      class=" bg-white text-black"
      :breakpoint="0"
      active-color="primary"
    >
      <q-tab v-for="tab in subs" :name="tab.sub" :label="tab.name" :key="tab.sub"></q-tab>
    </q-tabs>
    <q-tab-panels class="bg-grey-2  flex-grow" v-model="activeTab" animated keep-alive>
      <q-tab-panel v-for="tab in subs" :name="tab.sub" :key="tab.sub" class=" q-pa-none">
        <rs-list name="mock-appdeploy" :ref="'r_' + tab.sub">
          <rs-field name="wxAppId"></rs-field>
          <rs-field name="wxAppName"></rs-field>
          <rs-field name="tenId"></rs-field>
          <rs-field name="tenCode"></rs-field>
          <rs-field name="deployInfo" v-slot="row">
            <span>{{ row.deployInfo && row.deployInfo.deployVersion }}</span>
          </rs-field>
          <rs-field name="lastCodeActInfo_appActVersion" v-slot="row">
            {{ row.lastCodeActInfo && row.lastCodeActInfo.appActVersion }}
          </rs-field>
          <rs-field name="lastCodeActInfo_appAuditStatus" v-slot="row">
            {{ row.lastCodeActInfo && row.lastCodeActInfo.appActStatus }}
          </rs-field>
          <rs-field name="status" v-slot="row">
            {{ statusTxt(row) }}
          </rs-field>
          <!--          <rs-field name="auditStatus" v-slot="row">-->
          <!--            {{ row.auditStatus === 0 ? "未审核" : "已审核" }}-->
          <!--          </rs-field>-->
          <!-- <rs-field name="flag"></rs-field> -->
          <rs-field name="liveState" v-slot="row">
            <span>{{ row.liveState === 0 ? "关闭" : "开启" }}</span>
          </rs-field>
          <!-- <rs-field name="liveEnable"></rs-field> -->
          <rs-field
            type="toolbar"
            exclude="*"
            :include="['edit', 'disableLive', 'enableLive', 'tempManage']"
            v-slot="row"
          >
            <rs-button
              :hidden="!row.liveEnable || row.liveState === 0"
              type="link"
              color="red"
              label="关闭直播"
              name="disableLive"
            ></rs-button>
            <rs-button
              :hidden="!row.liveEnable || row.liveState === 1"
              type="link"
              label="开启直播"
              name="enableLive"
            ></rs-button>
          </rs-field>
          <rs-toolbar exclude="*" :include="['batUpdate', 'batDeploy', 'batApproval', 'batTempUpdate', 'tempManage']">
            <rs-button name="batUpdate" color="primary" label="批量更新"></rs-button>
            <rs-button name="batApproval" color="primary" label="批量提审"></rs-button>
            <rs-button name="batDeploy" color="primary" label="批量发布"></rs-button>
            <rs-button
              name="tempManage"
              :fold="false"
              color="primary"
              label="模板管理"
              action="tenants.app_deploy._mptemplate"
            ></rs-button>
            <rs-button name="batTempUpdate" fold color="primary" label="批量更新订阅消息模板"></rs-button>
          </rs-toolbar>
          <template v-slot:summary>
            <div class="q-gutter-sm row items-center">
              <div>
                <q-input
                  placeholder="小程序APP名称"
                  v-model="queryForm.keywords"
                  dense
                  class="rs-size-sm w-search"
                  outlined
                ></q-input>
              </div>
              <rs-select
                placeholder="租户(全部)"
                v-model="queryForm.tenCode"
                class="rs-size-sm w-search"
                :data="companyList"
                outlined
              ></rs-select>
              <rs-select
                placeholder="直播状态(全部)"
                v-model="queryForm.liveState"
                :data="liveStatus"
                class="rs-size-sm w-search"
                outlined
              ></rs-select>
              <rs-select
                placeholder="提审状态(全部)"
                v-model="queryForm.appStatus"
                :data="appStatus"
                class="rs-size-sm w-search"
                outlined
              ></rs-select>
              <div>
                <q-btn size="sm" @click="search" icon-right="search" color="primary"></q-btn>
              </div>
              <div>
                <q-btn size="sm" @click="refresh" icon-right="autorenew" color="info"></q-btn>
              </div>
            </div>
          </template>
        </rs-list>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import RsThirdApiMixins from "./appdeploy-mixins.vue";
import Dlg from "./app_deploy__mptemplate";

const org = {
  tenCode: null,
  keywords: null,
  liveState: null,
  appStatus: null,
};

const liveStatus = [
  { code: 0, name: "关闭" },
  { code: 1, name: "开启" },
];

const appStatus = [
  { code: 0, name: "初始化-未发代码" },
  { code: 1, name: "待提审" },
  { code: 2, name: "审核中" },
  { code: 3, name: "待发布" },
  { code: 4, name: "审核失败" },
  { code: 5, name: "已发布" },
  { code: 6, name: "无提审版本" },
];

export default {
  mixins: [RsThirdApiMixins],
  data() {
    return {
      subs: [], // 以sub 为 tab 单位，分立多个tab
      activeTab: null, // 当前sub
      companyList: [], // 授权的租户
      // 查询表单
      queryForm: {
        ...org,
      },
      appStatus, // app审核状态机
      liveStatus, // 直播状态机
      templateCode: null, // 选中的订阅消息模版
      templates: [], //订阅消息模版
    };
  },
  methods: {
    // 获取订阅消息模版
    getTemplates(wxAppId) {
      if (this.templates && this.templates.length) {
        return Promise.resolve(this.templates);
      }
      return this.post("/mini/mp/new/message/template/list", { wxAppId }).then((r) => {
        this.templates = r.list;
      });
    },
    // 刷新表格
    refresh() {
      try {
        const [cmp] = this.$refs[`r_${this.activeTab}`];
        cmp.refreshTable();
      } catch (error) {
        console.info(error);
      }
    },
    // 重置搜索条件
    reset() {
      this.$set(this, "queryForm", { ...org });
    },

    search() {
      const [cmp] = this.$refs[`r_${this.activeTab}`];
      console.log(cmp);
      cmp.refreshTable();
    },
    // TODO 切换tab 事件
    activeTabChange() {
      //
    },
    // 获取数据
    beforeQuery(e, params) {
      e.preventDefault();
      e.context.modelService.loading = true;
      this.dealList({ url: "/mini/mp/list", data: { sub: this.activeTab, ...this.queryForm } }, e, params, (r) => {
        r.list.forEach((element) => {
          element["id"] = element["wxAppId"];
        });
        return r;
      }).finally(() => {
        e.context.modelService.loading = false;
      });
    },
    // 批量更新订阅模板
    onMockAppdeployBatTempUpdate(e, b, c) {
      // /mini/mp/new/message/template/batch/config

      const selected = e?.context?.selected;
      console.error(selected);
      if (!selected?.length) {
        this.$rs_warning("请选择要更新订阅模版的小程序");
        return;
      }

      const appids = selected.map((i) => {
        return i.wxAppId;
      });
      this.getTemplates(appids[0]).then((temps) => {
        const ctx = this;
        this.$rs_dlg({
          parent: this,
          title: "订阅消息模板",
          contentComponent: {
            render() {
              return (
                <div class="q-pa-sm">
                  <rs-select
                    class="rs-size-sm"
                    v-model={ctx.templateCode}
                    setDefault={true}
                    keyLabel="templateDesc"
                    keyValue="templateCode"
                    data={ctx.templates}
                    outlined></rs-select>
                </div>
              );
            },
          },
        }).onOk((e, { content, dlg }) => {
          this.post("/mini/mp/new/message/template/batch/config", {
            wxAppIds: appids,
            templateCode: this.templateCode,
          }).then((r) => {
            if (Array.isArray(r.list)) {
              const re = r.list.map((i, index) => {
                return (
                  <div class="q-pa-sm">
                    {index + 1}
                    <span>{i.wxAppId}</span> <span>{i.result ? "提审成功" : i.errMessage}</span>
                  </div>
                );
              });
              this.$rs_dlg({
                parent: this,
                showFooter: false,
                cardStyle: "max-height:600px;overflow:auto",
                title: "提审结果",
                content: re,
              });
            }
            dlg.close();
          });

          // e.context.refreshTable();
        });
      });
    },

    // 关闭直播 TODO
    onMockAppdeployDisableLive() {
      //
    },
    // 关闭直播 TODO
    onMockAppdeployEnableLive() {
      //
    },
    // 批量更新 TODO
    onMockAppdeployBatUpdate(e, b, c) {
      "/mini/mp/code/commit";
      const selected = e?.context?.selected;
      if (!selected?.length) {
        this.$rs_warning("请选择要更新的小程序");
        return;
      }
      this.$rs_dlg({
        parent: this,
        cardStyle: "width: 840px",
        title: "更新代码",
        contentComponent: Dlg,
        contentComponentProps: { pickmode: true, selection: "single" },
      }).onOk((e, { content, dlg }) => {
        // dlg.close();
        const [selectedTmp] = content.getSelected();
        if (!selectedTmp) {
          this.$rs_error("请选择更新的模板");
          return;
        }
        if (selected?.length && selectedTmp) {
          debugger;
          const list = selected.map((i) => {
            return {
              wxAppId: i.wxAppId,
              codeTemplateId: selectedTmp.templateId,
              version: selectedTmp.templateVersion,
              userDesc: selectedTmp.templateDesc,
            };
          });
          this.post("/mini/mp/code/commit", { list }).then((r) => {
            console.error(r);
            this.$rs_success("更新成功");
            dlg.close();
            e.context.refreshTable();
          });
        }
      });
    },
    // 提交审核 TODO
    onMockAppdeployBatApproval(e) {
      "/mini/mp/code/audit";
      const selected = e?.context?.selected;
      console.error(selected);
      if (!selected?.length) {
        this.$rs_warning("请选择要提审的小程序");
        return;
      }

      const list = selected.map((i) => {
        return {
          wxAppId: i.wxAppId,
        };
      });
      this.post("/mini/mp/code/audit", { list }).then((r) => {
        console.error(r);
        if (Array.isArray(r)) {
          const re = r.map((i) => {
            return (
              <div class="q-pa-sm">
                <span>{i.wxAppId}</span> <span>{i.result ? "提审成功" : i.errMessage}</span>
              </div>
            );
          });
          this.$rs_dlg({
            parent: this,
            showFooter: false,
            cardStyle: "max-height:600px;overflow:auto",
            title: "提审结果",
            content: re,
          });
        }
        e.context.refreshTable();
      });
    },
    // 发布 TODO
    onMockAppdeployBatDeploy(e) {
      const selected = e?.context?.selected;
      console.error(selected);
      if (!selected?.length) {
        this.$rs_warning("请选择要发布的小程序");
        return;
      }

      const release = () => {
        if (!selected.length) {
          return;
        }
        const wxAppId = selected.pop();
        this.post("/mini/mp/code/release", {
          wxAppId: wxAppId?.wxAppId,
        })
          .then(
            (r) => {
              console.error(r);
            },
            (error) => {
              console.error(error);
            },
          )
          .finally(() => {
            release();
          });
      };

      release();
    },

    statusTxt(row) {
      const { status, lastCodeActInfo } = row || {};
      const { appActStatus, appAuditStatus } = lastCodeActInfo || {};
      const stateMap = {
        "-1": "未审核",
        "0": "审核成功",
        "1": "审核失败",
        "2": "审核中",
        "3": "审核撤回",
      };
      if (appActStatus === "COMMIT") {
        return {
          1: "待更新模版",
          2: "待提审",
        }[status];
      }
      if (appActStatus === "AUDIT") {
        return stateMap[appAuditStatus];
      }
      if (appActStatus === "RELEASE") {
        return { 0: "未发布", 1: "已发布" }[appAuditStatus];
      }
      if (appActStatus === "REVERT") {
        return "已撤回";
      }

      return {
        0: "初始化-未发代码",
        1: "待更新模版",
        2: "待提审",
      }[status];
    },
  },
  created() {
    // 获取 sub，转化为tab
    this.post("/mini/biz/sub").then((r) => {
      this.subs = r.list.filter((i) => i.enable);
      if (Array.isArray(this.subs)) {
        this.activeTab = this.subs[0].sub;
        this.post("/mini/company/list", { sub: this.activeTab }).then((r) => {
          this.companyList = r.list;
        });
      }
    });
  },
};
</script>
<style scoped>
.w-search {
  width: 180px;
}
</style>
