<template>
  <div class="full-height rs-page-retail">
    <div class="full-height   scroll">
      <rs-list
        name="biz-subject"
        :conditions="condition"
        keywords="id,name"
        :filter-placeholder="$t_mdata('bizSubject-filterPlaceholder')"
        :defImportConfig="{ type: 'DS' }"
      >
        <rs-search label-width="6.5em" :value="{ status: 1 }">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="settleType"></rs-filter>
          <rs-filter name="overseas"></rs-filter>
          <rs-filter name="channel" front></rs-filter>
          <rs-filter name="bset"></rs-filter>
          <rs-filter name="pset" front></rs-filter>
          <rs-filter name="brands" front></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="crtTime"></rs-filter>
          <rs-filter name="crtUser"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <!-- <rs-field name="info"></rs-field> -->
        <rs-field name="channel.name"></rs-field>
        <rs-field name="bset.name"></rs-field>
        <rs-field name="pset.name"></rs-field>
        <rs-field name="settleType"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field name="crtPlat"></rs-field>
        <!-- <rs-field name="overseas"></rs-field>
      <rs-field name="taxRate"></rs-field>
      <rs-field name="settleType"></rs-field> -->
        <!-- <rs-field name="remark"></rs-field> -->
        <rs-field name="crtTime"></rs-field>
        <rs-field name="crtUser"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <!-- <rs-field name="brands"></rs-field> -->
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.retail._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary" action="mdata.retail._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      condition: [{ key: "type", vals: ["DS"] }],
    };
  },
  computed: {},
  methods: {},
};
</script>
