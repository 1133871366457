<template>
  <div class="q-pa-sm full-height ten-order-form q-pa-sm">
    <q-card>
      <q-card-section>
        <q-item-label class="text-primary">租户信息</q-item-label>
      </q-card-section>
      <q-separator />
      <q-card-section class="row">
        <div class="col-2 row items-center">
          <span class="text-caption">租户ID：</span>
          <span>{{ tenInfo.id }}</span>
        </div>
        <div class="col-2 row items-center">
          <span class="text-caption">租户代号：</span>
          <span>{{ tenInfo.uname }}</span>
        </div>
        <div class="col-2 row items-center">
          <span class="text-caption">租户名称：</span>
          <span>{{ tenInfo.name }}</span>
        </div>
        <div class="col-6 row items-center">
          <span class="text-caption">应用数据库：</span>
          <span v-if="tenInfo.masterDb && tenInfo.masterDb.id">{{ tenInfo.masterDb.name }}</span>
          <div v-else class="flex-grow col row items-center">
            <rs-select
              v-model="tenDatabase"
              model="ten-database"
              key-value="id"
              dense
              :multiple="false"
              :conditions="[{ key: 'tenant.id', vals: [tenantId] }]"
              :fields="['id', 'name', 'dbHost']"
              outlined
              :simpleMode="true"
              class="rs-size-sm col rs-no-wrap"
              style="max-width: 250px"
              :canRefresh="true"
              @input="showRightPanel = false"
            ></rs-select>
            <span class="q-pl-md col-auto">
              <q-icon name="info" size="sm" color="info" />
              无应用数据库,
              <a @click="purchageDatabase" class="rs-link">立即购买</a>
              或者
              <a @click="customeDatabase" class="rs-link">自定义私有数据库</a>
            </span>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <q-card class="q-mt-sm">
      <q-card-section>
        <q-item-label class="text-primary">待购应用</q-item-label>
      </q-card-section>
      <q-separator />
      <q-card-section class="q-pa-none">
        <div class="q-pa-sm">
          <q-table
            grid
            :data="moduleList"
            :columns="moduleColumns"
            row-key="id"
            :filter="moduleFilter"
            :pagination="{ rowsPerPage: 2000 }"
            hide-header
            selection="multiple"
            :selected.sync="moduleSelected"
            @update:selected="showRightPanel = false"
          >
            <template v-slot:top-left>
              <q-input dense debounce="300" v-model="moduleFilter" placeholder="搜索">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:top-right>
              <q-btn
                :disable="moduleSelected.length === 0"
                color="primary"
                icon="add_shopping_cart"
                label="订购"
                @click="purchase"
              >
                <q-badge color="green" floating>{{ moduleSelected.length }}</q-badge>
              </q-btn>
            </template>
            <template v-slot:item="props">
              <div class="q-pa-xs grid-style-transition">
                <q-card :class="props.selected ? 'bg-grey-2' : ''" style="width:190px; min-height:135px;">
                  <q-checkbox dense v-model="props.selected" class="q-pl-sm q-pt-sm" />
                  <q-card-section align="center" class="q-pa-sm">
                    <q-icon class="rs-icon">
                      <svg aria-hidden="true">
                        <use :xlink:href="props.row.image" />
                      </svg>
                    </q-icon>
                    <q-item-label>{{ props.row.name }}</q-item-label>
                  </q-card-section>
                  <q-card-section class="q-pa-sm">
                    <q-item-label caption>{{ props.row.title }}</q-item-label>
                  </q-card-section>
                </q-card>
              </div>
            </template>
            <template v-slot:bottom></template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
    <q-card v-if="showRightPanel" class="absolute-right full-height width-50 bg-white" style="z-index:2">
      <div class="full-height flex-column">
        <q-card-section class="flex-no-grow shadow-down-3">
          <q-item-label class="text-black row items-center">
            <span class="text-subtitle1 text-weight-bold">订购应用配置确认</span>
            <q-space />
            <q-btn flat size="md" icon="clear" @click="showRightPanel = false" />
          </q-item-label>
        </q-card-section>
        <q-separator class="flex-no-grow" />
        <q-card-section class="flex-grow no-scroll q-gutter-y-lg q-ma-md">
          <q-table
            class="rs-sticky-header-table"
            :data="moduleSelected"
            :columns="moduleColumns"
            row-key="name"
            :pagination="{ rowsPerPage: 2000 }"
          >
            <template v-slot:bottom></template>
            <template v-slot:body-cell-image="props">
              <q-td :props="props">
                <q-icon class="rs-icon">
                  <svg aria-hidden="true">
                    <use :xlink:href="props.row.image" />
                  </svg>
                </q-icon>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
        <q-card-section class="flex-no-grow shadow-up-3" style="height: 90px">
          <q-item-label class="text-black full-height row items-center q-gutter-x-md q-pt-md">
            <span>购买时长</span>
            <rs-input-number
              v-model="timeConfig.timeValue"
              class="rs-size-sm"
              style="width: 100px"
              size="sm"
              dense
              outlined
              :min="1"
              :max="999"
            />
            <rs-select
              v-model="timeConfig.timeUnit"
              :data="unitOptions"
              class="rs-size-sm"
              style="width: 70px"
              size="sm"
              dense
              outlined
              :clearable="false"
            />
            <q-space />
            <span>配置费用</span>
            <span class="text-red">
              ￥
              <span class="text-h6 text-weight-bold">{{ (+totalMoney.split(".")[0]).toLocaleString() }}.</span>
              <span style="font-size: 10px">{{ totalMoney.split(".")[1] }}</span>
            </span>
            <q-btn dense color="primary" style="min-width:100px" label="下单" @click="order" />
          </q-item-label>
        </q-card-section>
      </div>
    </q-card>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
      tenantService: new ModelService(),
      moduleService: new ModelService(),
      tenModuleInfoService: new ModelService(),
      tenModuleService: new ModelService(),
      tenOrderService: new ModelService(),
      tenInfo: {},
      tenDatabase: null,
      loading: false, // 下单按钮loading
      moduleList: [],
      moduleSelected: [],
      showRightPanel: false, // 右侧订购应用确认弹窗是否显示
      timeConfig: {
        timeValue: 1, // 默认1
        timeUnit: "year",
      }, // 右侧确认订购弹框时间配置
      moduleColumns: [
        { name: "image", field: "image", label: "图片" },
        {
          name: "ext.moduleId",
          label: "应用ID",
          field: (row) => row.ext.moduleId,
        },
        { name: "name", field: "name", label: "名称" },
        { name: "title", field: "title", label: "标题" },
        { name: "price", field: "price", label: "价格" },
      ],
      moduleFilter: "",
      unitOptions: [
        {
          name: "天",
          code: "day",
          val: 1,
        },
        {
          name: "周",
          code: "week",
          val: 7,
        },
        {
          name: "月",
          code: "month",
          val: 30,
        },
        {
          name: "年",
          code: "year",
          val: 360,
        },
      ],
    };
  },
  computed: {
    totalMoney() {
      let total = 0; // 总价
      if (this.showRightPanel) {
        const sumPrice = this.moduleSelected.reduce((sum, d) => {
          return sum + (d.price || 0);
        }, 0);
        const timeItem = this.unitOptions.find((d) => d.code === this.timeConfig.timeUnit);
        total = sumPrice * this.timeConfig.timeValue * timeItem.val;
      }
      return total.toFixed(2);
    },
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    if (!this.tenantId) {
      this.$rs_error("缺少租户信息!!!");
      return;
    }
    this.tenantService
      .refs(
        [this.tenantId],
        {
          fields: ["id", "name", "uname", "masterDb.id", "masterDb.name"],
        },
        "tenant",
      )
      .then((resp) => {
        if (resp && resp[0]) {
          this.tenInfo = resp[0];
          this.tenDatabase = this.tenInfo.masterDb.id;
        } else {
          this.$rs_error("无效的租户信息");
        }
      });
    const getModuleListAPI = this.moduleService.list(
      {
        fields: ["id", "name", "title", "image", "price", "ext"],
        page: 1,
        size: -1,
        conditions: [
          { key: "type.id", vals: ["MODULE"] },
          { key: "status", vals: [1] },
        ],
        orderby: "sort",
      },
      "product",
    );
    const getTenModuleListAPi = this.tenModuleInfoService.list(
      { conditions: [{ key: "tenant.id", vals: [this.tenantId] }], fields: ["id", "module.id"] },
      "ten-module-info",
    );
    Promise.all([getModuleListAPI, getTenModuleListAPi]).then(([resp1, resp2]) => {
      const hasModuleIds = (resp2?.list || []).map((d) => d.module.id);
      this.moduleList = (resp1?.list || []).filter((d) => !hasModuleIds.includes(d?.ext?.moduleId));
    });
  },
  methods: {
    // 下单
    purchase() {
      if (!this.tenDatabase) {
        this.$rs_warning("应用数据库不能为空！");
        return;
      }
      const selectedIds = this.moduleSelected.map((d) => d?.ext?.moduleId); // 准备订购的ids
      // 获取依赖服务，
      this.tenModuleService.refs(selectedIds, { fields: ["id", "deepDepends.id"] }, "ten-module").then((resp) => {
        let deepDepends = []; // 所有依赖ids
        (resp || []).forEach((d) => {
          deepDepends.push(...(d.deepDepends || []).map((t) => t.id));
        });
        deepDepends = deepDepends.filter((d) => !selectedIds.includes(d)); // 排除掉 准备购买的
        const needModules = this.moduleList.filter((d) => deepDepends.includes(d?.ext?.moduleId)); // 需要一起购买的产品

        const done = () => {
          this.$set(this, "timeConfig", {
            timeValue: 1, // 默认1
            timeUnit: "year",
          });
          this.showRightPanel = true;
        };

        if (needModules.length) {
          const dlg = this.$q
            .dialog({
              title: this.$t_s("tips"),
              message: `订购模块中有依赖模块：${needModules.map((d) => d.name).join()},是否一并购买？`,
              persistent: true,
              cancel: true,
            })
            .onOk(() => {
              this.moduleSelected = [...this.moduleSelected, ...needModules];
              dlg.hide();
              done();
            });
        } else {
          done();
        }
      });
    },
    // 订购模块
    order() {
      const { timeValue, timeUnit } = this.timeConfig;
      const timeItem = this.unitOptions.find((d) => d.code === this.timeConfig.timeUnit);
      const sumPrice = this.moduleSelected.reduce((sum, d) => {
        return sum + (d.price || 0);
      }, 0);
      const total = sumPrice * this.timeConfig.timeValue * timeItem.val;
      const items = this.moduleSelected.map((d) => {
        return {
          sku: {
            id: d.id,
          },
          name: d.name,
          price: timeValue * timeItem.val * d.price,
          quantity: 1,
          unit: "suit",
          duration: timeValue,
          durationUnit: timeUnit,
          ext: {
            moduleId: d.ext.moduleId,
          },
        };
      });
      const params = {
        type: "module_new",
        tenant: {
          id: this.tenantId,
        },
        payStatus: 1,
        orderFrom: "admin",
        discount: 0,
        totalPrice: total,
        totalPaid: 0,
        ext: {
          appDb: this.tenInfo?.masterDb?.id || this.tenDatabase,
        },
        items: items,
      };
      this.tenOrderService.create(params, "ten-order").then((resp) => {
        this.$rs_success("订购成功!");
        this.$store.commit("closeCurrentTab");
        this.$rs_action("tenants.orders._edit", { query: { tenantId: this.tenantId, id: resp.id } });
      });
    },
    purchageDatabase() {
      this.$rs_action("tenants.dbs._order", { query: { tenantId: this.tenantId } });
    },

    customeDatabase() {
      this.$rs_action("tenants.dbs._add", { query: { tenantId: this.tenantId } });
    },
  },
};
</script>
