<template>
  <div class="full-height product-spec-group-form scroll">
    <rs-form name="product-spec-group" label-width="6.5em" floatLayout @change="onGroupFormChange">
      <rs-field
        :hidden="typeNotColor"
        name="icon"
        :widget="{ _type: 'rs-head-image', fixedNumber: [10, 10], width: '105px', height: '105px' }"
        floatRight
      ></rs-field>
      <rs-field name="id"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="info"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.spec._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <div>
      <rs-list
        ref="list"
        :isPagination="false"
        :loadDefault="false"
        :filterHidden="true"
        :preventDbClick="true"
        :editMode="isValSortMode ? 'none' : 'row'"
        :data="valList"
        name="product-spec"
        :key="typeNotColor"
      >
        <!-- <rs-field name="id"></rs-field> -->
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field
          :hidden="typeNotColor"
          name="icon"
          v-slot="row"
          :widget="{ _type: 'rs-color-image', fixedNumber: [10, 10] }"
        >
          <q-img v-if="row.icon" :src="row.icon" class="rs-border" style="height: 35px; max-width: 35px;" />
        </rs-field>
        <rs-field type="toolbar" v-slot="row" exclude="*" :include="isValSortMode ? ['moveUp', 'moveDown'] : null">
          <!-- <rs-button v-if="!isValSortMode" color="secondary" name="edit" dense outline icon="edit"></rs-button>
            <rs-button v-if="!isValSortMode" color="negative" name="delete" dense outline icon="close"></rs-button> -->
          <rs-button
            v-if="isValSortMode && row.__index !== 0"
            color="secondary"
            name="moveUp"
            dense
            outline
            icon="arrow_upward"
          ></rs-button>
          <rs-button
            v-if="isValSortMode && row.__index !== valList.length - 1"
            color="primary"
            name="moveDown"
            dense
            outline
            icon="arrow_downward"
          ></rs-button>
        </rs-field>
        <rs-toolbar :include="isValSortMode ? ['cancelSort', 'saveSort'] : ['add', 'sortMode']" exclude="*">
          <rs-button name="add" label="" icon="add" color="white" text-color="black"></rs-button>
          <rs-button v-if="isValSortMode" name="saveSort" icon="check" color="primary"></rs-button>
          <rs-button v-if="isValSortMode" name="cancelSort" icon="close" color="grey"></rs-button>
          <rs-button
            v-if="!isValSortMode"
            name="sortMode"
            icon="swap_vert"
            color="white"
            text-color="black"
          ></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import { listToMove } from "rsui-core/src/core/util/functions";
import ModelService from "rsui-core/src/core/model-service";
export default {
  data: function() {
    return {
      id: this.$route.query.id,
      isValSortMode: false,
      modelService: new ModelService(),
      valList: [],
      oldValList: [],
      typeId: null,
    };
  },
  computed: {
    typeNotColor: function() {
      return this.typeId !== "color";
    },
  },
  methods: {
    // 请求form前 添加获取specs 属性
    beforeRequest(e, params) {
      params.fields.push("specs.id", "specs.name", "specs.sort", "specs.icon");
      return {
        params,
        resolve: ({ params }) => {
          this.typeId = params.type.id;
          let list = [];
          if (params && params.specs) {
            list = params.specs.sort((a, b) => a.sort - b.sort);
          }
          list.forEach((d) => {
            d.group = { id: this.id };
          });
          this.valList = list;
        },
      };
    },
    beforeSubmit({ preventDefault }, params) {
      return {
        resolve: ({ response }) => {
          this.modelService.cascade("specs", params.id, this.valList, "product-spec-group").then(() => {
            this.$rs_actionRefresh("mdata.spec._view");
          });
        },
      };
    },
    onGroupFormChange(val, fireKey, core) {
      if (fireKey.find((d) => d === "type")) {
        this.typeId = val.type ? val.type.id : null;
      }
    },
    onProductSpecAdd(e) {
      return {
        params: {
          group: { id: this.id },
        },
      };
    },
    onProductSpecSortMode(e, params, eventId) {
      if (this.$refs.list.isEditing) {
        this.$rs_warning(this.$t_mdata("specForm-notToSort"));
        e.preventDefault();
        return;
      }
      this.oldValList = cloneDeep(this.valList);
      this.isValSortMode = true;
    },
    onProductSpecCancelSort(e, params, eventId) {
      this.valList = this.oldValList;
      this.isValSortMode = false;
    },
    onProductSpecSaveSort(e, params, eventId) {
      this.isValSortMode = false;
    },
    onProductSpecMoveUp(e, params, eventId) {
      this.valList = listToMove(this.valList, params.__index, true, false, "sort");
    },
    onProductSpecMoveDown(e, params, eventId) {
      this.valList = listToMove(this.valList, params.__index, false, false, "sort");
    },
    onProductSpecSaveLine(e, params, eventId) {
      e.preventDefault();
      const row = params.row;
      const getValidateConfig = ({ index, row }) => {
        return {
          id: [
            {
              required: true,
              validator: (rule, value, callback) => {
                const sameIdItems = this.valList.filter((d) => d.id === value);
                if ((index >= 0 && sameIdItems.length > 1) || (isNaN(index) && sameIdItems.length > 0)) {
                  callback(new Error(this.$t_s(`exists-invalid`)));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
          name: [
            {
              required: true,
              validator: (rule, value, callback) => {
                const sameNameItems = this.valList.filter((d) => d.name === value);
                if ((index >= 0 && sameNameItems.length > 1) || (isNaN(index) && sameNameItems.length > 0)) {
                  callback(new Error(this.$t_s(`exists-invalid`)));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
        };
      };
      this.$refs.list.formCore.setValidateConfig(getValidateConfig({ index: row.__index, row }));
      this.$refs.list.formCore.validate().then((r) => {
        if (!r) {
          if (params.isCreated) {
            row.sort = Math.max(0, ...this.valList.map((d) => d.sort || 0)) + 1;
            this.valList.push(row);
          } else {
            this.$set(this.valList, row.__index, row);
          }
          this.$refs.list.cacelEdit();
        }
      });
    },
    onProductSpecEdit(e, params, eventId) {
      e.preventDefault();
      this.$refs.list.updateRow({ row: params });
    },
    onProductSpecDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      this.valList.splice(params.__index, 1);
    },
  },
};
</script>
