<template>
  <div>
    结算主体 详情
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
