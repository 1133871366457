<template>
  <div class="rs-page-retail">
    <!-- <rs-form name="platform" >
      <rs-field name="name" required></rs-field>
      <rs-field name="id" :editable="false"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field :label="$t_settings('platform.term_of_validity')" readonly :help="help">
        <rs-field name="beginDate"></rs-field>
        <rs-field name="endDate"></rs-field>
      </rs-field>
      <rs-field name="loginUrl" :col="12" readonly></rs-field>
      <rs-field
        name="adminUser.userName"
        :label="$t_settings('platform.user_name')"
        readonly
        help="如需变更系统管理员,请联系软件的提供者."
      ></rs-field>
      <rs-field name="adminUser.mobile" readonly></rs-field>
      <rs-field name="adminUser.email" readonly></rs-field>
      <rs-field name="logo" widget="avart"></rs-field>
      <rs-toolbar>
        <rs-button label="abc" color="info" icon="add" name="a" fold></rs-button>
        <rs-button label="abc" color="info" icon="add" name="b" fold></rs-button>
        <rs-button label="abc" color="info" icon="add" name="c" fold></rs-button>
      </rs-toolbar>
    </rs-form> -->
    <rs-list name="module" title="应用软件">
      <rs-field name="id"></rs-field>
      <rs-field name="name" v-slot="row">
        <a class="rs-link">{{ row.name }}</a>
        <a class="rs-link float-right">编辑</a>
      </rs-field>
      <rs-field name="version"></rs-field>
      <rs-field name="summary"></rs-field>
      <rs-field name="icon"></rs-field>
      <rs-field name="website"></rs-field>
      <rs-field name="license"></rs-field>
      <rs-field name="description"></rs-field>
      <rs-field type="toolbar">
        <rs-button name="conf" type="link" label="启用配置" action="mine.app.config"></rs-button>
      </rs-field>
    </rs-list>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      date: null,
      dateStart: null,
      dateEnd: null,
      help: `1.企业平台的租赁有效期来自于软件厂商的各应用注册许可有效期的并集;<br/> 2.分销商平台的租赁有效期来自于企业平台授权应用的有效期的并集;`,
    };
  },
  methods: {
    beforeQuery(a, b, c) {},
    onFormSubmit({ next }) {
      return {
        params: {},
        resolve: () => {},
        reject: () => {
          return false;
        },
      };
    },
  },
};
</script>
