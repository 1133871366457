<script>
export default {
  data() {
    return {
      conditions: [],
      modelId: "ten-menu",
    };
  },
  render() {
    return (
      <rs-tree-picker
        ref="treePicker"
        class="no-padding full-height"
        modelKey={this.modelId}
        conditions={this.conditions}
        tickStrategy="none"
        fields={["module.name"]}
        beforeSetData={(treeList, cb) => {
          let mp = new Map();
          let names = new Map();
          treeList.forEach((e) => {
            const children = mp.get(e.module.id) || [];
            mp.set(e.module.id, [...children, e]);
            names.set(e.module.id, e.module.name);
          });
          const re = [];
          for (const [key, value] of mp.entries()) {
            re.push({ id: key, name: names.get(key), children: value, pid: "" });
          }
          mp = null;
          names = null;
          cb(re);
        }}
        on-filterFinish={(e) => {
          if (e.length === 0) {
            this.$rs_warning("无匹配记录，请重新输入！");
          }
        }}
      />
    );
  },
};
</script>
