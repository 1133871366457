<template>
  <div class="full-height rs-page-ten-database-list">
    <rs-list name="ten-database" :conditions="dbConditions">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="platform"></rs-filter>
        <rs-filter name="mode"></rs-filter>
        <rs-filter name="tenant" v-if="!tenantId"></rs-filter>
        <rs-filter name="dbHost"></rs-filter>
        <rs-filter name="database"></rs-filter>
        <rs-filter name="slaveEnable"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="platform"></rs-field>
      <rs-field name="mode"></rs-field>
      <rs-field name="tenant.uname"></rs-field>
      <rs-field name="tenant.name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="dbHost"></rs-field>
      <rs-field name="database"></rs-field>
      <rs-field name="slaveEnable"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*"></rs-field>
      <rs-toolbar exclude="*" :include="['add']">
        <rs-button name="add" label="自定义应用数据库"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
    };
  },
  computed: {
    dbConditions: function() {
      const cons = [];
      if (this.tenantId) {
        cons.push({ key: "tenant.id", vals: [this.tenantId] });
      }
      return cons;
    },
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
  },
  methods: {},
};
</script>
