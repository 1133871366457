<template>
  <div class="q-pa-sm full-height tenant-biz-broker">
    <rs-form ref="form" name="biz-broker" allDirty @change="onChange">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id" :hidden="!id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field name="provider"></rs-field>
        <rs-field name="tenant"></rs-field>
        <rs-field
          name="configs"
          col="12"
          :rules="[configRule]"
          :widget="{ _type: 'rs-ace-editor', height: '200px', lang: 'json' }"
        ></rs-field>
        <!-- :widget="{ _type: 'rs-code-mirror', height: '200px', option: { mode: 'application/json' } }" -->
      </rs-field>
      <rs-field type="group" ignore name="services">
        <rs-cascades
          key="servicesCas"
          ref="serviceList"
          :loadDefault="id"
          :disable="!providerId"
          add-model="biz-provider-service"
          :add-conditions="addServiceCond"
          :on-select="onProviderServiceSelect"
          :convertInitList="convertInitList"
        >
          <rs-field name="service" :editable="false"></rs-field>
          <rs-field name="priority"></rs-field>
          <rs-field name="configs"></rs-field>
          <rs-field type="toolbar"></rs-field>
        </rs-cascades>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      providerId: null,
    };
  },
  computed: {
    addServiceCond() {
      let codes = [];
      try {
        codes = this.$refs.serviceList.list.innerList.map((d) => d.service);
      } catch (error) {
        codes = [];
      }
      return this.providerId
        ? [
            { key: "provider.id", vals: [this.providerId] },
            { key: "code", op: OP_TYPE.NOTIN, vals: codes },
          ]
        : [];
    },
  },
  mounted() {},
  methods: {
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          this.$refs.serviceList.save(id, (list) => {
            return list.map((d) => {
              if (typeof d.configs === "string") {
                try {
                  d.configs = JSON.parse(d.configs);
                } catch (error) {
                  d.configs = null;
                }
              }
              return d;
            });
          });
          this.$rs_actionRefresh("tenants.broker._view");
        },
      };
    },
    convertInitList(list) {
      return list.map((d) => {
        if (d.configs && typeof d.configs === "object") {
          d.configs = JSON.stringify(d.configs);
        }
        return d;
      });
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("provider")) {
        if (fireKey.length === 1 && (!val.provider || val.provider.id !== this.providerId)) {
          this.clearCascades();
        }
        this.providerId = val.provider ? val.provider.id : null;
      }
    },
    clearCascades() {
      this.$refs.serviceList.list.innerList = [];
    },
    onProviderServiceSelect(data) {
      return {
        service: data.code,
        priority: data.priority,
        configs: null,
      };
    },
    // config JSON 验证规则添加， 如果value 是字符串类型 说明JSON格式是错误的，因为在rs-code-mirror组件里如果是正确的会转换成object
    configRule(rule, value, callback) {
      if (value && typeof value === "string") {
        callback(new Error(this.$t_s("json-error")));
      } else {
        callback();
      }
    },
  },
};
</script>
