<template>
  <div class="q-pa-sm full-height product-type-form">
    <rs-form ref="form" name="product-type" :col="1">
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="stockUnit"></rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.productType._view");
        },
      };
    },
  },
};
</script>
