<template>
  <div class="error-page-wrap full-height full-width">
    <div>
      <div class="container q-pa-lg column">
        <h1 class="q-ma-md">404</h1>
        <p><strong>This is not the page you are looking for!</strong></p>
        <div class="col">&nbsp;</div>
        <!-- <p>
        错误描述
      </p>

      <p>
        <code>错误描述</code>
      </p>

      <p>
        <a href="/">链接</a>
        提醒<strong>这很重要</strong>.
      </p> -->

        <div id="suggestions">
          <router-link :to="{ name: 'home' }">
            返回
          </router-link>
          —
          <router-link :to="{ name: 'login' }">
            登录
          </router-link>
        </div>

        <a href="/" class="logo logo-img-2x">
          <img height="32" title="" alt="" :src="require('@/assets/runsa.png')" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
function hasClass(el, cls) {
  if (!el || !cls) return false;
  if (cls.indexOf(" ") !== -1) throw new Error("className should not contain space.");
  if (el.classList) {
    return el.classList.contains(cls);
  } else {
    return (" " + el.className + " ").indexOf(" " + cls + " ") > -1;
  }
}

/* istanbul ignore next */
function addClass(el, cls) {
  if (!el) return;
  let curClass = el.className;
  const classes = (cls || "").split(" ");

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += " " + clsName;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

/* istanbul ignore next */
function removeClass(el, cls) {
  if (!el || !cls) return;
  const classes = cls.split(" ");
  let curClass = " " + el.className + " ";

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(" " + clsName + " ", " ");
    }
  }
  if (!el.classList) {
    el.className = `${curClass}`.trim();
  }
}
export default {
  activated() {
    this.toggleCss();
  },
  deactivated() {
    this.toggleCss();
  },
  beforeDestroy() {
    this.toggleCss();
  },
  mounted() {
    this.toggleCss();

    document.body.addEventListener("mousemove", this.fn);
  },
  data: function() {
    return {
      tag: null,
    };
  },
  methods: {
    fn(e) {
      const rangeW = 150;
      const rangeH = 150;
      const h = document.documentElement.clientHeight;
      const w = document.documentElement.clientWidth;
      const niX = ((w / 2 - e.pageX) * rangeW) / w;
      const niY = ((h / 2 - e.pageY) * rangeH) / h;
      if (!this.tag) {
        this.tag = document.createElement("style");
      }
      this.tag.innerHTML = `
        body.on-error-page,
        .container::before {
               background-position: calc(50% + ${niX}px) calc(50% + ${niY}px);
        }
      `;
      document.head.appendChild(this.tag);
    },
    toggleCss() {
      document.body.removeEventListener("mousemove", this.fn);
      this.tag && this.tag.remove();
      this.tag = null;
      if (hasClass(document.body, "on-error-page")) {
        removeClass(document.body, "on-error-page");
      } else {
        addClass(document.body, "on-error-page");
      }
    },
  },
};
</script>
<style>
@keyframes rd {
  0% {
    border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
  }
  60% {
    border-radius: 40% 60%;
  }
  100% {
    border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
  }
  to {
    /* transform: rotate(1turn); */
  }
}
body.on-error-page,
.container::before {
  background-color: blanchedalmond;
  background: url("~@/assets/error.png") center / auto fixed no-repeat;
}
body.on-error-page {
  background-color: blanchedalmond;
  background-blend-mode: luminosity;
}
.error-page-wrap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 1em;
  min-width: 40vw;
  max-width: 23em;
  max-height: 80vh;
  width: 647px;
  height: 400px;
  background: hsla(0, 0%, 100%, 0.35) border-box;
  overflow: hidden;
  border-radius: 0.3em;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.1) inset, 0 0.5em 1em rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.3);
  text-align: center;
  animation: rd 25s infinite ease-in-out;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -30px;
  z-index: -1;
  filter: blur(2px);
}

a {
  color: #4183c4;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

h1 {
  line-height: 60px;
  font-size: 60px;
  font-weight: 100;
  text-shadow: 0 1px 0 #fff;
}
p {
  color: rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  line-height: 1.6;
}

ul {
  list-style: none;
  margin: 25px 0;
  padding: 0;
}
/* 追加not(.el-time-spinner__item) 修复rs-time打开的panel 样式全加粗问题 */
li:not(.el-time-spinner__item) {
  display: table-cell;
  font-weight: bold;
  width: 1%;
}

.logo {
  display: inline-block;
  margin-top: 25px;
}
.logo-img-2x {
  display: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .logo-img-1x {
    display: none;
  }
  .logo-img-2x {
    display: inline-block;
  }
}

#suggestions {
  margin-top: 35px;
  color: #ccc;
}
#suggestions a {
  color: #666666;
  font-weight: 200;
  font-size: 14px;
  margin: 0 10px;
}
</style>
