<template>
  <div class="full-height q-pa-sm">
    <div class="full-height bg-white flex-column no-scroll">
      <div class="flex-no-grow q-px-md q-pt-md">
        <q-btn color="primary" :label="$t_s('exports')" @click="exportArea" />
      </div>
      <div class="flex-grow row scroll">
        <div v-for="(card, index) in districtList" :key="index" class="full-height q-pa-md col column">
          <q-toolbar class="bg-grey-2 q-list--bordered">
            <q-toolbar-title class="text-subtitle2">{{ card.title }}</q-toolbar-title>
          </q-toolbar>
          <q-list bordered class="col scroll">
            <q-item
              v-for="item in card.list"
              :key="item.id"
              :class="['q-my-sm', selectIds[card.type - 1] === item.id && 'bg-info']"
              clickable
              v-ripple
              @click="getNextChildrenById(item.id, item.type)"
            >
              <q-item-section>
                <q-item-label>{{ item.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import addressService, { RsAddressItemModel } from "rsui-core/src/system/service/address";
export default {
  data: function() {
    return {
      selectIds: [],
      districtList: [
        {
          title: this.$t_settings("district-province"),
          list: [],
          type: 1,
        },
        {
          title: this.$t_settings("district-city"),
          list: [],
          type: 2,
        },
        {
          title: this.$t_settings("district-area"),
          list: [],
          type: 3,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    addressService.districtProvinces().then((res) => {
      if (res && res.content) {
        this.districtList[0].list = res.content;
      }
    });
  },
  methods: {
    getNextChildrenById(id, pType) {
      if (pType === 3) {
        return;
      }
      addressService.districtChildren(id).then((res) => {
        if (pType === 1) {
          this.districtList[2].list = [];
          this.selectIds = [id];
        } else {
          this.selectIds[1] = id;
        }
        if (res && res.content) {
          this.districtList[pType].list = res.content;
        }
      });
    },
    exportArea() {
      const dlg = this.$rs_dlg({
        parent: this,
        title: this.$t_s("exports"),
        cardStyle: "width: 700px;",
        cardClass: "no-scroll",
        showFooter: false,
        content: <rs-exports on-finish={() => dlg.hide()} listModel={{ id: "district" }} query={this.curQuery} />,
      }).onOk(() => {});
    },
  },
};
</script>
