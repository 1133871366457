<template>
  <div class="full-height rs-page-ops-ten-module-version-list">
    <rs-list name="ten-module-version" keywords="module.id">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="module"></rs-filter>
        <rs-filter name="version"></rs-filter>
        <rs-filter name="chgDate"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="module.name"></rs-field>
      <rs-field name="version"></rs-field>
      <rs-field name="chgDate"></rs-field>
      <rs-field name="summary"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']">
        <rs-button name="edit" color="primary" action="ops.moduleVersion._edit"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add']" exclude="*">
        <rs-button name="add" color="primary" action="ops.moduleVersion._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
  },
};
</script>
