<template>
  <div class="q-pa-sm full-height tenant-module-form">
    <rs-form ref="form" name="ten-module" :value="{ type: 1, status: 1 }">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id"></rs-field>
        <rs-field name="type"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="status" hidden></rs-field>
        <rs-field name="sort"></rs-field>
        <rs-field name="icon"></rs-field>
        <rs-field name="version" :hidden="!!dependServices.length"></rs-field>
        <rs-field name="website" col="8"></rs-field>
        <rs-field
          name="summary"
          col="12"
          :widget="{ _type: 'input', inputStyle: { height: '40px' }, type: 'textarea' }"
        ></rs-field>
        <rs-field
          name="description"
          col="12"
          :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
        ></rs-field>
      </rs-field>
      <rs-field type="group" name="dependModules" label="依赖的模块">
        <rs-list
          ref="modulelist"
          name="ten-module"
          :data="dependModules"
          :loadDefault="false"
          class="full-width"
          hideBottom
          :selection="false"
        >
          <rs-field name="id"></rs-field>
          <rs-field name="name"></rs-field>
          <rs-field name="type"></rs-field>
          <rs-field type="toolbar" :include="['delete']" exclude="*"></rs-field>
          <rs-toolbar :include="['addItem']" exclude="*">
            <rs-button name="addItem" icon="add" color="primary" label="添加"></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-field type="group" name="dependServices" label="依赖的服务">
        <rs-list
          ref="servicelist"
          name="ten-service"
          :data="dependServices"
          :loadDefault="false"
          class="full-width"
          hideBottom
          :selection="false"
        >
          <rs-field name="id"></rs-field>
          <rs-field name="name"></rs-field>
          <rs-field name="info"></rs-field>
          <rs-field type="toolbar" :include="['delete']" exclude="*"></rs-field>
          <rs-toolbar :include="['addItem']" exclude="*">
            <rs-button name="addItem" icon="add" color="primary" label="添加"></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.module._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      dependModules: [],
      dependServices: [],
      modelService: new ModelService(),
    };
  },
  computed: {},
  mounted() {
    if (this.id) {
      // 查询已经保存的列表
      this.modelService
        .list(
          {
            fields: ["id", "depends.id", "depends.name", "depends.type", "depends.summary"],
            page: 1,
            size: -1,
            conditions: [{ key: "id", vals: [this.id] }],
          },
          "ten-module",
        )
        .then((resp) => {
          this.dependModules = resp.list[0].depends || [];
        });

      this.modelService
        .list(
          {
            fields: ["id", "services.id", "services.name", "services.info"],
            page: 1,
            size: -1,
            conditions: [{ key: "id", vals: [this.id] }],
          },
          "ten-module",
        )
        .then((resp) => {
          this.dependServices = resp.list[0].services || [];
        });
    }
  },
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      // 新建 添加默认可见
      if (!this.id) {
        params.visible = 1;
      }
      params.depends = this.dependModules.map((d) => pick(d, ["id"]));
      params.services = this.dependServices.map((d) => pick(d, ["id"]));
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.module._view");
        },
      };
    },

    onTenModuleAddItem(e) {
      e.preventDefault();
      const existModuleIds = this.dependModules.map((d) => d.id);
      if (this.id) {
        existModuleIds.push(this.id);
      }
      this.$rs_model_dlg(
        {
          modelName: "ten-module",
          multiple: true,
          conditions: [{ key: "id", op: OP_TYPE.NE, vals: existModuleIds }],
          fields: ["id", "name", "type", "summary"],
        },
        {
          beforeEvent: ({ name, e, selected, dlg }) => {
            // 点击是dlg 确定按钮且selected没有值时，e.preventDefault()一下，阻止它进行onOK处理,导致下次点击取消不走onCancel方法【因为global-dialog中emittedOK = true】
            if (name === "confirm" && selected.length === 0) {
              e.preventDefault();
              dlg.hide();
              return;
            }
          },
        },
      ).then(({ selected, dlg }) => {
        this.$set(this, "dependModules", [...this.dependModules, ...selected]);
        dlg.hide();
      });
    },
    onTenModuleDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      const delIndexs = params.map((d) => d.__index);
      this.$set(
        this,
        "dependModules",
        this.dependModules.filter((d, index) => !delIndexs.includes(index)),
      );
    },
    onTenServiceAddItem(e) {
      e.preventDefault();
      const existServiceIds = this.dependServices.map((d) => d.id);
      this.$rs_model_dlg(
        {
          modelName: "ten-service",
          multiple: true,
          conditions: [{ key: "id", op: OP_TYPE.NE, vals: existServiceIds }],
          fields: ["id", "name", "info"],
        },
        {
          beforeEvent: ({ name, e, selected, dlg }) => {
            // 点击是dlg 确定按钮且selected没有值时，e.preventDefault()一下，阻止它进行onOK处理,导致下次点击取消不走onCancel方法【因为global-dialog中emittedOK = true】
            if (name === "confirm" && selected.length === 0) {
              e.preventDefault();
              dlg.hide();
              return;
            }
          },
        },
      ).then(({ selected, dlg }) => {
        this.$set(this, "dependServices", [...this.dependServices, ...selected]);
        dlg.hide();
      });
    },
    onTenServiceDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      const delIndexs = params.map((d) => d.__index);
      this.$set(
        this,
        "dependServices",
        this.dependServices.filter((d, index) => !delIndexs.includes(index)),
      );
    },
  },
};
</script>
