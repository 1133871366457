<template>
  <div class="full-height scroll">
    <q-splitter v-model="splitterModel" style="height:100%" separator-class="bg-grey-2">
      <template v-slot:after>
        <div class="form q-pa-lg">
          <h5 class="text-center">I18N settings</h5>
          <q-input v-model="form.id" v-if="mode !== 'add'"></q-input>
          <rs-select label="开发代码对应模块 module" v-model="form.module" keyValue="id" v-bind="moduleProps">
            <template v-slot:after>
              <q-icon name="ballot" @click.stop="setSysModule" class="cursor-pointer" />
            </template>
          </rs-select>
          <q-input label="关联字段 resId" v-model="form.resId" clearable></q-input>
          <q-input label="开发使用的代码编号 code" v-model="form.code" clearable></q-input>
          <q-input ref="valueInput" label="对应语种的翻译 value" autofocus v-model="form.value" clearable></q-input>
          <q-select
            label="语种 lang"
            v-model="form.lang"
            use-input
            hide-selected
            fill-input
            input-debounce="0"
            :options="options"
            hide-dropdown-icon
            new-value-mode="toggle"
            @filter="filterFn"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  无匹配项
                </q-item-section>
              </q-item>
            </template>
          </q-select>
          <p></p>

          <div>
            <q-btn size @click="submit" label="保存" color="primary" class="full-width"></q-btn>
            <p></p>
            <q-btn size @click="reset" label="重置" color="grey-6" class="full-width"></q-btn>
            <p></p>
            <q-btn v-if="clearCache" size @click="clear" label="清空缓存" color="secondary" class="full-width"></q-btn>
          </div>
        </div>
      </template>

      <template v-slot:before>
        <rs-list
          ref="list"
          :name="name"
          selection="single"
          keywords="resId,code,module,value"
          filter-placeholder="search"
          :title="title"
          @selected="selected"
        >
          <rs-search>
            <rs-filter name="lang"></rs-filter>
            <rs-filter name="type"></rs-filter>
            <rs-filter name="module" front></rs-filter>
            <rs-filter name="resId" front></rs-filter>
            <rs-filter name="code" front></rs-filter>
          </rs-search>
          <rs-field name="id" :editable="false"></rs-field>
          <rs-field name="module" :editable="false"></rs-field>
          <rs-field name="resId" sortable :editable="false"></rs-field>
          <rs-field name="code" sortable></rs-field>
          <rs-field name="value"></rs-field>
          <rs-field name="lang" sortable :editable="false"></rs-field>
          <rs-field name="type" :editable="false"></rs-field>
          <rs-field name="recTime" :editable="false"></rs-field>
          <rs-field type="toolbar">
            <rs-button :auth="null" name="delete"></rs-button>
          </rs-field>
          <rs-toolbar :include="['deleteAll']" exclude="*"></rs-toolbar>
        </rs-list>
      </template>
    </q-splitter>
  </div>
</template>
<script>
import Config from "rsui-core/src/config";
import ApiService from "rsui-core/src/system/service/api";

const stringOptions = ["zh-CN", "default", "en"];
export default {
  props: {
    name: {
      type: String,
      default: "translation",
    },
    title: {
      type: String,
      default: "International",
    },
    clearCache: {
      type: Boolean,
      default: true,
    },
    moduleProps: {
      default: () => ({ model: "module" }),
    },
  },
  data() {
    return {
      splitterModel: 70,
      mode: "add",
      options: stringOptions,
      model: "",
      currentRow: {},
      form: {
        code: "",
        type: "code",
        resId: "",
        value: "",
        module: null,
        lang: "zh-CN",
      },
    };
  },
  methods: {
    clear() {
      this.$rs_storage.forgetItem("i18n-default");
    },
    setSysModule(e) {
      this.form.module = "_system_";
      e.stopPropagation();
      e.preventDefault();
    },
    submit() {
      if (JSON.stringify(this.form) === JSON.stringify(this.currentRow)) {
        this.$rs_warning("没有更改");
        this.reset();
        return;
      }
      const p = { ...this.form };
      if (this.currentRow.lang !== this.form.lang) {
        delete p.id;
      }
      const dosave = () => {
        ApiService.metaModel(this.name).then((r) => {
          const api = p.id ? ApiService.update : ApiService.create;
          api(r.content, p).then(
            (r) => {
              this.$rs_success("成功");
              this.$refs.list.refreshTable();
              this.reset();
            },
            (e) => {
              this.$rs_error((e && e.msg) || "失败");
            },
          );
        });
      };
      if (p.id && p.lang === "default") {
        this.$q
          .dialog({
            title: "Confirm",
            message: "确认要修改默认翻译项?",
            cancel: true,
            persistent: true,
          })
          .onOk(() => {
            dosave();
          });
      } else {
        dosave();
      }
    },
    selected([r]) {
      if (!r) {
        this.reset();
        return;
      }
      this.$set(this, "currentRow", { ...r });
      this.$set(this, "form", { ...r });
      this.mode = "update";
      this.$refs.valueInput.focus();
    },
    reset() {
      this.mode = "add";
      this.$set(this, "form", {
        code: "",
        type: "code",
        resId: "",
        value: "",
        module: null,
        lang: "zh-CN",
      });
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.options = stringOptions.filter((v) => v.toLowerCase().indexOf(needle) > -1);
      });
    },
  },
};
</script>
