<template>
  <div class="q-pa-sm full-height xxl-job-info-form">
    <rs-form ref="form" name="xxl-job-info">
      <rs-field name="id"></rs-field>
      <rs-field name="tenant"></rs-field>
      <rs-field name="jobGroup"></rs-field>
      <rs-field name="jobCron"></rs-field>
      <rs-field name="jobDesc"></rs-field>
      <rs-field name="uid"></rs-field>
      <rs-field name="bizId"></rs-field>
      <rs-field name="triggerStatus"></rs-field>
      <rs-field name="triggerNextTime"></rs-field>
      <rs-field name="triggerLastTime"></rs-field>
      <rs-field name="addTime"></rs-field>
      <rs-field name="updateTime"></rs-field>
      <rs-field name="executorHandler"></rs-field>
      <rs-field name="executorRouteStrategy"></rs-field>
      <rs-field name="alarmEmail"></rs-field>
      <rs-field name="glueType"></rs-field>
      <rs-field name="startTime"></rs-field>
      <rs-field name="endTime"></rs-field>
      <rs-field name="executorFailRetryCount"></rs-field>
      <rs-field name="executorTimeout"></rs-field>
      <rs-field name="executorBlockStrategy"></rs-field>
      <rs-field
        col="12"
        name="executorParam"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar :include="['cancel']" exclude="*">
        <rs-button name="cancel" action="matrix.jobInfo._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
