<template>
  <div class="q-pa-sm full-height site-org-list">
    <div class="full-height scroll">
      <rs-classify namespace="org" :canAddRootNode="false" :treeConf="{ expandAll: false, selectFirst: false }">
        <rs-field name="id" />
        <rs-field name="name" />
        <rs-field name="type" />
      </rs-classify>
    </div>
  </div>
</template>
<script>
import RsClassify from "rsui-core/src/core/components/classify/classify.vue";
export default {
  components: { RsClassify },
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
