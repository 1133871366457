<template>
  <div class="full-height rs-page-xxl-job-log-list">
    <rs-list ref="list" name="xxl-job-log" keywords="id,jobGroup,executorHandler" :conditions="logConditions">
      <rs-search label-width="5em">
        <rs-filter name="jobInfo.id" v-if="!jobId"></rs-filter>
        <rs-filter name="jobGroup"></rs-filter>
        <rs-filter name="triggerCode" :widget="{ _type: 'rs-select', data: triggerCodeOptions }"></rs-filter>
        <rs-filter name="handleCode" :widget="{ _type: 'rs-select', data: handleCodeOptions }"></rs-filter>
      </rs-search>
      <rs-field name="id" sortable></rs-field>
      <rs-field name="jobGroup"></rs-field>
      <rs-field name="executorParam" hidden></rs-field>
      <rs-field name="jobInfo.id" label="任务ID"></rs-field>
      <rs-field name="triggerCode" v-slot="row">
        {{ (triggerCodeOptions.find((d) => row.triggerCode === d.code) || { name: row.triggerCode }).name }}
      </rs-field>
      <rs-field name="triggerTime"></rs-field>
      <rs-field name="handleCode" v-slot="row">
        {{
          row.triggerCode === 500
            ? "-"
            : (handleCodeOptions.find((d) => row.handleCode === d.code) || { name: row.handleCode }).name
        }}
      </rs-field>
      <rs-field name="handleTime"></rs-field>
      <rs-field name="executorAddress"></rs-field>
      <rs-field name="executorHandler"></rs-field>
      <!-- <rs-field name="executorFailRetryCount"></rs-field> -->
      <rs-field type="toolbar" :include="['edit', 'toLog', 'retry', 'kafka']" exclude="*" v-slot="props">
        <rs-button name="edit" color="primary" action="matrix.jobLog._edit"></rs-button>
        <rs-button
          :label="$t_matrix('jobLog-detail')"
          color="secondary"
          name="toLog"
          icon="picture_in_picture"
          :hidden="checkDataNotIn7Day(props.triggerTime)"
          auth="log"
          action="matrix.jobLog._log"
          :payload="{
            query: { id: props.id, seconds: getSeconds(props.triggerTime), jobGroup: props.jobGroup },
          }"
        ></rs-button>
        <rs-button
          :label="$t_matrix('jobLog-retry')"
          color="secondary"
          :hidden="props.handleCode !== 500 || checkDataNotIn7Day(props.triggerTime)"
          name="retry"
          icon="replay"
          auth="retry"
        ></rs-button>
        <rs-button
          :label="$t_matrix('jobLog-kafka')"
          color="secondary"
          :hidden="
            props.handleCode !== 200 ||
              !['orderModuleHandler', 'upgradeModuleHandler'].includes(props.executorHandler) ||
              checkDataNotIn7Day(props.triggerTime)
          "
          name="kafka"
          icon="send"
          auth="kafka"
        ></rs-button>
      </rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import moment from "moment";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      jobId: null,
      directlog: false,
      triggerCodeOptions: [
        {
          code: 0,
          name: "等待",
        },
        {
          code: 200,
          name: "成功",
        },
        {
          code: 500,
          name: "失败",
        },
      ],
      handleCodeOptions: [
        {
          code: 0,
          name: "运行中",
        },
        {
          code: 200,
          name: "成功",
        },
        {
          code: 500,
          name: "失败",
        },
      ],
    };
  },
  computed: {
    logConditions: function() {
      const cons = [
        {
          key: "triggerTime",
          op: OP_TYPE.GE,
          vals: [
            moment()
              .subtract(7, "days")
              .unix(),
          ],
        },
      ];
      if (this.jobId) {
        cons.push({ key: "jobInfo.id", vals: [this.jobId] });
      }
      return cons;
    },
  },
  created() {},
  mounted() {
    this.jobId = this.$route.query.jobId;
    this.directlog = this.$route.query.directlog?.toLowerCase() === "true";
  },
  methods: {
    beforeQuery(e, params, eventId) {
      params[0].orderby = "id desc";
      return {
        params,
        resolve: ({ response }) => {
          if (this.directlog && response?.list.length === 1) {
            const props = response.list[0];
            this.$router.push({
              path: "matrix.jobLog._log",
              query: { id: props.id, seconds: this.getSeconds(props.triggerTime), jobGroup: props.jobGroup },
            });
          }
        },
      };
    },
    getSeconds(date) {
      return moment(date).unix();
    },
    checkDataNotIn7Day(date) {
      return moment()
        .subtract(7, "days")
        .isAfter(moment(date));
    },
    // 处理结果是失败 的 可以重试
    onXxlJobLogRetry(e, params) {
      this.$rs_post("/api/ops/task/trigger?limit=true", {
        id: params.jobInfo.id,
        executorParam: params.executorParam,
      }).then((resp) => {
        if (resp && resp.code === 0 && resp.content.code === 200) {
          this.$refs.list.refreshTable();
          this.$rs_success("重试成功");
        } else {
          this.$rs_warning("重试失败：" + resp.content.msg || resp.message || "");
        }
      });
    },
    // 执行器handler:orderModuleHandler和upgradeModuleHandler
    onXxlJobLogKafka(e, params) {
      this.$rs_get(`/api/ops/ten/deoloy/kafka/retry/${params.id}`, {}).then((resp) => {
        if (resp && resp.code === 0) {
          this.$rs_success("发送成功");
        } else {
          this.$rs_warning("发送失败：" + resp.content.msg || resp.message || "");
        }
      });
    },
  },
};
</script>
