<template>
  <div class="full-height rs-page-site">
    <div class="full-height scroll">
      <rs-list
        ref="siteList"
        name="site"
        keywords="id,name"
        :defImportConfig="{ 'orgs.ns': 'org' }"
        :filter-placeholder="$t_mdata('site-filterPlaceholder')"
      >
        <rs-search label-width="5em" :value="{ status: 1 }">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="type"></rs-filter>
          <rs-filter name="bset" front></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter
            front
            name="orgs"
            :widget="{
              _type: 'rs-tree-select',
              _extend: true,
              conditions: [{ key: 'ns', vals: ['org'] }],
            }"
          ></rs-filter>
          <rs-filter name="channels.channel"></rs-filter>
          <rs-filter name="cashType"></rs-filter>
          <rs-filter name="currency"></rs-filter>
          <rs-filter name="crtTime"></rs-filter>
          <rs-filter name="crtUser"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
          <!-- <rs-filter name="pset"></rs-filter> -->
        </rs-search>
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="type"></rs-field>
        <rs-field name="bset.name"></rs-field>
        <rs-field name="address"></rs-field>
        <rs-field name="status"></rs-field>
        <rs-field name="crtPlat"></rs-field>
        <rs-field name="crtTime"></rs-field>
        <rs-field name="crtUser"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.site._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary" action="mdata.site._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
import RsMenuPicker from "rsui-core/src/system/components/menu-picker/index.vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { RsMenuPicker },
  data: function() {
    return {
      tab: "base",
    };
  },
  computed: {},
  methods: {
    // onMenuPickerSelected(item, e, dlg, event) {
    //   event.next({ actionPayload: { query: { type: item.code } } }); // 继续跳转页面
    //   dlg.hide();
    // },
    // onSiteAddBeforeClick(e, params, eventId) {
    //   const dlg = this.$rs_dlg({
    //     title: "新建业务网点",
    //     cardStyle: "width: 700px",
    //     content: (
    //       <rs-menu-picker
    //         selectionId="system.siteType"
    //         onMenuItemSelected={(item, event) => this.onMenuPickerSelected(item, event, dlg, e)}
    //       />
    //     ),
    //     showFooter: false,
    //   });
    //   e.preventDefault(); // 阻止跳转页面
    // },
  },
};
</script>
