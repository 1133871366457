<template>
  <div class="full-height rs-page-ops-deploy-list">
    <rs-list
      name="ten-deploy"
      keywords="name,id"
      :conditions="[
        { key: 'tenantMode', vals: ['saas'] },
        { key: 'type', vals: ['upgrade'] },
      ]"
    >
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="upgradeType"></rs-filter>
        <rs-filter name="apvStatus"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="tenantMode"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="upgradeType"></rs-field>
      <rs-field name="apvStatus"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['editinfo', 'delete']" v-slot="row">
        <rs-button name="editinfo" icon="edit" color="secondary" :sort="1"></rs-button>
        <rs-button name="delete" icon="delete" color="negative" :hidden="row.apvStatus === 2"></rs-button>
      </rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
      <template slot="filter">
        <q-btn-dropdown
          class="q-ml-md"
          color="primary"
          icon="published_with_changes"
          label="升级发布. ."
          size="sm"
          auto-close
        >
          <q-list>
            <q-item clickable v-close-popup @click="addUpgrade('ops.saasGrayUpgrade._add')">
              <q-item-section>
                <q-item-label>灰度发布</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addUpgrade('ops.saasFullUpgrade._add')">
              <q-item-section>
                <q-item-label>全量发布</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
    addUpgrade(path) {
      this.$router.push({ path });
    },

    editUpgrade(path, params) {
      this.$router.push({ path, query: { id: params.id } });
    },

    onTenDeployEditinfo(e, params, eventId) {
      e.preventDefault();
      let path = null;
      if (params.upgradeType === "gray") {
        path = "ops.saasGrayUpgrade._edit";
      } else if (params.upgradeType === "full") {
        path = "ops.saasFullUpgrade._edit";
      } else if (params.upgradeType === "hotfix") {
        path = "ops.saasHotfixUpgrade._edit";
      }
      this.editUpgrade(path, params);
    },
  },
};
</script>
