<template>
  <div class=" full-height scroll">
    <rs-form ref="form" name="template-user" :value="newFormData" label-width="6.5em" :col="3">
      <rs-field name="name"></rs-field>
      <rs-field name="platform.name" :disable="true"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="info" :col="12"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="settings.acTemplate._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <div class="row q-px-sm ">
      <div class="col">
        <q-list class="q-col-gutter-sm row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <rs-group :label="$t_settings('authDataTitle')" class="bg-white no-padding col-6 ">
              <rs-auth-data ref="authData" type="user" :id="id" />
            </rs-group>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <rs-group :label="$t_settings('account-actionRole')" class="bg-white no-padding col-6 ">
              <rs-role-list
                ref="roleList"
                :plain="true"
                :roleIds="roleIds"
                :conditions="[{ key: 'platform.id', vals: [$rs_user.platform] }]"
              />
            </rs-group>
          </div>
        </q-list>
      </div>
    </div>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import RsRoleList from "rsui-core/src/system/components/role-list/rs-role-list";
import RsAuthData from "rsui-core/src/system/components/auth-data/rs-auth-data";
export default {
  components: {
    RsRoleList,
    RsAuthData,
  },
  data: function() {
    return {
      id: this.$route.query.id,
      newFormData: {},
      restoreIds: [],
      platformModelService: new ModelService(),
      roleIds: [],
    };
  },
  computed: {},
  mounted() {
    if (!this.id) {
      this.platformModelService.find(this.$rs_user.platform, { fields: ["id", "name"] }, "platform").then((res) => {
        const newData = {
          ...this.$refs.form.core.value,
          platform: {
            id: res.id,
            name: res.name,
          },
        };
        setTimeout(() => {
          this.$set(this, "newFormData", newData);
        }, 300);
      });
    }
  },
  methods: {
    beforeRequest(e, params, eventId) {
      params.fields.push("roles");
      return {
        params,
        resolve: ({ params }) => {
          if (params.roles) {
            this.$set(this, "roleIds", params.roles);
          }
        },
      };
    },
    beforeSubmit({ preventDefault }, params) {
      const saveData = {
        ...params,
        roles: this.$refs.roleList.getValue(),
      };
      return {
        params: saveData,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          this.$refs.authData.save(id).then(() => {
            this.$rs_actionRefresh("settings.acTemplate._view");
          });
          // this.$router.back();
        },
      };
    },
  },
};
</script>
