<template>
  <div class="q-pa-sm full-height settings-ops-callback">
    <rs-form ref="form" allDirty name="ops-callback" v-slot="form">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id" :hidden="!id"></rs-field>
        <rs-field name="url"></rs-field>
        <rs-field name="signature"></rs-field>
        <rs-field name="secret" :hidden="!id || !form.signature" :widget="{ copyable: true }"></rs-field>
        <rs-field name="info"></rs-field>
        <!-- <rs-field name="module"></rs-field> -->
      </rs-field>
      <rs-field type="group" ignore name="events">
        <rs-cascades
          ref="eventList"
          :loadDefault="id"
          add-model="ops-event"
          :on-select="onEventSelect"
          :convertInitList="convertInitList"
        >
          <rs-field name="event.id" :editable="false"></rs-field>
          <rs-field name="event.name" :editable="false"></rs-field>
          <rs-field name="configs"></rs-field>
          <rs-field type="toolbar"></rs-field>
        </rs-cascades>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    convertInitList(list) {
      return list.map((d) => {
        if (d.configs && typeof d.configs === "object") {
          d.configs = JSON.stringify(d.configs);
        }
        return d;
      });
    },
    beforeSubmit(e, params) {
      const p = cloneDeep(params);
      delete p.secret;
      return {
        params: p,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          this.$refs.eventList.save(id, (data) => {
            if (data?.length) {
              data.forEach((d) => {
                if (typeof d.configs === "string") {
                  try {
                    d.configs = JSON.parse(d.configs);
                  } catch (error) {
                    d.configs = null;
                  }
                }
              });
            }
            return data;
          });
          this.$rs_actionRefresh("settings.opsCallback._view");
        },
      };
    },
    onEventSelect(data) {
      return {
        event: { id: data.id, name: data.name },
        configs: null,
      };
    },
  },
};
</script>
