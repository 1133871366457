<template>
  <div class="full-height rs-page-tenant-module-list">
    <rs-list name="ten-module" keywords="name,id">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="type"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="icon" v-slot="row">
        <q-icon class="rs-icon">
          <svg aria-hidden="true">
            <use :xlink:href="row.icon" />
          </svg>
        </q-icon>
      </rs-field>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="summary"></rs-field>
      <rs-field name="website"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']"></rs-field>
      <rs-toolbar :include="['add']" exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
