<template>
  <div class="full-height rs-page-xxl-job-info-list">
    <rs-list
      v-if="defaultQuery"
      name="xxl-job-info"
      :conditions="jobConditions"
      keywords="executorHandler,jobDesc"
      :filterEq="['执行器handler']"
      filterPlaceholder="请输入执行器handler/任务描述进行检索"
    >
      <rs-search label-width="5em" :value="defaultQuery">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="tenant" front v-if="!tenantId"></rs-filter>
        <rs-filter name="jobGroup" front v-if="!jobGroup"></rs-filter>
        <rs-filter name="bizId" front></rs-filter>
        <rs-filter name="triggerStatus"></rs-filter>
        <rs-filter name="addTime" front></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="tenant.uname"></rs-field>
      <rs-field name="tenant.name"></rs-field>
      <rs-field name="jobGroup"></rs-field>
      <rs-field name="jobCron"></rs-field>
      <rs-field name="bizId"></rs-field>
      <rs-field name="jobDesc"></rs-field>
      <rs-field name="triggerStatus" v-slot="row">{{ formatStatus(row) }}</rs-field>
      <rs-field name="triggerNextTime" hidden></rs-field>
      <rs-field name="addTime"></rs-field>
      <rs-field name="updateTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'toLog']" exclude="*" v-slot="props">
        <rs-button name="edit" color="primary" action="matrix.jobInfo._edit"></rs-button>
        <rs-button
          :label="$t_matrix('jobInfo-toLog')"
          color="secondary"
          name="toLog"
          icon="assignment"
          action="matrix.jobLog._view"
          :payload="{ query: { jobId: props.id } }"
        ></rs-button>
      </rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
      jobGroup: null,
      defaultQuery: null,
    };
  },
  computed: {
    jobConditions: function() {
      const cons = [];
      if (this.tenantId) {
        cons.push({ key: "tenant.id", vals: [this.tenantId] });
      }
      if (this.jobGroup) {
        cons.push({ key: "jobGroup", vals: [this.jobGroup] });
      }
      return cons;
    },
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    this.jobGroup = this.$route.query.jobGroup;
    const queryTime = moment()
      .subtract(1, "month")
      .format("YYYY-MM-DD 00:00:00");
    this.defaultQuery = this.tenantId || this.jobGroup ? [] : [{ vals: [queryTime], op: "ge", key: "addTime" }];
  },
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
    formatStatus(row) {
      if (row.triggerNextTime === -1) {
        return "结束";
      }
      if (row.triggerStatus === 1) {
        return "运行";
      } else {
        return "停止";
      }
    },
  },
};
</script>
