<template>
  <div class="full-height tenant-domain-form">
    <rs-list ref="list" name="ten-api-user" keywords="name,id">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="globals"></rs-filter>
        <rs-filter name="tenant"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="globals"></rs-field>
      <rs-field name="tenant.name"></rs-field>
      <rs-field name="expire" v-slot="props">
        {{ getExpire(props.expire) }}
      </rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete', 'seeKey']">
        <rs-button name="edit" color="primary" action="tenants.apiuser._edit"></rs-button>
        <rs-button name="seeKey" color="secondary" icon="vpn_key"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary" action="tenants.apiuser._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    getExpire(expire) {
      return typeof expire === "number" ? expire + "秒" : "";
    },
    onTenApiUserSeeKey(e, params) {
      e.preventDefault();
      this.$refs.list.modelService.find(params.id, { fields: ["id", "name", "apiSecret"] }).then((resp) => {
        const dlg = this.$rs_dlg({
          parent: this,
          title: this.$t_tenants("ten-seeApiKey"),
          content: (
            <rs-form ref="form" restoreByQuery={false} value={resp} name="ten-api-user" col="1" label-width="6.5em">
              <rs-field name="name" readonly />
              <rs-field name="id" readonly />
              <rs-field name="apiSecret" readonly />
            </rs-form>
          ),
        }).onOk(() => {
          dlg.hide();
        });
      });
    },
  },
};
</script>
