<template>
  <div class="full-height flex-column ">
    <q-tabs
      v-if="!pickmode"
      v-model="activeTab"
      @input="activeTabChange"
      dense
      align="left"
      class=" bg-white text-black"
      :breakpoint="0"
      active-color="primary"
    >
      <q-tab v-for="tab in subs" :name="tab.sub" :label="tab.name" :key="tab.sub"></q-tab>
    </q-tabs>
    <q-tab-panels class="bg-grey-2  flex-grow" v-model="activeTab" keep-alive>
      <q-tab-panel v-for="tab in subs" :name="tab.sub" :key="tab.sub" class=" q-pa-none">
        <rs-list name="mock-mptemplate" :ref="'r_' + tab.sub" :selection="selection">
          <rs-field name="templateId"></rs-field>
          <rs-field name="templateVersion"></rs-field>
          <rs-field name="templateDesc"></rs-field>
          <rs-field name="createTime"></rs-field>
          <rs-field name="status"></rs-field>
          <rs-field type="toolbar" exclude="*" :include="['del', 'addtomp']">
            <rs-button
              :hidden="activeTab === 2"
              type="link"
              color="primary"
              label="添加到小程序模版"
              name="addtomp"
            ></rs-button>
            <rs-button :hidden="activeTab === 1" type="link" color="red" label="删除" name="del"></rs-button>
          </rs-field>
        </rs-list>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import RsThirdApiMixins from "./appdeploy-mixins.vue";
const org = {
  tenCode: null,
  keywords: null,
  liveState: null,
  appStatus: null,
};

const liveStatus = [
  { code: 0, name: "关闭" },
  { code: 1, name: "开启" },
];

const appStatus = [
  { code: 0, name: "初始化-未发代码" },
  { code: 1, name: "待提审" },
  { code: 2, name: "审核中" },
  { code: 3, name: "待发布" },
  { code: 4, name: "审核失败" },
  { code: 5, name: "已发布" },
  { code: 6, name: "无提审版本" },
];

export default {
  props: {
    pickmode: { type: Boolean, defalut: false },
    selection: { default: "multiple", type: [Boolean, String] },
  },
  mixins: [RsThirdApiMixins],
  data() {
    return {
      subs: [
        { sub: 2, name: "小程序模板" },
        { sub: 1, name: "草稿箱模板" },
      ], // 以sub 为 tab 单位，分立多个tab
      activeTab: 2, // 当前sub
      companyList: [], // 授权的租户
      // 查询表单
      queryForm: {
        ...org,
      },
      appStatus, // app审核状态机
      liveStatus, // 直播状态机
    };
  },

  methods: {
    // 获取选中数据
    getSelected() {
      try {
        const [cmp] = this.$refs[`r_${this.activeTab}`];
        return cmp.selected;
      } catch (error) {
        console.info(error);
        return [];
      }
    },
    // 刷新表格
    refresh() {
      try {
        const [cmp] = this.$refs[`r_${this.activeTab}`];
        cmp.refreshTable();
      } catch (error) {
        console.info(error);
      }
    },
    // 重置搜索条件
    reset() {
      this.$set(this, "queryForm", { ...org });
    },
    // TODO 切换tab 事件
    activeTabChange() {
      //
    },
    onMockMptemplateAddtomp(e, p) {
      this.post("/mini/mp/code/template/add", { templateDraftId: p.templateId }).then((r) => {
        this.$rs_success("添加成功");
      });
    },

    onMockMptemplateDel(e, p) {
      this.post("/mini/mp/code/template/del", { templateId: p.templateId }).then((r) => {
        this.$rs_success("删除成功");
        this.refresh();
        // this.$refs["r_"+this.activeTab].refreshTable();
      });
    },
    // 获取数据
    beforeQuery(e, params) {
      e.preventDefault();
      this.dealList(
        { url: "/mini/mp/code/template/list", data: { type: this.activeTab, wxAppId: "none" } },
        e,
        params,
        (r) => {
          r.list.forEach((element) => {
            element["id"] = element["templateId"];
          });
          return r;
        },
      );
    },
  },
  created() {},
};
</script>
