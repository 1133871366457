<template>
  <div class="full-height">
    <q-splitter v-model="splitterModel" class="full-height" after-class="no-scroll">
      <template v-slot:before>
        <rs-list
          ref="optionList"
          name="option"
          keywords="id,name"
          class="full-height"
          selection="single"
          @selected="selected"
          :linkHidden="true"
          :filter-placeholder="$t_settings('option-filterPlaceholder')"
        >
          <rs-search label-width="5em">
            <rs-filter type="keywords" name="name,id"></rs-filter>
            <rs-filter name="ns"></rs-filter>
            <rs-filter name="code"></rs-filter>
            <rs-filter name="name"></rs-filter>
            <rs-filter name="sysReserved"></rs-filter>
            <rs-filter name="enumType"></rs-filter>
          </rs-search>
          <rs-field name="id"></rs-field>
          <rs-field name="name"></rs-field>
          <rs-field name="ns"></rs-field>
          <rs-field name="sysReserved"></rs-field>
          <!-- <rs-field name="comment"></rs-field> -->
          <rs-toolbar exclude="*"></rs-toolbar>
        </rs-list>
      </template>

      <template v-slot:after>
        <div class="column full-height">
          <rs-form
            ref="optionForm"
            name="option"
            class="q-form-gutter-y-sm col-auto"
            :value="formData"
            col="2"
            label-width="6.5em"
            :isUpdateMode="isUpdateMode"
          >
            <rs-field name="code" :disable="isUpdateMode"></rs-field>
            <!-- <rs-field name="ns" col="4" :disable="isUpdateMode"></rs-field> -->
            <!-- <rs-field name="enumType" col="4" :disable="isUpdateMode"></rs-field> -->
            <rs-field name="name"></rs-field>
            <!-- <rs-field name="sysReserved" col="4" :value="false" disable readonly></rs-field> -->
            <rs-field name="comment" col="12"></rs-field>
            <rs-toolbar>
              <rs-button name="cancel" hidden />
            </rs-toolbar>
          </rs-form>
          <rs-list
            :dense="true"
            ref="optionItemList"
            class="col scroll full-width"
            :data="formData.items"
            :loadDefault="false"
            name="option-item"
            :isPagination="false"
            :selection="false"
            :filterHidden="true"
            :preventDbClick="true"
            edit-mode="row"
          >
            <rs-field name="code"></rs-field>
            <rs-field name="name"></rs-field>
            <!-- <rs-field name="property"></rs-field> -->
            <rs-field name="sysReserved" :value="false" :widget="{ disable: true }"></rs-field>
            <rs-field name="status"></rs-field>
            <!-- <rs-field name="sort"></rs-field> -->
            <rs-field type="toolbar" :alias="isSortMode ? 'sort' : null">
              <template v-slot="prop">
                <rs-button :hidden="isSortMode || prop.sysReserved" name="edit" />
                <rs-button :hidden="isSortMode || prop.sysReserved" name="delete" />
              </template>
            </rs-field>
            <!-- <rs-field name="remark"></rs-field> -->
            <rs-toolbar :include="isSortMode ? ['cancelSort', 'saveSort'] : ['add', 'sortMode']" exclude="*">
              <rs-button
                name="add"
                :hidden="formData.sysReserved"
                label=""
                icon="add"
                color="white"
                text-color="black"
              ></rs-button>
              <rs-button v-if="isSortMode" name="saveSort" icon="check" color="primary"></rs-button>
              <rs-button v-if="isSortMode" name="cancelSort" icon="close" color="grey"></rs-button>
              <rs-button
                v-if="!isSortMode"
                name="sortMode"
                icon="swap_vert"
                color="white"
                text-color="black"
              ></rs-button>
            </rs-toolbar>
          </rs-list>
        </div>
      </template>
    </q-splitter>
  </div>
</template>
<script>
import ApiService from "rsui-core/src/system/service/api";
import { listToMove } from "rsui-core/src/core/util/functions";
import cloneDeep from "lodash/cloneDeep";
export default {
  data: function() {
    return {
      splitterModel: 50,
      selectedRow: {},
      formData: { items: [] },
      isSortMode: false,
      cloneItemList: [], // 排序时，备份数据
    };
  },
  computed: {
    isUpdateMode: function() {
      return !!this.formData.id;
    },
  },
  methods: {
    // 导购列表请求数据 设置添加过滤条件
    beforeQuery(e, params, eventId) {
      if (eventId.model === "option") {
        params[0].orderby = "sysReserved desc,recTime desc";
        return {
          params,
        };
      }
    },
    // 左侧列表 选中事件
    selected(e) {
      if (this.$refs.optionItemList && this.$refs.optionItemList.isEditing) {
        this.$refs.optionItemList.cacelEdit();
      }
      if (this.isSortMode) {
        this.isSortMode = false;
        this.cloneItemList = [];
      }
      if (e && e[0] && this.selectedRow.id !== e[0].id) {
        this.selectedRow = e[0];
        ApiService.option(e[0].id, false).then((res) => {
          if (res && res.content) {
            if (res.content.items) {
              res.content.items.sort((a, b) => a.sort - b.sort);
            } else {
              res.content.items = [];
            }
            this.formData = res.content;
          }
        });
      } else {
        this.$refs.optionList.selected = [this.selectedRow];
      }
    },
    // 右侧表单保存事件 用于处理移除items中的自定义
    beforeSubmit(e, params, eventId) {
      if (eventId.model !== "option") {
        e.preventDefault();
        return;
      }
      return {
        params: { ...params, items: params.items || [] },
      };
    },
    // 左侧列表添加事件【已禁用】
    onOptionAdd(e, params, eventId) {
      this.selectedRow = {};
      this.$refs.optionForm.core.value = {};
      this.formData = { items: [] };
      this.$refs.optionList.selected = [];
    },
    // 左侧列表删除事件【已禁用】
    onOptionDeleteBeforeConfirm(e, params, eventId) {
      if (params[0].sysReserved) {
        this.$rs_warning(this.$t_settings("validate-optionIsSysReserved"));
        e.preventDefault();
      }
    },
    // 开启排序模式
    onOptionItemSortMode(e, params, eventId) {
      if (this.$refs.optionItemList.isEditing) {
        this.$rs_warning(this.$t_s("list-notToSort"));
        e.preventDefault();
        return;
      }
      this.cloneItemList = cloneDeep(this.formData.items);
      this.isSortMode = true;
    },
    // 取消排序，放弃修改
    onOptionItemCancelSort(e, params, eventId) {
      this.$set(this.formData, "items", this.cloneItemList);
      this.isSortMode = false;
    },
    // 保存排序
    onOptionItemSaveSort(e, params, eventId) {
      this.isSortMode = false;
    },
    // 右侧option-item列表 本地添加事件，并给新建数据添加临时id属性【为了表格需要索引，来修复点击一行选中全部新数据bug】
    onOptionItemAdd(e, params, eventId) {
      return {
        params: { __id: Math.random(), status: 1 },
      };
    },

    // 右侧option-item列表 本地删除事件
    onOptionItemDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      this.formData.items.splice(params[0].__index, 1);
    },
    // 右侧option-item列表 本地编辑事件
    onOptionItemSaveLine(e, params, eventId) {
      e.preventDefault();
      const row = params.row;
      this.$refs.optionItemList.formCore.validate().then((r) => {
        if (!r) {
          if (params.isCreated) {
            row.sort = Math.max(0, ...this.formData.items.map((d) => d.sort || 0)) + 1;
            this.formData.items.push(row);
          } else {
            this.$set(this.formData.items, row.__index, row);
          }
          this.$refs.optionItemList.cacelEdit();
        }
      });
    },
  },
};
</script>
