<template>
  <div class="full-height rs-page-spec">
    <div class="full-height   scroll">
      <rs-list
        ref="list"
        name="product-spec-group"
        keywords="name,id"
        :filter-placeholder="$t_mdata('productSpecGroup-filterPlaceholder')"
      >
        <rs-field name="id"></rs-field>
        <rs-field name="type.name"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="icon"></rs-field>
        <rs-field name="info"></rs-field>
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <!-- <rs-field type="toolbar" v-slot="row">
        <rs-button color="secondary" name="editGroup" icon="edit" action="mdata.spec._edit"></rs-button>
      </rs-field> -->
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.spec._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" action="mdata.spec._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    beforeQuery(e, params) {
      params[0].orderby = "recTime desc";
      return {
        params,
      };
    },
  },
};
</script>
