<script>
import Config from "rsui-core/src/config";
export default {
  data() {
    return {};
  },
  props: ["defValue", "closeDialog", "endDateRefresh"],
  mounted() {},
  computed: {},
  methods: {
    beforeSubmit(e, params) {
      e.preventDefault();
      this.$rs_post(Config.API.TEN_MODULES_END_DATE_UPDATE, params).then((resp) => {
        this.$rs_success("修改结束日期成功！");
        this.closeDialog();
        this.endDateRefresh(params.endDate);
      });
    },
  },
  render() {
    const dateProps = {
      props: {
        width: "220px",
        widget: {
          dateProps: {
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              },
            },
          },
        },
      },
    };
    return (
      <rs-form
        ref="form"
        noBorder
        class="full-height"
        name="mock-crmAppAuth"
        labelWidth="10em"
        col={1}
        restoreByQuery={false}
        toolbar={{ forceAlign: "end" }}
        wrapPadding=""
        bodyPadding="q-ma-sm"
        value={this.defValue}>
        <rs-field name="endDate" {...dateProps}></rs-field>
        <rs-field name="remark" widget={{ inputStyle: { height: "80px" }, type: "textarea" }}></rs-field>
        <rs-toolbar>
          <rs-button name="submit" icon="" label="确定" sort={999} auth={null}></rs-button>
        </rs-toolbar>
      </rs-form>
    );
  },
};
</script>
