<template>
  <rs-list name="data-job-log" :conditions="[{ key: 'dataJob.id', vals: [+$route.query.id] }]">
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="modelName" v-slot="row" label="业务描述">
      {{ (row.topic === "event-callback" ? "全量事件回调-" : "全量数据同步-") + (row.modelName || "") }}
    </rs-field>
    <rs-field name="beginTime"></rs-field>
    <rs-field name="endTime"></rs-field>
    <rs-field name="dataCount"></rs-field>
    <rs-field name="topic" hidden></rs-field>
    <rs-field name="queryKey" hidden></rs-field>
    <rs-field name="logStore" hidden></rs-field>
    <rs-field name="result" v-slot="row">
      <span v-if="row.result === '失败'" class="text-red">{{ row.result }}</span>
      <span v-else>{{ row.result }}</span>
    </rs-field>
    <rs-field name="failCause" width="240px"></rs-field>
    <rs-field type="toolbar" :include="['toLog']" exclude="*" v-slot="p">
      <rs-button
        :label="$t_matrix('jobLog-detail')"
        color="secondary"
        name="toLog"
        icon="picture_in_picture"
        auth="log"
        action="tenants.datajob._alilog"
        :payload="{
          query: { id: p.id, from: p.beginTime, topic: p.topic, logStore: p.logStore, queryKey: p.queryKey },
        }"
      ></rs-button>
    </rs-field>
    <!-- <rs-toolbar type="toolbar">
      <rs-button name="submit" label="确认执行" icon=""></rs-button>
      <rs-button color="primary" name="log" label="日志"></rs-button>
    </rs-toolbar> -->
  </rs-list>
</template>
<script>
import moment from "moment";

export default {
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "beginTime desc";
      return {
        params,
      };
    },
    getSeconds(date) {
      return moment(date).unix();
    },
  },
};
</script>
