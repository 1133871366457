// const ctx = require.context("@/views", true, /^((?!\/components\/|\/layout\/|\/login\/).)+\.vue$/, "lazy");
// const all = ctx.keys().map((key) => {
//   return { key: () => ctx(key).then((r) => r.default) };
// });

const ctx = require.context("@/views", true, /^((?!\/components\/|\/layout\/|\/login\/).)+\.vue$/);
const all = ctx.keys().map((key) => {
  const newkey = key.replace(".vue", "");
  return { [newkey]: () => Promise.resolve(ctx(key).default) };
  // return { [newkey]: () => ctx(key).then((r) => r.default) };
});
// const re = Object.assign.apply({}, all);
// console.info(re);
export default all;
// export default {};
