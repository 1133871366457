<template>
  <div class="full-height trade-account-form scroll">
    <rs-form name="trade-account" :isUpdateMode="!!id">
      <rs-field name="id"></rs-field>
      <rs-field name="actType"></rs-field>
      <rs-field name="bankName"></rs-field>
      <rs-field name="trader"></rs-field>
      <rs-field name="bankAccount"></rs-field>
      <rs-field name="bset"></rs-field>
      <rs-field name="payment" :widget="{ dlgTitle: $t_mdata('tradeAccount-paymentDlgTitle') }"></rs-field>
      <rs-field name="transRate" :widget="{ _type: 'rs-percent-input', _extend: true }" suffix="%"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="isDefault"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.tradeAccount._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <div v-if="false">
      <rs-list ref="list" name="site" :conditions="listConditions">
        <!-- <rs-search label-width="5em">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="type"></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="currency"></rs-filter>
        </rs-search> -->
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>

        <rs-toolbar :exclude="['add', 'delete', 'deleteAll', 'edit', 'imports', 'exports']">
          <rs-button name="append" icon="add" color="primary" :label="$t_s('add')"></rs-button>
          <rs-button name="remove" icon="delete" color="red" :label="$t_s('delete')"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
// import RsClassifyService from "rsui-core/src/core/components/classify/classify-service";
export default {
  data: function() {
    return {
      id: null,
    };
  },
  computed: {
    listConditions: {
      get: function() {
        return [{ key: "channel", op: "relExists", vals: [{ key: "rel.id", vals: [this.id] }] }];
      },
    },
    dialogListConditions: {
      get: function() {
        return [
          // {
          //   key: "channel",
          //   op: "relNotExists",
          //   vals: [{ key: "root", vals: [this.id] }],
          // },
        ];
      },
    },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    beforeSubmit(e) {
      return {
        resolve: ({ response }) => {
          if (response && response.content && response.content.id) {
            this.id = response.content.id;
          }
          this.$rs_actionRefresh("mdata.tradeAccount._view");
        },
      };
    },
    onSiteAppend(name, e) {
      const dlg = this.$rs_dlg({
        parent: this,
        title: this.$t_s("add"),
        cardStyle: "width: 700px",
        content: (
          <rs-list ref="dlgList" class="no-padding" name="site" conditions={this.dialogListConditions}>
            <rs-field name="id" />
            <rs-field name="name" />
            <rs-toolbar exclude={["add", "delete", "edit", "imports", "export"]} />
          </rs-list>
        ),
      }).onOk(() => {
        const ids = this.$refs.dlgList.selectedId;
        if (ids.length > 0) {
          // RsClassifyService.addObject({
          //   ns: this.namespace,
          //   group: this.selectedTreeNode.id,
          //   objects: ids,
          // }).then((resp) => {
          //   this.$refs.dlgList.refreshTable(true);
          //   dlg.hide();
          //   this.$q.notify({
          //     message: this.$t_s("add-success"),
          //     color: "positive",
          //     icon: "tag_faces",
          //   });
          // });
        } else {
          this.$q.notify({
            message: this.$t_s("validate-addNoData"),
            color: "warning",
            icon: "tag_faces",
          });
        }
      });
    },
  },
};
</script>
