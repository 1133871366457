<template>
  <div class="q-pa-sm full-height site-form">
    <div class="full-height bg-white column">
      <div class="col-auto">
        <q-tabs v-model="activeTab" dense align="left" class="text-primary" @input="scrollIntoView">
          <q-tab v-for="tab in tabs" :key="tab" :name="tab" :label="typeNameMap[tab]" />
          <q-space></q-space>
          <q-btn
            v-if="!!id"
            size="sm"
            v-auth="$route.query.id ? 'edit' : 'add'"
            :loading="statusBtnLoading"
            :label="siteStatus == 1 ? $t_s('disable') : $t_s('enable')"
            @click="setEnable"
          />
          <q-btn
            size="sm"
            class="q-ml-md"
            v-auth="$route.query.id ? 'edit' : 'add'"
            :loading="loading"
            color="primary"
            :label="$t_s('save')"
            @click="save"
          />
          <q-btn size="sm" class="q-ml-md" color="grey-6" :label="$t_s('cancel')" @click="cancel" />
          &emsp;
        </q-tabs>
        <q-separator />
      </div>
      <div class="col site-scroll-content scroll">
        <q-list class=" q-py-sm q-gutter-sm">
          <rs-group name="base" v-if="isShowGroup('base')" :label="typeNameMap.base">
            <rs-form
              v-if="!!type"
              ref="baseForm"
              name="site"
              :col="3"
              label-width="7.5em"
              floatLayout
              :showLoading="false"
              :value="{ type: type, status: 1 }"
              :isUpdateMode="isUpdateMode"
              @change="onBaseFormChange"
            >
              <rs-field
                name="images"
                :widget="{ _type: 'rs-multiple-image', fixedNumber: [320, 180], height: '180px', width: '320px' }"
                floatRight
              ></rs-field>
              <rs-field name="id"></rs-field>
              <rs-field name="name"></rs-field>
              <rs-field name="displayName"></rs-field>
              <rs-field name="type" readonly></rs-field>
              <rs-field name="usedCode"></rs-field>
              <rs-field name="bset" :widget="{ returnAllAttrs: true }"></rs-field>
              <rs-field name="status" readonly></rs-field>
              <rs-field name="orgs" :widget="orgsWidget"></rs-field>
              <rs-field
                name="storage"
                :widget="{
                  conditions: [{ key: 'type', vals: [4, 5] }],
                }"
              ></rs-field>
              <rs-field name="mainBrand" :widget="{ isSelectedMode: true }"></rs-field>
              <rs-field name="remark" col="12"></rs-field>
              <rs-field name="area" widget="rs-address-picker"></rs-field>
              <rs-field name="address"></rs-field>
              <rs-field name="longitude"></rs-field>
              <rs-field name="latitude"></rs-field>
              <rs-field name="contact"></rs-field>
              <rs-field name="telephone"></rs-field>
              <rs-field name="openings" col="12" widget="rs-business-hours"></rs-field>
              <rs-field name="launchDate"></rs-field>
              <rs-field name="closeDate"></rs-field>
              <rs-field name="festival"></rs-field>
              <rs-field name="totalArea"></rs-field>
              <rs-field name="openArea"></rs-field>
              <rs-field name="storeArea"></rs-field>
              <rs-field name="minSku"></rs-field>
              <rs-field name="maxSku"></rs-field>
              <rs-field name="minStock"></rs-field>
              <rs-field name="maxStock"></rs-field>
              <rs-field name="id" :label-show="false" :col="12" v-slot>
                <div class="text-subtitle2 col-auto text-black">{{ $t_s("customizeAttrLabel") }}:</div>
              </rs-field>
              <rs-field name="ext" :col="12"></rs-field>
            </rs-form>
          </rs-group>
          <rs-group name="classify" v-if="isShowGroup('classify')" :label="typeNameMap.classify">
            <rs-classify-field
              ref="siteGroup"
              ns="siteGroup"
              :restoreId="id"
              :limitTypes="[type]"
              label-width="7.5em"
            />
          </rs-group>
          <rs-group name="channel" v-if="isShowGroup('channel')" :label="typeNameMap.channel">
            <div class="row q-px-md q-pb-md" v-if="showOtherTab">
              <q-space />
              <q-btn dense :label="$t_mdata('site-addChannel')" @click="addChannel" />
            </div>
            <q-card v-for="(form, index) in channelList" :key="form.key" flat class="q-mb-md" bordered>
              <q-card-section>
                <q-icon
                  v-if="index !== 0"
                  style="position: absolute; right: 10px; top: 5px;"
                  size="sm"
                  name="delete"
                  color="negative"
                  @click="deleteChannel(index)"
                />
                <rs-form
                  :ref="form.key"
                  :value="form.value"
                  name="site-channel"
                  :showLoading="false"
                  label-width="6.5em"
                  floatLayout
                  class="no-padding  q-mt-md"
                  :restoreByQuery="false"
                  @change="(val, fireKey, core) => onChannelFormChange(val, fireKey, core, form.key)"
                >
                  <rs-field name="channel" :disable="!bset" :widget="{ conditions: getChannelCond(index) }"></rs-field>
                  <rs-field
                    name="brands"
                    :disable="!bset || !form.channel"
                    :widget="{ conditions: getBrandsCond(index) }"
                  ></rs-field>
                  <rs-field name="supplys"></rs-field>
                  <rs-field name="refunds"></rs-field>
                  <rs-field :hidden="!bsetTypeIsDS" :label="$t_mdata('site-channelBset')">
                    <span class="self-center text-black">{{ bset ? bset.name : "" }}</span>
                  </rs-field>
                  <rs-field
                    :hidden="!bsetTypeIsDS"
                    name="refunds"
                    :label="$t_mdata('site-channelParentBset')"
                    v-slot="props"
                  >
                    <div class="row full-height">
                      <span class="self-center text-black">{{ props.psetName || " " }}</span>
                    </div>
                  </rs-field>
                  <rs-field :hidden="!bsetTypeIsDS" :required="bsetTypeIsDS" name="settleType"></rs-field>
                </rs-form>
              </q-card-section>
            </q-card>
          </rs-group>
          <rs-group name="payment" v-if="isShowGroup('payment')" :label="typeNameMap.payment">
            <rs-form
              key="site-payment"
              ref="paySiteForm"
              :restoreByQuery="false"
              :isUpdateMode="!!id"
              :value="sitePayFormData"
              class="col-auto"
              name="site"
              :showLoading="false"
              label-width="6.5em"
            >
              <rs-field name="currency"></rs-field>
              <rs-field name="cashType"></rs-field>
              <rs-field name="cashScale"></rs-field>
              <rs-field name="mantissaMode"></rs-field>
            </rs-form>
            <div class="text-subtitle2 q-pa-sm q-mt-md col-auto ">{{ $t_mdata("site-paymentLabel") }}:</div>
            <rs-list
              ref="sitePaymentList"
              name="site-payment"
              class="col"
              :isPagination="false"
              :selection="false"
              edit-mode="all"
              :filterHidden="true"
              :data="paymentList"
              :loadDefault="false"
            >
              <rs-field name="pay.name" :editable="false"></rs-field>
              <rs-field name="pay.isCash" :editable="false"></rs-field>
              <rs-field name="pay.currency" :editable="false"></rs-field>
              <rs-field name="pay.isThird" :editable="false"></rs-field>
              <rs-field name="account" :disable="!bset" :widget="{ conditions: accountCond }"></rs-field>
              <rs-field name="refundLevel"></rs-field>
              <rs-field name="status"></rs-field>
              <rs-field type="toolbar" exclude="*" :include="['moveUp', 'moveDown']">
                <template v-slot="prop">
                  <rs-button hidden />
                  <rs-button
                    :hidden="prop.__index === 0"
                    color="negative"
                    name="moveUp"
                    icon="arrow_upward"
                    size="sm"
                    dense
                    outline
                  />
                  <rs-button
                    :hidden="prop.__index === paymentList.length - 1"
                    color="primary"
                    name="moveDown"
                    icon="arrow_downward"
                    size="sm"
                    dense
                    outline
                  />
                </template>
              </rs-field>
              <rs-toolbar :exclude="['add', 'delete', 'deleteAll', 'edit', 'imports', 'exports']">
                <rs-button name="add" icon="add" label="" color="secondary"></rs-button>
              </rs-toolbar>
            </rs-list>
          </rs-group>
          <rs-group name="invoice" v-if="isShowGroup('invoice')" :label="typeNameMap.invoice">
            <rs-list
              ref="siteInvoice"
              class="full-height"
              name="site-invoice"
              :filterHidden="true"
              :isPagination="false"
              :selection="false"
              :data="invoiceList"
              edit-mode="all"
              @table-form-change="tableFormChange"
              :loadDefault="false"
            >
              <rs-field name="sort" :label="$t_mdata('siteForm-sortLabel')"></rs-field>
              <rs-field name="relationStart" :label="$t_mdata('siteForm-relationLabel')"></rs-field>
              <rs-field
                name="minAmount"
                required
                :editable="({ index }) => index !== 0"
                :widget="{ maxlength: 9 }"
              ></rs-field>
              <rs-field name="relationEnd" :label="$t_mdata('siteForm-relationLabel')"></rs-field>
              <rs-field name="maxAmount" :label="$t_mdata('siteForm-maxAmountLabel')"></rs-field>
              <rs-field name="invoice" required :disable="!bset" :widget="{ conditions: invoiceCond }"></rs-field>

              <rs-field type="toolbar" exclude="*" :include="['deleteItem', 'addItem']">
                <template v-slot="prop">
                  <rs-button
                    :hidden="prop.__index === 0"
                    color="negative"
                    name="deleteItem"
                    icon="remove"
                    size="sm"
                    dense
                    outline
                  />
                  <rs-button
                    :hidden="$refs.siteInvoice ? $refs.siteInvoice.dataLength - 1 !== prop.__index : true"
                    color="primary"
                    name="addItem"
                    icon="add"
                    size="sm"
                    dense
                    outline
                  />
                </template>
              </rs-field>
              <rs-toolbar :exclude="['add', 'delete', 'deleteAll', 'edit', 'imports', 'exports']">
                <rs-button name="add" icon="add" label="" color="secondary"></rs-button>
              </rs-toolbar>
            </rs-list>
          </rs-group>
          <rs-group name="guider" v-if="isShowGroup('guider')" :label="typeNameMap.guider">
            <rs-list
              ref="guider"
              name="guider"
              :preventDbClick="true"
              edit-mode="row"
              :filterHidden="true"
              :conditions="guiderConditions"
              class="guider-sticky-column-table"
              :loadDefault="!!$route.query.id"
            >
              <rs-field
                name="emp"
                minWidth="120px"
                :required="true"
                :widget="{ conditions: empConditions, returnAllAttrs: true }"
                v-slot="props"
              >
                <div class="full-height row items-center">{{ props.emp ? props.emp.id || "" : "" }}</div>
              </rs-field>
              <rs-field name="emp.name" :editable="false"></rs-field>
              <rs-field name="emp.mobile" :editable="false"></rs-field>
              <rs-field name="emp.email" :editable="false"></rs-field>
              <rs-field name="role" :required="true" :value="102"></rs-field>
              <rs-field name="status"></rs-field>
              <rs-field type="toolbar"></rs-field>
              <rs-toolbar :exclude="['add', 'imports', 'exports']">
                <rs-button name="addGuider" icon="add" color="primary" :label="$t_s('add')"></rs-button>
              </rs-toolbar>
            </rs-list>
          </rs-group>
        </q-list>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "rsui-core/src/system/service/api";
import { listToMove } from "rsui-core/src/core/util/functions";
import cloneDeep from "lodash/cloneDeep";
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
import RsClassifyField from "rsui-core/src/core/components/classify-field/rs-classify-field";
import RsMenuPicker from "rsui-core/src/system/components/menu-picker/index.vue";
import { OP_TYPE } from "rsui-core/src/core/types";
import { uid } from "quasar";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { RsClassifyField, RsMenuPicker },
  data: function() {
    return {
      loading: false, // 用于保存时未完成不可在此点击，以及回显数据时，当获取到site详情时在获取渠道信息完成后才可以保存【忽略了收款方式和开票属性接口,因为他们返回快】
      activeTab: "base", // 当前选中的锚点，默认基本信息
      typeNameMap: {
        base: this.$t_mdata("site-baseTab"),
        channel: this.$t_mdata("site-channelTab"),
        payment: this.$t_mdata("site-paymentTab"),
        invoice: this.$t_mdata("site-invoiceTab"),
        guider: this.$t_mdata("site-guiderTab"),
        classify: this.$t_mdata("product-classifyTab"),
      },
      orgsWidget: {
        _type: "rs-tree-select",
        _extend: true,
        treeConf: {
          onlyCheckOne: true,
          tickStrategy: "strict",
          // 没有这个方法 表示每个根节点只能选一个，有这个就表示全部的只能选一个
          getOnlyCheckOneKey: (node) => {
            return "id";
          },
        },
        conditions: [{ key: "ns", vals: ["org"] }],
      },
      scrollFunction: this.debounce(this.handleScroll, 200), // 锚点回显用到的滚动绑定事件
      channelList: [{ key: "channel-0", value: {}, channel: null }],
      paymentList: [],
      invoiceList: [],
      id: null, // 编辑网点的ID
      preCreatedId: null, // 新建页面上一次创建成功但是保存其他信息失败时 的ID,用于区别下一次点击保存时是更新【id没有变化】还是创建【id和上一次创建不一致】
      modelService: new ModelService(),
      fieldModelService: new ModelService(), // field 服务，用于获取site自定义属性字段
      bset: null, // 当前选中的管辖组织
      bizSubjectService: new ModelService(), // 用于渠道属性tab中 获取上级结算主体名称字段
      sitePayFormData: {}, // 网点详情中收银属性中form回显时，【为了减少call 一次详情API】
      isUpdateMode: false, // 用于表示基本信息form是不是更新
      type: null, // 网点类型
      siteStatus: null, // 网点状态
      statusBtnLoading: false,
      empConditions: [],
    };
  },
  computed: {
    // 根据不同的网点类型显示不同tab信息
    tabs: function() {
      if ([1, 2, 3].includes(this.type)) {
        return ["base", "classify", "channel", "payment", "invoice", "guider"];
      } else if ([4, 5, 6].includes(this.type)) {
        return ["base", "classify", "channel"];
      }
      return [];
    },
    // 实体仓、虚拟仓、积分兑换商城商户 只有基本属性和渠道属性和业务属性
    showOtherTab: function() {
      return [1, 2, 3].includes(this.type);
    },
    // 结算主体是不是分销商类型，来控制分销渠道属性展示
    bsetTypeIsDS: function() {
      return this.bset && this.bset.type !== "HQ";
    },
    accountCond: function() {
      return this.bset ? [{ key: "bset.id", vals: [this.bset.id] }] : [];
    },
    invoiceCond: function() {
      return this.bset ? [{ key: "bset.id", vals: [this.bset.id] }] : [];
    },
    guiderConditions: function() {
      return this.id ? [{ key: "sit.id", vals: [this.id] }] : [];
    },
  },
  activated() {
    if (!this.id && !this.type) {
      this.selectSiteType();
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    // this.type = +this.$route.query.type;
    if (this.id) {
      this.modelService.find(this.id, { fields: ["id", "type"] }, "site").then((resp) => {
        this.type = resp.type;
        this.init();
      });
    } else {
      // this.selectSiteType();
    }
    this.isUpdateMode = !!this.id;
    this.loading = !!this.id;
    this.statusBtnLoading = !!this.id;
  },
  beforeDestroy() {
    this.$el.querySelector(".site-scroll-content").removeEventListener("scroll", this.scrollFunction);
  },
  methods: {
    // 新建时
    selectSiteType() {
      const dlg = this.$rs_dlg({
        title: this.$t_mdata("siteForm-selectTypeTitle"),
        cardStyle: "width: 700px",
        cancel: false,
        parent: this,
        content: (
          <rs-menu-picker
            selectionId="system.siteType"
            onMenuItemSelected={(item, event) => {
              this.type = +item.code;
              dlg.hide();
              this.init();
            }}
          />
        ),
        showFooter: false,
      });
    },
    init() {
      if (this.showOtherTab) {
        this.initSitePayment();
        this.initSiteInvoice();
      }
      setTimeout(() => {
        this.$el.querySelector(".site-scroll-content").addEventListener("scroll", this.scrollFunction);
      });
    },
    /**
     * 根据不同商品类型显示不同tab
     */
    isShowGroup(groupName) {
      return this.tabs.includes(groupName);
    },
    /**
     * 顶部tabs点击时触发锚点事件，页面滚动到对应未知
     */
    scrollIntoView(e) {
      this.activeTab = e;
      if (this.$el.querySelector(`div[name='${e}']`)) {
        this.$el.querySelector(`div[name='${e}']`).scrollIntoView();
      }
    },
    /**
     * 防抖
     */
    debounce(fn, wait) {
      let timeout = null;
      return function() {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    handleScroll() {
      const scrollTop = this.$el.querySelector(".site-scroll-content").scrollTop;
      for (let i = 0; i < this.tabs.length; i++) {
        const key = this.tabs[i];
        const item = this.$el.querySelector(`div[name='${key}']`);
        if (item && scrollTop <= item.offsetTop) {
          this.activeTab = key;
          return;
        }
      }
    },
    initSiteChannel() {
      if (this.id) {
        ApiService.listByModel(
          { id: "site-channel", service: "mdata" },
          {
            fields: ["id", "channel.name", "brands", "supplys", "refunds", "settleType"],
            conditions: [{ key: "sit.id", vals: [this.id] }],
            orderby: "id",
          },
        )
          .then((resp) => {
            if (resp && resp.content && resp.content.list) {
              const list = resp.content.list.map((d, index) => {
                return { key: `channel-${index}`, value: { ...d, psetName: null } };
              });
              this.$set(this, "channelList", list);
            }
          })
          .finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    },
    initSitePayment() {
      const initPaymentList = (list) => {
        return list.map((d, index) => {
          const val = {};
          Object.keys(d).forEach((k) => {
            val[`pay.${k}`] = d[k];
          });
          return { ...val, refundLevel: d.refundLevel, sort: index + 1, status: d.status };
        });
      };
      ApiService.listByModel(
        { id: "payment", service: "mdata" },
        {
          fields: ["id", "name", "isCash", "currency", "isThird", "status", "refundLevel", "sort"],
          conditions: [{ key: "status", vals: [1] }],
          orderby: "sort",
        },
      ).then((res) => {
        if (res && res.content && res.content.list) {
          if (this.id) {
            ApiService.listByModel(
              { id: "site-payment", service: "mdata" },
              {
                fields: ["id", "pay.id", "account.bankName", "refundLevel", "status", "sort"],
                conditions: [{ key: "sit.id", vals: [this.id] }],
                orderby: "sort",
              },
            ).then((resp) => {
              if (resp && resp.content && resp.content.list) {
                const sitePayMap = {};
                const excludePayList = []; // 收款方式不在 已保存的list中
                const includePayList = [];
                res.content.list.forEach((d) => {
                  const item = resp.content.list.find((t) => t.pay.id === d.id);
                  if (item) {
                    sitePayMap[d.id] = item;
                    d.sort = item.sort;
                    includePayList.push(d);
                  } else {
                    excludePayList.push(d);
                  }
                });
                includePayList.sort((a, b) => a.sort - b.sort);
                this.paymentList = [...includePayList, ...excludePayList].map((d, index) => {
                  const val = {};
                  Object.keys(d).forEach((k) => {
                    val[`pay.${k}`] = d[k];
                  });
                  const sitePayMent = sitePayMap[d.id];
                  return {
                    ...val,
                    account: sitePayMent ? sitePayMent.account : null,
                    refundLevel: sitePayMent ? sitePayMent.refundLevel : d.refundLevel,
                    status: sitePayMent ? sitePayMent.status : d.status, // 默认新增的支付方式可用
                    sort: index + 1,
                  };
                });
              } else {
                this.paymentList = initPaymentList(res.content.list);
              }
            });
          } else {
            this.paymentList = initPaymentList(res.content.list);
          }
        }
      });
    },
    initSiteInvoice() {
      if (this.id) {
        ApiService.listByModel(
          { id: "site-invoice", service: "mdata" },
          {
            fields: ["id", "invoice.name", "minAmount"],
            conditions: [{ key: "sit.id", vals: [this.id] }],
            orderby: "id",
          },
        ).then((resp) => {
          if (resp && resp.content && resp.content.list) {
            const invoiceList = resp.content.list.map((d, index) => {
              const nextItem = index !== resp.content.list.length - 1 ? resp.content.list[index + 1] : null;
              return {
                ...d,
                sort: index + 1,
                relationStart: ">=",
                relationEnd: "<",
                maxAmount: nextItem ? nextItem.minAmount : null,
              };
            });
            this.$set(this, "invoiceList", invoiceList);
          } else {
            this.$set(this, "invoiceList", [this.getSiteInvoiceItem(1, 0)]);
          }
        });
      } else {
        this.$set(this, "invoiceList", [this.getSiteInvoiceItem(1, 0)]);
      }
    },
    // 根据结算主体类型判断渠道属性属于自营渠道【HQ】还是分销渠道【DS】
    beforeRequest(e, params, eventId) {
      if (eventId.key !== "site-payment" && eventId.model === "site") {
        params.fields.push("bset.type", "openings");
        // 如果显示收银属性tab，需要获取一些site的收银属性用于其form回显值
        params.fields.push("currency", "cashType", "cashScale", "mantissaMode");
        return {
          params,
          resolve: ({ params }) => {
            if (params.type) {
              this.type = params.type;
            }
            this.siteStatus = params.status;
            this.statusBtnLoading = false;
            if (params && params.bset) {
              this.$set(this, "bset", params.bset);
            }
            if (this.showOtherTab) {
              this.$set(this, "sitePayFormData", {
                ...pick(params, ["currency", "cashType", "cashScale", "mantissaMode"]),
              });
            }
            // 渲染channel时页面需要bset值，所以获取详情以后在获取渠道回显信息
            this.initSiteChannel();
          },
        };
      }
    },
    /**
     * 基本信息表单change事件，监听bset变化，来改变渠道属性
     */
    onBaseFormChange(val, fireKey, core) {
      // 当新建页面时，已经保存了，如果val.id变化，需要修改isUpdateMode,来区别是否需要id字段验证【防止验证重复id 】
      if (!this.id && this.preCreatedId && fireKey.find((d) => d === "id")) {
        this.isUpdateMode = val.id === this.preCreatedId;
      }
      if (fireKey.find((d) => d === "bset")) {
        // 当结算主体发生变化时，需要清空渠道信息tab中的渠道属性，重新选择渠道
        if (!val.bset || (this.bset && val.bset && this.bset.id !== val.bset.id)) {
          this.resetChannelList();
          this.resetPaymentList();
          this.resetInvoiceList();
        }
        this.$set(this, "bset", val.bset);
        if (!this.isUpdateMode) {
          setTimeout(() => {
            this.$refs.paySiteForm.core.setValue({ currency: val.bset.currency });
          });
        }
      }
    },
    // 当结算主体属性bset 发生变化时，渠道信息中所有的渠道清空，重新选择，上级结算主体名称也需要清空,品牌也是
    resetChannelList() {
      this.channelList.forEach((d) => {
        this.$refs[d.key][0].core.setValue({ channel: null, psetName: null, brands: null });
      });
    },
    // 当结算主体属性bset 发生变化时，收银属性Tab中收支账户都要清空
    resetPaymentList() {
      if (
        this.showOtherTab &&
        this.$refs.sitePaymentList &&
        this.$refs.sitePaymentList.tableForm &&
        this.$refs.sitePaymentList.tableForm.formCoreDic
      ) {
        Object.values(this.$refs.sitePaymentList.tableForm.formCoreDic).forEach((core) => {
          core.setValue({ account: null });
        });
      }
    },
    // 当结算主体属性bset 发生变化时，开票属性Tab中开票资料都要清空
    resetInvoiceList() {
      if (
        this.showOtherTab &&
        this.$refs.siteInvoice &&
        this.$refs.siteInvoice.tableForm &&
        this.$refs.siteInvoice.tableForm.formCoreDic
      ) {
        Object.values(this.$refs.siteInvoice.tableForm.formCoreDic).forEach((core) => {
          core.setValue({ invoice: null });
        });
      }
    },

    // 添加开票属性和初始化开票属性时，获取单个数据
    getSiteInvoiceItem(sort = 1, minAmount = null) {
      return { id: uid(), minAmount: minAmount, sort: sort, relationStart: ">=", relationEnd: "<", maxAmount: null };
    },
    // 当结算主体是分销商时，分销渠道显示上级结算主体名称显示
    onChannelFormChange(val, fireKey, core, channelListkey) {
      if (fireKey.find((d) => d === "channel")) {
        // 用于html中设置品牌disable属性，判断渠道未选择时，品牌不能选择
        const item = this.channelList.find((d) => d.key === channelListkey);
        if (item) {
          item.channel = val.channel;
        }
        // 防止第一次设置值时，移除品牌，当channel变化且brands没变时且brands有值时
        if (!fireKey.find((d) => d === "brands") && val.brands) {
          // 当渠道发生变化时，品牌清空，因为品牌是根据渠道过滤选择的
          setTimeout(() => {
            this.$refs[channelListkey][0].core.setValue({ brands: null });
          });
        }
        // 下面用于设置psetName名称【分销渠道显示上级结算主体名称显示】
        if (!val.channel || !val.channel.id) {
          if (val.psetName) {
            setTimeout(() => {
              this.$refs[channelListkey][0].core.setValue({ psetName: null });
            });
          }
        } else if (this.bsetTypeIsDS && this.bset && this.bset.id) {
          this.bizSubjectService
            .list(
              {
                fields: ["id", "pset.name"],
                conditions: [
                  { key: "channel.id", vals: [val.channel.id] },
                  { key: "bset.id", vals: [this.bset.id] },
                ],
              },
              "biz-subject",
            )
            .then((resp) => {
              if (Array.isArray(resp.list) && resp.list.length) {
                if (resp.list.length === 1) {
                  const psetName = resp.list[0].pset ? resp.list[0].pset.name : "";
                  this.$refs[channelListkey][0].core.setValue({ psetName });
                }
              }
            });
        }
      }
    },
    // 添加渠道
    addChannel() {
      const index = this.channelList[this.channelList.length - 1].key.split("-")[1];
      this.channelList.push({ key: `channel-${index + 1}`, value: { psetName: null }, channel: null });
    },
    // 删除单个渠道
    deleteChannel(index) {
      this.$q
        .dialog({
          title: this.$t_s("tips"),
          message: this.$t_s("delete-tip"),
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          this.channelList.splice(index, 1);
        });
    },
    /**
     * 渠道属性添加过滤条件：
     * 1.不能添加已经添加的分销渠道;
     * 2.当结算主体是分销商类型时，只能添加结算主体是当前结算主体的渠道
     */
    getChannelCond(index) {
      const vals = this.channelList.reduce((res, item, i) => {
        try {
          const val = this.$refs[item.key][0].core.value;
          if (i !== index && val && val.channel) {
            res.push(val.channel.id);
          }
        } catch (error) {
          //
        }
        return res;
      }, []);
      const conds = vals && vals.length > 0 ? [{ key: "id", op: "ne", vals }] : [];
      if (this.bset) {
        if (this.bsetTypeIsDS) {
          conds.push({ key: "subs.bset.id", vals: [this.bset.id] });
        } else {
          conds.push({ key: "bset.id", vals: [this.bset.id] });
        }
      }
      return conds;
    },
    // 根据不同的渠道显示对应的品牌
    getBrandsCond(index) {
      const item = this.channelList[index];
      if (
        this.$refs[item.key] &&
        this.$refs[item.key][0] &&
        this.$refs[item.key][0].core.value &&
        this.$refs[item.key][0].core.value.channel
      ) {
        return [{ key: "channels.id", vals: [this.$refs[item.key][0].core.value.channel.id] }];
      }
      return [];
    },
    // 收银属性中上移事件
    onSitePaymentMoveUp(e, params) {
      this.$refs.sitePaymentList.tableForm.moveFormCore(params.__index, true, false, "sort");
      this.paymentList = listToMove(this.paymentList, params.__index, true, false, "sort");
    },
    // 收银属性中下移事件
    onSitePaymentMoveDown(e, params) {
      this.$refs.sitePaymentList.tableForm.moveFormCore(params.__index, false, false, "sort");
      this.paymentList = listToMove(this.paymentList, params.__index, false, false, "sort");
    },
    // 开票属性中删除事件
    onSiteInvoiceDeleteItem(e, params) {
      e && e.preventDefault && e.preventDefault();
      const cb = () => {
        this.$refs.siteInvoice.innerList.forEach((d, index) => {
          d.sort = index + 1;
          if (index < this.$refs.siteInvoice.innerList.length - 1) {
            d.maxAmount = this.$refs.siteInvoice.tableForm.formCoreDic[index + 1].value.minAmount;
          }
        });
      };
      this.$q
        .dialog({
          title: "提示!",
          message: this.$t_s("delete-tip"),
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          this.$refs.siteInvoice.deleteEditRowByIndex(params.__index);
          cb();
        });
    },
    // 开票属性中添加事件
    onSiteInvoiceAddItem(e) {
      e && e.preventDefault && e.preventDefault();
      const maxSort = Math.max(1, ...this.$refs.siteInvoice.innerList.map((d) => d.sort || 0));
      this.$refs.siteInvoice.innerList.push(this.getSiteInvoiceItem(maxSort + 1));
    },
    // 开票信息列表编辑数据发生变化，实时修改上条数据的最大值
    tableFormChange({ index, form, fields, core, list }) {
      if (fields.includes("minAmount")) {
        if (index > 0) {
          list[index - 1].maxAmount = form.minAmount || null;
        }
      }
    },
    // 获取渠道信息保存数据
    getChannels() {
      return this.channelList.map((d) => {
        return this.$refs[d.key][0].core.getValue();
      });
    },
    // 获取收银属性保存数据
    getPayments() {
      const formCoreDic = this.$refs.sitePaymentList.tableForm.formCoreDic;
      return Object.keys(formCoreDic).map((d, index) => {
        const val = formCoreDic[d].value;
        return {
          ...pick(val, ["account", "refundLevel", "status", "sort"]),
          pay: {
            id: val["pay.id"],
          },
        };
      });
    },
    // 获取开票信息需要保存的数据
    getInvoices() {
      const formCoreDic = this.$refs.siteInvoice.tableForm.formCoreDic;
      return Object.keys(formCoreDic).map((d, index) => {
        const val = formCoreDic[d].value;
        return pick(val, ["invoice", "minAmount"]);
      });
    },
    // 获取导购信息需要保存的数据
    getGuiders(isCreated = false) {
      const list = this.$refs.guider.innerList;
      return list.map((d) => {
        return {
          emp: {
            id: d.emp.id,
          },
          role: d.role,
          status: d.status,
        };
      });
    },
    // 创建网点
    createdSave() {
      const allValidate = [this.$refs.baseForm.core.validate()];
      // 验证渠道属性
      this.channelList.forEach((d) => {
        allValidate.push(this.$refs[d.key][0].core.validate());
      });
      if (this.showOtherTab) {
        // 验证收银属性
        allValidate.push(this.$refs.paySiteForm.core.validate());
        allValidate.push(this.$refs.sitePaymentList.tableForm.validate());
        // 开票属性验证
        const getValidateConfig = ({ index, row, preRow, nextRow }) => {
          return {
            minAmount: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (value <= 0) {
                    callback(new Error(this.$t_mdata("validate-minAmountLess1")));
                  } else if ((preRow && preRow.minAmount >= value) || (nextRow && nextRow.minAmount <= value)) {
                    callback(new Error(this.$t_mdata("validate-minAmountNotGrow")));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          };
        };
        allValidate.push(this.$refs.siteInvoice.tableForm.validate(getValidateConfig));
      }
      Promise.all(allValidate).then((vaildateArr) => {
        const validateSucces = vaildateArr.findIndex((d) => !!d) === -1;
        if (validateSucces) {
          const groups = this.$refs.siteGroup.getValue();
          const saveData = {
            ...this.$refs.baseForm.core.getValue(),
            ...(this.showOtherTab ? this.$refs.paySiteForm.core.getValue() : {}),
            groups,
          };
          this.modelService.create(saveData, "site").then((resp) => {
            if (resp && resp.id) {
              this.preCreatedId = resp.id;
              this.isUpdateMode = true;
              const savePromise = [this.modelService.cascade("channels", resp.id, this.getChannels())];
              if (this.showOtherTab) {
                savePromise.push(
                  this.modelService.cascade("payments", resp.id, this.getPayments()),
                  this.modelService.cascade("invoices", resp.id, this.getInvoices()),
                  this.modelService.cascade("guiders", resp.id, this.getGuiders()),
                );
              }
              Promise.all(savePromise)
                .then((res) => {
                  this.$rs_success(this.$t_s("add-success"));
                  this.cancel();
                })
                .finally(() => (this.loading = false));
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // 更新网点
    updatedSave(id) {
      const allValidate = [this.$refs.baseForm.core.validate()];
      const savePromise = [];
      if (this.$refs[this.channelList[0].key]) {
        this.channelList.forEach((d) => {
          allValidate.push(this.$refs[d.key][0].core.validate());
        });
        savePromise.push(() => this.modelService.cascade("channels", id, this.getChannels(), "site"));
      }
      if (this.$refs.sitePaymentList && this.$refs.paySiteForm) {
        // 验证收银属性
        allValidate.push(this.$refs.paySiteForm.core.validate());
        allValidate.push(this.$refs.sitePaymentList.tableForm.validate());
        // call api
        const groups = this.$refs.siteGroup.getValue();
        const saveData = {
          ...this.$refs.baseForm.core.getValue(),
          ...this.$refs.paySiteForm.core.getValue(),
          groups,
        };
        savePromise.push(() => this.modelService.update(saveData, "site"));
        savePromise.push(() => this.modelService.cascade("payments", id, this.getPayments(), "site"));
      } else {
        // 如果没有打开收银属性tab，site信息保存只需要base Tab中信息即可
        savePromise.push(() => this.modelService.update(this.$refs.baseForm.core.getValue(), "site"));
      }
      if (this.$refs.siteInvoice) {
        // 开票属性验证
        const getValidateConfig = ({ index, row, preRow, nextRow }) => {
          return {
            minAmount: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (value <= 0) {
                    callback(new Error(this.$t_mdata("validate-minAmountLess1")));
                  } else if ((preRow && preRow.minAmount >= value) || (nextRow && nextRow.minAmount <= value)) {
                    callback(new Error(this.$t_mdata("validate-minAmountNotGrow")));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          };
        };
        allValidate.push(this.$refs.siteInvoice.tableForm.validate(getValidateConfig));

        savePromise.push(() => this.modelService.cascade("invoices", id, this.getInvoices(), "site"));
      }
      if (this.$refs.guider) {
        savePromise.push(() => this.modelService.cascade("guiders", id, this.getGuiders(), "site"));
      }
      Promise.all(allValidate).then((vaildateArr) => {
        const validateSucces = vaildateArr.findIndex((d) => !!d) === -1;
        if (validateSucces) {
          Promise.all(savePromise.map((i) => i()))
            .then((res) => {
              this.$rs_success(this.$t_s("edit-success"));
              this.cancel();
            })
            .finally(() => (this.loading = false));
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // 保存按钮事件
    save() {
      // 判断导购信息是否处于行编辑状态
      if (this.$refs.guider && this.$refs.guider.isEditing) {
        this.$rs_warning(this.$t_mdata("site-guiderIsEditing"));
        return;
      }
      this.loading = true;
      // 当id不存在【表示新建网点页面】
      if (!this.id) {
        const siteFormData = this.$refs.baseForm.core.getValue();
        // 并且上一次新建的preCreatedId也不存在【表示新建页面第一次保存网点表单】 或者不等于当前新建页面中的ID【上一次保存成功但是又修改网点ID,重新新建一个网点】
        if (!this.preCreatedId || (siteFormData && siteFormData.id !== this.preCreatedId)) {
          this.createdSave();
          return;
        }
      }
      // 当this.id存在时表示编辑网点页面；当this.id不存在时使用this.preCreatedId【上一次创建成功的ID但是保存其他信息失败后再次保存，且这次保存id不变时，保存其他信息】
      this.updatedSave(this.id || this.preCreatedId);
    },
    // 关闭tab信息
    cancel() {
      this.$rs_actionRefresh("mdata.site._view");
    },
    // 启用禁用
    setEnable() {
      if (this.id) {
        this.statusBtnLoading = true;
        const toStatus = this.siteStatus == 1 ? 0 : 1;
        this.modelService
          .setEnable([this.id], toStatus, "site")
          .then((resp) => {
            if (resp.result) {
              this.siteStatus = toStatus;
              this.$refs.baseForm.core.setValue({ status: this.siteStatus });
            } else {
              this.$rs_error(this.$t_s("statusUpdateFail"));
            }
          })
          .finally(() => (this.statusBtnLoading = false));
      }
    },
    // 设置添加员工条件
    setGuiderEmpCond(list = null) {
      this.empConditions = [
        { key: "id", op: OP_TYPE.NE, vals: (list || this.$refs.guider.innerList).map((d) => d.emp.id) },
      ];
    },
    // 添加多个导购
    onGuiderAddGuider(e, params, eventId) {
      if (this.$refs.guider.isEditing) {
        this.$rs_warning(this.$t_mdata("site-guiderIsEditing"));
        return;
      }
      const dlg = this.$rs_model_dlg({
        modelName: "employee",
        multiple: true,
        conditions: this.empConditions,
      }).then(({ selected, dlg }) => {
        if (selected.length > 0) {
          const data = selected.map((d) => {
            return {
              id: uid(),
              emp: d,
              role: 102,
              status: 1,
            };
          });
          this.$set(this.$refs.guider, "innerList", [...data, ...this.$refs.guider.innerList]);
          this.setGuiderEmpCond();
        }
        dlg.hide();
      });
    },
    // 导购信息保存
    onGuiderSaveLine(e, params, eventId) {
      e.preventDefault();
      this.$refs.guider.formCore.validate().then((res) => {
        if (!res) {
          const row = params.row;
          if (params.isCreated) {
            row.status = 1;
            row.id = uid();
            this.$refs.guider.innerList.push(row);
          } else {
            this.$set(this.$refs.guider.innerList, this.$refs.guider.tableForm.currentEditRowIndex, {
              ...this.$refs.guider.innerList[this.$refs.guider.tableForm.currentEditRowIndex],
              ...row,
            });
          }
          this.$refs.guider.cacelEdit();
          this.setGuiderEmpCond();
        }
      });
    },
    // 导购操作列中删除按钮 事件
    onGuiderDeleteBeforeConfirm(e, params, eventId) {
      this.onGuiderDeleteAllBeforeConfirm(e, params, eventId);
    },
    // 导购批量删除事件
    onGuiderDeleteAllBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      params.forEach((d) => {
        this.$refs.guider.deleteEditRowByIndex(d.__index);
      });
      this.setGuiderEmpCond();
    },
    // 导购列表初始化回显数据
    beforeQuery(e, params, eventId) {
      if (eventId.model === "guider") {
        params[0].fields.push("emp.id", "status");
        return {
          params,
          resolve: ({ response }) => {
            if (response && response.list) {
              this.setGuiderEmpCond(response.list);
            }
          },
        };
      }
    },
  },
};
</script>
