<script>
export default {
  data() {
    return {
      list: [{ value: null }],
    };
  },
  props: ["appAuthList"],
  mounted() {
    if (Array.isArray(this.appAuthList)) {
      this.list = this.appAuthList.map((i) => {
        return { value: i.appId, nickName: i.nickName };
      });
    }
  },
  computed: {
    isAdd: function() {
      return !this.appAuthList;
    },
  },
  render() {
    const items = this.list.map((item, index) => {
      const exclude = this.list
        .filter((i) => i.value !== item.value)
        .map((i) => i.value)
        .filter(Boolean);
      const conditions = [{ or: [{ key: "openPlat", op: "isNull" }] }];
      const updateIncludeSelf = { key: "appId", vals: [item.value] };
      if (!this.isAdd) {
        conditions[0].or.push(updateIncludeSelf);
      }
      // 处理选中第一个后 限制同一个租户下的appID
      if (index > 0 && item.value) {
        conditions.push({ key: "tenCode", vals: [item.tenCode] });
      }
      const conf = {
        props: {
          // disable: index === 0,
          fields: ["tenCode", "openPlat.id"],
          model: "wechat-app",
          placeholder: "请选择公众号或者小程序",
          outlined: true,
          keyLabel: "nickName",
          keyValue: "id",
          value: item.value,
          excludeByKey: exclude,
          simpleMode: true,
          conditions,
        },
        on: {
          input(e, row) {
            Object.assign(item, row);
            item.value = e;
          },
        },
      };
      return (
        <q-item>
          {index > 0 && (
            <q-item-section avatar>
              <q-btn
                size="sm"
                icon="remove"
                color="negative"
                on-click={() => {
                  this.list.splice(index, 1);
                }}></q-btn>
            </q-item-section>
          )}
          {index === 0 && (
            <q-item-section avatar>
              <q-btn
                size="sm"
                icon="add"
                color="primary"
                on-click={() => {
                  this.list.push({ value: null });
                }}></q-btn>
            </q-item-section>
          )}
          <q-item-section>
            <rs-select class="rs-size-sm" {...conf}></rs-select>
          </q-item-section>
        </q-item>
      );
    });
    return (
      <q-list>
        {items}
        {JSON.stringify(this.list)}
      </q-list>
    );
  },
};
</script>
