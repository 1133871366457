<template>
  <div class="q-pa-sm full-height product-spec-form">
    <rs-form ref="form" name="product-spec" :col="2">
      <rs-field type="group" label="基本信息">
        <rs-field name="id"></rs-field>
        <rs-field name="type" :readonly="!!typeId"></rs-field>
        <rs-field name="code"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="sort"></rs-field>
        <rs-field name="specValues" hidden></rs-field>
      </rs-field>
      <rs-field type="group" label="规格值列表">
        <rs-list
          ref="specList"
          name="mock-productSpecVal"
          class="col"
          :isPagination="false"
          :selection="false"
          edit-mode="all"
          :filterHidden="true"
          :data="specValList"
          :loadDefault="false"
        >
          <rs-field name="code" :rules="[specCodeRule]"></rs-field>
          <rs-field name="name"></rs-field>
          <rs-field type="toolbar" :include="['deleteItem']" exclude="*"></rs-field>
          <rs-toolbar :include="['add']" exclude="*">
            <rs-button name="add" label="添加"></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      typeId: null,
      modelService: new ModelService(),
      specValList: [],
    };
  },
  computed: {},
  mounted() {
    this.typeId = this.$route.query.typeId;
    if (this.typeId) {
      this.modelService
        .refs(
          [this.typeId],
          {
            fields: ["id", "name"],
          },
          "product-type",
        )
        .then((resp) => {
          if (resp && resp[0]) {
            this.$refs.form.core.setValue({ type: resp[0] });
          }
        });
    }
  },
  methods: {
    // 请求数据后，给规格值列表赋值
    beforeRequest(e, params) {
      return {
        params,
        resolve: ({ params }) => {
          this.specValList = Object.keys(params.specValues || {}).map((d) => {
            return { code: d, name: params.specValues[d] };
          });
        },
      };
    },
    // 保存表单时,先校验下方表格数据
    beforeSubmit(e, params) {
      e.preventDefault();
      this.$refs.specList.tableForm.validate().then((resp) => {
        if (!resp) {
          params.specValues = this.getSpecValues();
          e.next({
            params,
            resolve: ({ response }) => {
              this.$rs_actionRefresh("tenants.productSpec._view");
            },
          });
        }
      });
    },

    // 规格值代号的校验规则：不能重复
    specCodeRule(rule, value, callback) {
      const sameItems = this.getSpecValues(true).filter((d) => d.code === value);
      if (value && sameItems.length > 1) {
        callback(new Error(this.$t_s(`exists-invalid`)));
      } else {
        callback();
      }
    },

    // 获取下方列表的规格值
    getSpecValues(isArray = false) {
      const formCoreDic = this.$refs.specList.tableForm.formCoreDic;
      const map = {};
      const list = Object.keys(formCoreDic).map((d, index) => {
        const val = formCoreDic[d].value;
        map[val.code] = val.name;
        return val;
      });
      return isArray ? list : map;
    },
  },
};
</script>
