<template>
  <div class="q-pa-sm full-height tenant-domain-form">
    <rs-form ref="form" name="ten-domain" :col="2" :value="{ customize: true, status: 1 }">
      <rs-field name="tenant" :readonly="!!tenantId"></rs-field>
      <rs-field name="domain"></rs-field>
      <rs-field name="customize" readonly></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field
        name="remark"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="cancel"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
      modelService: new ModelService(),
    };
  },
  computed: {},
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    if (this.tenantId) {
      this.modelService
        .refs(
          [this.tenantId],
          {
            fields: ["id", "name"],
          },
          "tenant",
        )
        .then((resp) => {
          if (resp && resp[0]) {
            this.$refs.form.core.setValue({ tenant: resp[0] });
          }
        });
    }
  },
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.domain._view");
        },
      };
    },
  },
};
</script>
