<template>
  <rs-list name="wx-work-suite-auth-info">
    <rs-field name="id" hidden></rs-field>
    <rs-field name="tenant.uname"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="name"></rs-field>
    <rs-field name="corpId"></rs-field>
    <rs-field name="corpName"></rs-field>
    <rs-field name="status"></rs-field>
  </rs-list>
</template>
<script>
export default {
  methods: {},
};
</script>
