<template>
  <div class="full-height rs-page-ops-deploy-list">
    <rs-list
      name="ten-deploy"
      keywords="name,id"
      :conditions="[
        { key: 'tenantMode', vals: ['saas'] },
        { key: 'type', vals: ['teninit', 'tenorder'] },
      ]"
    >
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="tenantMode"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="info"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*"></rs-field>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
  },
};
</script>
