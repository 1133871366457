<template>
  <div class="q-pa-sm full-height ten-api-rate-limiting-form">
    <rs-form ref="form" name="ten-api-rate-limiting">
      <rs-field name="id" :hidden="!id"></rs-field>
      <rs-field name="service" :widget="{ fields: ['id', 'name.name', 'host', 'port', 'path'] }"></rs-field>
      <rs-field name="path"></rs-field>
      <rs-field name="priority"></rs-field>
      <rs-field name="tenant"></rs-field>
      <rs-field name="user"></rs-field>
      <rs-field name="limitRate" col="12" widget="rs-limit-rate"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.apiRateLimit._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      // tenantWidget: {},
      // userConditions: {},
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.apiRateLimit._view");
        },
      };
    },
    //   onChange(val, fireKey, core) {
    //     if (fireKey.include("tenant")) {
    //       if (val.tenant) {
    //         this.userConditions = {
    //           conditions: [
    //             {
    //               or: [
    //                 { key: "globals", vals: [true] },
    //                 { key: "tenant.id", vals: [val.tenant.id] },
    //               ],
    //             },
    //           ],
    //         };
    //       } else {
    //         this.userConditions = {};
    //       }
    //     }
    //     if (fireKey.include("user")) {
    //       if (val.user) {
    //         this.tenantWidget = {
    //           conditions: [{ key: "id", op: OP_TYPE.IN, vals: [val.user] }],
    //         };
    //       } else {
    //         this.tenantWidget = {};
    //       }
    //     }
    //   },
  },
};
</script>
