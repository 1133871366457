<template>
  <div class="full-height rs-page-product-spec-list">
    <rs-list name="product-spec" keywords="name,id" :conditions="specConditions">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="code"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="type" v-if="!typeId"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="type.name"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="sort"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*"></rs-field>
      <rs-toolbar :include="['add']" exclude="*">
        <rs-button name="add" color="primary" :payload="{ query: { typeId } }"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return { typeId: null };
  },
  computed: {
    specConditions: function() {
      const cons = [];
      if (this.typeId) {
        cons.push({ key: "type.id", vals: [this.typeId] });
      }
      return cons;
    },
  },
  mounted() {
    this.typeId = this.$route.query.typeId;
  },
  methods: {},
};
</script>
