<template>
  <div>
    <rs-form name="admin-group-info" label-width="8em" v-slot="data">
      <rs-field name="groupName" readonly></rs-field>
      <rs-field
        name="isInternalFlag"
        :widget="{ _type: 'q-toggle', label: data.isInternalFlag ? '已开通' : '未开通', trueValue: 1, falseValue: 0 }"
      ></rs-field>
      <rs-field name="regionType" :widget="{ multiple: true, disable: data.isInternalFlag != 1 }"></rs-field>
      <rs-field
        name="isEnable"
        :widget="{ _type: 'q-toggle', label: data.isEnable === 1 ? '可用' : '不可用', trueValue: 1, falseValue: 0 }"
      ></rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
    <div class="q-pa-sm" v-if="groupId">
      <q-tabs v-model="activeTab" dense align="left" inline-label class="rs-border" active-color="primary">
        <q-tab name="channel" label="通道" class="text-caption" />
        <q-tab name="assign" label="签名" />
        <q-tab name="internal" label="国内短信定价" />
        <q-tab name="international" label="国际短信定价" />
      </q-tabs>
      <q-separator class="full-width" />
      <q-tab-panels class=" " v-model="activeTab" animated keep-alive>
        <!-- 通道 -->
        <q-tab-panel name="channel" class="no-padding">
          <rs-list style="height:400px !important" name="admin-sub-app-group-info" :selection="false">
            <rs-field name="id"></rs-field>
            <rs-field name="subAppId"></rs-field>
            <rs-field name="subAppName"></rs-field>
            <rs-field name="signature"></rs-field>
            <rs-field name="platform"></rs-field>
            <rs-field name="platformName"></rs-field>
            <rs-field name="internalSmsSurplusNumber"></rs-field>
            <!-- isWarnSurplusNumber -->
            <rs-field name="nationalSmsSurplusAmount"></rs-field>
            <!-- isWarnSurplusAmount -->
            <rs-field name="isEnable"></rs-field>
            <!-- isDeleted -->
            <rs-field name="creator"></rs-field>
            <rs-field name="createdAt"></rs-field>
            <rs-field name="updatedAt"></rs-field>
            <rs-field name="updator"></rs-field>
            <rs-field type="toolbar" alias="empty" v-slot="row">
              <rs-button
                v-if="row.isEnable"
                name="addCNL"
                label="充值"
                icon="local_grocery_store"
                color="primary"
              ></rs-button>
              <rs-button
                v-if="row.isEnable && !row.platform"
                name="editCNL"
                label="编辑"
                icon="edit"
                color="primary"
              ></rs-button>
              <rs-button v-if="row.isEnable" name="lockCNL" label="禁用" icon="lock" color="red"></rs-button>
              <rs-button v-if="!row.isEnable" name="unlockCNL" label="启用" icon="lock_open" color="green"></rs-button>
              <rs-button
                v-if="row.isEnable && !row.platform"
                name="deleteCNL"
                tips="确认删除?"
                label="删除"
                icon="delete"
                color="negative"
              ></rs-button>
            </rs-field>
            <rs-toolbar exclude="*" :include="['addInernational']">
              <rs-button name="addInernational" label="添加" icon="add" color="primary"></rs-button>
            </rs-toolbar>
          </rs-list>
        </q-tab-panel>
        <!-- 签名 -->
        <q-tab-panel name="assign" class="no-padding">
          <rs-list style="height:400px !important" name="admin-signature-info" :selection="false">
            <rs-field name="id"></rs-field>
            <rs-field name="appId"></rs-field>
            <rs-field name="name"></rs-field>
            <rs-field name="isEnable"></rs-field>
            <rs-field name="creator"></rs-field>
            <rs-field name="createdAt"></rs-field>
            <rs-field name="updator"></rs-field>
            <rs-field name="updatedAt"></rs-field>
            <rs-field type="toolbar" alias="empty" v-slot="row">
              <rs-button v-if="row.isEnable" name="lockSignature" label="禁用" icon="lock" color="red"></rs-button>
              <rs-button
                v-if="!row.isEnable"
                name="unlockSignature"
                label="启用"
                icon="lock_open"
                color="green"
              ></rs-button>
              <rs-button
                v-if="row.isEnable"
                name="deleteSignature"
                label="删除"
                icon="delete"
                color="negative"
                tips="确认删除?"
              ></rs-button>
            </rs-field>
            <rs-toolbar alias="empty">
              <rs-button name="addSignature" label="添加" icon="add" color="primary"></rs-button>
            </rs-toolbar>
          </rs-list>
        </q-tab-panel>
        <!-- 国内短信定价 -->
        <q-tab-panel name="internal" class="no-padding">
          <rs-list style="height:400px !important" name="admin-internal-price-info" :selection="false">
            <rs-field name="id"></rs-field>
            <rs-field name="goodsName"></rs-field>
            <rs-field name="goodsUnit"></rs-field>
            <rs-field name="goodsPrice"></rs-field>
            <rs-field name="goodsSmsNumber"></rs-field>
            <rs-field name="goodsState"></rs-field>
            <rs-field name="isRecommend"></rs-field>
            <rs-field name="creator"></rs-field>
            <rs-field name="createdAt" v-slot="row">
              {{ row.createdAt ? $rs_format(row.createdAt, "YYYY-MM-DD HH:mm:ss") : "" }}
            </rs-field>
            <rs-field name="updator"></rs-field>
            <rs-field name="updatedAt" v-slot="row">
              {{ row.updatedAt ? $rs_format(row.updatedAt, "YYYY-MM-DD HH:mm:ss") : "" }}
            </rs-field>
            <rs-toolbar alias="empty">
              <rs-button name="addGoods" label="添加" icon="add" color="primary"></rs-button>
            </rs-toolbar>
            <rs-field type="toolbar" alias="empty" v-slot="row">
              <rs-button v-if="row.goodsState" name="editGoods" label="编辑" icon="edit" color="primary"></rs-button>

              <rs-button v-if="row.goodsState" name="lockGoods" label="禁用" icon="lock" color="red"></rs-button>
              <rs-button
                v-if="!row.goodsState"
                name="unlockGoods"
                label="启用"
                icon="lock_open"
                color="green"
              ></rs-button>
              <rs-button
                v-if="row.goodsState"
                name="deleteGoods"
                label="删除"
                icon="delete"
                color="negative"
                tips="确认删除?"
              ></rs-button>
            </rs-field>
          </rs-list>
        </q-tab-panel>
        <!-- 国际短信定价 -->
        <q-tab-panel name="international" class="no-padding">
          <rs-list style="height:400px !important" name="admin-national-price-info" :selection="false">
            <rs-field name="id"></rs-field>
            <rs-field name="regionCode"></rs-field>
            <rs-field name="regionPltPrice" sortable></rs-field>
            <rs-field name="regionCname" sortable></rs-field>
            <rs-field name="isEnable"></rs-field>
            <rs-field name="updator" sortable></rs-field>
            <rs-field name="updatedAt" v-slot="row">
              {{ row.updatedAt ? $rs_format(row.updatedAt, "YYYY-MM-DD HH:mm:ss") : "" }}
            </rs-field>
            <rs-field type="toolbar" alias="empty">
              <rs-button name="editNation" label="编辑" icon="edit" color="primary"></rs-button>
            </rs-field>
          </rs-list>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script>
import PurchaseMsg from "@/dialogs/purchase-msg.vue";
import ChannelEdit from "@/dialogs/channel-edit.vue";
import SignatureEdit from "@/dialogs/signature-edit.vue";
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";
import GoodsEdit from "@/dialogs/goods-edit.vue";
import RegionGoodsEdit from "@/dialogs/region-goods-edit.vue";
export default {
  mixins: [RsThirdApiMixins],
  data() {
    return {
      activeTab: "channel",
      groupId: null,
      tenUser: null,
    };
  },
  methods: {
    // 保存表单
    beforeSubmit(e, params) {
      e.preventDefault();
      this.post("/admin/group/edit", { ...params, nationalRegion: params.regionType.join("|") }).then(() => {
        this.$rs_success(this.$t_s("save-success"));
      });
    },
    // 列表回显
    beforeQuery(e, params, { model }) {
      e.preventDefault();
      let url = "";
      if (model === "admin-sub-app-group-info") {
        url = "/admin/sms/account/list";
      }
      if (model === "admin-signature-info") {
        url = "/admin/sms/signature/list";
      }
      if (model === "admin-internal-price-info") {
        url = "/admin/sms/internal/goods/list";
      }
      if (model === "admin-national-price-info") {
        url = "/admin/sms/national/region/list";
      }
      this.dealList({ url, data: { groupId: this.groupId } }, e, params);
    },
    // 表单回显
    beforeRequest(e, { id }) {
      e.preventDefault();
      this.groupId = id;
      this.getInfo("/admin/group/info", { groupId: this.groupId }).then((r) => {
        const newResp = { ...(r || {}), regionType: r && r.regionType.map((i) => i.csrId + "") };
        r.regi = e.context.setInitialValue(newResp);
        this.tenUser = newResp;
      });
    },

    // 充值维护处理
    onAdminSubAppGroupInfoAddCNL(e, p) {
      this.$rs_dlg({
        parent: this,
        title: "充值/维护",
        cardStyle: "width: 440px",
        contentComponent: PurchaseMsg,
        contentComponentProps: { channel: e.params, groupId: this.groupId },
      }).onOk((e, { content, dlg }) => {
        content &&
          content.submit().then((r) => {
            this.$rs_success("充值成功!");
            dlg.close();
            e.context.refreshTable();
          });
      });
    },
    // 启用
    onAdminSubAppGroupInfoUnlockCNL(e) {
      this.post("/admin/sms/account/change", { gsaId: e.payload.id, gsaState: 1 }).then((r) => {
        this.$rs_success("已启用");
        e.context.refreshTable();
      });
    },
    // 禁用
    onAdminSubAppGroupInfoLockCNL(e) {
      this.post("/admin/sms/account/change", { gsaId: e.payload.id, gsaState: 0 }).then((r) => {
        this.$rs_success("已禁用");
        e.context.refreshTable();
      });
    },
    onAdminSubAppGroupInfoAddInernational(e) {
      this.doAddOrEditCNL(e.context);
    },
    // 编辑
    onAdminSubAppGroupInfoEditCNL(e) {
      //
      this.doAddOrEditCNL(e.context, e.params);
    },
    doAddOrEditCNL(ctx, editParams) {
      this.$rs_dlg({
        parent: this,
        title: editParams ? "编辑通道" : "新增通道",
        cardStyle: "width: 440px",
        contentComponent: ChannelEdit,
        contentComponentProps: { groupId: this.groupId, form: editParams, appId: this.tenUser.appId },
      }).onOk((ee, { content, dlg }) => {
        content &&
          content.submit().then(() => {
            ctx.refreshTable();
            dlg.close();
          });
        // .catch((er) => 0);
      });
    },
    // 删除
    onAdminSubAppGroupInfoDeleteCNL(e) {
      this.post("/admin/sms/account/delete", { gsaId: e.payload.id }).then((r) => {
        this.$rs_success("已删除");
        e.context.refreshTable();
      });
    },
    // 签名 新增
    onAdminSignatureInfoAddSignature(e) {
      //tenUser
      this.doSignatureEdit(e.context);
    },
    // 删除签名
    onAdminSignatureInfoDeleteSignature(e) {
      this.post("/admin/sms/signature/delete", {
        appId: this.tenUser.appId,
        name: e.params.name,
        updator: this.$rs_user.nickName,
      }).then(() => {
        this.$rs_success("已删除");
        e.context.refreshTable();
      });
    },
    onAdminSignatureInfoLockSignature(e) {
      this.doEnableDisableSignature(e, 0).then(() => {
        this.$rs_success("已禁用!");
        e.context.refreshTable();
      });
    },
    onAdminSignatureInfoUnlockSignature(e) {
      this.doEnableDisableSignature(e, 1).then(() => {
        this.$rs_success("已启用!");
        e.context.refreshTable();
      });
    },
    // 禁用启用
    doEnableDisableSignature(e, flg) {
      return this.post("/admin/sms/signature/enabled", {
        appId: this.tenUser.appId,
        name: e.params.name,
        updator: this.$rs_user.nickName,
        isEnable: flg,
      });
    },
    doSignatureEdit(ctx, editParams) {
      this.$rs_dlg({
        parent: this,
        title: editParams ? "编辑签名" : "添加签名",
        cardStyle: "width: 440px",
        contentComponent: SignatureEdit,
        contentComponentProps: { tenUser: this.tenUser, name: editParams },
      }).onOk((ee, { content, dlg }) => {
        content &&
          content.submit().then(() => {
            ctx.refreshTable();
            dlg.close();
          });
        // .catch((er) => 0);
      });
    },
    // -------------短信包------------
    //新增
    onAdminInternalPriceInfoAddGoods(e) {
      //
      this.doGoodsEdit(e.context);
    },
    doGoodsEdit(ctx, editParams) {
      this.$rs_dlg({
        parent: this,
        title: editParams ? "短信包价格编辑" : "短信包价格添加",
        cardStyle: "width: 440px",
        contentComponent: GoodsEdit,
        contentComponentProps: { tenUser: this.tenUser, goods: editParams },
      }).onOk((ee, { content, dlg }) => {
        content &&
          content.submit().then(() => {
            ctx.refreshTable();
            dlg.close();
          });
        // .catch((er) => 0);
      });
    },
    // 编辑
    onAdminInternalPriceInfoEditGoods(e) {
      //
      this.doGoodsEdit(e.context, e.params);
    },
    // 禁用
    onAdminInternalPriceInfoLockGoods(e) {
      //
      this.doGoodsState(e, 0).then(() => {
        this.$rs_success("已禁用");
        e.context.refreshTable();
      });
    },
    //启用
    onAdminInternalPriceInfoUnlockGoods(e) {
      //
      this.doGoodsState(e, 1).then(() => {
        this.$rs_success("已启用");
        e.context.refreshTable();
      });
    },
    doGoodsState(e, flg) {
      return this.post("/admin/sms/account/goods/enabled", {
        appId: this.tenUser.appId,
        goodsId: e.params.id,
        updator: this.$rs_user.nickName,
        goodsState: flg,
      });
    },
    // 删除
    onAdminInternalPriceInfoDeleteGoods(e) {
      //
      this.post("/admin/sms/account/goods/delete", {
        appId: this.tenUser.appId,
        goodsId: e.params.id,
        updator: this.$rs_user.nickName,
      }).then(() => {
        this.$rs_success("已删除");
        e.context.refreshTable();
      });
    },
    // -------------国际短信定价-------
    onAdminNationalPriceInfoEditNation(e) {
      const editParams = e.params;
      const ctx = e.context;
      this.$rs_dlg({
        parent: this,
        title: "国际短信定价",
        cardStyle: "width: 440px",
        contentComponent: RegionGoodsEdit,
        contentComponentProps: { tenUser: this.tenUser, goods: editParams },
      }).onOk((ee, { content, dlg }) => {
        content &&
          content.submit().then(() => {
            ctx.refreshTable();
            dlg.close();
            this.$rs_success("更新成功!");
          });
      });
    },
  },
};
</script>
