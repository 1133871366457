<template>
  <div class="page-variable_form full-height flex-column">
    <rs-form name="variable" v-if="true" class="flex-no-grow">
      <rs-field name="id"></rs-field>
      <rs-field name="ns"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="valType"></rs-field>
      <rs-field name="comment"></rs-field>
      <rs-field
        name="value"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("settings.variable._view");
        },
      };
    },
  },
};
</script>
