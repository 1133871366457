<script>
import EditDlg from "./relation-edit.vue";
export default {
  methods: {
    toEdit(isAdd, appAuthList, id) {
      this.$rs_dlg({
        parent: this,
        title: isAdd ? "新增" : "编辑",
        cardStyle: "width:600px",
        contentComponent: EditDlg,
        contentComponentProps: { appAuthList },
      }).onOk((e, { content }) => {
        this.doAddAndUpdate(isAdd, content.list, id);
      });
    },
    doAddAndUpdate(isAdd, list, id) {
      const url = isAdd ? `/api/app/bind/add` : `/api/app/bind/update`;
      const params = {
        uid: this.$rs_user.nickName,
        appAuthList: list.map((i) => {
          return { appId: i.appId, nickName: i.nickName };
        }),
      };
      Object.assign(params, isAdd ? { cltId: this.$rs_user.tenant } : { id });
      this.$rs_post(url, params).then(() => {
        this.$rs_success(this.$t_s(isAdd ? "add-success" : "edit-success"));
        this.$refs?.list.refreshTable();
      });
    },
    onAppAuthBindInfoBind() {
      this.toEdit(true);
    },
  },
  render() {
    const gridItemRender = ({ row }, isFirstItem, list) => {
      const bindApps = row?.appAuthList || [];
      const items = bindApps.map((item) => {
        return (
          <q-item-section>
            <q-item-label>{item.nickName}</q-item-label>
            <q-item-label caption>{item.appId}</q-item-label>
          </q-item-section>
        );
      });
      return (
        <q-card class="width-100 q-ma-sm bg-grey-2 shadow-1">
          <q-card-section>
            <div class="text-subtitle2 row q-gutter-x-lg">
              <span>
                <span class="text-body2">绑定人</span>：{row?.uid}
              </span>
              <span>
                <span class="text-body2">绑定时间</span>：{row?.bindTime}
              </span>
              <span>
                <span class="text-body2">租户</span>：{row?.cltId}
              </span>
              <q-space></q-space>
              <span class="rs-link" on-click={this.toEdit.bind(this, false, bindApps, row?.id)}>
                {this.$t_s("edit")}
              </span>
            </div>
          </q-card-section>

          <q-card-section>
            <q-list>
              <q-item>{items}</q-item>
            </q-list>
          </q-card-section>
        </q-card>
      );
    };
    return (
      <rs-list
        ref="list"
        name="app-auth-bind-info"
        style="height:450px !important"
        class="no-padding"
        keywords="id,name"
        emptyGridItem
        grid
        gridItem={gridItemRender}>
        <rs-field name="name"></rs-field>
        <rs-toolbar exclude="*" include="bind">
          <rs-button name="bind" color="primary" label="新增绑定"></rs-button>
        </rs-toolbar>
      </rs-list>
    );
  },
};
</script>
