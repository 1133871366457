<template>
  <rs-list ref="list" name="admin-group-info" :selection="false" keywords="id,groupName">
    <rs-search></rs-search>
    <rs-field name="id"></rs-field>
    <rs-field name="tenid"></rs-field>
    <rs-field name="appId"></rs-field>
    <rs-field name="groupName"></rs-field>
    <rs-field name="internalSmsSurplusNumber" align="right" v-slot="row" sortable>
      <span :class="[row.isWarnSurplusNumber ? 'text-red' : '']">{{ row.internalSmsSurplusNumber }}</span>
    </rs-field>
    <rs-field name="nationalSmsSurplusAmount" align="right" v-slot="row" sortable>
      <span :class="[row.isWarnSurplusAmount ? 'text-red' : '']">¥ {{ row.nationalSmsSurplusAmount }}</span>
    </rs-field>
    <rs-field name="subAccountNumber" align="right"></rs-field>
    <rs-field name="isEnable"></rs-field>
    <!-- <rs-field name="isInternalFlag"></rs-field> -->
    <rs-field name="updatedPerson"></rs-field>
    <rs-field name="updatedAt"></rs-field>
    <rs-field type="toolbar" :exclude="['delete']"></rs-field>
    <rs-toolbar exclude="*" include="add">
      <rs-button name="add" :payload="{ on: after, dlgWidth: '500px' }"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";

export default {
  mixins: [RsThirdApiMixins],
  methods: {
    beforeQuery(e, params) {
      e.preventDefault();
      this.dealList({ url: "/admin/group/list" }, e, params);
    },
    // 新增或编辑以后
    after() {
      this.$refs.list.refreshTable(true);
    },
  },
};
</script>
