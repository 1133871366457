<template>
  <div class="full-height ten-api-rate-limiting-form">
    <rs-list ref="list" name="ten-api-rate-limiting" keywords="id">
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="path"></rs-filter>
        <rs-filter name="priority"></rs-filter>
        <rs-filter name="service"></rs-filter>
        <rs-filter name="tenant"></rs-filter>
        <rs-filter name="user"></rs-filter>
        <rs-filter name="crtUser"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="service.name.name"></rs-field>
      <rs-field name="path"></rs-field>
      <rs-field name="priority"></rs-field>
      <rs-field name="tenant.id"></rs-field>
      <rs-field name="user.id"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']">
        <rs-button name="edit" color="primary" action="tenants.apiRateLimit._edit"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary" action="tenants.apiRateLimit._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
