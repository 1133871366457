<script>
import RsInputNumber from "rsui-core/src/system/package/input/rs-input-number";
// 借用rs-input-number 的属性
const RsInputNumberProps = RsInputNumber.options.props;
const RsInputNumberPropsMixsn = { props: RsInputNumberProps };

// 许可数组件， 不限 OR 输入数字
export default {
  data() {
    return {
      unlimited: true, // true:不限; false: 数字输入框
      innerValue: null, // unlimited=false时，输入框数字绑定值;
    };
  },
  mixins: [RsInputNumberPropsMixsn],
  props: [],
  watch: {
    value: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.unlimited = newVal === -1;
        if (!this.unlimited) {
          this.innerValue = newVal;
        }
      },
    },
  },
  mounted() {},
  computed: {},
  methods: {
    emitValue() {
      if (this.unlimited) {
        this.$emit("input", -1);
      } else {
        this.$emit("input", this.innerValue);
      }
    },
    validate() {
      if (!this.unlimited) {
        return this.$refs.inputNumber.validate();
      }
    },
    resetValidation() {
      this.$refs.inputNumber.resetValidation();
    },
  },
  render() {
    const { value, ...p } = this.$props;
    const conf = {
      ref: "inputNumber",
      class: [!this.unlimited && "rs-required_item"],
      style: "width: 10em",
      props: {
        ...p,
        disable: this.unlimited,
      },
    };
    return (
      <div class="row items-center text-black">
        <q-radio dense size="sm" v-model={this.unlimited} val={true} label="不限" on-input={() => this.emitValue()} />
        <q-radio
          dense
          size="sm"
          class="q-ml-md q-mr-sm"
          v-model={this.unlimited}
          val={false}
          label="限制"
          on-input={() => this.emitValue()}
        />
        <rs-input-number {...conf} v-model={this.innerValue} on-input={() => this.emitValue()} />
      </div>
    );
  },
};
</script>
