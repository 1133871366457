<template>
  <div class="q-pa-sm full-height ops-ver-data-form column">
    <rs-form
      ref="form"
      name="ten-service-data"
      @change="onChange"
      :value="{ type: 'migration', fileName: 'migration.sql', customize: true }"
    >
      <rs-field
        name="svcVersion"
        :hidden="!!id || !!versionId"
        required
        :widget="{
          fields: ['service.id', 'service.name', 'version', 'versionType', 'recTime'],
          conditions: [{ key: 'apvStatus', op: 'ne', vals: [2] }],
        }"
      ></rs-field>
      <rs-field name="svcVersion.service.name" readonly :hidden="!id && !versionId"></rs-field>
      <rs-field name="svcVersion.version" readonly :hidden="!id && !versionId"></rs-field>
      <rs-field name="svcVersion.apvStatus" hidden></rs-field>
      <rs-field name="type" readonly></rs-field>
      <rs-field name="fileName" readonly></rs-field>
      <rs-field name="filePath" readonly></rs-field>
      <rs-field name="customize" readonly></rs-field>
      <rs-field
        name="content"
        col="12"
        required
        :readonly="disable"
        :widget="{ _type: 'rs-ace-editor', height: '500px', lang: codeMode }"
      ></rs-field>
      <!-- :widget="{ _type: 'rs-code-mirror', height: '500px', option: { mode: codeMode } }" -->
      <rs-toolbar>
        <rs-button
          name="cancel"
          action="ops.versionData._view"
          :payload="{ query: { versionId, disableAdd } }"
        ></rs-button>
        <rs-button name="submit" :hidden="disable"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import SparkMD5 from "spark-md5";
import trim from "lodash/trim";
import DbcToCdb from "rsui-core/src/core/util/dbcToCdb";
export default {
  components: {},
  data: function() {
    return {
      id: null,
      codeMode: "pgsql",
      disable: true,
      disableAdd: false,
      versionId: null,
      modelService: new ModelService(),
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.disable = !!this.id;
    this.versionId = this.$route.query.versionId;
    if (this.$route.query.disableAdd) {
      this.disableAdd = this.$route.query.disableAdd.toLowerCase() === "true";
    }
    if (!this.id && this.versionId) {
      this.modelService
        .refs(
          [this.versionId],
          {
            fields: ["id", "version", "service.id", "service.name", "apvStatus"],
          },
          "ten-service-version",
        )
        .then((resp) => {
          if (resp && resp[0]) {
            this.$refs.form.core.setValue({ svcVersion: resp[0] });
          }
        });
    }
  },
  methods: {
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          this.disable = !params.customize || params.svcVersion.apvStatus === 2;
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      // httpPost 时 会对数据trim 和 DbcToCdb 全角转半角处理
      params.checksum = SparkMD5.hash(trim(DbcToCdb(params.content)));
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("ops.versionData._view", { query: { versionId: this.versionId } });
        },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("type")) {
        this.determineCodeType(val.type);
      }
    },

    determineCodeType(type) {
      if (type === "normal") {
        this.codeMode = "xml";
      } else if (type === "i18n") {
        this.codeMode = "properties";
      } else if (type === "security") {
        this.codeMode = "plain_text";
      } else {
        this.codeMode = "pgsql";
      }
    },
  },
};
</script>
