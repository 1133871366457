<template>
  <div class="full-height biz-subject scroll">
    <rs-form name="retail-channel" label-width="6.5em">
      <!-- <rs-field type="group">
      </rs-field> -->
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <!-- <rs-field name="type" value="HQ" disable></rs-field> -->
      <rs-field name="bset" :widget="{ conditions: [{ key: 'type', vals: ['HQ'] }] }"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="brands"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.channel._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <!-- <div v-if="!!id">
        <rs-list ref="list" name="site-channel" :conditions="listConditions">
          <rs-field name="sit.id"></rs-field>
          <rs-field name="sit.name"></rs-field>
          <rs-toolbar :exclude="['add', 'delete', 'edit', 'imports', 'exports']"> </rs-toolbar>
        </rs-list>
      </div> -->
  </div>
</template>
<script>
// import RsClassifyService from "rsui-core/src/core/components/classify/classify-service";
export default {
  data: function() {
    return {
      id: null,
    };
  },
  computed: {
    listConditions: {
      get: function() {
        return [{ key: "channel.id", vals: [this.id] }];
      },
    },
    // dialogListConditions: {
    //   get: function() {
    //     return [
    //       // {
    //       //   key: "channel",
    //       //   op: "relNotExists",
    //       //   vals: [{ key: "root", vals: [this.id] }],
    //       // },
    //     ];
    //   },
    // },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    beforeSubmit(e) {
      return {
        resolve: ({ response }) => {
          if (response && response.content && response.content.id) {
            this.id = response.content.id;
          }
          this.$rs_actionRefresh("mdata.channel._view");
        },
      };
    },
    // onSiteAppend(name, e) {
    //   const dlg = this.$rs_dlg({
    //     parent: this,
    //     title: this.$t_s("add"),
    //     cardStyle: "width: 700px",
    //     content: (
    //       <rs-list ref="dlgList" class="no-padding" name="site" conditions={this.dialogListConditions}>
    //         <rs-field name="id" />
    //         <rs-field name="name" />
    //         <rs-toolbar exclude={["add", "delete", "edit", "imports", "export"]} />
    //       </rs-list>
    //     ),
    //   }).onOk(() => {
    //     const ids = this.$refs.dlgList.selectedId;
    //     dlg.hide();
    //     if (!this.selectedTreeNode) {
    //       console.error("no selected tree node,don't to remove site");
    //       return;
    //     }
    //     if (ids.length > 0) {
    //       RsClassifyService.addObject({
    //         ns: this.namespace,
    //         group: this.selectedTreeNode.id,
    //         objects: ids,
    //       }).then((resp) => {
    //         this.refreshTable();
    //         this.$refs.treePicker.reloadData();
    //         this.$q.notify({
    //           message: this.$t_s("add-success"),
    //           color: "positive",
    //           icon: "tag_faces",
    //         });
    //       });
    //     } else {
    //       this.$q.notify({
    //         message: this.$t_s("validate-addNoData"),
    //         color: "warning",
    //         icon: "tag_faces",
    //       });
    //     }
    //   });
    // },
  },
};
</script>
