<template>
  <div class="deal-msg-page bg-grey-2 full-height flex-column" style="height:490px !important" comment="短信充值">
    <div class="q-px-md q-py-sm">通道：{{ channel && channel.subAppId }}【{{ channel && channel.subAppName }}】</div>
    <q-tabs
      v-model="activeTab"
      @input="activeTabChange"
      dense
      align="left"
      class=" bg-white text-black"
      :breakpoint="0"
      active-color="primary"
    >
      <q-tab name="internal" label="国内短信充值" />
      <q-tab name="international" label="国际短信充值" />
    </q-tabs>
    <!-- <div class="fixed-top bg-white">{{ payModel }}</div> -->
    <!-- <q-separator class="full-width" /> -->
    <q-tab-panels class="bg-grey-2  flex-grow" v-model="activeTab" animated keep-alive>
      <!-- 国内短信定价 -->
      <q-tab-panel name="internal" class=" q-pb-xs">
        <!-- 固定充值金额 1-->
        <section class="q-mb-sm">
          <q-radio dense v-model="payType" :val="1" label="固定充值金额"></q-radio>
        </section>
        <q-slide-transition>
          <div v-show="payType === 1" class="row overflow-auto items-center q-py-xs">
            <template v-for="(item, index) in goodsList">
              <section :key="index" v-if="item.goodsState === 1" class="col-4 ">
                <div class="q-pa-xs cursor-pointer" @click="payModel.goodsId = item.id">
                  <div
                    class="rs-radius bg-white  q-pa-xs q-pl-md rs-border text-primary"
                    :style="{ borderColor: payModel.goodsId === item.id ? 'currentcolor' : '' }"
                  >
                    <div class="text-primary q-mb-xs" style="line-height:1">
                      <span style="font-size:24px;font-weight:400">{{ item.goodsName }}</span>
                      <span class="q-pa-xs">条</span>
                    </div>
                    <div class="text-black">
                      <span>售价:</span>
                      <span>{{ item.goodsPrice }}元</span>
                    </div>
                    <span class="text-caption text-grey-8">
                      {{ (item.goodsPrice / item.goodsName).toFixed(3) }}元/条
                    </span>
                  </div>
                </div>
              </section>
            </template>
          </div>
        </q-slide-transition>
        <!-- 自定义充值条数 2-->
        <section class="q-mb-sm">
          <q-radio dense v-model="payType" :val="2" label="自定义充值金额" class=""></q-radio>
        </section>
        <q-slide-transition>
          <div v-show="payType === 2">
            <section class="row items-center q-py-sm q-gutter-xs">
              <span>充值</span>
              <rs-input-number
                v-model="payModel.payMoney"
                autofocus
                dense
                outlined
                :precision="2"
                :max="99999.99"
                :min="0"
                class="rs-size-sm"
                type="number"
                style="width:120px"
              ></rs-input-number>
              <span>元，到账</span>
              <rs-input-number
                v-model="payModel.paySmsTotal"
                :hint="hint"
                dense
                outlined
                :max="9999999"
                :min="1"
                class="rs-size-sm"
                type="number"
                style="width:120px"
              ></rs-input-number>
              <span>条短信</span>
            </section>
            <section class="q-pb-sm ">
              <div class="q-mb-xs text-bold	">
                <span>充值说明</span>
                <span class="text-red text-bold q-pl-xs">*</span>
              </div>
              <q-input v-model="payModel.remarks" dense outlined type="textarea" rows="4"></q-input>
            </section>
          </div>
        </q-slide-transition>
        <!-- 短信条数维护 3-->
        <section class="q-mb-sm">
          <q-radio dense v-model="payType" :val="4" label="短信数量维护" class=""></q-radio>
        </section>
        <q-slide-transition>
          <div v-show="payType === 4">
            <section class="row items-center q-gutter-xs">
              <span>扣除</span>
              <rs-input-number
                v-model="payModel.upholdSmsTotal"
                dense
                outlined
                :max="Math.min(internalSmsSurplusNumber, 9999999)"
                :min="1"
                class="rs-size-sm"
                type="number"
                style="width:120px"
              ></rs-input-number>
              <span>条短信（当前剩余{{ internalSmsSurplusNumber }}条）</span>
              <span class="rs-link" @click="payModel.upholdSmsTotal = internalSmsSurplusNumber">全部扣除</span>
            </section>
            <section class="q-py-xs">
              <span class="text-red text-bold	q-pr-xs">*</span>
              <span class="text-caption">如扣除数量超过实际剩余短信数量,则将剩余短信条数扣至0</span>
            </section>
            <section class="q-pb-sm ">
              <div class="q-mb-xs text-bold	">
                <span>维护说明</span>
                <span class="text-red text-bold q-pl-xs">*</span>
              </div>
              <q-input v-model="payModel.remarks" dense outlined type="textarea" rows="4"></q-input>
            </section>
          </div>
        </q-slide-transition>
      </q-tab-panel>
      <!-- 国际短信定价 -->
      <q-tab-panel name="international" class="">
        <!-- 自定义充值金额 -->
        <section class="q-mb-sm">
          <q-radio dense v-model="payType" :val="3" label="充值金额" class=""></q-radio>
        </section>
        <q-slide-transition>
          <div v-show="payType === 3">
            <section class="row items-center q-py-sm q-gutter-xs">
              <span>充值</span>
              <rs-input-number
                v-model="payModel.payMoney"
                dense
                outlined
                :precision="2"
                :max="99999.99"
                :min="0.01"
                class="rs-size-sm"
                type="number"
                :hint="hint"
                style="width:120px"
              ></rs-input-number>
              <span>元;</span>
            </section>
            <section class="q-pb-sm ">
              <div class="q-mb-xs text-bold	">
                <span>充值说明</span>
                <span class="text-red text-bold q-pl-xs">*</span>
              </div>
              <q-input v-model="payModel.remarks" dense outlined type="textarea" rows="4"></q-input>
            </section>
          </div>
        </q-slide-transition>
        <!-- 维护金额 -->
        <section class="q-mb-sm">
          <q-radio dense v-model="payType" :val="5" label="维护金额" class=""></q-radio>
        </section>
        <q-slide-transition>
          <div v-show="payType === 5">
            <section class="row items-center q-gutter-xs">
              <span>扣除</span>
              <rs-input-number
                v-model="payModel.upholdSmsMoney"
                dense
                outlined
                :precision="2"
                :max="Math.min(nationalSmsSurplusAmount, 99999.99)"
                :min="0.01"
                class="rs-size-sm"
                type="number"
                style="width:120px"
              ></rs-input-number>
              <span>元（当前剩余{{ nationalSmsSurplusAmount }}元）</span>
              <span class="rs-link">全部扣除</span>
            </section>
            <section class="q-py-xs">
              <span class="text-red text-bold	q-pr-xs">*</span>
              <span class="text-caption">如扣除数量超过实际剩余短信数量,则将剩余短信条数扣至0</span>
            </section>
            <section class="q-pb-sm ">
              <div class="q-mb-xs text-bold	">
                <span>维护说明</span>
                <span class="text-red text-bold q-pl-xs">*</span>
              </div>
              <q-input v-model="payModel.remarks" dense outlined type="textarea" rows="4"></q-input>
            </section>
          </div>
        </q-slide-transition>
      </q-tab-panel>
    </q-tab-panels>

    <div class="q-px-md q-pb-md">
      <q-separator class="full-width" />
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>授权码</span>
        <span class="text-red text-bold	q-pl-xs">*</span>
      </section>
      <section class="row items-center">
        <q-input v-model="payModel.authCode" dense outlined class="rs-size-sm" style="width:120px"></q-input>
        <span class="rs-link q-ml-sm" @click="getAuthCode()">获取授权码</span>
      </section>
      <section class="q-py-xs">
        <span class="text-red text-bold q-pr-xs">*</span>
        <span class="text-caption">授权码将发送给尾号{{ sendPhoneMessage }}的手机号</span>
      </section>
    </div>
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";
const dftModel = {
  payType: 1,
  payMoney: null,
  paySmsTotal: null,
  remarks: null,
  goodsId: null,
  authCode: null,
  upholdSmsTotal: null,
  upholdSmsMoney: null,
};
export default {
  props: ["channel", "groupId"],
  mixins: [RsThirdApiMixins],
  data() {
    return {
      activeTab: "internal", // "international",
      payType: 1,
      goodsList: [{}, {}, {}, {}],
      nationalSmsSurplusAmount: 0,
      internalSmsSurplusNumber: 0,
      sendPhoneMessage: "", // 手机尾号
      currentPayItem: null,
      payModel: {
        ...dftModel,
      },
    };
  },
  watch: {
    payType: function(v) {
      this.payModel = { ...dftModel, payType: v };
    },
  },
  created() {
    this.get("/admin/sms/internal/goods/list", { groupId: this.groupId, gsaId: this.channel.id }).then((resp) => {
      if (resp && resp.list) {
        this.goodsList = resp.list;
        // 默认第一个推荐的选中【且可用】，如果没有推荐，选中第一个可用的
        const firstRecommendItem = this.goodsList.find((d) => d.isRecommend === 1 && d.goodsState === 1);
        const firstEnableItem = this.goodsList.find((d) => d.goodsState === 1);
        this.payModel.goodsId = firstRecommendItem?.id || firstEnableItem?.id;
        this.nationalSmsSurplusAmount = resp.nationalSmsSurplusAmount;
        this.internalSmsSurplusNumber = resp.internalSmsSurplusNumber;
        this.sendPhoneMessage = resp.sendPhoneMessage;
      }
    });
  },
  computed: {
    per() {
      const m = parseInt(this.payModel.payMoney);
      const t = parseInt(this.payModel.paySmsTotal);
      const per = t > 0 ? (m / t).toFixed(3) : "";
      return per;
    },
    hint() {
      return this.per ? (!isNaN(this.per) ? `单价: ${this.per} 元/条` : "") : "";
    },
  },
  methods: {
    submit() {
      const rules = {
        1: [["goodsId", "请选择充值金额!"]],
        2: [
          ["payMoney", "请输入充值金额!"],
          ["paySmsTotal", "请输入充值条数!"],
          ["remarks", "请输入充值说明!"],
        ],
        4: [
          ["upholdSmsTotal", "请输入短信扣除数量"],
          ["remarks", "请输入维护说明!"],
        ],
        3: [
          ["payMoney", "请输入充值金额!"],
          ["remarks", "请输入充值说明!"],
        ],
        5: [
          ["upholdSmsMoney", "请输入维护金额"],
          ["remarks", "请输入维护说明!"],
        ],
      };
      const invalid = [...rules[this.payType], ["authCode", "请输入授权码!"]].some((arr) => {
        const [key, msg] = arr;
        if (!this.payModel[key]) {
          this.$rs_error(msg);
          return true;
        }
        return false;
      });
      if (!invalid) {
        return this.post("/admin/sms/pay/order/create", {
          ...this.payModel,
          gsaId: this.channel.id,
          userName: this.$rs_user.nickName,
        });
      }
      return Promise.reject();
    },
    getAuthCode() {
      const internalFlag = this.activeTab === "internal" ? 1 : 0;
      const payType = this.payType;
      this.post("/admin/sms/pay/auth/code", { gsaId: this.channel.id, internalFlag, payType }).then(() => {
        this.$rs_success("短信已发送");
      });
    },
    activeTabChange(v) {
      if (v === "internal") {
        this.payType = 1;
      } else {
        this.payType = 3;
      }
    },
  },
};
</script>
<style>
.deal-msg-page .q-field__bottom {
  padding-left: 0 !important;
}
</style>
