<template>
  <div class="full-height rs-page-ten-order-list">
    <rs-list name="ten-order" keywords="id" :conditions="orderConditions">
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="id"></rs-filter>
        <rs-filter name="tenant" v-if="!tenantId"></rs-filter>
        <rs-filter name="type"></rs-filter>
        <rs-filter name="orderStatus"></rs-filter>
        <rs-filter name="payStatus"></rs-filter>
        <rs-filter name="orderTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="orderFrom"></rs-field>
      <rs-field name="tenant.uname"></rs-field>
      <rs-field name="tenant.name"></rs-field>
      <rs-field name="totalPrice"></rs-field>
      <rs-field name="orderStatus"></rs-field>
      <rs-field name="payStatus"></rs-field>
      <rs-field name="orderTime"></rs-field>
      <rs-field name="payTime"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*" v-slot="row">
        <rs-button name="edit" :payload="{ query: { tenantId, id: row.id } }"></rs-button>
      </rs-field>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
    };
  },
  computed: {
    orderConditions: function() {
      const cons = [];
      if (this.tenantId) {
        cons.push({ key: "tenant.id", vals: [this.tenantId] });
      }
      return cons;
    },
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
  },
  methods: {},
};
</script>
