<template>
  <div class="full-height flex-column tenant-menu-form">
    <rs-form
      ref="form"
      label-width="7em"
      name="ten-menu"
      allDirty
      @change="onChange"
      :value="moduleId ? { pid, module: { id: moduleId } } : { pid }"
    >
      <rs-field name="id" :required="false" hidden></rs-field>
      <rs-field name="module"></rs-field>
      <rs-field name="pid" :widget="pidWidget" :disable="!!id && !dbPid ? true : null"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="scopes" :widget="scopesWidget"></rs-field>
      <rs-field name="modelId"></rs-field>
      <rs-field name="globals"></rs-field>
      <rs-field name="tenants" v-bind="tenantProps"></rs-field>
      <rs-field name="icon"></rs-field>
      <rs-field name="url"></rs-field>
      <rs-field name="sort"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="level" readonly></rs-field>
      <rs-field name="roleGroups"></rs-field>
      <rs-field name="summary"></rs-field>
      <rs-field name="autoInstall" :hidden="autoInstallHidden"></rs-field>
      <rs-field name="visible" label="是否可见" :value="true"></rs-field>
      <rs-field name="virtual"></rs-field>
      <rs-field name="_" v-if="isPortalMenu" :label="$t_tenants('menu-listLink')">
        <template v-slot>
          <q-toggle v-model="showLink" />
        </template>
      </rs-field>
      <rs-field
        name="configs"
        col="12"
        :rules="[configRule]"
        :widget="{ _type: 'rs-ace-editor', height: '100px', lang: 'json' }"
      ></rs-field>
      <!-- <rs-field name="actions"></rs-field> -->
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.menu._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <rs-list
      ref="list"
      name="ten-menu-action"
      edit-mode="row"
      :loadDefault="false"
      :filterHidden="true"
      :isPagination="false"
      :data="actionList"
      style="min-height:350px !important"
      class="col rs-sticky-header-table"
    >
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="type"></rs-field>
      <!-- <rs-field name="model.id"></rs-field> -->
      <rs-field name="label"></rs-field>
      <rs-field name="url"></rs-field>
      <!-- <rs-field name="sort" hidden></rs-field> -->
      <rs-field name="scopes"></rs-field>
      <rs-field name="target"></rs-field>
      <rs-field name="view" v-slot="props">
        <div class="full-height row items-center">{{ props.view ? props.view.name || "" : "" }}</div>
      </rs-field>
      <rs-field type="toolbar" :alias="isSortMode ? 'sort' : null"></rs-field>
      <rs-toolbar :include="isSortMode ? ['cancelSort', 'saveSort'] : ['add', 'sortMode']" exclude="*">
        <rs-button name="add" label="" icon="add" color="white" text-color="black"></rs-button>
        <rs-button v-if="isSortMode" name="saveSort" icon="check" color="primary"></rs-button>
        <rs-button v-if="isSortMode" name="cancelSort" icon="close" color="grey"></rs-button>
        <rs-button v-if="!isSortMode" name="sortMode" icon="swap_vert" color="white" text-color="black"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
import cloneDeep from "lodash/cloneDeep";
import { listToMove } from "rsui-core/src/core/util/functions";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      pid: this.$route.query.pid || null,
      dbPid: null, // 回显数据的pid， 用于判断编辑时为空时 父节点不可选择
      moduleId: this.$route.query.moduleId,
      tenantProps: { disable: true },
      actionList: [],
      oldActionList: [], // 排序模式时，取消时需要的回滚数据
      isSortMode: false,
      autoInstallHidden: false,
      isPortalMenu: false, // 是不是门户菜单
      showLink: false, // 门户菜单列表上recName字段 是否显示link 连接
      formModule: this.$route.query.moduleId ? { id: this.$route.query.moduleId } : null, // 表单中当前选择的模块，用于新建menu-action时设置默认值；
      pidWidget: { disable: this.$route.query.pid ? false : true },
      modelService: new ModelService(), // ten-menu modelService 用于获取父节点的作用域，来限制当前的作用域选择
      scopesWidget: {},
    };
  },
  computed: {},
  mounted() {
    if (this.id) {
      this.getTenMenuActionList();
    }
    if (this.pid) {
      this.limitScopesByPid(this.pid);
    }
  },
  methods: {
    // config JSON 验证规则添加， 如果value 是字符串类型 说明JSON格式是错误的，因为在rs-code-mirror组件里如果是正确的会转换成object
    configRule(rule, value, callback) {
      if (value && typeof value === "string") {
        callback(new Error(this.$t_s("json-error")));
      } else {
        callback();
      }
    },
    // 根据父节点的作用域限制 当前节点的作用域【即 如果父节点是不限，那么当前节点不限制，
    // 如果是仅企业平台或者仅分销商平台，那么子节点只能与之相同】
    limitScopesByPid(pid, core) {
      const done = () => {
        if (core) {
          setTimeout(() => {
            core.setValue({ scopes: null });
          });
        }
      };
      if (pid) {
        this.modelService.find(pid, { fields: ["id", "scopes"] }, "ten-menu").then((resp) => {
          // 如果父节点存在 且不是“不限0”
          this.scopesWidget = resp.scopes !== 0 ? { includeByKey: [resp.scopes] } : {};
          if (resp.scopes !== 0 && !(core && core.value.scopes === resp.scopes)) {
            done();
          }
        });
      } else {
        this.scopesWidget = {}; // pid 不存在清空限制
        done();
      }
    },
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          this.dbPid = params.pid;
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      const pp = { ...params };
      if (!pp.globals) {
        delete pp.autoInstall;
      }
      return {
        params: pp,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          const list = this.isPortalMenu
            ? this.actionList.map((d) => ({ ...d, modelId: this.showLink ? pp.modelId : null }))
            : this.actionList;

          this.$refs.form.modelService.cascade("actions", id, list, "ten-menu").then((res) => {
            // this.$rs_action("tenants.menu._view");
            this.$rs_action_refresh_table("tenants.menu._view");
          });
        },
      };
    },
    // form change 事件
    onChange(val, fireKey, core) {
      if (fireKey.includes("module")) {
        this.isPortalMenu = val.module && ["PORTAL", "MDATA"].includes(val.module.id);
        this.formModule = val.module;
        if (val.module && val.module.id) {
          const conds = [{ key: "module.id", vals: [val.module.id] }];
          // 如果编辑时，修改父节点要排除自己及其下级节点
          if (this.id) {
            conds.push({
              key: "seniors",
              op: "relNotExists",
              vals: [
                {
                  key: "rel.pid",
                  op: "eq",
                  vals: [this.id],
                },
              ],
            });
          }
          this.pidWidget = {
            conditions: conds,
            disable: false,
            dlgProps: { keywords: "id,name,code" },
          };
        } else {
          this.pidWidget = {
            disable: this.pid ? false : true,
          };
        }
        // 如果所属模块 发生变化，pid 置空
        if (fireKey.length === 1) {
          setTimeout(() => {
            core.setValue({ pid: null });
          });
        }
      }
      // 如果是全局，适用组合不可选，且清空
      if (fireKey.includes("globals")) {
        this.tenantProps = { disable: !!val.globals || null };
        this.autoInstallHidden = !val.globals;
        if (val.globals && val.tenants) {
          setTimeout(() => {
            core.setValue({ tenants: null });
          });
        }
      }
      // 根据父节点的作用域 判断是否限制子节点
      if (fireKey.includes("pid")) {
        this.limitScopesByPid(val.pid, core);
      }
    },
    // rs-list 加载完成后，获取actionlist 数据
    getTenMenuActionList() {
      const conditions = this.id ? [{ key: "menu.id", vals: [this.id] }] : [];
      this.$refs.list.modelService
        .list(
          {
            conditions,
            fields: [
              "id",
              "code",
              "name",
              "type",
              "label",
              "url",
              "sort",
              "target",
              "module.id",
              "modelId",
              "view.id",
              "view.name",
              "scopes",
            ],
            page: 1,
            size: 100,
            orderby: "sort",
          },
          "ten-menu-action",
        )
        .then((resp) => {
          if (resp && resp.list) {
            this.actionList = resp.list;
            // 初始化的actions中 是否含有
            this.showLink = !!this.actionList
              .filter((d) => ["edit", "detail"].includes(d.label) && d.type === "view")
              .find((d) => !!d.modelId);
          }
        });
    },
    // 新建action
    onTenMenuActionAdd(e, params) {
      if (this.$refs.list.isEditing) {
        this.$rs_warning(this.$t_tenants("ten-menuActionIsEditing"));
        e.preventDefault();
        return;
      }
      return {
        params: {
          customize: false,
          module: this.formModule,
        },
      };
    },
    // list启动排序功能
    onTenMenuActionSortMode(e, params, eventId) {
      if (this.$refs.list.isEditing) {
        this.$rs_warning(this.$t_tenants("ten-menuActionIsEditing"));
        e.preventDefault();
        return;
      }
      this.oldActionList = cloneDeep(this.actionList);
      this.isSortMode = true;
    },
    // list取消排序
    onTenMenuActionCancelSort(e, params, eventId) {
      this.actionList = this.oldActionList;
      this.isSortMode = false;
    },
    // list保存排序功能
    onTenMenuActionSaveSort(e, params, eventId) {
      this.isSortMode = false;
      this.oldActionList = [];
    },
    // list 编辑行或者新建行 保存事件
    onTenMenuActionSaveLine(e, params, eventId) {
      e.preventDefault();
      const row = params.row;
      this.$refs.list.formCore.validate().then((r) => {
        if (!r) {
          if (params.isCreated) {
            row.sort = Math.max(0, ...this.actionList.map((d) => d.sort || 0)) + 1;
            this.actionList.push(row);
          } else {
            this.$set(this.actionList, row.__index, row);
          }
          this.$refs.list.cacelEdit();
        }
      });
    },
    // list 编辑行事件
    onTenMenuActionEdit(e, params, eventId) {
      e.preventDefault();
      this.$refs.list.updateRow({ row: params });
    },
    // list 删除行事件
    onTenMenuActionDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      this.actionList.splice(params[0].__index, 1);
    },
  },
};
</script>
