<template>
  <div class="full-height rs-page-xxl-job-group-list">
    <rs-list name="xxl-job-group" keywords="appName">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="appName"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="appName"></rs-field>
      <rs-field name="title"></rs-field>
      <rs-field name="addressType"></rs-field>
      <rs-field name="addressList"></rs-field>
      <rs-field type="toolbar" exclude="*"></rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
