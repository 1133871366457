<template>
  <div class="q-pa-sm full-height product-form">
    <div class="full-height bg-white column">
      <div class="col-auto">
        <q-tabs v-model="activeTab" dense align="left" class="text-primary" @input="scrollIntoView">
          <q-tab v-for="item in showTabsByType[this.type]" :key="item" :name="item" :label="typeNameMap[item]" />
          <q-space />
          <div class="q-pa-sm">
            <q-btn
              size="sm"
              v-auth="$route.query.id ? 'edit' : 'add'"
              color="primary"
              :loading="loading"
              :label="$t_s('save')"
              @click="save"
            />
            <q-btn size="sm" class="q-ml-md" color="grey-6" :label="$t_s('cancel')" @click="cancel" />
          </div>
        </q-tabs>
        <q-separator />
      </div>
      <div class="product-scroll-content col scroll">
        <q-list class="q-pa-sm q-gutter-sm">
          <rs-group name="base" v-if="isShowGroup('base')" :label="typeNameMap.base">
            <rs-form
              v-if="!!typeValue"
              ref="baseForm"
              name="product"
              no-border
              label-width="6.5em"
              :isUpdateMode="isUpdateMode"
              @change="onBaseFormChange"
            >
              <rs-field name="id"></rs-field>
              <rs-field name="name"></rs-field>
              <rs-field name="type" :value="typeValue" :disable="true"></rs-field>
              <rs-field name="title"></rs-field>
              <rs-field name="brand"></rs-field>
              <rs-field name="purchasePrice"></rs-field>
              <rs-field name="distributionPrice"></rs-field>
              <rs-field name="price"></rs-field>
              <rs-field name="assetType"></rs-field>
              <rs-field name="archivalNature"></rs-field>
              <rs-field name="id" :label-show="false" :col="12" v-slot>
                <div class="text-subtitle2 col-auto text-black">{{ $t_s("customizeAttrLabel") }}:</div>
              </rs-field>
              <rs-field name="ext" :col="12"></rs-field>
            </rs-form>
          </rs-group>
          <rs-group name="classify" v-if="isShowGroup('classify')" :label="typeNameMap.classify">
            <rs-classify-field
              ref="pdtClass"
              ns="pdtClass"
              :restoreId="this.id"
              :limitTypes="[type]"
              label-width="6.5em"
            />
          </rs-group>
          <rs-group name="tag" v-if="isShowGroup('tag')" :label="typeNameMap.tag">
            <rs-classify-field ref="pdtTag" ns="pdtTag" :restoreId="this.id" label-width="6.5em" />
          </rs-group>
          <rs-group name="spec" v-if="isShowGroup('spec') && !!specGroups" :label="typeNameMap.spec">
            <div class="row q-col-gutter-md q-pa-sm">
              <div v-for="t in specTypes" :key="t.id" class="col-4">
                <q-card>
                  <q-card-section class="q-pa-sm">
                    <div class="text-subtitle1">{{ t.name }}</div>
                  </q-card-section>
                  <q-separator />
                  <q-card-section>
                    <div class="row items-center">
                      <span class="q-px-sm text-right" style="minWidth: 120px;">
                        {{ `${$t_mdata("product.specSelected")}${t.name}   ：` }}
                      </span>
                      <rs-obj-select
                        :plain="true"
                        class="col"
                        :model="specGroupModel"
                        :conditions="[{ key: 'type.id', vals: [t.id] }]"
                        :fields="['id', 'type.name', 'name', 'info', 'recTime', 'recUser']"
                        v-model="specGroups[t.id].value"
                        @input="(e) => onSpecsChange(t.id, e)"
                      />
                    </div>
                    <div class="row q-mt-md">
                      <span class="q-px-sm text-right flex-no-grow" style="minWidth: 120px;line-height: 40px">
                        {{ `${t.name}${$t_mdata("product.specVal")}   ：` }}
                      </span>
                      <div class="flex-grow">
                        <q-checkbox
                          v-for="(item, index) in specGroups[t.id].specs"
                          class="q-mr-lg"
                          :key="index"
                          v-model="specGroups[t.id].selectedCodes"
                          :val="item.id"
                          :label="item.name"
                        />
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </div>
          </rs-group>
          <rs-group name="build" v-if="isShowGroup('build')" :label="typeNameMap.build"></rs-group>
          <rs-group name="sku" v-if="isShowGroup('sku')" :label="typeNameMap.sku">
            <div class="q-gutter-sm">
              <q-radio
                v-model="isSamePrice"
                :val="true"
                :label="$t_mdata('product-isSamePrice')"
                @input="onIsSamePriceChange"
              />
              <q-radio
                v-model="isSamePrice"
                :val="false"
                :label="$t_mdata('product-notSamePrice')"
                @input="onIsSamePriceChange"
              />
            </div>
            <rs-list
              :key="isSamePrice"
              ref="skuList"
              editMode="all"
              :selection="false"
              :isPagination="false"
              :loadDefault="false"
              :filterHidden="true"
              :data="skuData"
              name="product-sku"
            >
              <rs-field :label="$t_mdata('product-skuIndex')" :editable="false" v-slot="props">
                {{ props.__index + 1 }}
              </rs-field>
              <rs-field name="_specs" :label="$t_mdata('product-skuSpecs')" :editable="false" v-slot="props">
                {{ props._specs.map((d) => d.name).join("*") }}
              </rs-field>
              <rs-field name="id" :updatable="true" />
              <rs-field name="purchasePrice" :readonly="isSamePrice" />
              <rs-field name="distributionPrice" :readonly="isSamePrice" />
              <rs-field name="price" :readonly="isSamePrice" />
            </rs-list>
          </rs-group>
        </q-list>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "rsui-core/src/system/service/api";
import { listToMove } from "rsui-core/src/core/util/functions";
import cloneDeep from "lodash/cloneDeep";
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
import isNumber from "lodash/isNumber";
import RsClassifyField from "rsui-core/src/core/components/classify-field/rs-classify-field";
export default {
  components: { RsClassifyField },
  data: function() {
    return {
      activeTab: "base",
      id: null,
      type: null, // 商品类型
      typeValue: null, // 新建时type 对象
      isUpdateMode: false, // 用于表示基本信息form是不是更新模式
      preCreatedId: null, // 新建页面上一次创建成功但是保存其他信息失败时 的ID,用于区别下一次点击保存时是更新【id没有变化】还是创建【id和上一次创建不一致】
      loading: false, // 保存按钮loading状态
      showTabsByType: {
        DEFAULT: ["base", "classify", "tag", "spec", "sku"], // 鞋服类 "price", "package", "company"
        // 2: ["base", "classify", "tag", "build", "sku"], // 组装商品
        // 3: ["base", "classify", "tag"], // 物料/道具
      },
      typeNameMap: {
        base: this.$t_mdata("product-baseTab"),
        classify: this.$t_mdata("product-classifyTab"),
        tag: this.$t_mdata("product-tagTab"),
        spec: this.$t_mdata("produt-specTab"),
        build: this.$t_mdata("product-buildTab"),
        // price: "价格信息",
        sku: this.$t_mdata("product-skuTab"),
        // package: "包装信息",
        // company: "厂商信息",
      },
      scrollFunction: this.debounce(this.handleScroll, 500), // 锚点回显用到的滚动绑定事件
      productModelService: new ModelService(), // 商品modelService
      productSkuModelService: new ModelService(), // 商品条码modelService
      productTypeModelService: new ModelService(), // 商品类型modelService
      productSpecTypeModelService: new ModelService(), // 商品规格类型modelService
      productSpecModelService: new ModelService(), // 商品规格类型对应值modelService
      specTypes: [], // 规格信息 需要展示的规格类型对象 like [{id:"color",name;"颜色"}]
      specGroupModel: {}, // 选择规格组 model 用于rs-obj-select
      specGroups: undefined, // 规格类型id 对应选中的 product-spec-group 组成的对象 like {color: {value: 1,specs:[id:2,name:"白色"],selectedCodes:[2]}}
      skuData: [], // 条码信息表格绑定值
      isSamePrice: true, // 规格不同是否价格相同
      allSpecCodeDic: {}, // 缓存所有的规格映射的一条条码表格数据，key="colorGroupId,colorId,sizeGroupId,sizeId"
    };
  },
  watch: {
    /**
     * 当商品规格信息发生变化时，条码信息中表格数据发生对应变化
     */
    specGroups: {
      handler(newVal, oldVal) {
        if (this.specTypes.length > 0) {
          let data = [];
          const visibleTypeIds = []; // 所有有选中值的 规格类型ids
          // 如果所有规格的商品价格一致时 获取表单中价格设置tableData中
          let samePurchasePrice = null;
          let sameDistributionPrice = null;
          let samePrice = null;
          if (this.isSamePrice && this.$refs.baseForm) {
            samePurchasePrice = isNumber(this.$refs.baseForm.core.value.purchasePrice)
              ? this.$refs.baseForm.core.value.purchasePrice
              : null;
            sameDistributionPrice = isNumber(this.$refs.baseForm.core.value.distributionPrice)
              ? this.$refs.baseForm.core.value.distributionPrice
              : null;
            samePrice = isNumber(this.$refs.baseForm.core.value.price) ? this.$refs.baseForm.core.value.price : null;
          }
          // 设置最新表格中修改的值
          this.getSkus().forEach((d) => {
            this.allSpecCodeDic[d._key].id = d.id;
            this.allSpecCodeDic[d._key].price = d.price;
          });
          this.specTypes.forEach((type, index) => {
            const specGroup = this.specGroups[type.id];
            // 当前某规格类型，有选中项时
            if (specGroup && specGroup.selectedCodes.length > 0) {
              visibleTypeIds.push(type.id);
              // 某规格类型选中的items
              const selectedItems = specGroup.specs.filter((item) => specGroup.selectedCodes.includes(item.id));
              // data 没有值时，设置初始值 like [[colorItem1],[colorItem2]]
              if (data.length === 0) {
                data = selectedItems.map((item) => [item]);
              }
              // data 有值时，给data每个添加一个维度 like [[colorItem1,sizeItem1],[colorItem2,sizeItem1],[colorItem1,sizeItem2],[colorItem2,sizeItem2]]
              else {
                const temp = [];
                data.forEach((items) => {
                  temp.push(...selectedItems.map((item) => [...items, item]));
                });
                data = temp;
              }
            }
          });
          const skuData = data.map((items) => {
            const specs = {}; // 保存需要的specs字段
            const key = visibleTypeIds
              .reduce((keys, typeId, index) => {
                specs[typeId] = items[index].id;
                keys.push(`${typeId},${items[index].id}`);
                return keys;
              }, [])
              .join(",");
            let val = null;
            if (this.allSpecCodeDic[key]) {
              // 如果没有_specs属性，说明该数据是回显设置的数据，需要设置_specs，来展示表格中商品规格名称
              if (!this.allSpecCodeDic[key]._specs) {
                this.allSpecCodeDic[key]._specs = items;
              }
              // 如果价格相同，需要设置之前隐藏的数据的价格
              if (this.isSamePrice) {
                this.allSpecCodeDic[key].purchasePrice = samePurchasePrice;
                this.allSpecCodeDic[key].distributionPrice = sameDistributionPrice;
                this.allSpecCodeDic[key].price = samePrice;
              }
              val = this.allSpecCodeDic[key];
            } else {
              val = {
                _key: key,
                _specs: items,
                specs,
                id: "",
                purchasePrice: this.isSamePrice ? samePurchasePrice : null,
                distributionPrice: this.isSamePrice ? sameDistributionPrice : null,
                price: this.isSamePrice ? samePrice : null,
              };
              this.allSpecCodeDic[key] = val;
            }
            return val;
          });
          this.setSkuData(skuData);
        }
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    // 获取规格信息中rs-obj-select组件需要的model
    ApiService.metaModel("product-spec-group").then((res) => {
      if (res && res.content) {
        this.specGroupModel = res.content;
      }
    });
  },
  activated() {
    if (!this.id && !this.type) {
      this.selectproductType();
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.id) {
      this.productModelService.find(this.id, { fields: ["id", "type.id", "type.name"] }, "product").then((resp) => {
        this.type = resp.type.id;
        this.typeValue = resp.type;
        this.init();
      });
    }
    this.isUpdateMode = !!this.id;
    this.loading = !!this.id;
  },
  beforeDestroy() {
    this.$el.querySelector(".product-scroll-content").removeEventListener("scroll", this.scrollFunction);
  },
  methods: {
    // 新建时
    selectproductType() {
      this.$rs_model_dlg(
        { modelName: "product-type", conditions: [{ key: "enable", vals: [true] }] },
        {
          cancel: false,
          title: `${this.$t_s("select")}-${this.$t_mdata("productType-name")}`,
          beforeEvent: ({ selected, dlg }) => {
            if (selected[0]) {
              dlg.hide();
              this.type = selected[0].id;
              this.typeValue = selected[0];
              this.init();
            } else {
              this.$rs_warning(this.$t_mdata("product-typeIsNull"));
            }
          },
        },
      );
    },
    init() {
      setTimeout(() => {
        this.$el.querySelector(".product-scroll-content").addEventListener("scroll", this.scrollFunction);
      });
    },
    /**
     * 根据不同商品类型显示不同tab
     */
    isShowGroup(groupName) {
      if (this.type) {
        return this.showTabsByType[this.type] && this.showTabsByType[this.type].includes(groupName);
      }
      return false;
    },
    /**
     * 顶部tabs点击时触发锚点事件，页面滚动到对应未知
     */
    scrollIntoView(e) {
      this.activeTab = e;
      if (this.$el.querySelector(`div[name='${e}']`)) {
        this.$el.querySelector(`div[name='${e}']`).scrollIntoView();
      }
    },
    /**
     * 防抖
     */
    debounce(fn, wait) {
      let timeout = null;
      return function() {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    handleScroll() {
      const scrollTop = this.$el.querySelector(".product-scroll-content").scrollTop;
      for (let i = 0; i < this.showTabsByType[this.type].length; i++) {
        const key = this.showTabsByType[this.type][i];
        const item = this.$el.querySelector(`div[name='${key}']`);
        if (item && scrollTop <= item.offsetTop) {
          this.activeTab = key;
          return;
        }
      }
    },
    /**
     * 基本信息form请求时数据拦截，如果有id时修改请求参数，获取商品类型中规格信息，以及商品的规格信息
     * 无id时请求新增时选择的商品类型详情中的规格信息，以及设置对应商品的规格信息初始值
     * 先设置this.specTypes属性，然后在设置specGroups属性，应该在specGroups监听中需要this.specTypes属性
     */
    beforeRequest(e, params, eventId) {
      if (this.id) {
        params.fields.push("type.specTypes", "specGroups");
        return {
          params,
          resolve: ({ params }) => {
            if (params.type && params.type.id) {
              this.type = params.type.id;
            }
            // 判断商品类型是否有设置的规格类型，如果没有无须处理
            if (Array.isArray(params.type.specTypes) && params.type.specTypes.length) {
              // 判断商品回显数据是否有设置的相关规格组类型信息，没有则只需初始化规格组展示信息，无须设置回显值
              if (params.specGroups) {
                this.getProductSpecTypesByIds(params.type.specTypes)
                  .then(() => {
                    return this.restoreSkus(params.specGroups).then((skus) => {
                      // 这里只是对规格信息checkBox 选中值进行回显
                      const allGetSpecApi = [];
                      Object.keys(params.specGroups).forEach((typeId) => {
                        let restoreCodes = []; // 获取skus 中对应typeId规格的 选中codes
                        skus.forEach((sku) => {
                          if (sku.specs && sku.specs[typeId]) {
                            restoreCodes.push(sku.specs[typeId]);
                          }
                        });
                        restoreCodes = Array.from(new Set(restoreCodes)); // 去重
                        allGetSpecApi.push(
                          this.getProductSpecByTypeIdApi(typeId, params.specGroups[typeId], restoreCodes),
                        );
                      });
                      return Promise.all(allGetSpecApi).then((results) => {
                        let specGroups = {};
                        // 设置默认值；防止回显数据中缺失某些类型，导致页面绑定报错 给后来添加的规格类型设置默认值
                        // 或者之前保存的商品类型的规格信息添加其他类型，而旧数据没有保存对应specGroups对应规格类型的值
                        if (Array.isArray(params.type.specTypes) && params.type.specTypes.length) {
                          params.type.specTypes.forEach((d) => {
                            specGroups[d] = {
                              value: null,
                              specs: [],
                              selectedCodes: [],
                            };
                          });
                        }
                        results.forEach((d) => {
                          specGroups = { ...specGroups, ...d };
                        });
                        this.$set(this, "specGroups", specGroups);
                      });
                    });
                  })
                  .finally(() => (this.loading = false));
              } else {
                this.setInitParams(params.type.specTypes).finally(() => (this.loading = false));
              }
            } else {
              this.loading = false;
            }
          },
        };
      } else {
        this.productTypeModelService
          .find(this.type, { fields: ["name", "specTypes"] }, "product-type")
          .then((res) => {
            this.typeValue = res;
            if (Array.isArray(res.specTypes) && res.specTypes.length) {
              return this.setInitParams(res.specTypes);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    /**
     * 设置规格信息页面中需要展示的规格信息，新建时或者回显数据无相关规格信息设置时请求
     * @param specTypes 商品类型中定义的规格展示信息有哪些
     */
    setInitParams(specTypes) {
      const specGroups = {};
      specTypes.forEach((d) => {
        specGroups[d] = {
          value: null,
          specs: [],
          selectedCodes: [],
        };
      });
      return this.getProductSpecTypesByIds(specTypes).then(() => this.$set(this, "specGroups", specGroups));
    },
    /**
     * 获取回显的skus数据，并设置isSamePrice值
     * @param specGroups API.find 返回的specGroups属性{color:2,size:3} 用于设置回显数据
     */
    restoreSkus(specGroups) {
      return this.productSkuModelService
        .list(
          {
            conditions: [{ key: "pdt.id", vals: [this.id] }],
            fields: ["id", "specs", "purchasePrice", "distributionPrice", "price"],
          },
          "product-sku",
        )
        .then((res) => {
          if (res && res.list) {
            // 判断三种价格是否各自相同，来定义是否价格是否一致
            const allPurchasePrice = res.list.map((d) => d.purchasePrice);
            const allDistributionPrice = res.list.map((d) => d.distributionPrice);
            const allPrice = res.list.map((d) => d.price);
            this.isSamePrice =
              Array.from(new Set(allPurchasePrice)).length === 1 &&
              Array.from(new Set(allDistributionPrice)).length === 1 &&
              Array.from(new Set(allPrice)).length === 1;

            res.list.forEach((item) => {
              const key = this.specTypes
                .reduce((keys, { id }) => {
                  // 当回显数据中specs属性含有对应选择的规格时，处理回显数据，【如果某个规格类型没有选择值时，这里会出现undefined，导致数据无法回显】
                  if (item.specs[id]) {
                    keys.push(`${id},${item.specs[id]}`);
                  }
                  return keys;
                }, [])
                .join(",");
              this.allSpecCodeDic[key] = { _key: key, ...item };
            });
            return res.list;
          }
          return [];
        });
    },
    /**
     * 根据商品类型属性中设置的规格类型，获取规格信息有哪些分类,设置this.specTypes like [{id:"color",name:"颜色"}]
     * @param specTypeIds 规格类型id数组
     */
    getProductSpecTypesByIds(specTypeIds) {
      return this.productSpecTypeModelService
        .refs(specTypeIds, { fields: ["name"] }, "product-spec-type")
        .then((list) => {
          const specTypes = [];
          specTypeIds.forEach((d) => {
            const item = (list || []).find((item) => item.id === d);
            if (item) {
              specTypes.push(item);
            }
          });
          this.$set(this, "specTypes", specTypes);
        });
    },
    /**
     * 规格信息发生修改时触发，更新保存的值
     * @param typeId 规格类型ID
     * @param groupId 规格组ID
     */
    onSpecsChange(typeId, groupId) {
      this.getProductSpecByTypeIdApi(typeId, groupId).then((res) => {
        if (this.specGroups[typeId] && res[typeId]) {
          this.$set(this.specGroups, typeId, res[typeId]);
        }
      });
    },
    /**
     * 根据规格类型typeId,和规格组groupId 获取specGroups[typeId]对象
     * @param typeId 规格类型ID
     * @param groupId 规格组ID
     * @param restoreCodes 回显对应某个规格类型 选中的值
     * @return Promise<{ typeId: { value: groupId, specs: any[], selectedCodes: string[] } }>
     */
    getProductSpecByTypeIdApi(typeId, groupId, restoreCodes = []) {
      return this.productSpecModelService
        .list({ conditions: [{ key: "group.id", vals: [groupId] }], fields: ["id", "name"] }, "product-spec")
        .then(({ list }) => {
          const defaultSelectedCodes = (list || []).map((d) => d.id);
          const val = {
            value: groupId,
            specs: list || [],
            selectedCodes:
              Array.isArray(restoreCodes) && restoreCodes.length > 0
                ? defaultSelectedCodes.filter((id) => restoreCodes.includes(id))
                : defaultSelectedCodes,
          };
          return { [typeId]: val };
        });
    },
    /**
     * 基本信息表单change事件，监听price变化，当是不同规格是相同价格时修改条码信息表格价格字段
     */
    onBaseFormChange(val, fireKey, core) {
      // 当新建页面时，已经保存了，如果val.id变化，需要修改isUpdateMode,来区别是否需要id字段验证【防止验证重复id 】
      if (!this.id && this.preCreatedId && fireKey.find((d) => d === "id")) {
        this.isUpdateMode = val.id === this.preCreatedId;
      }
      if (this.isSamePrice && fireKey.length === 1) {
        const data = this.getSkus();
        if (fireKey.find((d) => d === "purchasePrice")) {
          data.forEach((d) => (d.purchasePrice = val.purchasePrice));
          this.setSkuData(data);
        } else if (fireKey.find((d) => d === "distributionPrice")) {
          data.forEach((d) => (d.distributionPrice = val.distributionPrice));
          this.setSkuData(data);
        } else if (fireKey.find((d) => d === "price")) {
          data.forEach((d) => (d.price = val.price));
          this.setSkuData(data);
        }
      }
    },
    /**
     * 不同规格的商品价格是否一致，监听isSamePrice 变化，修改条码信息表格价格字段
     */
    onIsSamePriceChange() {
      if (this.isSamePrice) {
        // 如果所有规格的商品价格一致时 获取表单中价格设置tableData中
        let samePurchasePrice = null; // 采购价格（含税）
        let sameDistributionPrice = null; //  分销价格（含税）
        let samePrice = null; // 零售价
        if (this.$refs.baseForm) {
          samePurchasePrice = isNumber(this.$refs.baseForm.core.value.purchasePrice)
            ? this.$refs.baseForm.core.value.purchasePrice
            : null;
          sameDistributionPrice = isNumber(this.$refs.baseForm.core.value.distributionPrice)
            ? this.$refs.baseForm.core.value.distributionPrice
            : null;
          samePrice = isNumber(this.$refs.baseForm.core.value.price) ? this.$refs.baseForm.core.value.price : null;
        }
        const data = this.getSkus();
        data.forEach((d) => {
          // 如果相同 那么值一样，不同：值不变还是一样
          d.purchasePrice = samePurchasePrice;
          d.sameDistributionPrice = sameDistributionPrice;
          d.price = samePrice;
        });
        this.setSkuData(data);
      }
    },
    /**
     * 获取当前条码信息表格中正在编辑的数据
     */
    getSkus() {
      if (this.$refs.skuList && this.$refs.skuList.tableForm) {
        const formCoreDic = this.$refs.skuList.tableForm.formCoreDic;
        return cloneDeep(Object.keys(formCoreDic).map((d, index) => formCoreDic[d].value));
      }
      return [];
    },
    /**
     * 设置表格数据，设置前清空rs-list中的tableForm
     * @param data 设置最新条码表格的数据
     */
    setSkuData(data) {
      this.$set(this, "skuData", data);
      this.$refs.skuList.tableForm.clear();
    },
    // 保存
    save() {
      this.loading = true;
      Promise.all([this.$refs.baseForm.core.validate(), this.$refs.skuList.tableForm.validate()]).then(
        (vaildateArr) => {
          const validateSucces = vaildateArr.findIndex((d) => !!d) === -1;
          if (validateSucces) {
            const specGroups = {};
            Object.keys(this.specGroups).forEach((typeId) => {
              if (this.specGroups[typeId].value) {
                specGroups[typeId] = this.specGroups[typeId].value;
              }
            });
            const cls = this.$refs.pdtClass.getValue();
            const tags = this.$refs.pdtTag.getValue();
            const saveData = { ...this.$refs.baseForm.core.value, specGroups, cls, tags };
            if (!this.id && this.type) {
              saveData.type = { id: this.type };
            }
            let isCreated = false;
            // 当id不存在【表示新建页面】
            if (!this.id) {
              const formData = this.$refs.baseForm.core.getValue();
              // 并且上一次新建的preCreatedId也不存在【表示新建页面第一次保存表单】 或者不等于当前新建页面中的ID【上一次保存成功但是又修改ID,重新新建一个】
              if (!this.preCreatedId || (formData && formData.id !== this.preCreatedId)) {
                isCreated = true;
              }
            }

            this.productModelService[isCreated ? "create" : "update"](saveData, "product")
              .then((res) => {
                if (this.isShowGroup("sku")) {
                  if (isCreated) {
                    this.preCreatedId = res.id;
                  }
                  const skus = this.getSkus().map((item) =>
                    pick(item, ["id", "purchasePrice", "distributionPrice", "price", "specs"]),
                  );
                  return this.productModelService
                    .cascade("skus", this.id || this.preCreatedId, skus, "product")
                    .then(() => {
                      this.$rs_success(this.$t_s("save-success"));
                      this.cancel();
                    });
                } else {
                  this.$rs_success(this.$t_s("save-success"));
                  this.cancel();
                }
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
            return false;
          }
        },
        () => (this.loading = false),
      );
    },
    // 取消
    cancel() {
      this.$rs_actionRefresh("mdata.product._view");
    },
  },
};
</script>
