<script>
import Config from "rsui-core/src/config";

export default {
  data() {
    return {};
  },
  props: ["closeDialog"],
  mounted() {},
  computed: {},
  methods: {
    beforeSubmit(e, params) {
      e.preventDefault();
      this.$rs_post(Config.API.RSTORE_CORP_IMPORT + "?ver=2", params).then((resp) => {
        this.$rs_success("新增成功！");
        this.closeDialog();
        this.$rs_actionRefresh("tenants.rstoreSetting._view");
      });
    },
  },
  render() {
    return (
      <rs-form
        ref="form"
        noBorder
        class="full-height"
        name="mock-corpConfig"
        labelWidth="10em"
        col={1}
        restoreByQuery={false}
        toolbar={{ forceAlign: "end" }}
        wrapPadding=""
        bodyPadding="q-ma-sm">
        <rs-field name="corpId"></rs-field>
        <rs-field name="version"></rs-field>
        <rs-field name="rwinUrl"></rs-field>
        <rs-field name="rwinCode"></rs-field>
        <rs-toolbar>
          <rs-button name="submit" icon="" label="确定" sort={999} auth={null}></rs-button>
        </rs-toolbar>
      </rs-form>
    );
  },
};
</script>
