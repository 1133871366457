<template>
  <div class="full-height mine__form">
    <div class=" full-height rs-page-your-platform scroll">
      <rs-form ref="form" name="platform" floatLayout @change="onFormChange" :isUpdateMode="!!plId">
        <rs-field name="name" required></rs-field>
        <rs-field name="id" readonly />
        <rs-field name="type" disable />
        <!-- <rs-field name="beginDate" hidden readonly /> -->
        <rs-field name="endDate" hidden readonly />
        <rs-field
          name="beginDate"
          :help="$t_settings('minePlatform.term_of_validity.help')"
          :label="$t_settings('platform.term_of_validity.name')"
          v-slot="props"
        >
          <q-input
            dense
            outlined
            disable
            :value="props.beginDate ? `${props.beginDate} ${$t_s('to')} ${props.endDate || $t_s('unlimited')}` : ' '"
          ></q-input>
          <!-- <div class="full-height row text-black">
            <span class="self-center padding-form-item">{{
              props.beginDate ? `${props.beginDate} — ${props.endDate || $t_s("unlimited")}` : " "
            }}</span>
          </div> -->
        </rs-field>
        <rs-field name="adminUser" readonly v-slot="form">
          <q-field dense outlined>
            <div class="row full-height">
              <router-link
                class="self-center"
                :to="{ name: 'settings.account._edit', query: { id: form.adminUser && form.adminUser.id } }"
              >
                {{ form.adminUser && form.adminUser.nickName }}
              </router-link>
            </div>
          </q-field>
        </rs-field>
        <rs-field name="logo" :widget="headImg" :readonly="$rs_user.isSub" floatRight />
        <rs-field name="adminUser.mobile" disable floatLeft />
        <rs-field name="adminUser.email" disable floatLeft />
        <rs-toolbar :exclude="['cancel']" />
      </rs-form>
      <q-list class="q-px-sm q-pb-sm">
        <rs-group :label="$t_settings('mine-app')" class="no-padding">
          <rs-list name="module" v-if="!!type" keywords="name,id">
            <rs-field name="id" />
            <rs-field name="name" />
            <rs-field name="version" />
            <rs-field name="status" />
            <rs-field name="startDate" />
            <rs-field name="endDate" />
            <rs-field name="website" />
          </rs-list>
        </rs-group>
      </q-list>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      type: null,
      plId: null,
      headImg: { _type: "rs-head-image", fixedNumber: [365, 80], height: "80px", width: "365px" },
    };
  },
  computed: {
    moduleCondition: function() {
      if (this.type === 2) {
        return [
          {
            key: "platforms.id",
            vals: [this.plId],
          },
        ];
      }
      return [];
    },
  },
  beforeCreate() {
    this.plId = this.$rs_user.platform;
    this.$router.replace({ path: "settings.mine._view", query: { id: this.plId } });
  },
  methods: {
    onFormChange(val, keys) {
      if (keys.find((d) => d === "type")) {
        this.type = val.type;
      }
    },
    beforeRequest(e, params, eventId) {
      params.fields.push("adminUser.nickName");
      return {
        params,
      };
    },
    beforeQuery(e, params, eventId) {
      params[0].orderby = "status desc";
      return {
        params,
      };
    },
  },
};
</script>
