<template>
  <RsConsoleLog :query-conf="props"></RsConsoleLog>
</template>
<script>
export default {
  data: () => {
    return {
      props: {
        query: null, // trigger:xxid | logId:xxxid
        from: null, // 开始时间
        topic: null,
        logStore: "message",
      },
    };
  },
  created() {
    const { id, from, topic, queryKey, logStore } = this.$route.query;
    Object.assign(this.props, { query: `${queryKey}:${id}`, logStore, from, topic });
  },
};
</script>
