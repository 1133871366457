<template>
  <div class="full-height rs-page-tenant-service-list">
    <rs-list name="ten-service" keywords="name,id">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="virtual"></rs-field>
      <rs-field name="lastVersion"></rs-field>
      <rs-field name="info"></rs-field>
      <rs-field name="verNumber" v-slot="row">
        <a v-if="row.verNumber > 0" class="rs-link" @click="toVersionList(row)">{{ row.verNumber }}</a>
        <span v-else>{{ row.verNumber }}</span>
      </rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']" v-slot="row">
        <rs-button name="delete" icon="delete" color="negative" :hidden="row.verNumber > 0"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add']" exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    toVersionList(row) {
      this.$router.push({ path: "ops.serviceVersion._view", query: { serviceId: row.id } });
    },
  },
};
</script>
