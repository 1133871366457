<template>
  <div class="q-pa-sm full-height ops-ten-service-version-form column">
    <rs-form
      ref="form"
      name="ten-service-version"
      class="col-auto full-width"
      @change="onChange"
      :value="{ compatibility: true }"
    >
      <rs-field name="id" hidden></rs-field>
      <rs-field
        name="service"
        :widget="{
          returnAllAttrs: true,
          fields: [
            'id',
            'name',
            'lastVersion',
            'dbless',
            { props: { name: 'gitServer.id', hidden: true } },
            'gitProject',
          ],
        }"
      ></rs-field>
      <rs-field name="service.dbless" hidden></rs-field>
      <rs-field name="service.gitServer.id" hidden></rs-field>
      <rs-field name="service.gitProject" hidden></rs-field>
      <rs-field name="versionType"></rs-field>
      <rs-field name="version"></rs-field>
      <rs-field name="fixVersion" :required="isHotfix" :hidden="!isHotfix"></rs-field>
      <rs-field name="gitBranch" :required="isGitEnable" :hidden="!isGitEnable"></rs-field>
      <rs-field name="compatibility"></rs-field>
      <rs-field name="deployStatus" readonly></rs-field>
      <rs-field name="onlineTime" readonly></rs-field>
      <rs-field name="offlineTime" readonly></rs-field>
      <rs-field
        name="info"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="ops.serviceVersion._view"></rs-button>
        <rs-button name="datas" label="查看版本数据" :hidden="!id"></rs-button>
        <rs-button
          name="refetch"
          label="重新获取版本数据"
          :hidden="!id || hasApproved || refetched || !isGitEnable"
        ></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      id: null,
      isGitEnable: false,
      isHotfix: false,
      hasApproved: true,
      refetched: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          // 审批之后不允许编辑
          this.hasApproved = params.apvStatus === 2;
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      if (!this.id) {
        params.apvStatus = 0;
      }
      if (!this.isGitEnable) {
        params.gitBranch = null;
      }
      if (!this.isHotfix) {
        params.fixVersion = null;
      }
      if (this.gitEnable) {
        e.preventDefault();
        this.$rs_post("/api/deploy/git/branch/check", {
          gitServerId: params.service.gitServer.id,
          gitProject: params.service.gitProject,
          gitBranch: params.gitBranch,
        }).then((resp) => {
          if (resp && resp.code === 0) {
            if (resp.content.result) {
              params.service = {
                id: params.service.id,
              };
              e.next({
                params,
                resolve: ({ response }) => {
                  this.$rs_actionRefresh("ops.serviceVersion._view");
                },
              });
            } else {
              this.$rs_warning("Git分支配置无效：" + resp.content.message || "");
            }
          } else {
            this.$rs_warning("Git分支配置无效：" + resp.message || "");
          }
        });
      } else {
        if (params.service) {
          params.service = {
            id: params.service.id,
          };
        }
        return {
          params,
          resolve: ({ response }) => {
            this.$rs_actionRefresh("ops.serviceVersion._view");
          },
        };
      }
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("service")) {
        this.isGitEnable = val.service && val.service.gitServer;
      }
      if (fireKey.includes("versionType")) {
        this.isHotfix = val.versionType && val.versionType === "hotfix";
      }
    },
    onTenServiceVersionDatas() {
      this.$router.push({
        path: "ops.versionData._view",
        query: { versionId: this.id, disableAdd: this.hasApproved ? "true" : "false" },
      });
    },
    onTenServiceVersionRefetch() {
      this.$rs_post("/api/ops/ten-service/refetchData", {
        id: this.id,
      }).then((resp) => {
        if (resp && resp.code === 0 && resp.content.result) {
          this.$rs_success("触发成功");
          this.refetched = true;
        } else {
          this.$rs_warning("触发失败：" + resp.content.message || resp.message || "");
        }
      });
    },
  },
};
</script>
