<template>
  <div>
    <rs-form name="admin-group-info" label-width="8em" :class="closeDialog && 'no-padding'" class="flex-column" col="1">
      <rs-field name="appId"></rs-field>
      <rs-field name="groupName"></rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";
export default {
  mixins: [RsThirdApiMixins],
  props: ["id", "closeDialog", "on"],
  data() {
    return {};
  },
  methods: {
    // 保存表单
    beforeSubmit(e, params) {
      e.preventDefault();
      this.post("/admin/group/add", { ...params }).then(() => {
        this.$rs_success(this.$t_s("save-success"));
        typeof this.on === "function" && this.on();
        this.closeDialog && this.closeDialog();
      });
    },
  },
};
</script>
