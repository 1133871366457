<template>
  <div class="full-height tenant-menu-list">
    <rs-list name="ten-menu" keywords="id,name,code" :defImportConfig="{ autoInstall: true }">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="id,name,code"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="code"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="pid"></rs-filter>
        <rs-filter name="globals"></rs-filter>
        <rs-filter name="scopes"></rs-filter>
        <rs-field name="module"></rs-field>
        <rs-field name="level"></rs-field>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <!-- <rs-field name="modelId"></rs-field> -->
      <rs-field name="pid"></rs-field>
      <rs-field name="level"></rs-field>
      <rs-field name="globals"></rs-field>
      <rs-field name="scopes"></rs-field>
      <!-- <rs-field name="icon"></rs-field> -->
      <rs-field name="status"></rs-field>
      <rs-field name="module.name"></rs-field>
      <!-- <rs-field name="actions"></rs-field>
      <rs-field name="tenants"></rs-field> -->
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']" v-slot="row">
        <rs-button
          name="addSub"
          :sort="-1"
          action="tenants.menu._add"
          :payload="{ query: { pid: row.id, moduleId: row.module ? row.module.id : '' } }"
          icon="add"
          color="primary"
          auth="add"
        ></rs-button>
        <rs-button name="edit" color="primary" action="tenants.menu._edit"></rs-button>
        <rs-button name="delete" :tips="$t_tenants('menu-deleteTips')"></rs-button>
      </rs-field>
      <rs-toolbar>
        <rs-button name="imports" auth="add"></rs-button>
        <rs-button
          name="treeview"
          color="secondary"
          icon="o_account_tree"
          :label="$t_tenants('menu-treeview')"
        ></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import dlg from "@/dialogs/menu-list/treeview-dlg.vue";
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "recTime desc";
      return {
        params,
      };
    },
    onTenMenuTreeview() {
      this.$rs_dlg({
        parent: this,
        title: "菜单树预览",
        cardStyle: "width: 700px;height: 550px;",
        contentComponent: dlg,
      });
    },
  },
};
</script>
