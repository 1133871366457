<template>
  <div class="q-pa-sm full-height ten-product-form">
    <rs-form ref="form" name="product" :value="{ status: 1 }">
      <rs-field type="group" label="基本信息">
        <rs-field name="id"></rs-field>
        <rs-field name="type"></rs-field>
        <rs-field name="status" readonly></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="image"></rs-field>
        <rs-field name="title" col="12"></rs-field>
      </rs-field>
      <rs-field type="group" label="规格信息">
        <div class="product-spec-list row q-gutter-sm">
          <q-card
            class="product-spec-card"
            v-for="(item, index) in specList"
            :key="index"
            style="width:150px; min-height:80px;"
          >
            <q-card-section class="q-pa-sm">
              <q-item-label>{{ item.name }}</q-item-label>
            </q-card-section>
            <q-separator />
            <q-card-section class="q-pa-sm row q-gutter-sm">
              <q-badge outline color="primary" v-for="(valItem, valIndex) in item.specValues" :key="valIndex">
                {{ valItem.name }}
              </q-badge>
            </q-card-section>
          </q-card>
        </div>
      </rs-field>
      <rs-field type="group" label="SKU列表">
        <rs-list
          ref="skuList"
          name="product-sku"
          class="col"
          :isPagination="false"
          :selection="false"
          :filterHidden="true"
          :conditions="[{ key: 'product.id', vals: [id] }]"
        >
          <rs-field name="id"></rs-field>
          <rs-field name="specs" v-slot="row">
            {{
              Object.keys(row.specs || {})
                .map((d) => {
                  return d + " : " + findSpecVal(d, row.specs[d]);
                })
                .join(", ")
            }}
          </rs-field>
          <rs-field name="price"></rs-field>
          <rs-toolbar exclude="*"></rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      modelService: new ModelService(),
      id: null,
      specList: [],
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    // 请求数据后，给规格值列表赋值
    beforeRequest(e, params) {
      params.fields.push("specs.id", "specs.code", "specs.name", "specs.specValues");
      return {
        params,
        resolve: ({ params }) => {
          const specs = params.specs || [];
          this.specList = specs.map((d) => {
            const spec = pick(d, ["id", "code", "name"]);
            if (d.specValues) {
              spec.specValmap = d.specValues;
              spec.specValues = Object.keys(d.specValues).map((key) => {
                return {
                  code: key,
                  name: d.specValues[key],
                };
              });
            }
            return spec;
          });
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.productInfo._view");
        },
      };
    },

    findSpecVal(key, val) {
      const spec = this.specList.find((r) => key === r.code);
      if (spec && spec.specValmap) {
        return spec.specValmap[val] || val;
      }
      return val;
    },
  },
};
</script>
