<template>
  <div class="full-height rs-page-product-list">
    <rs-list name="product" keywords="name,id">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="type"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="status"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="type.name"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="title"></rs-field>
      <rs-field name="image"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*"></rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
