<template>
  <div class="deal-msg-page bg-grey-2 full-height flex-column" style="height:490px !important" comment="短信充值">
    <div class="q-px-md q-py-sm bg-orange-1">
      <span class="text-red text-bold	q-pr-xs">*</span>
      <span>该定价将用于租户国际短信扣款依据,请谨慎设置!</span>
    </div>
    <q-separator class="full-width " style="flex: 0;" />
    <div class="q-px-md q-pb-md">
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>国家/地区</span>
      </section>
      <section class="row items-center q-py-sm  ">
        {{ this.goods.regionCname }}
      </section>
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>定价</span>
        <span class="text-red text-bold	q-pl-xs">*</span>
      </section>
      <section class="row items-center q-py-sm q-gutter-xs ">
        <q-input
          v-model.number="payModel.regionPltPrice"
          dense
          outlined
          class="rs-size-sm"
          type="number"
          style="width:120px"
        ></q-input>
        <span>元/条</span>
      </section>
      <section>
        <span class="text-red text-bold	q-pl-xs">*</span>
        <span>参考定价 {{ this.goods.initialRsPltPrice }}元/条</span>
      </section>
    </div>

    <!-- <div class="q-px-md q-pb-md">
      // <q-separator class="full-width" />
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>授权码</span>
        <span class="text-red text-bold	q-pl-xs">*</span>
      </section>
      <section class="row items-center  q-py-sm">
        <q-input v-model="payModel.authCode" dense outlined class="rs-size-sm" style="width:120px"></q-input>
        <span class="rs-link q-ml-sm" @click="getAuthCode()">获取授权码</span>
      </section>
      <section class="">
        <span class="text-red text-bold q-pr-xs">*</span>
        <span class="text-caption text-grey">授权码将发送给尾号{{ sendPhoneMessage }}的手机号</span>
      </section>
    </div> -->
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";
const dftModel = {
  regionPltPrice: 0,
  // authCode: null,
};
export default {
  props: ["tenUser", "goods"],
  mixins: [RsThirdApiMixins],
  data() {
    return {
      activeTab: "internal", // "international",
      sendPhoneMessage: "", // 手机尾号
      currentPayItem: null,

      payModel: {
        ...dftModel,
      },
    };
  },
  created() {
    // TODO 手机尾号
    if (this.goods) {
      this.payModel = {
        regionPltPrice: +this.goods.regionPltPrice,
      };
    }
  },
  computed: {},
  methods: {
    submit() {
      const rules = [["regionPltPrice", "请输入定价!"]];

      const invalid = [
        ...rules,
        //  ["authCode", "请输入授权码!"]
      ].some((arr) => {
        const [key, msg] = arr;
        if (!this.payModel[key]) {
          this.$rs_error(msg);
          return true;
        }
        return false;
      });
      if (!invalid) {
        const p = {
          ...this.payModel,
          gorId: this.goods.id,
          updator: this.$rs_user.nickName,
          appId: this.tenUser.appId,
        };
        return this.post(`/admin/sms/region/goods/update`, p);
      }
      return Promise.reject();
    },
    // getAuthCode() {
    //   const internalFlag = this.activeTab === "internal" ? 0 : 1;
    //   const payType = this.payType;
    //   this.post("/admin/sms/pay/auth/code", { gsaId: this.channel.id, internalFlag, payType }).then(() => {
    //     this.$rs_success("短信已发送");
    //   });
    // },
  },
};
</script>
<style>
.deal-msg-page .q-field__bottom {
  padding-left: 0 !important;
}
</style>
