<template>
  <div class="full-height rs-page-ops-ten-version-data-list">
    <rs-list name="ten-service-data" :conditions="dataConditions" keywords="svcVersion.service.id">
      <rs-search label-width="5em">
        <rs-filter name="svcVersion.service"></rs-filter>
        <rs-filter name="svcVersion.version"></rs-filter>
        <rs-filter name="type"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="svcVersion.service.id" label="服务代号"></rs-field>
      <rs-field name="svcVersion.service.name" label="服务名称"></rs-field>
      <rs-field name="svcVersion.version"></rs-field>
      <rs-field name="svcVersion.apvStatus" hidden></rs-field>
      <rs-field name="customize"></rs-field>
      <rs-field name="fileName"></rs-field>
      <rs-field name="filePath"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'delete']" v-slot="row">
        <rs-button
          name="edit"
          action="ops.versionData._edit"
          :payload="{ query: { id: row.id, versionId, disableAdd } }"
        ></rs-button>
        <rs-button name="delete" :hidden="!row.customize || row.svcVersion.apvStatus === 2"></rs-button>
      </rs-field>
      <rs-toolbar :include="disableAdd ? [] : ['add']" exclude="*">
        <rs-button
          name="add"
          color="primary"
          action="ops.versionData._add"
          :payload="{ query: { versionId } }"
        ></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      versionId: null,
      disableAdd: false,
    };
  },
  computed: {
    dataConditions: function() {
      const cons = [];
      if (this.versionId) {
        cons.push({ key: "svcVersion.id", vals: [this.versionId] });
      }
      return cons;
    },
  },
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "id desc";
      return {
        params,
      };
    },
  },
  mounted() {
    this.versionId = this.$route.query.versionId;
    if (this.$route.query.disableAdd) {
      this.disableAdd = this.$route.query.disableAdd.toLowerCase() === "true";
    }
  },
};
</script>
