<script>
import RsDateProps from "rsui-core/src/system/package/date/rs-date-props";
import UpdateEndDateForm from "./update-end-date-form.vue";

export default {
  name: "UpdateEndDate",
  mixins: [RsDateProps],
  props: ["moduleId", "tenId", "endDateRefresh"],
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    // 修改结束时间
    updateEndDate() {
      this.$rs_dlg({
        parent: this,
        title: "修改授权",
        cardStyle: "width:560px",
        showFooter: false,
        contentComponent: UpdateEndDateForm,
        contentComponentProps: {
          defValue: { tenId: this.tenId, module: this.moduleId, endDate: this.value },
          endDateRefresh: this.endDateRefresh, // 修改后，刷新数据
        },
      });
    },
    // 修改履历
    updateEndDateHistory() {
      this.$rs_dlg({
        parent: this,
        title: "修改履历",
        cardStyle: "width: 1050px;height: 500px;",
        toolbar: [
          { name: "confirm", hidden: true },
          { name: "cancel", label: "关闭", color: "primary" },
        ],
        content: (
          <rs-list
            hiddenTop
            indexColumn
            bordered={false}
            selection={false}
            class="no-padding"
            name="ten-module-setting-detail-his"
            conditions={[
              { key: "type", vals: [`${this.moduleId}EndDate`] }, // 租户应用有效期修改
              { key: "tenant.id", vals: [this.tenId] },
            ]}>
            <rs-field name="id" hidden />
            <rs-field name="summary" />
            <rs-field name="remark" />
            <rs-field name="recUser" />
            <rs-field name="recTime" />
          </rs-list>
        ),
      });
    },
  },
  render() {
    const conf = {
      class: "col",
      props: {
        ...this.$props,
      },
    };
    return (
      <div class="row">
        <rs-date {...conf} />
        <q-btn size="sm" label="修改" color="primary" on-click={() => this.updateEndDate()} />
        <q-btn
          flat
          size="sm"
          class="q-ml-sm text-underline"
          label="修改履历"
          color="primary"
          on-click={() => this.updateEndDateHistory()}
        />
      </div>
    );
  },
};
</script>
