<template>
  <div class="full-height rs-page-product-list">
    <div class="full-height">
      <rs-list ref="list" name="product" keywords="name,id" :filter-placeholder="$t_mdata('product-filterPlaceholder')">
        <rs-search label-width="5em">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="price"></rs-filter>
          <rs-filter name="type" front></rs-filter>
          <rs-filter name="brand" front></rs-filter>
          <rs-filter
            front
            name="cls"
            :widget="{
              _type: 'rs-tree-select',
              _extend: true,
              conditions: [{ key: 'ns', vals: ['pdtClass'] }],
            }"
          ></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="type.name"></rs-field>
        <rs-field name="brand.name"></rs-field>
        <!-- <rs-field name="description"></rs-field> -->
        <rs-field name="recTime"></rs-field>
        <rs-field name="recUser"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.product._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary" action="mdata.product._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
