<script>
import RsInputNumber from "rsui-core/src/system/package/input/rs-input-number";
import UnlimitedNumber from "./unlimited-number.vue";

// 借用rs-input-number 的属性
const RsInputNumberProps = RsInputNumber.options.props;
const RsInputNumberPropsMixsn = { props: RsInputNumberProps };

// 许可数组件， 不限 OR 输入数字
export default {
  data() {
    return {
      innerValue: -1, // 默认不限
    };
  },
  mixins: [RsInputNumberPropsMixsn],
  components: { "unlimited-number": UnlimitedNumber },
  props: [],
  watch: {
    value: {
      immediate: true,
      handler: function(newVal) {
        this.innerValue = newVal;
      },
    },
  },
  mounted() {},
  computed: {},
  methods: {
    emitValue() {
      this.$emit("input", this.innerValue);
    },
    validate() {
      return this.$refs.unlimitedNumber.validate();
    },
    resetValidation() {
      this.$refs.unlimitedNumber.resetValidation();
    },
  },
  render() {
    const { value, ...p } = this.$props;
    const conf = {
      ref: "unlimitedNumber",
      class: "col q-ml-md",
      props: {
        ...p,
      },
    };
    return (
      <div class="text-black">
        <div>当订购Rstore时：（在租户信息中进行Rstore订购）</div>
        <div class="row items-center q-mt-xs">
          <span>许可导购数</span>
          <unlimited-number {...conf} v-model={this.innerValue} on-input={() => this.emitValue()} />
        </div>
      </div>
    );
  },
};
</script>
