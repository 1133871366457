<template>
  <div class="q-pa-sm full-height product-class-list">
    <rs-classify namespace="pdtClass">
      <rs-field name="id" />
      <rs-field name="name" />
      <rs-field name="type.name" />
    </rs-classify>
  </div>
</template>
<script>
import RsClassify from "rsui-core/src/core/components/classify/classify.vue";
export default {
  components: { RsClassify },
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
