<template>
  <div class="full-height account-form scroll">
    <rs-form
      ref="form"
      name="user"
      :value="newFormData"
      :isUpdateMode="!!id"
      :restoreByQuery="!!id"
      :col="3"
      @change="onChange"
    >
      <rs-field name="type" :hidden="true"></rs-field>
      <rs-field name="empNo"></rs-field>
      <rs-field name="nickName"></rs-field>
      <rs-field name="mobile"></rs-field>
      <rs-field name="email"></rs-field>
      <rs-field name="comp"></rs-field>
      <rs-field name="adminUser"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="beginDate"></rs-field>
      <rs-field name="endDate"></rs-field>
      <rs-field name="language"></rs-field>
      <!-- <rs-field name="id" :required="false" :label-show="false" :col="12" v-slot>
        <div class="text-subtitle2 col-auto text-black">{{ $t_s("customizeAttrLabel") }}:</div>
      </rs-field>
      <rs-field name="ext" :col="12"></rs-field> -->
      <rs-toolbar>
        <rs-button name="cancel" action="settings.account._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <div class="row q-px-sm" v-if="!adminUser">
      <div class="col">
        <q-list class="q-col-gutter-sm row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <rs-group :label="$t_settings('authDataTitle')" class="bg-white no-padding col-6 ">
              <rs-auth-data ref="authData" type="user" :id="id" :moduleIds="moduleIds" />
            </rs-group>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <rs-group :label="$t_settings('account-actionRole')" class="bg-white no-padding col-6 ">
              <rs-role-list ref="roleList" :id="id" :conditions="[{ key: 'platform.id', vals: [$rs_user.platform] }]" />
            </rs-group>
          </div>
        </q-list>
      </div>
    </div>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import RsRoleList from "rsui-core/src/system/components/role-list/rs-role-list";
import RsAuthData from "rsui-core/src/system/components/auth-data/rs-auth-data";
import pick from "lodash/pick";
import moment from "moment";
import ApiService from "rsui-core/src/system/service/api";
export default {
  components: { RsRoleList, RsAuthData },
  data: function() {
    return {
      id: this.$route.query.id,
      newFormData: { status: 1, type: 1 },
      platformService: new ModelService(),
      moduleIds: [],
      moduleService: new ModelService(),
      adminUser: true,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (!this.id) {
      this.adminUser = false;
      this.initNewAccount();
    }
    this.moduleService.list({ fields: ["id"] }, "module").then((resp) => {
      this.moduleIds = resp.list.map((d) => d.id);
    });
  },
  methods: {
    beforeRequest(e, params) {
      return {
        params,
        resolve: ({ params }) => {
          this.adminUser = params.adminUser;
        },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.find((d) => d === "adminUser")) {
        this.adminUser = val.adminUser;
      }
    },
    initNewAccount() {
      this.$refs.form.core.setStaticValue({
        type: 1, // 1:后台用户,2:接口用户
        platform: {
          id: this.$rs_user.platform,
          name: this.$rs_user.platformName,
        },
        beginDate: moment().format("YYYY-MM-DD"),
        endDate: moment()
          .add(50, "years")
          .format("YYYY-MM-DD"),
      });
    },
    beforeSubmit({ preventDefault }, params) {
      const saveData = this.adminUser
        ? params
        : {
            ...params,
            roles: this.$refs.roleList.getValue(),
          };

      return {
        params: saveData,
        resolve: ({ response }) => {
          let id = this.id;
          if (response && response.content && response.content.id) {
            id = response.content.id;
          }
          if (!this.adminUser) {
            this.$refs.authData.save(id).then(() => {
              this.$rs_actionRefresh("settings.account._view");
            });
          } else {
            this.$rs_actionRefresh("settings.account._view");
          }
          // this.$router.back();
        },
      };
    },
  },
};
</script>
