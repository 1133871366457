<template>
  <div class="full-height">
    <rs-list ref="list" name="menu" v-if="true">
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="type"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="currency"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="pid"></rs-field>
      <rs-field name="sort"></rs-field>
      <rs-field name="icon"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="module.id"></rs-field>
      <rs-toolbar :exclude="['imports', 'exports']">
        <!-- <rs-button name="add" color="primary" action="mdata.book_set"></rs-button>
        <rs-button name="edit" action="mdata.book_set"></rs-button> -->
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    onMenuAdd() {
      this.$router.push("menuForm");
    },
    onMenuEdit(name, e) {
      // this.$refs.list.selected
      this.$router.push({ name: "menuForm", query: { id: this.$refs.list.selected[0].id } });
    },
  },
};
</script>
