<template>
  <div class="q-pa-md">
    <div class="q-gutter-x-md q-gutter-y-md row">
      <q-card
        v-for="(md, index) in list"
        :key="index"
        style="width:8em"
        @mouseenter.native="md.hover = true"
        @mouseleave.native="md.hover = false"
        @click="onclick(md.data)"
        :class="cardStyle(md)"
        class="cursor-pointer"
        v-ripple
      >
        <q-card-section class="text-center" v-if="md.data">
          <q-icon v-if="isRsIcon(md.data.icon)">
            <svg class="rs-icon" aria-hidden="true">
              <use :xlink:href="md.data.icon"></use>
            </svg>
          </q-icon>
          <q-icon v-else color="primary" style="font-size: 3em;" :name="md.data.icon"></q-icon>
          <q-item-label>{{ md.data.name }}</q-item-label>
          <q-item-label caption>{{ md.data.version }}</q-item-label>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<style></style>

<script>
import ApiService from "rsui-core/src/system/service/api";

export default {
  name: "PageHome",
  data: function() {
    return {
      list: [],
    };
  },
  computed: {
    activeModule() {
      return this.$store.getters.activeModule;
    },
  },

  created() {
    this.list = this.$rs_menu.getModules.map((i) => {
      return {
        data: i,
        hover: false,
      };
    });
    this.list.sort((a, b) => {
      const A = a.data.sort;
      const B = b.data.sort;
      const be = isNaN(A) ? 999 : parseInt(A); // TODO
      const af = isNaN(B) ? 999 : parseInt(B);
      return be - af;
    });
    const [firstModule] = this.list || [];
    if (!firstModule) {
      return;
    }
    this.$store.commit("setActiveModule", firstModule.data.id);
    // ApiService.listByModelC("module").then((resp) => {
    //   const list = resp.content.list.map((i) => {
    //     return {
    //       data: i,
    //       hover: false,
    //     };
    //   });
    //   list.sort((a, b) => {
    //     const A = a.data.sort;
    //     const B = b.data.sort;
    //     const be = isNaN(A) ? 999 : parseInt(A); // TODO
    //     const af = isNaN(B) ? 999 : parseInt(B);
    //     return be - af;
    //   });
    //   this.list = list;
    // });
  },
  methods: {
    cardStyle(md) {
      return {
        "no-shadow": !md.hover && this.activeModule !== md.data.id,
        "bg-menu-active": this.activeModule === md.data.id,
      };
    },
    isRsIcon(icon) {
      if (icon && `${icon}`.indexOf("#") === 0) {
        return true;
      }
    },
    onclick(m) {
      this.$store.commit("setActiveModule", m.id);
      this.$store.commit("setMenuShow");
    },
  },
};
</script>
<style>
.rs-icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
}
</style>
