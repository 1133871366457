<template>
  <div class="deal-msg-page bg-grey-2 full-height flex-column" style="" comment="短信充值">
    <div class="q-px-md q-py-sm bg-orange-1">
      <span class="text-red text-bold	q-pr-xs">*</span>
      <span>该定价将出现在租户操作的短信充值选项中,请谨慎设置!</span>
    </div>
    <q-separator class="full-width " style="flex: 0;" />
    <div class="q-px-md q-pb-md">
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>短信定价</span>
      </section>
      <section class="row items-center q-py-sm q-gutter-xs ">
        <span>充值</span>
        <rs-input-number
          v-model.number="payModel.goodsPrice"
          autofocus
          dense
          outlined
          :precision="2"
          :max="99999.99"
          :min="0"
          class="rs-size-sm"
          type="number"
          style="width:120px"
        ></rs-input-number>
        <span>元，到账</span>
        <q-input
          v-model.number="payModel.goodsSmsNumber"
          :hint="hint"
          dense
          outlined
          :max="9999999"
          :min="1"
          class="rs-size-sm"
          type="number"
          style="width:120px"
        ></q-input>
        <span>条短信</span>
      </section>
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>是否推荐</span>
      </section>
      <section class="q-py-sm">
        <q-toggle
          dense
          v-model="payModel.isRecommend"
          :falseValue="0"
          :trueValue="1"
          :label="payModel.isRecommend ? '推荐' : '不推荐'"
        ></q-toggle>
      </section>
    </div>

    <!-- <div class="q-px-md q-pb-md">
      <section class="q-pt-sm q-pb-xs text-bold	">
        <span>授权码</span>
        <span class="text-red text-bold	q-pl-xs">*</span>
      </section>
      <section class="row items-center  q-py-sm">
        <q-input v-model="payModel.authCode" dense outlined class="rs-size-sm" style="width:120px"></q-input>
        <span class="rs-link q-ml-sm" @click="getAuthCode()">获取授权码</span>
      </section>
      <section class="">
        <span class="text-red text-bold q-pr-xs">*</span>
        <span class="text-caption text-grey">授权码将发送给尾号{{ sendPhoneMessage }}的手机号</span>
      </section> 
    </div>-->
  </div>
</template>
<script>
import RsThirdApiMixins from "@/dialogs/sms-mixins.vue";
const dftModel = {
  goodsPrice: null,
  goodsSmsNumber: null,
  remarks: null,
  authCode: null,
  isRecommend: 0,
  goodsState: null,
};
export default {
  props: ["tenUser", "goods"],
  mixins: [RsThirdApiMixins],
  data() {
    return {
      activeTab: "internal", // "international",
      sendPhoneMessage: "", // 手机尾号
      currentPayItem: null,

      payModel: {
        ...dftModel,
      },
    };
  },
  created() {
    // TODO 手机尾号
    if (this.goods) {
      this.payModel = {
        goodsPrice: +this.goods.goodsPrice,
        goodsSmsNumber: +this.goods.goodsSmsNumber,
        goodsState: this.goods.goodsState,
        authCode: null,
        isRecommend: this.goods.isRecommend == 1 ? 1 : 0,
        goodsId: this.goods.id,
      };
    }
  },
  computed: {
    per() {
      const m = parseInt(this.payModel.goodsPrice);
      const t = parseInt(this.payModel.goodsSmsNumber);
      const per = t > 0 ? (m / t).toFixed(3) : "";
      return per;
    },
    hint() {
      return this.per ? (!isNaN(this.per) ? `单价: ${this.per} 元/条` : "") : "";
    },
  },
  methods: {
    submit() {
      const rules = [
        ["goodsPrice", "请输入充值金额!"],
        ["goodsSmsNumber", "请输入到账条数!"],
        // ["remarks", "请输入充值说明!"],
      ];
      const ruleAuthcode = ["authCode", "请输入授权码!"];
      const invalid = [...rules].some((arr) => {
        const [key, msg] = arr;
        if (!this.payModel[key]) {
          this.$rs_error(msg);
          return true;
        }
        return false;
      });
      if (!invalid) {
        const updateMode = !!this.goods;
        const p = {
          ...this.payModel,
          goodsName: this.payModel.goodsSmsNumber + "",
          goodsState: 1,
          creator: this.$rs_user.nickName,
          updator: this.$rs_user.nickName,
          appId: this.tenUser.appId,
        };
        if (updateMode) {
          delete p.creator;
        }
        return this.post(`/admin/sms/account/goods/${!updateMode ? "add" : "update"}`, p);
      }
      return Promise.reject();
    },
    getAuthCode() {
      const internalFlag = this.activeTab === "internal" ? 0 : 1;
      const payType = this.payType;
      this.post("/admin/sms/pay/auth/code", { gsaId: this.channel.id, internalFlag, payType }).then(() => {
        this.$rs_success("短信已发送");
      });
    },
  },
};
</script>
<style>
.deal-msg-page .q-field__bottom {
  padding-left: 0 !important;
}
</style>
