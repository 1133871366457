<template>
  <div class="q-pa-lg">
    <div class="text-h5 q-mb-xl q-ml-md">{{ title }}</div>
    <div class="row items-center q-mb-sm" v-for="(item, index) in list" :key="index">
      <div class="col text-right q-mr-md">{{ item.label }}</div>
      <div class="col">
        <span :class="item.numClass ? item.numClass : 'text-grey-10 text-h6'">{{ item.number }}</span>
        &nbsp;个店铺
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      title: "RStore（店铺助手） V1.4",
      list: [
        { label: "企业授权许可：", number: 800 },
        { label: "我的平台已使用：", number: 260 },
        { label: "分销商平台已授权：", number: 300 },
        { label: "剩余可分配授权：", number: 240, numClass: "text-light-blue text-h6" },
        { label: "当前分销商平台授权：", number: 260, numClass: "text-light-blue text-h6" },
        { label: "当前分销商平台已使用：", number: 80 },
      ],
    };
  },
  methods: {},
};
</script>
