<template>
  <div class="q-pa-sm full-height tenant-domain-form">
    <rs-form ref="form" name="ten-api-user" @change="onChange">
      <rs-field name="id" :required="!!id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="apiSecret" :hidden="!!id"></rs-field>
      <rs-field name="globals"></rs-field>
      <rs-field name="tenant" v-bind="tenantProps"></rs-field>
      <rs-field name="trustIps" :widget="{ _type: 'rs-chip-select' }"></rs-field>
      <rs-field name="expire" :widget="{ suffix: '秒' }"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field
        name="config"
        col="12"
        :rules="[configRule]"
        :widget="{ _type: 'rs-ace-editor', height: '200px', lang: 'json' }"
      ></rs-field>
      <!-- :widget="{ _type: 'rs-code-mirror', height: '200px', option: { mode: 'application/json' } }" -->
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.apiuser._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      tenantProps: {},
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      if (!params.config) {
        params.config = null;
      }
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.apiuser._view");
        },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("globals")) {
        this.tenantProps = { disable: !!val.globals };
        if (val.globals) {
          setTimeout(() => {
            core.setValue({ tenant: null });
          });
        }
      }
    },
    // config JSON 验证规则添加， 如果value 是字符串类型 说明JSON格式是错误的，因为在rs-code-mirror组件里如果是正确的会转换成object
    configRule(rule, value, callback) {
      if (value && typeof value === "string") {
        callback(new Error(this.$t_s("json-error")));
      } else {
        callback();
      }
    },
  },
};
</script>
