<template>
  <div class="q-pa-sm full-height xxl-job-log-form">
    <rs-form ref="form" name="xxl-job-log">
      <rs-field name="id"></rs-field>
      <rs-field name="mutex"></rs-field>
      <rs-field name="jobInfo.id"></rs-field>
      <rs-field name="jobGroup"></rs-field>
      <rs-field name="alarmStatus"></rs-field>
      <rs-field name="handleMsg"></rs-field>
      <rs-field name="handleCode"></rs-field>
      <rs-field name="handleTime"></rs-field>
      <rs-field name="executorAddress"></rs-field>
      <rs-field name="executorHandler"></rs-field>
      <rs-field name="triggerCode"></rs-field>
      <rs-field name="triggerTime"></rs-field>
      <rs-field name="executorFailRetryCount"></rs-field>
      <rs-field name="executorShardingParam"></rs-field>
      <rs-field
        col="12"
        name="triggerMsg"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-field
        col="12"
        name="executorParam"
        :widget="{ _type: 'input', inputStyle: { height: '80px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="submit" :hidden="!!id"></rs-button>
        <rs-button name="cancel" action="matrix.jobLog._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
