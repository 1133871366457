<template>
  <div class="q-pa-sm full-height book-set rs-page-retail-form scroll">
    <rs-form name="biz-subject" :isUpdateMode="!!id" label-width="6.5em" :value="{ type: 'DS' }" @change="onFormChange">
      <rs-field :label="$t_mdata('retail-base')" type="group">
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="channel"></rs-field>
        <rs-field name="type" :hidden="true"></rs-field>
        <rs-field name="pset" :disable="psetWidget.disable" :widget="psetWidget"></rs-field>
        <rs-field name="bset" :disable="bsetWidget.disable" :widget="bsetWidget"></rs-field>
        <rs-field name="taxRate" :widget="{ _type: 'rs-percent-input', _extend: true }" suffix="%"></rs-field>
        <rs-field name="overseas"></rs-field>
        <rs-field name="status"></rs-field>
      </rs-field>
      <rs-field :label="$t_mdata('retail-channel')" type="group">
        <rs-field name="invoice" :disable="invoiceWidget.disable" :widget="invoiceWidget"></rs-field>
        <rs-field name="account" :disable="accountWidget.disable" :widget="accountWidget"></rs-field>
        <rs-field name="brands" :disable="brandWidget.disable" :widget="brandWidget"></rs-field>
      </rs-field>
      <rs-field :label="$t_s('customizeAttrLabel')" type="group">
        <rs-field name="ext" :col="12"></rs-field>
      </rs-field>
      <rs-field :label="$t_mdata('retail-control')" type="group">
        <rs-field name="settleType"></rs-field>
      </rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.retail._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <q-list class="q-px-sm">
      <rs-group v-if="!!id && listCondition" :label="$t_mdata('retail-siteList')">
        <rs-list ref="list" name="site" :conditions="listCondition">
          <rs-field name="id"></rs-field>
          <rs-field name="name"></rs-field>
          <rs-field name="type"></rs-field>
          <rs-toolbar :exclude="['add', 'delete', 'deleteAll', 'edit', 'imports', 'exports']"></rs-toolbar>
        </rs-list>
      </rs-group>
    </q-list>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      id: null,
      psetWidget: { conditions: [], disable: true },
      bsetWidget: { conditions: [{ key: "type", vals: ["DS"] }], disable: true },
      brandWidget: { conditions: [], disable: true },
      invoiceWidget: { conditions: [], disable: true },
      accountWidget: { conditions: [], disable: true },
      listCondition: null,
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    /**
     * 基本信息表单change事件
     */
    onFormChange(val, fireKey, core) {
      // 先选择分销渠道才能选择上级结算主体和结算主体以及经营品牌
      if (fireKey.find((d) => d === "channel")) {
        // 分销渠道有值时，设置上级结算主体、结算主体、经营品牌的过滤条件
        if (val.channel && val.channel.id) {
          this.psetWidget = { conditions: [{ key: "subs.channel.id", vals: [val.channel.id] }], disable: false };
          this.bsetWidget = {
            conditions: [
              { key: "type", vals: ["DS"] },
              { key: "subs", op: "relNotExists", vals: [{ key: "rel.channelId", vals: [val.channel.id] }] },
            ],
            disable: false,
          };
          this.brandWidget = {
            conditions: [{ key: "channels.id", vals: [val.channel.id] }],
            disable: false,
          };
          // 防止修改页面初始化时设空，分销渠道修改时，清空上级结算主体、结算主体、经营品牌的值
          if (fireKey.length === 1) {
            setTimeout(() => {
              core.setValue({ pset: null, bset: null, brands: null });
            });
          }
        } else {
          // 分销渠道为空时，清空上级结算主体、结算主体、经营品牌的值
          this.psetWidget = { disable: true };
          this.bsetWidget = {
            conditions: [{ key: "type", vals: ["DS"] }],
            disable: true,
          };
          this.brandWidget = {
            disable: true,
          };
          setTimeout(() => {
            core.setValue({ pset: null, bset: null, brands: null });
          });
        }
      }
      // 需求：下拉选择上级结算主体的开票资料或者收支账户
      if (fireKey.find((d) => d === "pset")) {
        // 如果上级结算主体为空时，清空开票资料和收支账户的值，并设置disabled=true;
        if (!val.pset || !val.pset.id) {
          this.invoiceWidget = { disable: true };
          this.accountWidget = { disable: true };
          setTimeout(() => {
            core.setValue({ invoice: null, account: null });
          });
        } else {
          // 如果上级结算主体不为空时，设置开票资料和收支账户的过滤条件【bset.id === val.pset.id】，并设置disabled=false;
          this.invoiceWidget = { disable: false, conditions: [{ key: "bset.id", vals: [val.pset.id] }] };
          this.accountWidget = { disable: false, conditions: [{ key: "bset.id", vals: [val.pset.id] }] };
          // 如果上级结算主体发生选择变化时【fireKey.length === 1】,清空开票资料和收支账户的值
          if (fireKey.length === 1) {
            setTimeout(() => {
              core.setValue({ invoice: null, account: null });
            });
          }
        }
      }
    },
    // 当编辑分销商时，设置业务网点过滤条件
    beforeRequest(e, params) {
      return {
        params,
        resolve: ({ params }) => {
          if (params.bset && params.channel) {
            this.listCondition = [
              { key: "bset.id", vals: [params.bset.id] },
              { key: "channels.channel.id", vals: [params.channel.id] },
            ];
          }
        },
      };
    },
    // 保存时默认设置类型是分销商，保存成功后关闭页面
    beforeSubmit(e, params) {
      params.type = "DS";
      return {
        params,
        resolve: ({ response }) => {
          if (response && response.content && response.content.id) {
            this.id = response.content.id;
          }
          this.$rs_actionRefresh("mdata.retail._view");
        },
      };
    },
  },
};
</script>
