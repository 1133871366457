<template>
  <div class="full-height scroll">
    <rs-form name="role" :col="2">
      <rs-field name="id" :hidden="!id" :disable="!!id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field
        name="platform"
        :value="{ id: $rs_user.platform, name: $rs_user.platformName }"
        :readonly="true"
      ></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="sysReserved" hidden></rs-field>
      <rs-field name="description"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="settings.role._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <q-list class="q-pa-sm">
      <rs-group :label="$t_settings('authActionTitle')" class=" ">
        <auth-tab ref="tab" tabs-class=" " :ids="restoreIds"></auth-tab>
      </rs-group>
    </q-list>
  </div>
</template>
<script>
import AuthTab from "rsui-core/src/system/components/auth-tree/rs-auth-tab";
import ApiService from "rsui-core/src/system/service/api";
export default {
  components: {
    AuthTab,
  },
  data: function() {
    return {
      id: this.$route.query.id,
      restoreIds: [],
      currentRefItem: null,
    };
  },
  computed: {},
  created() {
    if (this.id) {
      ApiService.grantMenuIds({ role: this.id }).then((res) => (this.restoreIds = res));
    }
  },
  methods: {
    beforeSubmit({ preventDefault }, params) {
      // preventDefault();
      const menusAndModules = this.$refs.tab.getValue();
      return {
        params: { ...params, menus: menusAndModules.menus },
        resolve: () => {
          this.$rs_actionRefresh("settings.role._view");
        },
      };
    },
  },
};
</script>
