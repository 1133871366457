<template>
  <rs-form class="full-height flex-column tenant-form" ref="baseForm" name="tenant" labelWidth="11em" :col="2">
    <rs-toolbar>
      <rs-button name="submit" />
    </rs-toolbar>
    <rs-field type="group" :label="$t_tenants('ten-baseInfo')">
      <rs-field name="id" :hidden="!id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="uname"></rs-field>
      <rs-field name="principal"></rs-field>
      <rs-field name="deployMode"></rs-field>
      <rs-field name="contact"></rs-field>
      <rs-field name="mobile"></rs-field>
      <rs-field name="email"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="bset_id"></rs-field>
      <rs-field name="businessEmails"></rs-field>
      <rs-field name="timelyChangePassword"></rs-field>
      <rs-field name="sessionTimeout" :widget="{ min: 60 }"></rs-field>
      <rs-field name="isGray"></rs-field>
    </rs-field>
    <rs-field type="group" label="常用入口" :hidden="!id">
      <div class="ten-quick-link row full-width text-secondary">
        <q-card-section
          align="center"
          class="cursor-pointer q-ma-sm q-pa-none"
          @click="() => quickLink('tenants.modules._order')"
        >
          <q-icon name="print" size="lg" />
          <q-item-label>订购新模块</q-item-label>
        </q-card-section>
        <q-card-section
          align="center"
          class="cursor-pointer q-ma-sm q-pa-none"
          @click="() => quickLink('tenants.domain._view')"
        >
          <q-icon name="print" size="lg" />
          <q-item-label>租户域名</q-item-label>
        </q-card-section>
        <q-card-section
          align="center"
          class="cursor-pointer q-ma-sm q-pa-none"
          @click="() => quickLink('tenants.orders._view')"
        >
          <q-icon name="print" size="lg" />
          <q-item-label>历史订单</q-item-label>
        </q-card-section>
        <q-card-section
          align="center"
          class="cursor-pointer q-ma-sm q-pa-none"
          @click="() => quickLink('tenants.tenant._menu')"
        >
          <q-icon name="print" size="lg" />
          <q-item-label>租户菜单</q-item-label>
        </q-card-section>
        <q-card-section align="center" class="cursor-pointer q-ma-sm q-pa-none" @click="() => onTenMenuTreeview()">
          <q-icon name="print" size="lg" />
          <q-item-label>租户菜单预览</q-item-label>
        </q-card-section>
      </div>
    </rs-field>
    <rs-field type="group" :label="$t_tenants('ten-authApp')" :hidden="!id">
      <div class="ten-module-list" v-for="(item, index) in moduleList" :key="index">
        <q-card class="ten-module-card" style="width:190px; height:120px;">
          <q-btn
            flat
            round
            color="primary"
            icon="settings"
            size="sm"
            class="absolute-top-right"
            style="z-index: 99;"
            @click="() => editModuleInfo(item)"
          ></q-btn>
          <q-badge :color="findModuleState(item.status).color" class="absolute-top-left">
            {{ findModuleState(item.status).text }}
          </q-badge>
          <q-card-section align="center" class="q-pa-sm">
            <q-icon class="rs-icon">
              <svg aria-hidden="true">
                <use :xlink:href="item.module.icon" />
              </svg>
            </q-icon>
            <q-item-label>{{ item.module.name }}</q-item-label>
            <q-badge outline align="middle" color="primary">{{ item.version }}</q-badge>
          </q-card-section>
          <q-card-section class="q-pa-none" align="center">
            <div class="text" v-if="item.endDate">{{ item.startDate }} ~ {{ item.endDate }}</div>
          </q-card-section>
        </q-card>
      </div>
    </rs-field>
  </rs-form>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
import moment from "moment";
import TenMenuTreeViewDlg from "./tenMenuTreeView-dlg.vue";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      moduleList: [], // 授权应用list数据
      modelService: new ModelService(),
      moduleStatus: [
        {
          code: 0,
          color: "indigo",
          text: "已购买",
        },
        {
          code: 2,
          color: "orange",
          text: "未安装",
        },
        {
          code: 4,
          color: "green",
          text: "已安装",
        },
        {
          code: 6,
          color: "red",
          text: "已过期",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    if (this.id) {
      this.getTenModuleInfoList();
    }
  },
  methods: {
    // 获取初始化的授权应用数据
    getTenModuleInfoList() {
      this.modelService
        .list(
          {
            conditions: [{ key: "tenant.id", vals: [this.id] }],
            fields: [
              "id",
              "module.id",
              "module.name",
              "module.type",
              "module.icon",
              "startDate",
              "endDate",
              "status",
              "version",
            ],
            orderby: "sort",
          },
          "ten-module-info",
        )
        .then((resp) => {
          if (resp && resp.list) {
            this.moduleList = resp.list.map((d) => {
              if (d.endDate && moment().isAfter(d.endDate, "day")) {
                d.status = 6;
              }
              return d;
            });
          }
        });
    },
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.tenant._view");
        },
      };
    },
    // 取消
    cancel() {
      this.$rs_actionRefresh("tenants.tenant._view");
    },

    editModuleInfo(params) {
      this.$router.push({ path: "tenants.tenModuleInfo._edit", query: { id: params.id, tenantId: this.id } });
    },

    quickLink(path) {
      this.$router.push({ path, query: { tenantId: this.id } });
    },

    findModuleState(stat) {
      return this.moduleStatus.find((d) => d.code === stat);
    },

    onTenMenuTreeview() {
      this.$rs_dlg({
        parent: this,
        title: "租户菜单预览",
        cardStyle: "width: 700px;height: 550px;",
        contentComponentProps: { tenantId: +this.id },
        contentComponent: TenMenuTreeViewDlg,
      });
    },
  },
};
</script>
