<template>
  <div class="q-gutter-x-md">
    <q-radio
      v-for="r in radioOpts"
      :key="r.val"
      size="sm"
      dense
      :val="r.val"
      :label="r.label"
      :value="radioValue"
      @input="onRadioInput"
    />
  </div>
</template>
<script>
export default {
  name: "WidgetSettingMethod",
  props: {
    row: { type: Object, default: () => {} },
  },
  data() {
    return {
      radioOpts: [],
      radioValue: null,
    };
  },
  created() {
    let opts = [];
    switch (this.row?.code) {
      case "EYE_SALE_TARGET":
        opts = [
          { val: "DRP", label: "从DRP获取" },
          { val: "EYE", label: "自行配置" },
        ];
        break;
      case "EYE_RECRUIT_TARGET":
        opts = [
          { val: "CRM", label: "从CRM获取" },
          { val: "EYE", label: "自行配置" },
        ];
        break;
    }
    this.radioOpts = opts;
    this.radioValue = this.row?.ext?.SETTING_METHOD;
  },
  computed: {},
  methods: {
    onRadioInput(e) {
      this.radioValue = e;
      const val = { ...this.row, ext: { SETTING_METHOD: e } };
      this.$emit("componentValueInput", val);
    },
  },
};
</script>
