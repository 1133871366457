<template>
  <div class=" q-pa-sm full-height rs-page-trade-account">
    <div class="full-height bg-white flex-column">
      <div class="row items-center q-pt-sm q-guitter-sm flex-no-grow">
        <span class="q-mx-sm">所属模型：</span>
        <rs-obj-select
          dense
          size="sm"
          style="max-width: 12em"
          v-model="selectModel"
          :model="modelModel"
          :canAdd="false"
          :conditions="[{ key: 'customize', vals: [true] }]"
          @input="modelInput"
        />
        <span class="q-mx-sm">动态表单值：</span>
        <rs-select
          style="max-width: 14em"
          v-model="selectDynm"
          :data="dynmOptions"
          dense
          outlined
          :multiple="false"
          @input="dynmChange"
        />
      </div>
      <rs-list
        name="field"
        class="no-margin flex-grow"
        keywords="name,propName"
        :conditions="listConditions"
        :loadDefault="false"
        :filter-placeholder="$t_mdata('field-filterPlaceholder')"
      >
        <rs-field name="id"></rs-field>
        <rs-field name="name" />
        <rs-field name="model.name" />
        <!-- 只要简单类型[排除 many] -->
        <rs-field name="type" />
        <rs-field name="required" />
        <!-- 类型是通用选项要selectionId -->
        <rs-field name="selectionId" />
        <rs-field name="propName" required />
        <rs-field name="size" />
        <!-- 数字要它       -->
        <rs-field name="precision" />
        <rs-field name="imports" />
        <rs-field name="exports" />
        <rs-field name="updatable" />
        <rs-field name="remark" />
        <rs-field name="recTime"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="edit" action="mdata.customizeField._edit"></rs-button>
        </rs-field>
        <rs-toolbar>
          <rs-button name="add" color="primary" action="mdata.customizeField._add"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<script>
import ApiService from "rsui-core/src/system/service/api";
import cloneDeep from "lodash/cloneDeep";
import { FIELD_TYPE } from "rsui-core/src/core/field";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      modelModel: null, // 所属模型model 用于生成rs-obj-select组件
      dynmOptions: [], // 动态表单下拉数据
      selectModel: null, // 选择的所属模型
      selectDynm: null, // 动态表单绑定的值
      listConditions: [{ key: "model.customize", vals: [true] }],
    };
  },
  computed: {},
  created() {
    ApiService.metaModel("model").then((resp) => {
      if (resp && resp.content) {
        this.modelModel = resp.content;
      }
    });
  },
  methods: {
    dynmChange(e) {
      this.selectDynm = e;
      const cond = [{ key: "model.customize", vals: [true] }];
      if (e) {
        cond.push({ key: "dynmFormVals", op: OP_TYPE.ARR_HAS, vals: [this.selectDynm] });
      } else if (this.selectModel) {
        cond.push({ key: "model.id", vals: [this.selectModel.id] });
      }
      this.listConditions = cond;
    },
    modelInput(e) {
      this.selectDynm = null;
      const cond = [{ key: "model.customize", vals: [true] }];
      this.selectModel = e;
      if (e && e.id) {
        cond.push({ key: "model.id", vals: [e.id] });
        const realModel = e.id.substring(0, e.id.length - 4); // 获取"product-ext"的真正关联model:"product"
        ApiService.metaModel(realModel).then((resp) => {
          if (resp && resp.content && resp.content.dynmFormKey) {
            const field = resp.content.fields.find((d) => d.propName === resp.content.dynmFormKey);
            if (field.type === FIELD_TYPE.SELECTION) {
              ApiService.option(field.selectionId).then(({ content }) => {
                if (content) {
                  const items = cloneDeep(content.items || []);
                  items.forEach((d) => (d.code = `${d.code}`));
                  items.sort((a, b) => a.sort - b.sort);
                  this.$set(this, "dynmOptions", items);
                }
              });
            } else if (field.type === FIELD_TYPE.MANY2ONE) {
              ApiService.metaModel(field.relation).then((res) => {
                if (res && res.content) {
                  const metaModel = res.content;
                  return ApiService.allListByModel(metaModel, {
                    fields: ["id", metaModel.recName],
                  }).then((r) => {
                    if (r && r.content && r.content.list) {
                      const data = r.content.list.map((d) => {
                        return {
                          code: `${d.id}`,
                          name: d[metaModel.recName],
                        };
                      });
                      this.$set(this, "dynmOptions", data);
                    }
                  });
                }
              });
            }
          }
        });
      } else {
        this.$set(this, "dynmOptions", []);
      }
      this.listConditions = cond;
    },
  },
};
</script>
