<template>
  <div class="full-height rs-page-biz-provider-list">
    <rs-list
      ref="list"
      name="mock-corpLoginInfo"
      keywords="wx_corp_id,wx_open_user_id,bind_phone_number,corp_emp_list.0.corp_id,corp_emp_list.0.emp_code"
      :filter-eq="[
        'wx_corp_id',
        'wx_open_user_id',
        'bind_phone_number',
        'corp_emp_list.0.corp_id',
        'corp_emp_list.0.emp_code',
      ]"
      filter-placeholder="请输入微信CORPID、登陆用户ID、绑定手机号、雇员信息进行精确检索"
    >
      <rs-search>
        <rs-filter name="wx_corp_id"></rs-filter>
        <rs-filter name="wx_open_user_id"></rs-filter>
        <rs-filter name="bind_phone_number"></rs-filter>
        <rs-filter name="corp_emp_list.0.corp_id"></rs-filter>
        <rs-filter name="corp_emp_list.0.emp_code"></rs-filter>
      </rs-search>
      <rs-field name="id" hidden></rs-field>
      <rs-field name="wx_corp_id" v-slot="row">{{ row.wx_corp_id ? row.wx_corp_id : "weixin" }}</rs-field>
      <rs-field name="wx_app_id"></rs-field>
      <rs-field name="wx_open_user_id"></rs-field>
      <rs-field name="bind_phone_number" align="center"></rs-field>
      <rs-field name="platform" align="center"></rs-field>
      <rs-field name="corp_emp_list.0.corp_id" v-slot="row">
        {{ getCorpId(row) }}
      </rs-field>
      <rs-field name="corp_emp_list.0.emp_code" v-slot="row">
        {{ getCorpName(row) }}
      </rs-field>
      <rs-field name="created_at" label="首次登陆时间"></rs-field>
      <rs-field name="updated_at"></rs-field>
      <rs-field type="toolbar" :include="['detail']" :exclude="['delete']" v-slot="props">
        <rs-button name="edit" :payload="{ query: { id: props._id } }" />
      </rs-field>
    </rs-list>
  </div>
</template>
<script>
import Config from "rsui-core/src/config";
import moment from "moment";

export default {
  components: {},
  data: function() {
    return {
      // tenantId: null,
      // jobGroup: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 列表数据变化时，call外部接口返回数据
    beforeQuery(e, params, { model, key }) {
      e.preventDefault();
      const { page, size, conditions } = params[0];
      this.$refs.list.loading = true;
      this.$rs_post(Config.API.RSTORE_CORP_LOGIN, {
        page,
        size,
        conditions,
        orderby: "created_at desc",
      })
        .then(
          (resp) => {
            if (resp?.content) {
              resp.content.list.forEach((d) => {
                d.id = d._id;
                d.created_at = moment(d.created_at).format("YYYY-MM-DD hh:mm:ss");
                d.updated_at = moment(d.updated_at).format("YYYY-MM-DD hh:mm:ss");
              });
              this.$refs.list.onQueryrSuccess(resp.content);
            }
          },
          (e) => this.$rs_error(e?.msg || "接口错误"),
        )
        .finally(() => (this.$refs.list.loading = false));
    },
    getCorpId(row) {
      return row?.corp_emp_list?.[0]?.corp_id;
    },
    getCorpName(row) {
      return row?.corp_emp_list?.length
        ? row?.corp_emp_list?.[0]?.emp_code + "/" + row?.corp_emp_list?.[0]?.emp_name
        : "无";
    },
  },
};
</script>
