<template>
  <rs-list name="template-user" keywords="name" :filter-placeholder="$t_settings('templateUser-filterPlaceholder')">
    <rs-search label-width="5em" :value="{ status: 1 }">
      <rs-filter type="keywords" name="name,platform.id"></rs-filter>
      <rs-filter name="id"></rs-filter>
      <rs-filter name="status"></rs-filter>
      <rs-filter name="crtTime"></rs-filter>
      <rs-filter name="crtUser"></rs-filter>
      <rs-filter name="recTime"></rs-filter>
      <rs-filter name="recUser"></rs-filter>
    </rs-search>
    <rs-field name="id"></rs-field>
    <rs-field name="name"></rs-field>
    <rs-field name="platform.name"></rs-field>
    <rs-field name="info"></rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field type="toolbar">
      <rs-button name="edit" action="settings.acTemplate._edit"></rs-button>
    </rs-field>
    <rs-toolbar>
      <rs-button name="add" color="primary" action="settings.acTemplate._add"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      isSub: this.$rs_user.isSub,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
