<template>
  <div class="q-pa-sm full-height tenant-biz-provider">
    <rs-form ref="form" name="biz-provider">
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="id"></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field name="info"></rs-field>
        <rs-field name="assignUid"></rs-field>
      </rs-field>
      <rs-field type="group" ignore name="services">
        <rs-cascades :loadDefault="id" :disable="true">
          <rs-field name="code"></rs-field>
          <rs-field name="name"></rs-field>
        </rs-cascades>
      </rs-field>
      <rs-toolbar :exclude="['submit']"></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
