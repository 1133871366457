<template>
  <div class="q-pa-sm full-height ops-deploy-full-upgrade-form">
    <rs-form
      ref="form"
      name="ten-deploy"
      :value="{ tenantMode: 'saas', type: 'upgrade', upgradeType: 'full', upgradeMode: 1 }"
    >
      <rs-field type="group" :label="$t_s('baseInfo')">
        <rs-field name="tenantMode" readonly></rs-field>
        <rs-field name="type" readonly></rs-field>
        <rs-field name="upgradeType" readonly></rs-field>
        <rs-field name="upgradeMode" hidden></rs-field>
        <rs-field name="srcDeploy" :required="srcDeployId" readonly :hidden="!srcDeployId"></rs-field>
        <rs-field name="shouldHalt"></rs-field>
        <rs-field name="status" readonly></rs-field>
        <rs-field name="name"></rs-field>
        <rs-field
          name="info"
          col="12"
          :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
        ></rs-field>
      </rs-field>
      <rs-field type="group" name="services">
        <rs-list
          ref="list"
          name="ten-deploy-service"
          class="full-width"
          :data="serviceList"
          :loadDefault="false"
          hideBottom
          :selection="false"
        >
          <rs-field name="service.id" label="微服务代号"></rs-field>
          <rs-field name="service.name" label="微服务名称"></rs-field>
          <rs-field name="version"></rs-field>
          <rs-field type="toolbar" :include="disable || srcDeployId ? [] : ['delete']" exclude="*"></rs-field>
          <rs-toolbar :include="disable || srcDeployId ? [] : ['addItem']" exclude="*">
            <rs-button name="addItem" icon="add" color="primary" :label="$t_s('add')"></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-field>
      <rs-toolbar>
        <rs-button name="submit" auth="grayAdd"></rs-button>
        <rs-button name="cancel" action="ops.saasUpgrade._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      srcDeployId: this.$route.query.srcDeployId,
      serviceList: [],
      modelService: new ModelService(),
      deployService: new ModelService(),
      disable: true, // 服务列表上 按钮是否disable
    };
  },
  mounted() {
    if (this.id || this.srcDeployId) {
      // 查询已经保存的微服务列表
      this.fetchServiceList(this.id || this.srcDeployId);
    } else {
      // 这里时新建情况
      this.disable = false;
    }

    if (!this.id && this.srcDeployId) {
      this.$refs.form.core.setValue({ upgradeMode: 2 });
      this.deployService.refs([this.srcDeployId], { fields: ["id", "name"] }, "ten-deploy").then((resp) => {
        if (resp && resp[0]) {
          this.$refs.form.core.setValue({ srcDeploy: resp[0] });
        }
      });
    }
  },
  methods: {
    fetchServiceList(deployId) {
      this.modelService
        .list(
          {
            fields: ["version", "service.id", "service.name"],
            page: 1,
            size: -1,
            conditions: [{ key: "deploy.id", vals: [deployId] }],
          },
          "ten-deploy-service",
        )
        .then((resp) => {
          this.serviceList = resp.list || [];
        });
    },
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          if (params.srcDeploy && params.srcDeploy.id) {
            this.srcDeployId = params.srcDeploy.id;
          }
          // 提交/审批之后不允许编辑
          this.disable = params.apvStatus === 2 || params.apvStatus === 1;
        },
      };
    },
    // 保存表单时
    beforeSubmit(e, params) {
      if (!this.id) {
        params.status = 1;
        params.apvStatus = 0;
      }
      const firstAddFromDeploy = !this.id && !!this.srcDeployId;
      return {
        params,
        resolve: ({ response }) => {
          if (firstAddFromDeploy || (!this.srcDeployId && !this.disable)) {
            const id = response && response.content && response.content.id ? response.content.id : this.id;
            this.$refs.form.modelService.cascade(
              "services",
              id,
              this.serviceList.map((d) => pick(d, ["service.id", "version"])),
              "ten-deploy",
            );
          }
          this.$rs_actionRefresh("ops.saasUpgrade._view");
        },
      };
    },
    onTenDeployServiceAddItem(e) {
      e.preventDefault();
      const existServiceIds = this.serviceList.map((d) => d.service.id);
      this.$rs_model_dlg(
        {
          modelName: "ten-service-version",
          multiple: true,
          conditions: [
            { key: "apvStatus", vals: [2] }, //服务已审批
            { key: "versionType", vals: ["stable"] }, //beta版本
            { key: "deployStatus", vals: [0] },
            { key: "service.id", op: OP_TYPE.NE, vals: existServiceIds },
          ],
          fields: ["service.id", "service.name", "version", "deployStatus", "recTime"],
        },
        {
          beforeEvent: ({ name, e, selected, dlg }) => {
            // 点击是dlg 确定按钮且selected没有值时，e.preventDefault()一下，阻止它进行onOK处理,导致下次点击取消不走onCancel方法【因为global-dialog中emittedOK = true】
            if (name === "confirm") {
              if (selected.length === 0) {
                e.preventDefault();
                dlg.hide();
                return;
              }
              if (!this.checkAddService(selected)) {
                this.$rs_warning("不能同时发布相同的服务！");
                e.preventDefault();
                return;
              }
            }
          },
        },
      ).then(({ selected, dlg }) => {
        const dealedData = selected.map((d) => {
          return {
            service: d.service,
            version: d.version,
          };
        });
        this.$set(this, "serviceList", [...dealedData, ...this.serviceList]);
        dlg.hide();
      });
    },
    onTenDeployServiceDeleteBeforeConfirm(e, params, eventId) {
      e.preventDefault();
      const delIndexs = params.map((d) => d.__index);
      this.$set(
        this,
        "serviceList",
        this.serviceList.filter((d, index) => !delIndexs.includes(index)),
      );
    },
    checkAddService(list) {
      const existServiceIds = this.serviceList.map((d) => d.service.id);
      let result = true;
      list.forEach((p) => {
        const id = p.service.id;
        if (existServiceIds.includes(id)) {
          result = false;
        } else {
          existServiceIds.push(id);
        }
      });
      return result;
    },
  },
};
</script>
