<script>
import Config from "rsui-core/src/config";
import UnlimitedNumber from "./unlimited-number.vue";
import UnlimitedNumberRstore from "./unlimited-number-rstore.vue";

export default {
  data() {
    return {};
  },
  props: ["defValue", "closeDialog", "refresh"],
  mounted() {},
  computed: {},
  methods: {
    beforeSubmit(e, params) {
      e.preventDefault();
      this.$rs_post(Config.API.TEN_MODULE_SETTING_DETAIL_CRM_APP_AUTH_CREATE_OR_UPDATE, params).then((resp) => {
        this.$rs_success("修改授权成功！");
        this.closeDialog();
        this.refresh();
      });
    },
    // 账套许可数、品牌组许可数、网点许可数 要>0
    numRule(rule, value, callback) {
      if (value && typeof value === "number" && value > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    // 会员商城许可数
    numRule1(rule, value, callback) {
      if (typeof value === "number" && value >= 0) {
        callback();
      } else {
        callback(new Error("请输入不小于0的整数"));
      }
    },
    // 不限数字组件验证
    unlimitedRule(rule, value, callback) {
      if (value === -1 || (value && typeof value === "number" && value > 0)) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    // RStore许可数  非不限时要>=0
    rstoreNumRule(rule, value, callback) {
      if (value === -1 || (typeof value === "number" && value >= 0)) {
        callback();
      } else {
        callback(new Error("请输入大于等于0的整数"));
      }
    },
  },
  render() {
    const numProps = {
      props: {
        required: true,
        width: "10em",
        rules: [this.numRule],
      },
    };
    const numProps1 = {
      props: {
        required: true,
        width: "10em",
        rules: [this.numRule1],
      },
    };
    return (
      <rs-form
        ref="form"
        noBorder
        class="full-height"
        name="mock-crmAppAuth"
        labelWidth="12em"
        col={1}
        restoreByQuery={false}
        toolbar={{ forceAlign: "end" }}
        wrapPadding=""
        bodyPadding="q-ma-sm"
        // 设置不限组件默认值-1, 外部数据可以覆盖
        value={{ siteLimit: -1, rstoreLimit: -1, mallPolicyLimit: 0, ...this.defValue }}>
        <rs-field name="accountSetLimit" {...numProps}></rs-field>
        <rs-field name="siteGroupLimit" {...numProps}></rs-field>
        <rs-field
          name="siteLimit"
          required
          showRequiredIcon={false}
          rules={[this.unlimitedRule]}
          widget={{ _component: UnlimitedNumber }}></rs-field>
        <rs-field
          name="rstoreLimit"
          required
          showRequiredIcon={false}
          widget={{ _component: UnlimitedNumberRstore }}
          rules={[this.rstoreNumRule]}></rs-field>
        <rs-field name="mallLimit" {...numProps1}></rs-field>
        <rs-field name="mallPolicyLimit" {...numProps1}></rs-field>
        <rs-field name="remark" widget={{ inputStyle: { height: "80px" }, type: "textarea" }}></rs-field>
        <rs-toolbar>
          <rs-button name="submit" icon="" label="确定" sort={999} auth={null}></rs-button>
        </rs-toolbar>
      </rs-form>
    );
  },
};
</script>
