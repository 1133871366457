<template>
  <rs-list
    class="full-height tenant-domain-list"
    name="ten-domain"
    keywords="tenant.uname,tenant.name,domain"
    filter-placeholder="不限(输入内容与租户代号,租户名称,域名关键字进行模糊匹配)"
    :conditions="domainConditions"
  >
    <rs-search label-width="5em">
      <rs-filter name="domain"></rs-filter>
      <rs-filter name="tenant" v-if="!tenantId"></rs-filter>
      <rs-filter name="customize"></rs-filter>
      <rs-filter name="crtTime"></rs-filter>
      <rs-filter name="recTime"></rs-filter>
    </rs-search>
    <rs-field name="id"></rs-field>
    <rs-field name="tenant.uname"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="domain"></rs-field>
    <rs-field name="remark"></rs-field>
    <rs-field name="customize"></rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="recTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field type="toolbar" :include="['edit', 'delete']" v-slot="row">
      <rs-button name="edit" :hidden="!row.customize"></rs-button>
      <rs-button name="delete" :hidden="!row.customize"></rs-button>
    </rs-field>
    <rs-toolbar :include="['add']" exclude="*">
      <rs-button
        name="add"
        color="primary"
        label="自定义租户域名"
        action="tenants.domain._add"
        :payload="{ query: { tenantId } }"
      ></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
    };
  },
  computed: {
    domainConditions: function() {
      const cons = [];
      if (this.tenantId) {
        cons.push({ key: "tenant.id", vals: [this.tenantId] });
      }
      return cons;
    },
  },
  created() {},
  mounted() {
    this.tenantId = this.$route.query.tenantId;
  },
  methods: {},
};
</script>
