<template>
  <div class="full-height ten-dbs-order-form">
    <div class="full-height ten-dbs-order-form q-pa-sm">
      <q-card>
        <q-card-section>
          <q-item-label class="text-primary">租户信息</q-item-label>
        </q-card-section>
        <q-separator />
        <q-card-section class="row">
          <div class="col-2 row items-center">
            <span class="text-caption">租户ID：</span>
            <span>{{ tenInfo.id }}</span>
          </div>
          <div class="col-2 row items-center">
            <span class="text-caption">租户代号：</span>
            <span>{{ tenInfo.uname }}</span>
          </div>
          <div class="col-2 row items-center">
            <span class="text-caption">租户名称：</span>
            <span>{{ tenInfo.name }}</span>
          </div>
        </q-card-section>
      </q-card>
      <q-card class="q-mt-sm">
        <q-card-section>
          <q-item-label class="text-primary">数据库</q-item-label>
        </q-card-section>
        <q-separator />
        <q-card-section class="row q-gutter-md">
          <q-card style="width: 250px" v-for="db in dbList" :key="db.id">
            <q-card-section>
              <div class="text-center text-h6 text-weight-bold">{{ db.name }}</div>
            </q-card-section>
            <q-card-section>
              <div class="text-grey text-caption">{{ db.title }}</div>
            </q-card-section>
            <q-card-section class="no-padding">
              <q-btn
                class="full-width no-border-radius"
                color="primary"
                label="立即购买"
                @click="(dbItem = db), setNewDbConfig()"
              />
            </q-card-section>
          </q-card>
        </q-card-section>
      </q-card>
    </div>
    <q-card v-if="dbItem" class="absolute-right full-height width-50 bg-white">
      <div class="full-height flex-column">
        <q-card-section class="flex-no-grow shadow-down-3">
          <q-item-label class="text-black row items-center">
            <span class="text-subtitle1 text-weight-bold">{{ dbItem.name }}配置确认</span>
            <q-space />
            <q-btn flat size="md" icon="clear" @click="(dbItem = null), setNewDbConfig()" />
          </q-item-label>
        </q-card-section>
        <q-separator class="flex-no-grow" />
        <q-card-section class="flex-grow q-gutter-y-lg q-ma-md">
          <section v-if="dbItem.id === 'DATABASE_shared'" class="row items-center">
            <span style="min-width: 6em">
              <span>产品级别</span>
            </span>
            <q-list class="col">
              <q-item tag="label" v-ripple v-for="lv in dbLevel" :key="lv.id">
                <q-item-section avatar top>
                  <q-radio v-model="dbConfig.level" :val="lv.id" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ lv.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </section>
          <template v-else>
            <section class="row items-center">
              <span style="min-width: 10em">
                <span>CPU（核）：</span>
              </span>
              <div class="col row q-gutter-md">
                <div
                  v-for="item in dbCpu"
                  :key="item.id"
                  :class="[
                    'row items-center justify-center rs-border rs-radius cursor-pointer',
                    dbConfig.cpu === item.id ? 'bg-primary text-white' : 'bg-white text-black',
                    dbConfig.mem && !dbMemToCpu[dbConfig.mem].includes(item.id) && 'rs-border-dashed disabled',
                  ]"
                  :style="{
                    minWidth: '90px',
                    minHeight: '36px',
                    borderColor: dbConfig.cpu === item.id ? 'var(--q-color-primary)' : '#ddd',
                  }"
                  @click="
                    (!dbConfig.mem || dbMemToCpu[dbConfig.mem].includes(item.id)) &&
                      (dbConfig.cpu = dbConfig.cpu === item.id ? null : item.id)
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </section>
            <section class="row items-center">
              <span style="min-width: 10em">
                <span>内存：</span>
              </span>
              <div class="col row q-gutter-md">
                <div
                  v-for="item in dbMem"
                  :key="item.id"
                  :class="[
                    'row items-center justify-center rs-border rs-radius cursor-pointer',
                    dbConfig.mem === item.id ? 'bg-primary text-white' : 'bg-white text-black',
                    dbConfig.cpu && !dbCpuToMem[dbConfig.cpu].includes(item.id) && 'rs-border-dashed disabled',
                  ]"
                  :style="{
                    minWidth: '90px',
                    minHeight: '36px',
                    borderColor: dbConfig.mem === item.id ? 'var(--q-color-primary)' : '#ddd',
                  }"
                  @click="
                    (!dbConfig.cpu || dbCpuToMem[dbConfig.cpu].includes(item.id)) &&
                      (dbConfig.mem = dbConfig.mem === item.id ? null : item.id)
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </section>
            <section class="row items-center">
              <span style="min-width: 10em">
                <span>存储类型：</span>
              </span>
              <div class="col row q-gutter-md">
                <div
                  v-for="item in dbType"
                  :key="item.id"
                  :class="[
                    'row items-center justify-center rs-border rs-radius cursor-pointer',
                    dbConfig.type === item.id ? 'bg-primary text-white' : 'bg-white text-black',
                  ]"
                  :style="{
                    minWidth: '90px',
                    minHeight: '36px',
                    borderColor: dbConfig.type === item.id ? 'var(--q-color-primary)' : '#ddd',
                  }"
                  @click="dbConfig.type = dbConfig.type === item.id ? null : item.id"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </section>
            <section class="row items-center">
              <span style="min-width: 10em">
                <span>存储空间：</span>
              </span>
              <div class="col row">
                <rs-input-number
                  v-model="dbConfig.space"
                  class="rs-size-sm"
                  style="width: 120px"
                  size="sm"
                  dense
                  outlined
                  :min="10"
                  :max="9999"
                  suffix="GB"
                />
              </div>
            </section>
            <section class="row items-center">
              <span style="min-width: 10em">
                <span>从库：</span>
              </span>
              <div class="col row">
                <q-toggle v-model="dbConfig.doubleDb" size="sm" dense />
              </div>
            </section>
          </template>
        </q-card-section>
        <q-card-section class="flex-no-grow shadow-up-3" style="height: 90px">
          <q-item-label class="text-black full-height row items-center q-gutter-x-md q-pt-md">
            <span>购买时长</span>
            <rs-input-number
              v-model="dbConfig.timeValue"
              class="rs-size-sm"
              style="width: 100px"
              size="sm"
              dense
              outlined
              :min="1"
              :max="999"
            />
            <rs-select
              v-model="dbConfig.timeUnit"
              :data="unitOptions"
              class="rs-size-sm"
              style="width: 70px"
              size="sm"
              dense
              outlined
              :clearable="false"
            />
            <q-space />
            <span>配置费用</span>
            <span class="text-red">
              ￥
              <span class="text-h6 text-weight-bold">{{ (+totalMoney.split(".")[0]).toLocaleString() }}.</span>
              <span style="font-size: 10px">{{ totalMoney.split(".")[1] }}</span>
            </span>
            <q-btn dense color="primary" style="min-width:100px" label="下单" @click="order" />
          </q-item-label>
        </q-card-section>
      </div>
    </q-card>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {},
  data: function() {
    return {
      tenantId: null,
      tenantService: new ModelService(),
      moduleService: new ModelService(),
      productSpecService: new ModelService(),
      productSkuService: new ModelService(),
      tenOrderService: new ModelService(),
      dbLevel: [], // 产品级别  DB_shared_level
      dbType: [], // 存储类型   STORE_cloud_type
      dbCpu: [], // CPU(核)    DB_exclude_cpu
      dbMem: [], // 内存       DB_exclude_mem
      dbCpuMemPriceMap: {}, // CPU(核) + 内存 组合的价格映射
      dbCpuToMem: {}, // CPU(核)可以选择的 内存 映射
      dbMemToCpu: {}, // 内存可以选择的 CPU(核) 映射
      tenInfo: {},
      dbConfig: null,
      dbList: [],
      dbItem: null, // dbList item
      loading: false, // 下单按钮loading
      unitOptions: [
        {
          name: "天",
          code: "day",
          val: 1,
        },
        {
          name: "周",
          code: "week",
          val: 7,
        },
        {
          name: "月",
          code: "month",
          val: 30,
        },
        {
          name: "年",
          code: "year",
          val: 360,
        },
      ],
    };
  },
  computed: {
    totalMoney() {
      const {
        level,
        type,
        space,
        cpu,
        mem,
        doubleDb,
        timeValue, // 默认1
        timeUnit,
      } = this.dbConfig;
      let total = 0; // 总价
      if (level !== null) {
        const levelItem = this.dbLevel.find((d) => d.id === level);
        total += levelItem?.price || 0;
      }
      if (type !== null && !isNaN(space)) {
        const typeItem = this.dbType.find((d) => d.id === type);
        total += typeItem?.price ? typeItem.price * space : 0;
      }
      if (cpu !== null && mem !== null) {
        total += this.dbCpuMemPriceMap[`cpu${cpu}_mem${mem}`]?.price || 0;
      }
      if (doubleDb) {
        total = 2 * total;
      }
      if (timeValue && timeUnit) {
        const timeItem = this.unitOptions.find((d) => d.code === timeUnit);
        total = total * timeItem.val * timeValue;
      }
      return total.toFixed(2);
    },
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    if (!this.tenantId) {
      this.$rs_error("缺少租户信息!!!");
      return;
    }
    this.getProductSpecConfig();
    this.tenantService
      .refs(
        [this.tenantId],
        {
          fields: ["id", "name", "uname"],
        },
        "tenant",
      )
      .then((resp) => {
        if (resp && resp[0]) {
          this.tenInfo = resp[0];
        } else {
          this.$rs_error("无效的租户信息");
        }
      });
    this.moduleService
      .list(
        {
          fields: ["id", "name", "title", "image", "price"],
          page: 1,
          size: -1,
          conditions: [
            { key: "type.id", vals: ["DATABASE"] },
            { key: "status", vals: [1] },
          ],
        },
        "product",
      )
      .then((resp) => {
        this.dbList = resp.list || [];
      });
  },
  methods: {
    // 订购数据库
    order() {
      // 购买时长不能为空
      if (!this.dbConfig.timeValue) {
        this.$rs_warning("请输入购买时长");
        return;
      }
      let params = null;
      // 订购共享数据库
      if (this.dbItem.id === "DATABASE_shared") {
        if (!this.dbConfig.level) {
          this.$rs_warning("请选择共享数据库的产品级别");
          return;
        }
        params = this.getCreatedShareDbParams();
      }
      //
      else {
        if (!this.dbConfig.cpu) {
          this.$rs_warning("请选择独享数据库的CPU（核）");
          return;
        }
        if (!this.dbConfig.mem) {
          this.$rs_warning("请选择独享数据库的内存");
          return;
        }
        if (!this.dbConfig.type) {
          this.$rs_warning("请选择独享数据库的存储类型");
          return;
        }
        if (!this.dbConfig.space) {
          this.$rs_warning("请选择独享数据库的存储空间");
          return;
        }
        params = this.getCreatedExcludeDbParams();
      }
      this.tenOrderService.create(params, "ten-order").then((resp) => {
        this.$rs_success("订购数据库成功!");
        this.$store.commit("closeCurrentTab");
        this.$rs_action("tenants.orders._edit", { query: { tenantId: this.tenantId, id: resp.id } });
      });
    },
    // 购买共享数据库
    getCreatedShareDbParams() {
      const { level, timeValue, timeUnit } = this.dbConfig; // 获取当前配置
      const timeItem = this.unitOptions.find((d) => d.code === timeUnit); // 获取当前选择时间单位的具体天数
      const lv = this.dbLevel.find((d) => d.id === level); // 获取当前产品级别
      const lvMoney = lv.price * timeValue * timeItem.val; // 计算价格
      return {
        type: "database_new",
        tenant: {
          id: this.tenantId,
        },
        payStatus: 1,
        orderFrom: "admin",
        discount: 0,
        totalPrice: lvMoney,
        totalPaid: 0,
        items: [
          {
            sku: {
              id: lv.skuId,
            },
            name: this.dbItem.name,
            specs: `level:${lv.name}`,
            price: lvMoney,
            quantity: 1,
            unit: "one",
            duration: timeValue,
            durationUnit: timeUnit,
            ext: {
              dbRole: "master",
            },
          },
        ],
      };
    },
    // 购买独享数据库
    getCreatedExcludeDbParams() {
      const { type, space, cpu, mem, doubleDb, timeValue, timeUnit } = this.dbConfig;
      const timeItem = this.unitOptions.find((d) => d.code === timeUnit); // 购买时长单位

      const cpuMemObj = this.dbCpuMemPriceMap[`cpu${cpu}_mem${mem}`]; // 获取cpu+mem 组合的价格和skuId
      const cpuItem = this.dbCpu.find((d) => d.id === cpu); // 选择的cpu
      const memItem = this.dbMem.find((d) => d.id === mem); // 选择的mem
      const cpuMemMoney = timeValue * timeItem.val * cpuMemObj.price; // 计算cpu+mem组合的价格

      const typeItem = this.dbType.find((d) => d.id === type); // 选择的存储类型
      const typeSpaceMoney = timeValue * timeItem.val * typeItem.price * space; // 计算存储价格
      const params = {
        type: "database_new",
        tenant: {
          id: this.tenantId,
        },
        payStatus: 1,
        orderFrom: "admin",
        discount: 0,
        totalPrice: cpuMemMoney + typeSpaceMoney,
        totalPaid: 0,
        items: [
          {
            sku: {
              id: cpuMemObj.skuId,
            },
            name: this.dbItem.name,
            specs: `cpu:${cpuItem.name},mem:${memItem.name}`,
            price: cpuMemMoney,
            quantity: 1,
            unit: "one",
            duration: timeValue,
            durationUnit: timeUnit,
            ext: {
              dbRole: "master",
            },
          },
          {
            sku: {
              id: typeItem.skuId,
            },
            name: typeItem.productName,
            specs: `type:${typeItem.name}`,
            price: typeSpaceMoney,
            quantity: space,
            unit: "GB",
            duration: timeValue,
            durationUnit: timeUnit,
            ext: {
              dbSku: cpuMemObj.skuId,
              dbRole: "master",
            },
          },
        ],
      };
      // 如果从库开启，那么需要翻倍
      if (doubleDb) {
        const doubleItems = params.items.map((d) => {
          const ext = { ...d.ext, dbRole: "slave" };
          return {
            ...d,
            ext,
          };
        });
        params.items.push(...doubleItems);
        params.totalPrice = 2 * params.totalPrice;
      }
      return params;
    },
    // 返回新的对象，即清空值
    setNewDbConfig() {
      const config = {
        level: null,
        type: null,
        space: 500,
        cpu: null,
        mem: null,
        doubleDb: false,
        timeValue: 1, // 默认1
        timeUnit: "year",
      };
      this.$set(this, "dbConfig", { ...config });
    },
    // 获取数据库配置
    getProductSpecConfig() {
      const bindKeyMap = {
        DB_shared_level: "dbLevel",
        STORE_cloud_type: "dbType",
        DB_exclude_cpu: "dbCpu",
        DB_exclude_mem: "dbMem",
      };
      Promise.all([
        this.productSkuService.list(
          {
            conditions: [{ key: "product.id", vals: ["STORAGE_cloud", "DATABASE_shared", "DATABASE_exclude"] }],
            fields: ["id", "specs", "price", "product.id", "product.name"],
          },
          "product-sku",
        ),
        this.productSpecService.refs(
          ["DB_shared_level", "STORE_cloud_type", "DB_exclude_cpu", "DB_exclude_mem"],
          {
            fields: ["id", "specValues"],
          },
          "product-spec",
        ),
      ]).then(([skuResp, resp]) => {
        const skuList = skuResp?.list || [];
        // 设置配置项
        (resp || []).forEach((d, index) => {
          if (d?.id && d?.specValues) {
            this.$set(
              this,
              bindKeyMap[d.id],
              Object.keys(d.specValues).map((t) => {
                return {
                  id: t,
                  name: d.specValues[t],
                };
              }),
            );
          }
        });
        // 处理sku
        const groups = {};
        skuList.forEach((sku) => {
          const group = sku.product.id;
          groups[group] = groups[group] || [];
          groups[group].push(sku);
        });
        // 处理 产品级别 价格
        if (groups?.DATABASE_shared?.length && this.dbLevel?.length) {
          const map = {};
          groups.DATABASE_shared.forEach((d) => (map[d.specs.level] = d));
          this.$set(
            this,
            "dbLevel",
            this.dbLevel.map((d) => ({ ...d, price: map[d.id].price, skuId: map[d.id].id })),
          );
        }
        // 处理 存储类型 每GB的价格
        if (groups?.STORAGE_cloud?.length && this.dbType?.length) {
          const map = {};
          groups.STORAGE_cloud.forEach((d) => (map[d.specs.type] = d));
          this.$set(
            this,
            "dbType",
            this.dbType.map((d) => ({
              ...d,
              price: map[d.id].price,
              skuId: map[d.id].id,
              productName: map[d.id].product.name,
            })),
          );
        }
        // 处理 CPU(核)+内存 组合的sku的价格
        if (groups?.DATABASE_exclude?.length) {
          const map = {}; // key eg: cpu1_mem1
          const cpuGrps = {}; // 每个cpu可以选择的内存IDs eg: {1:[1,2,4,8]}
          const memGrps = {}; // 每个mem可以选择的cpuIDs eg: {1:[1,2,4,8]}
          groups.DATABASE_exclude.forEach((d) => {
            map[`cpu${d.specs.cpu}_mem${d.specs.mem}`] = { price: d.price, skuId: d.id };
            cpuGrps[d.specs.cpu] = cpuGrps[d.specs.cpu] || [];
            cpuGrps[d.specs.cpu].push(d.specs.mem);

            memGrps[d.specs.mem] = memGrps[d.specs.mem] || [];
            memGrps[d.specs.mem].push(d.specs.cpu);
          });
          this.$set(this, "dbCpuMemPriceMap", map);
          this.$set(this, "dbCpuToMem", cpuGrps);
          this.$set(this, "dbMemToCpu", memGrps);
        }
      });
    },
  },
};
</script>
