<template>
  <rs-list name="alipay-app">
    <rs-field name="id"></rs-field>
    <rs-field name="tenCode"></rs-field>
    <rs-field name="status"></rs-field>
    <rs-field name="appType"></rs-field>
    <rs-field name="userId"></rs-field>
    <rs-field name="authAppId"></rs-field>
    <!-- <rs-field name="authMethods"></rs-field> -->
    <!-- <rs-field name="pt"></rs-field> -->
    <rs-field name="crtPlat"></rs-field>
    <rs-field name="tenant.uname"></rs-field>
    <rs-field name="tenant.name"></rs-field>
    <rs-field name="crtUser"></rs-field>
    <rs-field name="crtTime"></rs-field>
    <rs-field name="recUser"></rs-field>
    <rs-field name="recTime"></rs-field>
  </rs-list>
</template>
<script>
export default {
  methods: {},
};
</script>
