<template>
  <div class="q-pa-sm full-height biz-error-form">
    <rs-form ref="form" name="biz-error">
      <rs-field name="id" :hidden="!id" :required="!!id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="message"></rs-field>
      <rs-field name="module"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="tenants.bizError._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("tenants.bizError._view");
        },
      };
    },
  },
};
</script>
