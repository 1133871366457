<template>
  <div class="q-pa-sm full-height xxl-job-group-form">
    <rs-form ref="form" name="xxl-job-group">
      <rs-field name="id" :hidden="!id"></rs-field>
      <rs-field name="appName" :readonly="!!id"></rs-field>
      <rs-field type="group" col="4" :expansion="false"></rs-field>
      <rs-field name="title" col="12"></rs-field>
      <rs-field name="addressType" readonly :value="0"></rs-field>
      <rs-field name="addressList" readonly :hidden="!id" col="8"></rs-field>
      <rs-toolbar :include="['cancel']" exclude="*">
        <!--
          // 暂时禁用保存
        <rs-button name="submit"></rs-button>
        -->
        <rs-button name="cancel" action="matrix.jobGroup._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      if (!this.id) {
        params.addressType = 0;
      }
      params.addressList = null;
      return {
        params,
        resolve: ({ response }) => {
          this.$rs_actionRefresh("matrix.jobGroup._view");
        },
      };
    },
  },
};
</script>
