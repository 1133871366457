<template>
  <div class="q-pa-sm full-height git-server-form">
    <rs-form ref="form" name="git-server" :value="{ status: 1 }">
      <rs-field name="id" :hidden="!id"></rs-field>
      <rs-field name="type"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="host"></rs-field>
      <rs-field name="token" :widget="{ type: 'password' }"></rs-field>
      <rs-field name="tokenType"></rs-field>
      <rs-field name="authMethod"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field
        name="info"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  mounted() {},
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      e.preventDefault();
      this.$rs_post("/api/deploy/git/server/check", params).then((resp) => {
        if (resp && resp.code === 0) {
          if (resp.content.result) {
            e.next({
              params,
              resolve: ({ response }) => {
                this.$rs_actionRefresh("settings.gitServer._view");
              },
            });
          } else {
            this.$rs_warning("Git配置无效：" + resp.content.message || "");
          }
        } else {
          this.$rs_warning("Git配置无效：" + resp.message || "");
        }
      });
    },
  },
};
</script>
