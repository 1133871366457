<template>
  <q-header :value="!rsLayout.simple" class="" reveal elevated>
    <q-toolbar :style="{ minHeight: this.$q.screen.gt.lg ? '40px' : '40px', paddingLeft: 0 }">
      <router-link :to="{ name: 'home' }">
        <!-- <img style="vertical-align: middle;" :src="appconf.homeLogo" /> -->
        <span class="text-white text-h5 q-mx-sm">系统治理平台</span>
      </router-link>

      <!-- <div class="text-subtitle1 ellipsis q-px-sm">
        <span style="font-weight: 600;font-size: 20px;font-style: oblique;">O</span>
        <span style="font-style: oblique;">ps</span>
        &nbsp;
        <sub class="text-left" v-if="version">
          <q-badge color="primary" style="transform:scale(.9)" class="bg-white text-primary">{{ version }}</q-badge>
        </sub>
      </div> -->

      <div class="ellipsis text-center flex-grow">
        <!-- <span class="shadow-rs">
          {{ $rs_user.platformName }}
        </span> -->
      </div>
      <menu-searcher class="q-ml-sm header-search" style="width:300px"></menu-searcher>
      <!-- <div class=" text-left q-mr-md text-caption">
        <q-badge class="bg-green text-white">
          <router-link target="_blank" :to="{ path: '/i18n' }">国际化</router-link>
        </q-badge>
      </div> -->

      <div style="width:10px"></div>
      <div class="q-gutter-sm row items-center no-wrap">
        <!-- <q-btn size="sm" flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />
          <q-btn size="sm" round dense flat color="text-grey-7" icon="apps">
            <q-tooltip>其他应用</q-tooltip>
          </q-btn> -->
        <q-btn size="sm" @click="$rs_notify()" round dense flat color="white" icon="notifications">
          <!-- <q-badge color="red" text-color="white" floating>
            2
          </q-badge> -->
          <q-tooltip>{{ this.$t_s("notification") }}</q-tooltip>
        </q-btn>
        <div class="shadow-rs">{{ $rs_user.nickName }}</div>
        <q-btn size="sm" dense flat @click="rsLayout.drawerRight = !rsLayout.drawerRight">
          <q-avatar size="32px">
            <img :src="require('rsui-core/src/assets/avatar.png')" />
          </q-avatar>
          <q-icon name="arrow_drop_down"></q-icon>
          <q-menu :offset="[5, 18]">
            <rs-profile></rs-profile>
          </q-menu>
        </q-btn>
      </div>
    </q-toolbar>
  </q-header>
</template>
<script>
import Header from "rsui-core/src/system/buildin/header.vue";
import Config from "rsui-core/src/config";
export default {
  mixins: [Header],
  computed: {
    appconf: function() {
      return this.$rs_user.acAppConf;
    },
  },
  created() {
    this.$rs_user.acSetDefaultConfig(Config.SYS.APP_CONFIG.defaultAppConf, Config.SYS.APP_CONFIG.changableAppConf);
  },
};
</script>
