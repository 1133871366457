<template>
  <div class="q-pa-sm  full-height column">
    <div class="logs-wraper bg-grey-9 text-white scroll col rs-radius full-width ">
      <pre
        @dblclick="toggleStyle"
        style="margin: 0;overflow:visible;background:unset"
        v-for="(log, index) in logs"
        :key="index"
        v-highlightjs="{ language: 'sql', code: log }"
      ></pre>
      <div class="blink-cursor q-my-sm">&nbsp;{{ `${$rs_user.nickName}@${$rs_user.platformName}:` }}</div>

      <q-linear-progress
        dark
        indeterminate
        v-if="emptyTimes !== 0 && emptyTimes <= maxTimes"
        color="cyan"
        class="q-mt-sm"
      />
    </div>
  </div>
</template>
<style scoped>
.logs-wraper /deep/ a {
  color: red;
}
</style>
<script>
import { date } from "quasar";
const DELAY = 10; // 10s 延时获取日志
function relavtiveSeconds(minites = 0, delay = 0) {
  return Math.ceil((Date.now() - 60 * 1e3 * minites) / 1000) - delay;
}
export default {
  name: "JobLogCache",
  data: function() {
    return {
      logs: [],
      from: this.$route.query.seconds,
      to: relavtiveSeconds(0, DELAY),
      offset: 0,
      timer: null,
      finish: false,
      emptyTimes: 0,
      maxTimes: 10,
    };
  },
  computed: {},
  mounted() {
    if (this.from >= this.to) {
      this.from = this.to - 2 * DELAY;
    }
    if (this.from > relavtiveSeconds(0, 7 * 24 * 3600)) {
      this.from = this.from - 30; // 任务触发时间 提前半分钟，为了防止客户端和服务端时间不同步
      this.getLogs();
    }
  },
  beforeDestroy() {
    this.getNext = () => {};
  },
  methods: {
    toggleStyle(e) {
      let re = e.target.style.whiteSpace;
      if (re == "nowrap") {
        re = "";
        e.target.style.color = "unset";
      } else {
        re = "nowrap";
        e.target.style.color = "red";
      }
      e.target.style.whiteSpace = re;
    },
    getNext(delay = 5000) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getLogs();
      }, delay);
    },
    sectionChange(val) {
      this.from = relavtiveSeconds(val);
    },
    cleanScreen() {
      this.logs = [];
      this.from = relavtiveSeconds(this.relativeTime);
    },
    add(logs) {
      if (!Array.isArray(logs) || !logs.length) {
        return;
      }
      const len = this.logs.length + logs.length;

      if (len >= 1000) {
        this.logs.splice(0, len - 1000);
      }
      this.logs = [...this.logs, ...logs];

      setTimeout(() => {
        const el = this.$el.querySelector(".logs-wraper");
        el.scrollTop = el.scrollHeight;
      });
    },
    getLogs() {
      if (!this.$route.query.id) {
        return;
      }
      if (!this.startTime) {
        this.startTime = date.formatDate(this.from * 1000, "YYYY-MM-DD HH:mm:ss");
      }
      this.$rs_post("/api/ops/logs/search", {
        from: this.from,
        to: this.to,
        logStore: "task",
        topic: this.$route.query.jobGroup,
        offset: this.offset,
        query: "trigger:" + this.$route.query.id,
      }).then((resp) => {
        const content = resp.content;
        if (content.suc) {
          const newLogs = [];
          if (content.logs) {
            this.emptyTimes = 0;
            content.logs.forEach((log) => {
              let time = "";
              let message = "";
              log.mLogItem.mContents.forEach((d) => {
                if (d.mKey === "time") time = d.mValue;
                if (d.mKey === "message") message = d.mValue;
              });
              if (!this.finish) {
                this.finish = message.startsWith("[finish]");
                newLogs.push(time + " " + message + "\n");
              }
            });
          }
          this.add(newLogs);
          if (!this.finish) {
            if (++this.emptyTimes > this.maxTimes) {
              return;
            }
            if (content.count === 0) {
              this.from = this.to;
              this.to = relavtiveSeconds(0, -2);
              this.offset = 0;
              this.getNext();
            } else {
              this.offset += 100;
              this.getNext(500);
            }
          }
        } else {
          throw Error(content.errMsg);
        }
      });
    },
  },
};
</script>
