import RsLogin from "rsui-core/src/views/login/login.vue";
export default {
    mixins: [RsLogin],
    data() {
        return {
            logoPng: require("@/assets/login/lg.svg"),
            logo: "Molectron",
            appTitle: "Runsa OPS",
            thirdLoginPermit: false,
        };
    },
};
