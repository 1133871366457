<template>
  <div class="full-height rs-page-payment">
    <div class="full-height scroll">
      <rs-list
        ref="list"
        name="payment"
        v-if="true"
        :preventDbClick="true"
        edit-mode="row"
        keywords="id,name"
        class="payment-sticky-column-table"
        @table-form-change="tableFormChange"
        :filter-placeholder="$t_mdata('payment-filterPlaceholder')"
      >
        <rs-search label-width="5em" :value="{ status: 1 }">
          <rs-filter type="keywords" name="name,id"></rs-filter>
          <rs-filter name="id"></rs-filter>
          <rs-filter name="name"></rs-filter>
          <rs-filter name="currency"></rs-filter>
          <rs-filter name="isMaster"></rs-filter>
          <rs-filter name="isThird"></rs-filter>
          <rs-filter name="isSettle"></rs-filter>
          <rs-filter name="refundLevel"></rs-filter>
          <rs-filter name="status"></rs-filter>
          <rs-filter name="recTime"></rs-filter>
          <rs-filter name="recUser"></rs-filter>
        </rs-search>
        <rs-field name="id" :updatable="false" minWidth="120px"></rs-field>
        <rs-field name="name" minWidth="120px"></rs-field>
        <rs-field name="isCash" :value="true"></rs-field>
        <rs-field name="currency" v-bind="currencyProps"></rs-field>
        <rs-field name="isGivChange" v-bind="isGivChangeProps" :value="true"></rs-field>
        <rs-field name="isMaster" :value="true"></rs-field>
        <rs-field
          name="master"
          minWidth="120px"
          v-bind="masterProps"
          :widget="{ conditions: masterConditions }"
          v-slot="props"
        >
          <div class="full-height row items-center">{{ props.master ? props.master.name || "" : "" }}</div>
        </rs-field>
        <rs-field name="isThird" :value="false"></rs-field>
        <rs-field name="isSettle" :value="true"></rs-field>
        <rs-field name="isIncome" :value="true"></rs-field>
        <rs-field name="canChgPrice" :value="false"></rs-field>
        <rs-field name="canInvoice" :value="true"></rs-field>
        <rs-field name="canDeCarriage" :value="true"></rs-field>
        <rs-field name="isPrint" :value="true"></rs-field>
        <rs-field name="refundLevel" minWidth="120px" :value="''"></rs-field>
        <rs-field name="status" :value="1"></rs-field>
        <rs-field name="customize" :editable="false" :value="false"></rs-field>
        <rs-field name="recTime" :editable="false"></rs-field>
        <rs-field name="recUser" :editable="false"></rs-field>
        <rs-field type="toolbar">
          <rs-button name="delete" :tips="$t_mdata('paymentList-deleteTip')"></rs-button>
        </rs-field>
        <rs-toolbar :exclude="['imports']">
          <rs-button name="deleteAll" :tips="$t_mdata('paymentList-deleteTip')"></rs-button>
        </rs-toolbar>
      </rs-list>
    </div>
  </div>
</template>
<style lang="stylus">
.payment-sticky-column-table {
  thead tr:last-child th:last-child {
    background-color: #fff;
    opacity: 1;
  }

  td:last-child {
    background-color: #fff;
  }

  thead tr:last-child th:last-child, td:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
  }
}
</style>
<script>
export default {
  data: function() {
    return {
      editRow: null,
      isGivChangeDisable: false,
      masterProps: {},
      currencyProps: {},
      isGivChangeProps: {},
    };
  },
  computed: {
    masterConditions: function() {
      if (this.editRow) {
        return [
          { key: "isMaster", vals: [true] },
          { key: "id", op: "ne", vals: [this.editRow.id] },
        ];
      }
      return [{ key: "isMaster", vals: [true] }];
    },
  },
  methods: {
    onPaymentAdd() {
      this.editRow = null;
      this.masterProps = { disable: true };
      this.currencyProps = { disable: false, required: true };
      this.isGivChangeProps = { disable: false };
    },
    onPaymentEdit(e, params) {
      this.editRow = params;
      this.masterProps = { disable: this.editRow.isMaster };
      this.currencyProps = { disable: !this.editRow.isCash, required: this.editRow.isCash };
      this.isGivChangeProps = { disable: !this.editRow.isCash };
    },
    onPaymentSaveLine(e, params) {
      if (params.isCreated) {
        params.row.customize = params.row.customize || false;
      }
      return {
        params: params.row,
      };
    },
    onPaymentDeleteBeforeConfirm(e, params) {
      if (params.find((d) => d.customize)) {
        e.preventDefault();
        this.$rs_warning(this.$t_mdata("payment-isCustomize"));
      }
    },
    // 表格编辑行change 事件
    tableFormChange({ index, form, fields, core, list }) {
      // 是否现金收款，是：找零可修改；否：找零置为false,且不可修改
      if (fields.includes("isCash")) {
        this.currencyProps = { disable: !form.isCash, required: form.isCash };
        this.isGivChangeProps = { disable: !form.isCash };
        if (!form.isCash) {
          setTimeout(() => {
            core.setValue({ isGivChange: false, currency: "" });
          });
        } else {
          setTimeout(() => {
            core.setValue({ isGivChange: true });
          });
        }
        this.$refs.list.$forceUpdate();
      }
      // 主从收款控制,true时 主收款【master】 不可选，可选时 必填
      if (fields.includes("isMaster")) {
        this.masterProps = { disable: form.isMaster, required: !form.isMaster };
        if (form.isMaster) {
          setTimeout(() => {
            core.setValue({ master: {} });
          });
        }
        this.$refs.list.$forceUpdate();
      }
    },
  },
};
</script>
