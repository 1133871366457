<template>
  <div class="full-height rs-page-biz-provider-list">
    <rs-list
      ref="list"
      name="mock-corpConfig"
      keywords="corp_name,corp_id,crm_id"
      :filter-eq="['corp_id', 'crm_id']"
      filter-placeholder="请输入集团CODE、租户CODE进行精确检索，输入集团名称进行模糊检索"
    >
      <rs-search>
        <rs-filter name="corp_name"></rs-filter>
        <rs-filter name="corp_id"></rs-filter>
        <rs-filter name="crm_id"></rs-filter>
      </rs-search>
      <!--      <rs-field name="_id" hidden></rs-field>-->
      <rs-field name="id" hidden></rs-field>
      <rs-field name="corp_id"></rs-field>
      <rs-field name="corp_name"></rs-field>
      <rs-field name="crm_id"></rs-field>
      <rs-field name="privatization" align="center">SAAS</rs-field>
      <rs-field name="wx_corp_list" v-slot="row">
        {{ getText(row) }}
      </rs-field>
      <rs-field name="created_at"></rs-field>
      <rs-field name="updated_at"></rs-field>
      <rs-field type="toolbar" :include="['edit']" v-slot="props">
        <rs-button name="edit" :payload="{ query: { id: props._id } }" />
      </rs-field>
      <rs-toolbar :include="['add']" exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
import Config from "rsui-core/src/config";
import moment from "moment";
import UpdateEndDateForm from "@/components/tenant-module-info/update-end-date-form.vue";
import CorpConfigAdd from "@/dialogs/corp-config-add.vue";

export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    // 列表数据变化时，call外部接口返回数据
    beforeQuery(e, params, { model, key }) {
      e.preventDefault();
      const { page, size, conditions } = params[0];
      this.$refs.list.loading = true;
      this.$rs_post(Config.API.RSTORE_CORP_CONFIG, {
        page,
        size,
        conditions,
        orderby: "created_at desc",
      })
        .then(
          (resp) => {
            if (resp?.content) {
              resp.content.list.forEach((d) => {
                d.id = d._id;
                d.created_at = moment(d.created_at).format("YYYY-MM-DD hh:mm:ss");
                d.updated_at = moment(d.updated_at).format("YYYY-MM-DD hh:mm:ss");
              });
              this.$refs.list.onQueryrSuccess(resp.content);
            }
          },
          (e) => this.$rs_error(e?.msg || "接口错误"),
        )
        .finally(() => (this.$refs.list.loading = false));
    },
    getText(row) {
      return row.wx_corp_list?.length
        ? `${row.wx_corp_list[0].wx_corp_id}（${row.wx_corp_list[0].wx_corp_name}）-共${row.wx_corp_list.length}项`
        : "微信小程序模式";
    },
    onMockCorpConfigAddBeforeClick(e) {
      e.preventDefault();
      this.$rs_dlg({
        parent: this,
        title: "新增租户",
        cardStyle: "width:560px",
        showFooter: false,
        contentComponent: CorpConfigAdd,
        contentComponentProps: {},
      });
    },
  },
};
</script>
