<template>
  <div class="q-pa-sm full-height ops-ten-module-version-form column">
    <rs-form ref="form" name="ten-module-version" class="col-auto" :value="{ info: '' }" @change="onChange">
      <rs-field type="group" label="基本信息">
        <rs-field name="id" hidden></rs-field>
        <rs-field name="module"></rs-field>
        <rs-field name="version"></rs-field>
        <rs-field name="chgDate"></rs-field>
        <rs-field
          name="summary"
          col="12"
          :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
        ></rs-field>
        <rs-field
          name="info"
          col="12"
          :widget="{
            _type: 'rs-editor',
            toolbar: [
              ['left', 'center', 'right', 'justify'],
              ['bold', 'italic', 'underline', 'strike', 'link'],
              ['undo', 'redo'],
            ],
          }"
        ></rs-field>
      </rs-field>

      <rs-field type="group" label="依赖的服务版本" col="12">
        <rs-list
          ref="list"
          class="col"
          :selection="false"
          hiddenTop
          name="ten-service-version"
          editMode="all"
          hideBottom
          :loadDefault="false"
          :data="serviceList"
        >
          <rs-field name="service.id" :editable="false"></rs-field>
          <rs-field name="service.name" :editable="false"></rs-field>
          <rs-field name="version" width="30%" :beforeRender="versionBeforeRender"></rs-field>
        </rs-list>
      </rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="ops.moduleVersion._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      id: this.$route.query.id,
      serviceList: [],
      tenServiceService: new ModelService(),
      tenServiceVersionService: new ModelService(),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    versionBeforeRender: ({ props, row, core }) => {
      return {
        ...props,
        disable: row.disable || false,
        widget: { _type: "rs-select", data: row.vList },
      };
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("module")) {
        if (val.module) {
          this.getServiceList(val.module.id);
        } else {
          this.serviceList = [];
        }
      }
    },
    //
    getServiceList(moduleId) {
      this.tenServiceService
        .list(
          {
            conditions: [{ key: "modules.id", vals: [moduleId] }],
            fields: ["id", "name"],
          },
          "ten-service",
        )
        .then((resp) => {
          const list = resp.list || [];
          const map = {};
          list.forEach((d) => {
            map[d.id] = {
              service: { ...d },
              version: null,
            };
          });
          this.getAllServiceVersions(list.map((d) => d.id)).then((versionMap) => {
            if (this.id) {
              this.tenServiceVersionService
                .list(
                  {
                    conditions: [{ key: "modules.id", vals: [this.id] }],
                    fields: ["id", "version", "service.id"],
                  },
                  "ten-service-version",
                )
                .then((result) => {
                  (result.list || []).forEach((d) => {
                    const m = map[d.service.id];
                    if (m) {
                      m.id = d.id;
                      m.version = `${d.id}`;
                      m.disable = true;
                    }
                  });
                  // 将vList 赋值【version 下拉数据设置】
                  this.serviceList = Object.values(map).map((d) => {
                    d.vList = versionMap[d.service.id];
                    return d;
                  });
                });
            } else {
              // 将vList 赋值【version 下拉数据设置】
              this.serviceList = Object.values(map).map((d) => {
                d.vList = versionMap[d.service.id];
                return d;
              });
            }
          });
        });
    },
    // 根据serviceIds 获取对应version 下拉数据
    getAllServiceVersions(ids) {
      return Promise.all(ids.map((d) => this.getItemServiceVersions(d))).then((result) => {
        const serviceMapVersions = (result || []).reduce((map, d) => {
          map[d.id] = d.versionList;
          return map;
        }, {});
        return serviceMapVersions;
      });
      // return this.tenServiceVersionService
      //   .list(
      //     {
      //       conditions: [{ key: "service.id", vals: ids }],
      //       fields: ["id", "version", "service.id"],
      //       orderby: "id desc",
      //       page: 1,
      //       size: 10, // 展示最新10个版本号
      //     },
      //     "ten-service-version",
      //   )
      //   .then((r) => {
      //     const serviceMapVersions = {};
      //     (r.list || []).forEach((d) => {
      //       const m = serviceMapVersions[d.service.id];
      //       const item = { name: d.version, code: `${d.id}` };
      //       if (m) {
      //         m.push(item);
      //       } else {
      //         serviceMapVersions[d.service.id] = [item];
      //       }
      //     });
      //     return serviceMapVersions;
      //   });
    },
    // 根据单个serviceId 获取对应version 下拉数据
    getItemServiceVersions(id) {
      return this.tenServiceVersionService
        .list(
          {
            conditions: [{ key: "service.id", vals: [id] }],
            fields: ["id", "version", "service.id"],
            orderby: "id desc",
            page: 1,
            size: 10, // 展示最新10个版本号
          },
          "ten-service-version",
        )
        .then((r) => {
          const versionList = [];
          (r.list || []).forEach((d) => {
            versionList.push({ name: d.version, code: `${d.id}` });
          });
          return { id, versionList };
        });
    },

    // 获取ten-service-version 数据
    getServiceData() {
      const formCoreDic = this.$refs.list.tableForm.formCoreDic;
      return Object.values(formCoreDic).map((d) => {
        return {
          id: +d.value.version,
        };
      });
    },
    beforeRequest(e, params) {
      if (!this.id) {
        this.$refs.form.core.setValue({ info: "" });
      }
    },
    // 保存表单时
    beforeSubmit(e, params) {
      return this.$refs.list.tableForm.validate().then((vaildateArr) => {
        const validateSucces = !vaildateArr || vaildateArr.findIndex((d) => !!d) === -1;
        if (validateSucces) {
          params.services = this.getServiceData();
          return {
            params,
            resolve: ({ response }) => {
              this.$rs_actionRefresh("ops.moduleVersion._view");
            },
          };
        } else {
          e.preventDefault();
        }
      });
    },
  },
};
</script>
