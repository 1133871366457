<template>
  <div class="q-pa-sm full-height ten-database-form">
    <rs-form
      ref="form"
      name="ten-database"
      @change="onChange"
      :value="{ platform: 'postgres', mode: 'personal', slaveEnable: false }"
    >
      <rs-field name="id" hidden></rs-field>
      <rs-field name="tenant" :readonly="!!tenantId"></rs-field>
      <rs-field name="tenant.uname" hidden></rs-field>
      <rs-field name="mode" readonly></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="platform" readonly></rs-field>
      <rs-field name="version" readonly></rs-field>
      <rs-field name="dbHost"></rs-field>
      <rs-field name="dbPort"></rs-field>
      <rs-field name="database" :hidden="!id" readonly></rs-field>
      <rs-field name="dbPass" :widget="{ type: 'password' }" col="8"></rs-field>
      <rs-field type="group" col="4" :expansion="false"></rs-field>
      <rs-field name="slaveEnable"></rs-field>
      <rs-field name="slaveHost" :required="slaveEnable"></rs-field>
      <rs-field name="slavePort" :required="slaveEnable"></rs-field>
      <rs-field
        name="dbCpu"
        :widget="{ _type: 'rs-input-select', selectOpts: 'core', defSelectedValue: 'core' }"
      ></rs-field>
      <rs-field name="dbMem" :widget="{ _type: 'rs-input-select', selectOpts: 'g', defSelectedValue: 'g' }"></rs-field>
      <rs-field name="dbDisk" :widget="{ suffix: 'GB' }"></rs-field>
      <rs-field
        name="info"
        col="12"
        :widget="{ _type: 'input', inputStyle: { height: '50px' }, type: 'textarea' }"
      ></rs-field>
      <rs-toolbar>
        <rs-button name="cancel"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
import pick from "lodash/pick";
export default {
  components: {},
  data: function() {
    return {
      id: null,
      tenantId: null,
      modelService: new ModelService(),
      slaveEnable: false,
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.tenantId = this.$route.query.tenantId;
    if (!this.id && this.tenantId) {
      this.modelService
        .refs(
          [this.tenantId],
          {
            fields: ["id", "uname", "name"],
          },
          "tenant",
        )
        .then((resp) => {
          if (resp && resp[0]) {
            this.$refs.form.core.setValue({ tenant: resp[0] });
          }
        });
    }
  },
  methods: {
    // 保存表单时
    beforeSubmit(e, params) {
      e.preventDefault();
      params.database = "rs_" + params.tenant.uname;
      const testPromise = [];
      const testParams = pick(params, ["platform", "database", "dbPass"]);
      testParams.dbUser = "portal";
      // 检查master
      testPromise.push(
        this.$rs_post("/api/deploy/db/server/test", {
          ...testParams,
          dbHost: params.dbHost,
          dbPort: params.dbPort,
        }),
      );
      if (this.slaveEnable) {
        // 检查slave
        testPromise.push(
          this.$rs_post("/api/deploy/db/server/test", {
            ...testParams,
            dbHost: params.slaveHost,
            dbPort: params.slavePort,
          }),
        );
      }
      Promise.all(testPromise).then((respList) => {
        let isSuc = true;
        let version = null;
        respList.every((resp) => {
          if (resp && resp.code === 0 && resp.content.result) {
            // 数据库版本号
            version = resp.content.version;
            return true;
          }
          isSuc = false;
          this.$rs_warning("DB配置无效：" + resp.content.message || resp.message || "");
          return false;
        });
        if (isSuc) {
          // 设置版本号
          params.version = version;
          e.next({
            params,
            resolve: ({ response }) => {
              this.$rs_actionRefresh("tenants.dbs._view", !this.tenantId ? {} : { query: { tenantId: this.tenantId } });
            },
          });
        }
      });
    },
    onChange(val, fireKey, core) {
      if (fireKey.includes("slaveEnable")) {
        this.slaveEnable = val.slaveEnable;
      }
    },
  },
};
</script>
