<template>
  <div class="q-pa-sm  full-height column  ">
    <div class="bg-white  rs-radius" style="height: 56px;">
      <div class="row q-pa-sm q-gutter-sm">
        <q-select
          class="col-2"
          v-model="service"
          :label="$t_settings('logs-service')"
          dense
          :options="options"
        ></q-select>
        <q-checkbox v-model="auto" :label="$t_settings('logs-autoRefresh')">
          <q-tooltip anchor="center right" self="center left">{{ $t_settings("logs-refreshLabel") }}</q-tooltip>
        </q-checkbox>
        <q-space></q-space>
        <q-option-group
          v-model="relativeTime"
          @input="sectionChange"
          :options="relativeTimeOptions"
          color="primary"
          inline
        />
        <q-space></q-space>
        <span class="self-center">
          {{ $t_settings("logs-prefixLengthLabel") }}
          <q-badge color="orange" text-color="black" :label="logs.length" />
          {{ $t_settings("logs-suffixLengthLabel") }}
        </span>
        <div v-if="startTime">
          <q-badge>{{ startTime }}</q-badge>
          <br />
          <q-badge>{{ endTime }}</q-badge>
        </div>
        <q-btn
          icon="autorenew"
          size="xs"
          @click="getNext"
          class=""
          color="primary"
          :label="$t_settings('logs-getLogBtn')"
        ></q-btn>
        <q-btn
          icon="delete"
          size="xs"
          @click="logs = []"
          class=""
          color="red"
          :label="$t_settings('logs-clearLogBtn')"
        ></q-btn>
      </div>
    </div>
    <div class="logs-wraper bg-grey-9 text-white scroll col rs-radius full-width ">
      <pre
        @dblclick="toggleStyle"
        style="margin: 0;overflow:visible;background:unset"
        v-for="(log, index) in logs"
        :key="index"
        v-highlightjs="{ language: 'sql', code: `${$rs_user.nickName}@${$rs_user.platformName}:` + log }"
      ></pre>
      <div class="blink-cursor q-my-sm">&nbsp;{{ `${$rs_user.nickName}@${$rs_user.platformName}:` }}</div>
    </div>
  </div>
</template>
<script>
import { date } from "quasar";
const DELAY = 10; // 10s 延时获取日志
function relavtiveSeconds(minites = 0, delay = 0) {
  return Math.ceil((Date.now() - 60 * 1e3 * minites) / 1000) - delay;
}
export default {
  name: "LogsListCache",
  data: function() {
    return {
      service: "sys-service",
      logs: [],
      options: ["sys-service", "main-data", "data-service"],
      relativeTimeOptions: [
        {
          label: this.$t_settings("logs-relative10Minute"),
          value: 10,
        },
        {
          label: this.$t_settings("logs-relative30Minute"),
          value: 30,
        },
        {
          label: this.$t_settings("logs-relative60Minute"),
          value: 60,
        },
        {
          label: this.$t_settings("logs-relative1Day"),
          value: 60 * 24,
        },
      ],
      from: relavtiveSeconds(10),
      to: relavtiveSeconds(0, DELAY),
      offset: 0,
      auto: false,
      startTime: null,
      relativeTime: 10,
      timer: null,
    };
  },
  computed: {
    endTime: function() {
      return date.formatDate(this.to * 1000, "YYYY-MM-DD HH:mm:ss");
    },
  },
  beforeDestroy() {
    this.getNext = () => {};
  },
  methods: {
    toggleStyle(e) {
      let re = e.target.style.whiteSpace;
      if (re == "nowrap") {
        re = "";
        e.target.style.color = "unset";
      } else {
        re = "nowrap";
        e.target.style.color = "red";
      }
      e.target.style.whiteSpace = re;
    },
    getNext(delay = 5000) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getLogs();
      }, delay);
    },
    sectionChange(val) {
      this.from = relavtiveSeconds(val);
    },
    cleanScreen() {
      this.logs = [];
      this.from = relavtiveSeconds(this.relativeTime);
    },
    add(logs) {
      if (!Array.isArray(logs) || !logs.length) {
        return;
      }
      const len = this.logs.length + logs.length;

      if (len >= 1000) {
        this.logs.splice(0, len - 1000);
      }
      this.logs = [...this.logs, ...logs];

      setTimeout(() => {
        const el = this.$el.querySelector(".logs-wraper");
        el.scrollTop = el.scrollHeight;
      });
    },
    getLogs() {
      if (!this.startTime) {
        this.startTime = date.formatDate(this.from * 1000, "YYYY-MM-DD HH:mm:ss");
      }
      this.$rs_post("/api/ops/logs/search", {
        from: this.from,
        to: this.to,
        topic: this.service,
        offset: this.offset,
      }).then((resp) => {
        const content = resp.content;
        if (content.suc) {
          const newLogs =
            content.logs &&
            content.logs.map((log) => {
              return log.mLogItem.mContents
                .filter((c) => c.mKey === "log")
                .map((l) => l.mValue)
                .join("\n");
            });
          this.add(newLogs);

          if (content.count === 0 || this.offset >= 500) {
            this.from = this.to;
            this.to = relavtiveSeconds(0, DELAY);
            this.offset = 0;
            if (this.auto) {
              this.getNext();
            }
          } else {
            this.offset += 100;
            this.getNext(500);
          }
        } else {
          throw Error(content.errMsg);
        }
      });
    },
  },
};
</script>
