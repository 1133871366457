<template>
  <div class="rs-page-product-type full-height">
    <rs-list
      ref="list"
      name="product-type"
      keywords="name,id"
      :filter-placeholder="$t_mdata('productType-filterPlaceholder')"
    >
      <rs-search label-width="5em">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="stockType"></rs-filter>
        <rs-filter name="stockUnit"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="stockType"></rs-field>
      <rs-field name="stockUnit"></rs-field>
      <rs-field name="amountScale"></rs-field>
      <rs-field name="enable"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" exclude="*" :include="['edit', 'changeEnable']" v-slot="row">
        <rs-button
          name="changeEnable"
          :icon="row.enable ? 'lock' : 'lock_open'"
          :label="row.enable ? $t_s('disable') : $t_s('enable')"
        />
        <rs-button name="edit" action="mdata.productType._edit"></rs-button>
      </rs-field>
    </rs-list>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  data: function() {
    return {
      modelService: new ModelService(),
    };
  },
  computed: {},
  methods: {
    onProductTypeChangeEnable(e, params) {
      const changeVal = !params.enable;
      this.modelService
        .update(
          {
            id: params.id,
            enable: changeVal,
          },
          "product-type",
        )
        .then(() => {
          const row = this.$refs.list.innerList.find((d) => d.id === params.id);
          row.enable = changeVal;
        });
    },
  },
};
</script>
