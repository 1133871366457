<template>
  <rs-form ref="form" name="mock-corpLoginInfo" no-border allDirty class="full-height row">
    <rs-field type="group" label="基本信息">
      <rs-field name="uid" readonly></rs-field>
      <rs-field name="wx_avatar" readonly v-slot="row">
        <img class="rs-border " style="width:120px" :src="row.wx_avatar" alt="" />
      </rs-field>
      <rs-field name="wx_qr_code" readonly v-slot="row">
        <img class="rs-border " style="width:120px" :src="row.wx_qr_code" alt="" />
      </rs-field>
      <rs-field name="created_at"></rs-field>
      <rs-field name="updated_at"></rs-field>
    </rs-field>
    <rs-field type="group" label="企微登录信息">
      <rs-field name="wx_corp_id"></rs-field>
      <rs-field name="wx_app_id" label="应用APPID"></rs-field>
      <rs-field name="wx_open_user_id"></rs-field>
    </rs-field>
    <rs-field type="group" label="微信登录信息">
      <rs-field name="wx_app_id"></rs-field>
      <rs-field name="wx_open_user_id" label="登录OPENID"></rs-field>
    </rs-field>
    <rs-field type="group" label="雇员帐号信息">
      <rs-list
        ref="empList"
        class="full-width"
        name="mock-corpLoginInfo"
        :data="corpEmpList"
        :loadDefault="false"
        hideBottom
        :selection="false"
      >
        <rs-field name="corp_id" label="集团信息"></rs-field>
        <rs-field name="emp_code"></rs-field>
        <rs-field name="emp_name"></rs-field>
        <rs-field name="emp_mobile"></rs-field>
        <rs-field name="emp_state" v-slot="row">{{ row.emp_state ? "正常" : "禁用" }}</rs-field>
        <rs-field name="is_wx_choose_default" v-slot="row">{{ row.is_wx_choose_default ? "是" : "否" }}</rs-field>
      </rs-list>
    </rs-field>
    <rs-field type="group" name="corp_emp_user" label="登录网点信息">
      <rs-list
        ref="userList"
        class="full-width"
        name="mock-corpLoginInfo"
        :data="corpEmpUser"
        :loadDefault="false"
        hideBottom
        :selection="false"
      >
        <rs-field name="site_code"></rs-field>
        <rs-field name="site_name"></rs-field>
        <rs-field name="guider_id"></rs-field>
        <rs-field name="updated_at" label="网点登录时间"></rs-field>
      </rs-list>
    </rs-field>
  </rs-form>
</template>
<script>
import Config from "rsui-core/src/config";
import moment from "moment/moment";

export default {
  mixins: [],
  data() {
    return {
      gatewaySet: null, //部署的会员服务地址
      corpEmpList: [], // 企微配置
      corpEmpUser: [], // 商品服务
    };
  },
  computed: {},
  created() {
    this.wxAppId = this.$route.query.id;
  },
  methods: {
    // 表单数据获取
    beforeRequest(e, params, eventId) {
      e.preventDefault();
      this.$rs_post(`${Config.API.RSTORE_CORP_LOGIN_FIND}/${this.wxAppId}`, {}).then((res) => {
        res.content.created_at = moment(res.content.created_at).format("YYYY-MM-DD hh:mm:ss");
        res.content.updated_at = moment(res.content.updated_at).format("YYYY-MM-DD hh:mm:ss");
        e.context.core.value = {};
        e.context.core.setValue(res.content || {}, { isDefault: true });
        this.corpEmpList = res.content.corp_emp_list;
        const empCode = res.content.corp_emp_list.map((i) => i.emp_code);
        const corpId = res.content?.corp_emp_list?.[0].corp_id;
        this.$rs_post(`${Config.API.RSTORE_CORP_EMP_USER_LIST}`, {
          page: 1,
          size: 50,
          conditions: [
            {
              key: "emp_code",
              op: "in",
              vals: empCode,
            },
            {
              key: "corp_id",
              op: "eq",
              vals: corpId,
            },
          ],
          orderby: "created_at desc",
        }).then((resp) => {
          console.log(resp);
        });
      });
    },
  },
};
</script>
