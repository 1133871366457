import "rsui-core/src/quasar";
// import "@/system/entry";
import boot from "rsui-core/src/system/entry";
import views from "@/views";
// import { client } from "rsui-core/src/post-message";
import I18n from "./views/i18n-setting.vue";
import Header from "./layout/header.vue";
import Profile from "./layout/profile.vue";
import RsLogin from "./views/login/login.vue";

// 支付数据查询 需要先选择组件，添加rs-search扩展
import RsSearchExt from "rsui-core/src/core/components/list/rs-search-simple-extend";

const isProd = process.env.NODE_ENV === "production" && process.env.VUE_APP_ENV === "production";
const dzApi=isProd ? "https://dz-service.qingger.cn" : "https://dz.service.qingger.cn"; // 短信请求地址
boot({
  views,
  assignConfig: (Config, host) => {
    const ns = "/ops";
    Object.assign(Config.SYS, {
      TASK_JOBS_GROUP: "opsProcessor",
      BASE_SERVICE: "ops",
      SYSTEM_SERVICE: "ops",
      TRANSLATION_SERVICE: null,
      APP_NAME: "ops",
      APP_CONFIG: {
        defaultAppConf: {
          contact: false,
          copyright: false,
          slogan: false,
        },
        changableAppConf: {
          // 初始配置配置可被覆盖
          homeLogo: require("./views/login/home-logo.png"),
          loginLogo: require("./views/login/login-logo.png"),
        },
      },
    });
    Object.assign(Config.API, {
      LOGIN: `${host}/api/login`,
      CAPTCHA: `${host}/api/captcha?scene=:scene&t=`,
      LOGOUT: `${host}/api/logout`,
      REFRESHTOKEN: `${host}/api/refreshToken?setCookie=true`,
      ANON_TOKEN: `${host}/api/auth`,
      SEND_CODE: `${host}/api/sendCode`,
      RESET_PASSWORD: `${host}/api/password/reset`,
      CHANGE_PASSWORD: `${host}/api/password/change`,
      MENUS: `${host}/api${ns}/menus/list?views=true`,
      MODEL_SET_ENABLE: `${host}/api/:module/:model/:target/setEnable/:status`,
      OPSVARS: `${host}/api/system/vars`,
      SMS_RUNSA_URL: isProd ? "https://smsrunsa.service.runsasoft.com" : "https://sms-maintence-uat.qingger.cn/api", // 短信请求地址
      APPDEPLOY_RUNSA_URL: isProd ? "" : "",
      IMPORTS_GROUP: `${host}/api/fields/imports/group`,
      IMPORTS_FIELDS: `${host}/api/fields/imports/list`,
      IMPORTS_DOWNLOAD: `${host}/api/fields/imports/download`,
      UPLOAD_TICKET: `${host}/api/resource/upload/ticket`,
      // 租户菜单管理中按钮新建、编辑、删除、启用禁用
      TEN_TENANT_MENU_ACTION_CREATE: `${host}/api/ops/ten-tenant-menu-action/create`,
      TEN_TENANT_MENU_ACTION_UPDATE: `${host}/api/ops/ten-tenant-menu-action/update`,
      TEN_TENANT_MENU_ACTION_REMOVE: `${host}/api/ops/ten-tenant-menu-action/remove`,
      TEN_TENANT_MENU_ACTION_SET_ENABLE: `${host}/api/ops/ten-tenant-menu-action/setEnable`,

      // 租户详情中 订购模块详情中 系统配置右侧列表的新建和编辑 以及启用、删除
      TEN_MODULE_SETTING_DETAIL_CREATE_OR_UPDATE: `${host}/api/ops/ten-module-setting-detail/createOrUpdate`,
      TEN_MODULE_SETTING_DETAIL_SET_ENABLE: `${host}/api/ops/ten-module-setting-detail/setEnable`,
      TEN_MODULE_SETTING_DETAIL_REMOVE: `${host}/api/ops/ten-module-setting-detail/remove`,
      // 租户详情中 订购模块详情中 系统配置右侧列表的 CRM应用授权 以及 修改结束日期
      TEN_MODULE_SETTING_DETAIL_CRM_APP_AUTH_CREATE_OR_UPDATE: `${host}/api/ops/ten-module-setting-detail/crmAppAuth/createOrUpdate`, // 修改授权
      TEN_MODULES_END_DATE_UPDATE: `${host}/api/ops/ten-modules/endDate/update`, // 修改结束日期
      // 支付数据查询
      TEN_PAY_ORDER_LIST: `${host}/api/ops/pay/model/ev_order_common/list`, // 订单列表
      TEN_PAY_REFUND_LIST: `${host}/api/ops/pay/model/ev_order_refund/list`, // 退单列表
      //RStore

      RSTORE_CORP_CONFIG: `${dzApi}/admin/model/corp-config/list`,
      RSTORE_CORP_CONFIG_UPDATE: `${dzApi}/admin/model/corp-config/update`,
      RSTORE_CORP_IMPORT: `${dzApi}/admin/corp/import`,
      RSTORE_CORP_CONFIG_FIND: `${dzApi}/admin/model/corp-config/find`,
      RSTORE_CORP_LOGIN: `${dzApi}/admin/model/corp-wx-user/list`,
      RSTORE_CORP_EMP_USER_LIST: `${dzApi}/admin/model/corp-emp-user/list`,
      RSTORE_CORP_LOGIN_FIND: `${dzApi}/admin/model/corp-wx-user/find`,
    });
  },
  afterBootup(vue) {
    vue.component("rs-search", RsSearchExt);
    vue.component("RsHeader", Header);
    vue.component("RsProfile", Profile);
    vue.component("RsLogin", RsLogin);
    vue.component("RsI18n", I18n);
  },
});
