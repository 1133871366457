<template>
  <RsJobLog :id="id" :seconds="seconds" :jobGroup="jobGroup" />
</template>
<script>
export default {
  data: function() {
    return {
      id: this.$route.query.id,
      seconds: this.$route.query.seconds,
      jobGroup: this.$route.query.jobGroup,
    };
  },
  computed: {},
  mounted() {},
};
</script>
