<template>
  <div class="q-pa-sm column full-height">
    <div class="col-auto">
      <q-tabs class="bg-white text-primary" v-model="tab" align="left">
        <q-tab name="excelProcessor" :label="$t_settings('jobsList-importExportTab')" />
      </q-tabs>
      <q-separator />
    </div>
    <q-tab-panels class="col" v-model="tab">
      <q-tab-panel class="q-pa-sm" name="excelProcessor">
        <q-table
          class="rs-sticky-header-table"
          :rows-per-page-options="[10]"
          :data="data"
          :columns="columns"
          row-key="id"
          :pagination="pagination"
        >
          <template v-slot:body-cell-op="props">
            <q-td :props="props">
              <div>
                <router-link
                  class="rs-link"
                  :to="{
                    name: 'settings.jobs._view2',
                    query: { id: props.row.id },
                  }"
                >
                  {{ $t_settings("jobsList-toTriggerBtn") }}
                </router-link>
              </div>
            </q-td>
          </template>
        </q-table>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "JobListCache",
  data: function() {
    return {
      tab: "excelProcessor",
      data: [],
      columns: [
        {
          field: "id",
          label: this.$t_settings("jobsList-id"),
          align: "left",
        },
        {
          field: "triggerType",
          label: this.$t_settings("jobsList-type"),
          align: "left",
          format: (val) => {
            return val == 1 ? this.$t_settings("jobsList-onceType") : this.$t_settings("jobsList-cycleType");
          },
        },
        {
          field: "jobCron",
          label: "Cron",
          align: "left",
        },
        {
          field: "jobDesc",
          label: this.$t_settings("jobsList-jobDesc"),
          align: "left",
        },
        {
          field: "triggerStatus",
          label: this.$t_settings("jobsList-triggerStatus"),
          align: "left",
          format: (val) => {
            switch (val) {
              case 0:
                return this.$t_settings("jobsList-run");
              case 1:
                return this.$t_settings("jobsList-stop");
              case 2:
                return this.$t_settings("jobsList-finish");
              default:
                return val;
            }
          },
        },
        {
          field: "triggerNextTime",
          label: this.$t_settings("jobsTrigger-triggerNextTime"),
          align: "left",
          format: (val) => {
            return val > 0 ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "-";
          },
        },
        {
          name: "op",
          label: this.$t_s("operate"),
          align: "center",
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
    };
  },
  mounted() {
    this.$rs_post("/api/ops/task/jobs", {
      start: (this.pagination.page - 1) * this.pagination.rowsPerPage,
      length: this.pagination.rowsPerPage,
      cltId: 1001,
      jobGroup: this.tab,
    }).then((resp) => {
      if (resp && resp.content && resp.content.data) {
        this.$set(this, "data", resp.content.data);
      }
    });
  },
};
</script>
