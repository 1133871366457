<template>
  <div class="q-pa-md full-height company-form">
    <div class="bg-white full-height scroll">
      <rs-classify
        namespace="department"
        :treeCondition="[{ key: 'rcode', vals: [id] }]"
        class="col"
        :canAddRootNode="false"
        :checkAppendRemove="checkAppendRemove"
        :canDeleteNode="canDeleteNode"
        :getAppendCond="getAppendCond"
      >
        <rs-field name="id" />
        <rs-field name="nickName" />
      </rs-classify>
    </div>
  </div>
</template>
<script>
import RsClassify from "rsui-core/src/core/components/classify/classify.vue";
export default {
  components: { RsClassify },
  data: function() {
    return {
      id: this.$route.query.id,
    };
  },
  computed: {},
  methods: {
    checkAppendRemove(node, mountable) {
      return !!node.pid && mountable;
    },
    canDeleteNode(node) {
      return !!node.pid;
    },
    getAppendCond(node, defCond) {
      return [
        ...defCond,
        {
          key: "comp.id",
          vals: [this.id],
        },
      ];
    },
  },
};
</script>
