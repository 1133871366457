var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rs-form',{key:"ten-module-info-form",ref:"form",staticClass:"full-height ten-module-info-form",attrs:{"flexLayout":"","name":"ten-module-info","col":2,"bodyPadding":"q-ma-sm"},scopedSlots:_vm._u([{key:"default",fn:function(form){return [_c('rs-field',{attrs:{"name":"id"}}),_c('rs-field',{attrs:{"name":"tenant","readonly":""}}),_c('rs-field',{attrs:{"name":"module","readonly":""}}),_c('rs-field',{attrs:{"name":"version","readonly":""}}),_c('rs-field',{attrs:{"name":"startDate","readonly":""}}),_c('rs-field',{attrs:{"name":"endDate","widget":_vm.endDataWidget,"readonly":""}}),_c('rs-field',{attrs:{"name":"status","readonly":""}}),_c('rs-field',{attrs:{"name":"url"}}),_c('rs-field',{attrs:{"name":"ext","col":"12","rules":[_vm.configRule],"widget":{ _type: 'rs-ace-editor', height: '100px', lang: 'json' }}}),_c('rs-field',{attrs:{"name":"_","type":"group","label":"系统配置","groupIsCard":"","anchorHidden":"","groupClass":"q-mt-sm no-padding","col":12},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-splitter',{staticStyle:{"height":"400px"},attrs:{"limits":[30, 70]},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('rs-list',{ref:"leftList",staticClass:"col",attrs:{"indexColumn":"","name":"ten-module-setting","keywords":"name,id","wrapClass":"no-padding q-mr-sm full-height","selection":"single","filter-placeholder":"不限(输入内容与配置ID,配置名称关键字进行模糊匹配)","conditions":[
              { key: 'module.id', vals: [form.module && form.module.id] },
              { key: 'status', vals: [1] } ],"isPagination":false},on:{"selected":_vm.listSelected}},[_c('rs-field',{attrs:{"name":"id"}}),_c('rs-field',{attrs:{"name":"name"}}),_c('rs-field',{attrs:{"name":"comment"}}),_c('rs-field',{attrs:{"name":"insertable","hidden":""}})],1)]},proxy:true},{key:"after",fn:function(){return [_c('rs-list',{key:"settingDetailList",ref:"rightList",attrs:{"indexColumn":"","name":"ten-module-setting-detail","wrapClass":"no-padding q-ml-sm full-height","selection":false,"filterHidden":true,"isPagination":false,"preventDbClick":true,"editMode":"row","conditions":_vm.detailListConds,"btnBottom":_vm.isCRMAppAuth},scopedSlots:_vm._u([{key:"prependTop",fn:function(){return [_c('span',{staticClass:"q-pl-xs",staticStyle:{"height":"32px","line-height":"32px"}},[_vm._v(" "+_vm._s(_vm.selectedSetting.name || "")+" ")])]},proxy:true},{key:"bottom",fn:function(){return [(_vm.isCRMAppAuth)?_c('q-btn',{attrs:{"size":"sm","label":"修改授权","color":"primary"},on:{"click":_vm.updateAppAuth}}):_vm._e(),(_vm.isCRMAppAuth)?_c('q-btn',{staticClass:"q-ml-sm text-underline",attrs:{"flat":"","size":"sm","label":"修改履历","color":"primary"},on:{"click":_vm.appAuthHistory}}):_vm._e()]},proxy:true}],null,true)},[_c('rs-field',{attrs:{"name":"id","hidden":""}}),_c('rs-field',{attrs:{"name":"globalsStatus","hidden":""}}),_c('rs-field',{attrs:{"name":"globalsStatusChange","hidden":""}}),_c('rs-field',{attrs:{"name":"code","hidden":_vm.diffConf.hideFields.includes('code'),"rules":[_vm.codeRule],"editable":function (ref) {
                        var row = ref.row;

                        return !row.id;
            }}}),_c('rs-field',{attrs:{"name":"name","label":_vm.diffConf.labelMap.name,"editable":!_vm.isSpecType}}),_vm._l((_vm.extFields),function(f){return [_c('rs-field',{key:f.propName,attrs:{"name":("ext." + (f.propName)),"label":f.name,"hidden":!!f.dynmFormVals && !f.dynmFormVals.includes(_vm.selectedSetting.id),"widget":f.configs,"minWidth":"100px","editable":function (ref) {
                        var row = ref.row;

                        return !row.id || !!f.updatable;
            }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(f.configs._component)?_c(_vm.componentIs(f.configs._component),{key:props.id,tag:"component",attrs:{"row":props},on:{"componentValueInput":_vm.componentValueInput}}):_c('span',[_vm._v(_vm._s(_vm.getExtCellValue(props, f.propName)))])]}}],null,true)})]}),_c('rs-field',{attrs:{"name":"sysReserved","hidden":_vm.diffConf.hideFields.includes('sysReserved'),"editable":false}}),_c('rs-field',{attrs:{"name":"status","hidden":_vm.diffConf.hideFields.includes('status'),"widget":{ _type: 'toggle', falseValue: 0, trueValue: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('q-toggle',{attrs:{"dense":"","size":"sm","false-value":0,"true-value":1,"value":props.status,"disable":!props.globalsStatusChange && !!props.sysReserved},on:{"input":function (val) { return _vm.statusChange(val, props); }}})]}}],null,true)}),_c('rs-field',{attrs:{"type":"toolbar","hidden":_vm.diffConf.hideFields.includes('toolbar'),"include":['edit', 'delete', 'saveLine', 'cancelEdit'],"exclude":"*"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('rs-button',{attrs:{"name":"edit","auth":null,"hidden":_vm.isSpecType ? false : !_vm.selectedSetting.id || props.sysReserved}}),_c('rs-button',{attrs:{"name":"delete","auth":null,"hidden":!_vm.selectedSetting.id || props.sysReserved}})]}}],null,true)}),_c('rs-toolbar',{attrs:{"include":['add'],"exclude":"*"}},[_c('rs-button',{attrs:{"name":"add","hidden":!_vm.selectedSetting.insertable,"auth":null}})],1)],2)]},proxy:true}],null,true),model:{value:(_vm.splitterModel),callback:function ($$v) {_vm.splitterModel=$$v},expression:"splitterModel"}})]},proxy:true}],null,true)}),_c('rs-toolbar',[_c('rs-button',{attrs:{"name":"cancel"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }