<template>
  <div class="full-height rs-page-product-type-list">
    <rs-list name="product-type" keywords="name,id">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="name,id"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="stockUnit"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field type="toolbar" :include="['edit', 'specs']" exclude="*" v-slot="props">
        <rs-button
          name="specs"
          icon="list"
          color="info"
          label="规格列表"
          action="tenants.productSpec._view"
          :payload="{
            query: { typeId: props.id },
          }"
        ></rs-button>
      </rs-field>
      <rs-toolbar exclude="*"></rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
