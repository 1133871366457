<template>
  <rs-list ref="list" name="wechat-channel-shop-auth-info">
    <rs-field name="id" hidden></rs-field>
    <rs-field name="authAppId" desc="视频号小店ID"></rs-field>
    <rs-field name="nickName" desc="视频号小店名称"></rs-field>
    <rs-field name="principalName" desc="企业主体名称"></rs-field>
    <rs-field name="tenant.uname" label="所属租户代号"></rs-field>
    <rs-field name="tenant.name" label="所属租户名称"></rs-field>
    <rs-field name="status" desc="状态"></rs-field>
    <rs-field name="crtTime" desc="创建时间"></rs-field>
    <rs-field name="recUser" desc="更新人"></rs-field>
    <rs-field name="recTime" desc="更新时间"></rs-field>
    <rs-field type="toolbar" exclude="*" :include="['bind', 'unbind']" v-slot="row">
      <rs-button name="bind" color="primary" type="link" label="绑定租户" :hidden="!!row.tenant"></rs-button>
      <rs-button
        name="unbind"
        color="negative"
        type="link"
        label="解绑租户"
        :hidden="!row.tenant"
        :tips="`此操作将解绑${row.nickName}的租户，是否确认解绑？`"
      ></rs-button>
    </rs-field>
    <rs-toolbar exclude="*" :include="['getList']">
      <rs-button name="getList" color="primary" :loading="getListLoading" label="获取视频号小店列表"></rs-button>
    </rs-toolbar>
  </rs-list>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";

// 视频号小店
export default {
  data() {
    return {
      sv: new ModelService(),
      tenant: null,
      getListLoading: false,
    };
  },
  methods: {
    // 绑定租户
    onWechatChannelShopAuthInfoBind(e, params) {
      e.preventDefault();
      this.tenant = null; // 清空之前选中的租户
      const dlg = this.$rs_dlg({
        parent: this,
        title: "绑定租户",
        cardStyle: "width: 500px;",
        footerAlign: "right",
        content: (
          <div class="row items-center no-wrap q-py-lg q-px-xl">
            <span class="q-mr-md">租户</span>
            <rs-obj-select
              dense
              outlined
              filterable
              returnAllAttrs
              isSelectedMode
              selectProps={{ simpleMode: true }}
              filterKeywords="id,uname,name"
              class="rs-size-sm col"
              modelName="tenant"
              placeholder="输入租户ID/租户代号/租户名称检索"
              v-model={this.tenant}
              fields={[
                "id",
                "name",
                "uname",
                "deployMode",
                "principal",
                "contact",
                "status",
                "crtTime",
                "crtUser",
                "recTime",
                "recUser",
              ]}
            />
          </div>
        ),
      }).onOk((e, { content }) => {
        if (!this.tenant) {
          this.$rs_warning("请选择要绑定租户");
          return;
        }
        const p = {
          id: params.id,
          tenant: { id: this.tenant.id },
          tenCode: this.tenant.uname,
        };
        this.sv.update(p, "wechat-channel-shop-auth-info").then(() => {
          this.$rs_success("绑定租户成功!");
          this.$refs.list.refreshTable();
          dlg.hide();
        });
      });
    },
    // 解绑租户
    onWechatChannelShopAuthInfoUnbind(e, params) {
      e.preventDefault();
      const p = {
        id: params.id,
        tenant: null,
        tenCode: null,
      };
      this.sv.update(p, "wechat-channel-shop-auth-info").then((resp) => {
        this.$rs_success("解绑租户成功!");
        this.$refs.list.refreshTable();
      });
    },
    // 获取视频号小店列表
    onWechatChannelShopAuthInfoGetList(e, params) {
      e.preventDefault();
      this.getListLoading = true;
      this.$rs_get("/api/wechat/wx/service/pull/authList", {})
        .then((r) => {
          if (r.code === 0) {
            this.$rs_success("获取成功!");
            this.$refs.list.refreshTable();
          }
        })
        .finally(() => (this.getListLoading = false));
    },
  },
};
</script>
