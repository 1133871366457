<template>
  <div class="rs-page-role full-height">
    <rs-list ref="role" name="role" keywords="name" :filter-placeholder="$t_settings('role-filterPlaceholder')">
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter type="keywords" name="name"></rs-filter>
        <rs-filter name="id"></rs-filter>
        <rs-filter name="code"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="crtUser"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
        <rs-filter name="recUser"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="code"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="sysReserved"></rs-field>
      <rs-field name="description"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar">
        <rs-button name="edit" action="settings.role._edit"></rs-button>
        <rs-button name="delete" :tips="$t_settings('roleList-deleteTip')"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add', 'deleteAll']" exclude="*">
        <rs-button name="add" color="primary" action="settings.role._add"></rs-button>
        <rs-button name="deleteAll" :tips="$t_settings('roleList-deleteTip')"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    onRoleEdit(e, row) {
      return { actionPayload: { query: { id: row.id } } };
    },
  },
};
</script>
