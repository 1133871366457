<template>
  <div class="full-height rs-page-tenant-list">
    <rs-list
      name="tenant"
      keywords="uname,name"
      filter-placeholder="不限(输入内容与租户代号,租户名称关键字进行模糊匹配)"
    >
      <rs-search label-width="5em" :value="{ status: 1 }">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="name"></rs-filter>
        <rs-filter name="uname"></rs-filter>
        <rs-filter name="deployMode"></rs-filter>
        <rs-filter name="status"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="uname"></rs-field>
      <rs-field name="deployMode"></rs-field>
      <rs-field name="principal"></rs-field>
      <rs-field name="contact"></rs-field>
      <rs-field name="status"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="crtUser"></rs-field>
      <rs-field name="recTime"></rs-field>
      <rs-field name="recUser"></rs-field>
      <rs-field type="toolbar" :include="['edit']" exclude="*">
        <rs-button name="edit" color="primary" action="tenants.tenant._edit"></rs-button>
      </rs-field>
      <rs-toolbar :include="['add']" exclude="*">
        <rs-button name="add" color="primary" action="tenants.tenant._add"></rs-button>
      </rs-toolbar>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
