<template>
  <div class="scroll full-height">
    <!-- <rs-list name="site" :load-default="false">
      <rs-field name="name"></rs-field>
      <rs-field name="id"></rs-field>
      <rs-toolbar>
        <rs-button name="add"></rs-button>
      </rs-toolbar>
    </rs-list> -->
    <rs-toolbar ref="toolbar" type="form"></rs-toolbar>
    <rs-form name="site" separate-toolbar="toolbar" :value="obj">
      <rs-field name="name"></rs-field>
      <rs-field name="id"></rs-field>
      <rs-field label="test">
        <span class="text-red">this is test</span>
      </rs-field>
      <rs-toolbar></rs-toolbar>
    </rs-form>
    <!-- <div class="q-pa-md">
      <q-input dense outlined v-model="model" :rules="[(val) => !!val]" @focus="onFocus" placeholder="请输入">
        <template v-slot:prepend>
          <q-icon name="event" size="xs" color="grey-5"></q-icon>
        </template>
        <template v-slot:append>
          <q-icon name="close" size="sm" color="grey-5" @click.stop.prevent="onClick"></q-icon>
        </template>
      </q-input>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      v: "name",
      obj: {
        name: "name a",
        id: "",
        a: "12",
      },
      model: "qqqq",
      text: "",
      startTime: "",
      endTime: "",
      value: "",
      value0: "",
      value1: "",
      value2: new Date(2016, 9, 10, 18, 40),
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      value13: "",
      value14: "",
      value15: "",
      value16: "",
      value17: "",
      value18: "",
      value19: "",
      value20: "",
      value21: "",
      value22: "",
      value23: "",
      value24: "",
      value25: "",
      value26: "",
      value27: "",
      value28: new Date(),
      value29: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      value30: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      endDate: "",
      startDate: "",
      drawerLeft: false,
      drawerRight: true,
      pickerOptions3: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pickerOptions2: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pickerOptions1: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    input(e) {
      this.obj.name = e;
    },
    // onSiteAdd() {
    //   console.info(arguments);
    // },
    onSiteAdd() {},
    onSiteMyadd() {},
    onBeforeSubmit(p, next) {},
    onClick(event) {
      this.model = null;
    },
    onFocus() {},
  },
};
</script>
