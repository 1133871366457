<script>
// 许可数组件， 不限 OR 输入数字
export default {
  name: "SignatureConfigs",
  props: ["value", "signatureType", "tenantId"],
  data() {
    return { apiId: null };
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.apiId = this.signatureType === "self" ? newVal?.apiId || null : null;
      },
    },
    signatureType: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (this.signatureType !== "self" && this.apiId) {
          this.apiId = null;
          this.emitValue();
        }
      },
    },
  },
  mounted() {},
  computed: {
    conditions() {
      const conds = [{ key: "globals", vals: [true] }];
      if (this.tenantId) {
        conds.push({ key: "tenant.id", vals: [this.tenantId] });
      }
      return [{ key: "status", vals: [1] }, { or: conds }];
    },
  },
  methods: {
    emitValue() {
      let val = null;
      // 平台apiId签名
      if (this.signatureType === "self") {
        val = { apiId: this.apiId };
      }
      this.$emit("input", val);
    },
    validate() {
      return this.$refs.config?.validate();
    },
    resetValidation() {
      this.$refs.config?.resetValidation();
    },
  },
  render() {
    // 平台apiId签名
    if (this.signatureType === "self") {
      return (
        <rs-obj-select
          ref="config"
          plain
          clearable={false}
          v-model={this.apiId}
          modelName="ten-api-user"
          conditions={this.conditions}
          fields={[
            "id",
            "name",
            "globals",
            { props: { name: "tenant.name", label: "租户名称" } },
            "expire",
            "status",
            "crtTime",
            "recTime",
          ]}
          no-error-icon
          rules={[(val) => !!val || "此字段必填"]}
          on-input={this.emitValue}
        />
      );
    }
    return <div>signatureType != self 时，暂未开发</div>;
  },
};
</script>
