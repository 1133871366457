





















































































export default {
  data() {
    return {
      list: [],
      defaultItem: {
        image: "",
        linkAddr: "",
        version: "",
        date: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    saveAll() {
      const { image, version, date } = this.defaultItem;
      const allList = [
        { ...this.defaultItem, globals: true, config: { version, date } },
        ...this.list.map((i) => {
          return { ...i, image, config: { version, date } };
        }),
      ];
      console.info(allList);
      this.$rs_post("/api/ten-upgrade-instruction/saveAll", allList).then(() => {
        this.$rs_success("保存成功！");
      });
    },
    getList() {
      this.$rs_post("/api/ten-upgrade-instruction/list", { size: 2000, fields: ["tenant.name"] })
        .then((resp) => {
          return resp?.content?.list;
        })
        .then((list) => {
          const deft = (list || []).find((i) => i.globals);
          if (deft) {
            const { globals, image, linkAddr, module, config } = deft;
            const { version, date } = config || {};
            this.defaultItem = { globals, image, linkAddr, module, version, date };
          }
          this.list = (list || [])
            .filter((i) => !i.globals)
            .map((i) => {
              const { image, linkAddr, module, tenant } = i;
              return { image, linkAddr, module, tenant, globals: false };
            });
        });
    },
    removeItem(itm) {
      const idx = this.list.findIndex((i) => i.id === itm.id);
      this.list.splice(idx, 1);
    },
    picktenants() {
      const ids = this.list.map((i) => i?.tenant?.id).filter(Boolean);
      const conditions = [
        {
          key: "id",
          vals: ids,
          op: "notIn",
        },
      ];
      this.$rs_model_dlg({ modelName: "tenant", multiple: true, conditions, fields: ["id", "name", "status"] }).then(
        ({ selected, dlg }) => {
          if (selected.length > 0) {
            //
            console.info(selected);
            const lst = selected.map((i) => {
              const { id, name } = i || {};
              return { tenant: { id, name }, linkAddr: "", globals: false };
            });
            this.list = [...this.list, ...lst];
          }
          dlg.hide();
        },
      );
    },
  },
};
