<template>
  <div class="full-height subs__form">
    <rs-form name="platform" :col="3" label-width="7.5em" class="position-relative">
      <rs-field name="name" required></rs-field>
      <rs-field
        name="id"
        :widget="{ _type: 'rs-prefix-input', maxlength: 6, mask: 'XXXXXX' }"
        :readonly="!!id"
        :hidden="!isSub"
        :prefix="`${mainPlat}_`"
      ></rs-field>
      <rs-field name="id" :hidden="isSub" :readonly="!!id"></rs-field>
      <rs-field name="type" :value="2" readonly></rs-field>
      <rs-field name="bset" :hidden="!isSub" :widget="bsetFilter"></rs-field>
      <rs-field name="status" :hidden="!isSub" :value="1" :widget="{ clearable: false }"></rs-field>
      <rs-field
        name="adminUser.nickName"
        :hidden="!!id || !isSub"
        :required="true"
        :label="$t_settings('platform.administrator')"
      ></rs-field>
      <rs-field name="adminUser" :hidden="!id || !isSub" readonly v-slot="form">
        <div class="row full-height">
          <router-link
            class="self-center padding-form-item"
            :to="{ name: 'settings.account._edit', query: { id: form.adminUser && form.adminUser.id } }"
          >
            {{ form.adminUser && form.adminUser.nickName }}
          </router-link>
        </div>
      </rs-field>
      <rs-field name="adminUser.mobile" :hidden="!!id || !isSub" :required="true"></rs-field>
      <rs-field name="adminUser.email" :hidden="!!id || !isSub" :required="true"></rs-field>
      <rs-field
        type="wrapper"
        :help="$t_settings('platform.term_of_validity.help')"
        :label="$t_settings('platform.term_of_validity.name')"
      >
        <rs-field name="beginDate" col="5"></rs-field>
        <span class="col-1 text-center vertical-middle">&nbsp;&nbsp;</span>
        <rs-field name="endDate" col="6"></rs-field>
      </rs-field>
      <rs-field name="traceMonth" :hidden="!isSub"></rs-field>
      <rs-field name="logo" hidden :value="$rs_user.platformLogo"></rs-field>
      <rs-field type="wrapper" :hidden="!id || !isSub" :label="$t_settings('platform.loginAddress')">
        <span class="row items-center">
          <a :href="loginUrl">{{ loginUrl }}</a>
        </span>
      </rs-field>
      <rs-toolbar>
        <rs-button name="submit" :hidden="!isSub"></rs-button>
        <rs-button name="cancel" action="settings.subs._view"></rs-button>
      </rs-toolbar>
    </rs-form>
    <q-list v-if="isSub" class="q-pa-sm q-gutter-sm">
      <!-- <rs-group :label="$t_settings('subsForm-control')" class=" ">
        <q-checkbox v-model="bizController.limitEdit" :label="$t_settings('subsForm-controlLabel')"></q-checkbox>
        <br />
        <q-checkbox v-model="bizController.limitLogin" :label="$t_settings('subsForm-controlLabel2')"></q-checkbox>
      </rs-group> -->
      <rs-group :label="$t_settings('subsForm-ModuleAPP')" class=" ">
        <rs-list key="module" ref="moduleList" name="module" :filterHidden="true" :conditions="moduleConditions">
          <rs-field name="id"></rs-field>
          <rs-field name="name" v-slot="row">
            <a class="rs-link">{{ row.name }}</a>
            <!-- <a class="rs-link float-right">编辑</a> -->
          </rs-field>
          <rs-field name="version"></rs-field>
          <rs-field name="icon" v-slot="row">
            <svg class="rs-icon" aria-hidden="true">
              <use :xlink:href="row.icon"></use>
            </svg>
          </rs-field>
          <rs-field name="summary"></rs-field>
          <rs-field name="website"></rs-field>
          <rs-field name="license"></rs-field>
          <rs-field type="toolbar" v-slot="row">
            <rs-button v-if="row.id !== 'settings'" name="remove" type="link" :label="$t_s('remove')"></rs-button>
          </rs-field>
          <rs-toolbar include="delete,auth" exclude="*">
            <rs-button
              name="auth"
              model-name="module"
              :dialogConfig="dialogConfig"
              color="primary"
              icon="add"
              :label="$t_s('add')"
            ></rs-button>
          </rs-toolbar>
        </rs-list>
      </rs-group>
      <rs-group :label="$t_settings('authDataTitle')">
        <rs-auth-data ref="authData" type="platform" :id="id" />
      </rs-group>
      <rs-group :label="$t_settings('authActionTitle')" class=" ">
        <auth-tab ref="tab" tabs-class=" " :moduleIds="moduleIds" :scopes="[0]" :ids="restoreIds"></auth-tab>
      </rs-group>
    </q-list>
  </div>
</template>
<script>
import RsAuthData from "rsui-core/src/system/components/auth-data/rs-auth-data";
import AuthTab from "rsui-core/src/system/components/auth-tree/rs-auth-tab";
import ApiService from "rsui-core/src/system/service/api";
import ModelService from "rsui-core/src/core/model-service";
import { OP_TYPE } from "rsui-core/src/core/types";
export default {
  components: {
    AuthTab,
    RsAuthData,
  },
  data: function() {
    return {
      id: null,
      apps: [],
      mainPlat: "",
      moduleIds: [],
      host: "",
      isSub: false, // 是否是分销商平台
      bizController: {
        limitEdit: true,
        limitLogin: true,
      },
      restoreIds: [],
      bsetFilter: {
        conditions: [
          {
            key: "type",
            vals: ["DS"],
          },
          {
            key: "platforms",
            vals: [],
            op: "relNotExists",
          },
        ],
      },
      dialogConfig: {
        title: this.$t_settings("subsForm-addAppTitle"),
        listConfig: {
          multiple: true,
          isArray: true,
          returnAllAttrs: true,
          orderBy: "sort",
          fields: ["id", "name", "version", "startDate", "endDate", "status", "license"],
          conditions: [],
        },
      },
    };
  },
  computed: {
    moduleConditions: function() {
      if (this.id) {
        return [{ key: "platforms.id", vals: [this.id] }];
      }
      return [{ key: "id", vals: ["settings"] }];
    },
    loginUrl: function() {
      return `https://${this.host}/web/${this.id}/login`;
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      ApiService.grantMenuIds({ platform: this.id }).then((res) => (this.restoreIds = res));
    } else {
      this.isSub = true;
    }
    ApiService.variableByIds("system.platform.main,system.host").then(({ list }) => {
      if (list) {
        this.mainPlat = list.find((d) => d.id === "system.platform.main").value;
        this.host = list.find((d) => d.id === "system.host").value;
      }
    });
  },
  methods: {
    beforeRequest(e, params, eventId) {
      return {
        params,
        resolve: ({ params }) => {
          if (params) {
            this.isSub = params.type === 2;
          }
        },
      };
    },
    beforeQuery(e, params, eventId) {
      if (eventId.key === "module") {
        params[0].orderby = "sort";
        return {
          params,
          resolve: ({ response }) => {
            if (response && response.list) {
              this.setAddListCond(response.list);
              this.getRealTabs(response.list);
            }
          },
        };
      }
    },
    beforeSubmit({ preventDefault }, params) {
      params.modules = this.$refs.moduleList.innerList.map((d) => {
        return { id: d.id };
      });
      const menusAndModules = this.$refs.tab.getValue();
      return {
        params: { ...params, ...menusAndModules },
        resolve: ({ response }) => {
          if (response && response.content && response.content.id) {
            this.id = response.content.id;
          }
          this.$refs.authData.save(this.id).then(() => {
            this.$rs_actionRefresh("settings.subs._view");
          });
        },
      };
    },
    // 设置添加应用软件弹框list conditions
    setAddListCond(list = null) {
      this.dialogConfig.listConfig.conditions = [
        { key: "status", vals: [1] },
        { key: "id", op: OP_TYPE.NOTIN, vals: (list || this.$refs.moduleList.innerList).map((d) => d.id) },
      ];
    },
    // 批量删除应用软件
    removeModuleByIds(ids) {
      this.$refs.moduleList.innerList = this.$refs.moduleList.innerList.filter((d) => !ids.includes(d.id));
      this.setAddListCond();
      this.getRealTabs();
    },
    // 批量删除事件
    onModuleDeleteBeforeConfirm(e, params) {
      e.preventDefault();
      const ids = params.map((d) => d.id);
      if (ids.includes("settings")) {
        this.$rs_warning(this.$t_settings("subsForm-removeSettingsError"));
        return;
      }
      this.removeModuleByIds(ids);
    },
    // 单个删除事件
    onModuleRemove(e, params) {
      this.$q
        .dialog({
          title: this.$t_s("tips"),
          message: this.$t_s("delete-tip"),
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          this.$refs.moduleList.innerList.splice(params.__index, 1);
          this.setAddListCond();
          this.getRealTabs();
        });
    },
    // 添加按钮 在选择成功后返回 选中数据处理
    onModuleAuth(e, params, eventId) {
      // e.preventDefault();
      e.next({
        resolve: (selected, dlg) => {
          this.$refs.moduleList.innerList.push(...selected);
          this.setAddListCond();
          this.getRealTabs();
          dlg.hide();
        },
      });
    },
    // 添加和删除时触发数据权限 显示的tabs
    getRealTabs(list = null) {
      this.$set(
        this,
        "moduleIds",
        (list || this.$refs.moduleList.innerList).map((d) => d.id),
      );
    },
  },
};
</script>
