<template>
  <div class="full-height rs-thd-event-process-log-list">
    <rs-list name="thd-event-process-log" keywords="id" :conditions="orderConditions">
      <rs-search label-width="5em">
        <rs-filter name="id"></rs-filter>
        <rs-filter name="tenant"></rs-filter>
        <rs-filter name="corpAppId"></rs-filter>
        <rs-filter name="suiteAppId"></rs-filter>
        <rs-filter name="eventType"></rs-filter>
        <rs-filter name="requestId"></rs-filter>
        <rs-filter name="keyUserId"></rs-filter>
        <rs-filter name="crtTime"></rs-filter>
        <rs-filter name="recTime"></rs-filter>
      </rs-search>
      <rs-field name="id"></rs-field>
      <rs-field name="tenant.uname"></rs-field>
      <rs-field name="tenant.name" label="租户名称"></rs-field>
      <rs-field name="corpAppId"></rs-field>
      <rs-field name="suiteAppId"></rs-field>
      <rs-field name="eventType"></rs-field>
      <rs-field name="event"></rs-field>
      <rs-field name="source"></rs-field>
      <rs-field name="requestId"></rs-field>
      <rs-field name="keyUserId"></rs-field>
      <rs-field name="eventBody"></rs-field>
      <rs-field name="extendBody"></rs-field>
      <rs-field name="processResult"></rs-field>
      <rs-field name="processMessage"></rs-field>
      <rs-field name="processUser"></rs-field>
      <rs-field name="crtTime"></rs-field>
      <rs-field name="recTime"></rs-field>
    </rs-list>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
