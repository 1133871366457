<template>
  <div class="full-height product-form scroll">
    <rs-form name="product-type">
      <rs-field name="id"></rs-field>
      <rs-field name="name"></rs-field>
      <rs-field name="stockType"></rs-field>
      <rs-field name="stockUnit"></rs-field>
      <rs-field name="amountScale"></rs-field>
      <rs-field name="enable"></rs-field>
      <rs-field type="group" :label="$t_mdata('product-baseTab')"></rs-field>
      <rs-field type="group" :label="$t_mdata('product-classifyTab')"></rs-field>
      <rs-field type="group" :label="$t_mdata('product-tagTab')"></rs-field>
      <rs-field type="group" :label="$t_mdata('produt-specTab')">
        <rs-field name="specTypes"></rs-field>
      </rs-field>
      <rs-field type="group" :label="$t_mdata('product-skuTab')"></rs-field>
      <rs-toolbar>
        <rs-button name="cancel" action="mdata.productType._view"></rs-button>
      </rs-toolbar>
    </rs-form>
  </div>
</template>
<script>
import ModelService from "rsui-core/src/core/model-service";
export default {
  data: function() {
    return {
      modelService: new ModelService(),
    };
  },
  computed: {},
  methods: {
    beforeSubmit({ preventDefault }, params) {
      return {
        resolve: ({ response }) => {
          this.$rs_actionRefresh("mdata.productType._view");
        },
      };
    },
  },
};
</script>
