<template>
  <div class="full-height rs-page-pay-data-list q-pa-sm flex-column">
    <q-tabs v-model="activeTab" dense align="left" class=" bg-white text-black" :breakpoint="0" active-color="primary">
      <q-tab name="payOrderList" label="订单列表" />
      <q-tab name="payRefundList" label="退单列表" />
    </q-tabs>
    <q-tab-panels class="bg-white flex-grow" v-model="activeTab" animated keep-alive>
      <!-- 订单列表 -->
      <q-tab-panel name="payOrderList" class="no-padding">
        <rs-list
          ref="orderList"
          name="mock-payOrder"
          key="payOrderList"
          :loadDefault="false"
          :conditions="orderGroupCode ? [{ key: 'groupCode', vals: [orderGroupCode] }] : []"
          @obj-select-change="payOrderTenantChange"
        >
          <rs-search objSelectMode leftPanelWidth="205px" :objSelectProps="tenantProps">
            <rs-filter name="payState" :filterConf="{ selections: payStateList }"></rs-filter>
            <rs-filter name="wxAppId" front :filterConf="{ opKey: 'eq' }"></rs-filter>
            <rs-filter name="wxOpenId" front :filterConf="{ opKey: 'eq' }"></rs-filter>
            <rs-filter name="orderSn" front :filterConf="{ opKey: 'eq' }"></rs-filter>
          </rs-search>
          <rs-field name="groupCode"></rs-field>
          <rs-field name="siteCode"></rs-field>
          <rs-field name="wxAppId"></rs-field>
          <rs-field name="wxOpenId"></rs-field>
          <rs-field name="orderType"></rs-field>
          <rs-field name="orderSn"></rs-field>
          <rs-field name="orderAmount" align="right"></rs-field>
          <rs-field name="payState" v-slot="props">{{ payStateMap[props.payState] || props.payState }}</rs-field>
          <rs-field name="payFrom"></rs-field>
          <rs-field name="targetOrderId"></rs-field>
          <rs-field name="createTime" v-slot="props">
            {{ $rs_format(props.createTime * 1000, "YYYY-MM-DD HH:mm:ss") }}
          </rs-field>
          <rs-field name="payTime" v-slot="props">
            {{ $rs_format(props.payTime * 1000, "YYYY-MM-DD HH:mm:ss") }}
          </rs-field>
        </rs-list>
      </q-tab-panel>
      <!-- 退单列表 -->
      <q-tab-panel name="payRefundList" class="no-padding">
        <rs-list
          ref="refundList"
          name="mock-payRefund"
          key="payRefundList"
          :loadDefault="false"
          :conditions="refundGroupCode ? [{ key: 'groupCode', vals: [refundGroupCode] }] : []"
          @obj-select-change="payRefundTenantChange"
        >
          <rs-search objSelectMode :objSelectProps="tenantProps">
            <rs-filter name="refundState" :filterConf="{ selections: refundStateList }"></rs-filter>
            <rs-filter name="vPaySn" front :filterConf="{ opKey: 'eq' }"></rs-filter>
            <rs-filter name="vReturnSn" front :filterConf="{ opKey: 'eq' }"></rs-filter>
          </rs-search>
          <rs-field name="groupCode"></rs-field>
          <rs-field name="siteCode"></rs-field>
          <rs-field name="vPaySn"></rs-field>
          <rs-field name="vReturnSn"></rs-field>
          <rs-field name="refundAmount" align="right"></rs-field>
          <rs-field name="refundState" v-slot="props">
            {{ refundStateMap[props.refundState] || props.refundState }}
          </rs-field>
          <rs-field name="payFrom"></rs-field>
          <rs-field name="targetOrderId"></rs-field>
          <rs-field name="refundTime" v-slot="props">
            {{ $rs_format(props.refundTime * 1000, "YYYY-MM-DD HH:mm:ss") }}
          </rs-field>
          <rs-field name="refundResultRemark"></rs-field>
        </rs-list>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script>
import Config from "rsui-core/src/config";
export default {
  data() {
    return {
      activeTab: "payOrderList", // payOrderList: 订单列表; payRefundList: 退单列表
      tenantProps: {
        width: "150px",
        recName: "uname",
        modelName: "tenant",
        multiple: false,
        clearable: false,
        placeholder: "请选择租户",
      },
      orderGroupCode: null, // 订单列表选择的租户Code
      refundGroupCode: null, // 订单列表选择的租户Code
      // 支付状态
      payStateList: [
        { code: -1, name: "已过期" },
        { code: 0, name: "待支付" },
        { code: 1, name: "已支付" },
        { code: 2, name: "已通知" },
        { code: 3, name: "已分帐" },
        { code: 9, name: "关闭(MOP银联支付用)" },
      ],
      payStateMap: {}, // 列表字段映射
      // 退单状态
      refundStateList: [
        { code: 0, name: "未退" },
        { code: 1, name: "已退" },
      ],
      refundStateMap: {}, // 列表字段映射
    };
  },
  watch: {},
  created() {
    this.payStateMap = this.payStateList.reduce((map, d) => {
      map[d.code] = d.name;
      return map;
    }, {});
    this.refundStateMap = this.refundStateList.reduce((map, d) => {
      map[d.code] = d.name;
      return map;
    }, {});
  },
  computed: {},
  methods: {
    // 列表数据变化时，call外部接口返回数据
    beforeQuery(e, params, { model, key }) {
      e.preventDefault();
      const { page, size, conditions } = params[0];
      // 如果是订单列表
      if (model === "mock-payOrder" && key === "payOrderList") {
        if (!this.orderGroupCode) {
          this.$rs_error("请选择租户");
          return;
        }
        this.$refs.orderList.loading = true;
        this.$rs_post(Config.API.TEN_PAY_ORDER_LIST, {
          page,
          size,
          conditions,
          orderby: "order_id desc",
        })
          .then(
            (resp) => {
              if (resp?.content) {
                resp.content.list.forEach((d) => (d.id = d.orderId));
                this.$refs.orderList.onQueryrSuccess(resp.content);
                this.$refs.orderList.searchedConds = params[3];
              }
            },
            (e) => this.$rs_error(e?.msg || "接口错误"),
          )
          .finally(() => (this.$refs.orderList.loading = false));
      }
      // 如果是退单列表
      if (model === "mock-payRefund" && key === "payRefundList") {
        if (!this.refundGroupCode) {
          this.$rs_error("请选择租户");
          return;
        }
        this.$refs.refundList.loading = true;
        this.$rs_post(Config.API.TEN_PAY_REFUND_LIST, {
          page,
          size,
          conditions,
          orderby: "refund_id desc",
        })
          .then(
            (resp) => {
              if (resp?.content) {
                resp.content.list.forEach((d) => (d.id = d.refundId));
                this.$refs.refundList.onQueryrSuccess(resp?.content);
                this.$refs.refundList.searchedConds = params[3];
              }
            },
            (e) => this.$rs_error(e?.msg || "接口错误"),
          )
          .finally(() => (this.$refs.refundList.loading = false));
      }
    },
    // 订单列表切换租户
    payOrderTenantChange(e) {
      this.orderGroupCode = e.uname;
    },
    // 退单列表切换租户
    payRefundTenantChange(e) {
      this.refundGroupCode = e.uname;
    },
  },
};
</script>
